import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import { apFranActionApiTypes, IStore, ITableHeader } from 'types';
import { AP_FRAN_LOCALIZED_PREVIEW_LINK_OPTION_DATA, LINK_PREVIEW_HEADERS } from 'utils/constants';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { addLocalizeLinkURL } from 'actions';
import { Loading, VirtualTable } from 'components';

export const AddLocalizeLink = () => {
  const dispatch = useDispatch();

  const localizePreviewLinks = useSelector((state: IStore) => state.addPostFranchisorAction.localizePreviewLinks);
  const localizeLinkURL = useSelector((state: IStore) => state.addPostFranchisorAction.localizeLinkURL);
  const isPreviewLinkFetching = useSelector((state: IStore) => state.addPostFranchisorAction.isFetching);
  const isPreviewGenerated = useSelector((state: IStore) => state.addPostFranchisorAction.isPreviewGenerated);

  const dropdownOption = useMemo(() => {
    return AP_FRAN_LOCALIZED_PREVIEW_LINK_OPTION_DATA;
  }, []);

  const renderHeader = useCallback((headerObj: any) => {
    return <div>{headerObj?.label}</div>;
  }, []);

  const renderColumn = useCallback((rowObj: apFranActionApiTypes.IPreviewLink, header: ITableHeader<apFranActionApiTypes.IPreviewLink>) => {
    switch (header.identifier) {
      case 'url':
        return (
          <div>
            <a href={rowObj[header.identifier]} target="_blank" rel="noreferrer">
              {rowObj[header.identifier]}
            </a>
          </div>
        );
      default:
        return <div>{rowObj[header.identifier as keyof apFranActionApiTypes.IPreviewLink]}</div>;
    }
  }, []);

  const tableHeader = useMemo(() => {
    return LINK_PREVIEW_HEADERS.map((it) => ({
      identifier: `${it.value}`,
      labelString: `${it.label}`,
      headerClassName: ``,
      label: <div>{it.label}</div>,
      renderHeader,
      renderColumn,
      headerDropdownOptions: []
    }));
  }, [renderHeader, renderColumn]);

  return (
    <>
      {isPreviewLinkFetching && <Loading />}
      <div className="alc-item">
        <div className={`form-group form-field-item s fg-dropdown m-align-items cs-item cs-clean s-mb2`}>
          <CustomDropdown
            classNamePrefix="cs-select"
            id="localize-preview-link-fields"
            placeholder={'Select a field below to insert'}
            onChange={(event: any) => {
              const updatedLinkText = localizeLinkURL.concat(localizeLinkURL.charAt(localizeLinkURL.length - 1) === '/' || !localizeLinkURL ? event.value : `/${event.value}`);
              dispatch(addLocalizeLinkURL(updatedLinkText));
            }}
            value={null}
            options={dropdownOption}
          />
        </div>
        <div className="alc-item alc-input">
          <Form.Group className="form-group form-field-item">
            <input
              name="localize_url"
              type="text"
              className="form-control"
              placeholder="Localized Link"
              value={localizeLinkURL}
              onChange={(event) => {
                dispatch(addLocalizeLinkURL(event.target.value));
              }}
            />
          </Form.Group>
        </div>
        {localizeLinkURL && isPreviewGenerated ? (
          <>
            <div className="addlExamples">
              <h3>Here are few examples</h3>
              <div className="lb-analytics__tbl new-drpdwn">
                <VirtualTable<apFranActionApiTypes.IPreviewLink>
                  data={[localizePreviewLinks]}
                  headers={tableHeader}
                  tableClassName="responsiveTable table-cmn--skeleton"
                  ipadBoundary={767}
                  ipadColCount={2}
                  mobileColCount={2}
                  pinColumnsRight={[4]}
                  isFetching={isPreviewLinkFetching}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
