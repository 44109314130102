import { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';
import Tree /* , { TreeNode } */ from 'rc-tree';

// import { ImageValidation } from 'widgets/Media';
import { NoDataFoundProps } from 'utils/constants';
import { ITreeObj } from 'utils/helpers';
import { Loading, NoDataFound } from 'components';

interface IListCardProps {
  title: string;
  subtitle: string;
  treeData: ITreeObj[];
  rootClass: string;
  checkable?: boolean;
  checkedKeys?: { checked: string[]; halfChecked: string[] };
  onCheck?: (selectedObj: { checked: string[]; halfChecked: string[] }, e: any) => void;
  checkStrictly?: boolean;
}

export const ListCard = ({ title, subtitle, treeData, rootClass, checkable, checkedKeys, checkStrictly, onCheck }: IListCardProps) => {
  const treeRef = useRef(null);
  const [expandedKeys, setExpandedKeys] = useState<string[]>();
  const [search, setSearch] = useState<string>('');
  const [tmpTreeData, setTreeData] = useState<ITreeObj[]>([]);
  const [loading, showLoading] = useState(true);

  const getExpandedKeys = useCallback((treeData: ITreeObj[]): Array<string> => {
    const resultArr: string[] = [];
    treeData.forEach((treeDataResponse: ITreeObj) => {
      let childExpandedKeys: string[] = [];
      if (!treeDataResponse.isLeaf && treeDataResponse.children.length > 0) {
        resultArr.push(String(treeDataResponse.key));
        childExpandedKeys = getExpandedKeys(treeDataResponse.children);
      }
      if (childExpandedKeys.length > 0) {
        resultArr.push(...childExpandedKeys);
      }
    });
    return resultArr;
  }, []);

  const searchTree = useCallback((data: ITreeObj[], search: string): ITreeObj[] => {
    const resultArr: ITreeObj[] = [];
    data.forEach((searchTreeResponse: ITreeObj) => {
      if (searchTreeResponse.title.toLowerCase().indexOf(search.toLowerCase()) > -1) {
        resultArr.push({ ...searchTreeResponse });
      } else {
        let matchedChildren: ITreeObj[] = [];
        if (searchTreeResponse.children.length > 0) {
          matchedChildren = searchTree(searchTreeResponse.children, search);
        }
        if (matchedChildren.length > 0) {
          resultArr.push({ ...searchTreeResponse, children: matchedChildren });
        }
      }
    });
    return resultArr;
  }, []);

  useLayoutEffect(() => {
    setExpandedKeys(getExpandedKeys(treeData));
  }, [treeData, getExpandedKeys]);

  useEffect(() => {
    if (!search) {
      setTreeData(treeData);
    } else {
      const filteredTree = searchTree(treeData, search.trim());
      setTreeData(filteredTree);
    }
  }, [search, treeData, searchTree]);

  /* const treeNodeRender = (treeList: ITreeObj[], index = 0, jsxArr: Array<any> = []): Array<any> => {
    if (index === treeList.length) {
      return jsxArr;
    }
    if (treeList[index].children.length > 0) {
      jsxArr.push(
        <TreeNode
          key={treeList[index].key}
          title={treeList[index].title}
          icon={<ImageValidation isImgValid defaultImg={'hub-grey'} customName="Hub" customClassname="tree-hub" />}
          className={`${treeList[index].key && treeList[index].type === 'hub' ? 'tree-franchisor' : 'tree-location'}`}
        >
          {treeNodeRender(treeList[index].children, 0, [])}
        </TreeNode>
      );
    } else {
      jsxArr.push(
        <TreeNode
          key={treeList[index].key}
          title={treeList[index].title}
          icon={<ImageValidation isImgValid defaultImg={'location-grey'} customName="Location" customClassname="tree-location" />}
          className={`${treeList[index].key && treeList[index].type === 'hub' ? 'tree-franchisor' : 'tree-location'}`}
        />
      );
    }
    return treeNodeRender(treeList, index + 1, jsxArr);
  }; */

  useEffect(() => {
    showLoading(false);
  }, []);

  return (
    <div className={`${rootClass} cas-item cas-left`}>
      {/* <h3 className="cas-head"> */}
      <h3 className="glbl__title--txt">
        {title}
        <span className="cas__sub--title">{subtitle}</span>
      </h3>
      <div className="cas-tree tree-add">
        <div className="cas-search">
          <div className="simple-search">
            <input type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} />
          </div>
        </div>
        <div className="casb">
          {tmpTreeData?.length > 0 ? (
            <Tree
              ref={treeRef}
              showIcon
              expandedKeys={expandedKeys}
              checkable={checkable}
              // switcherIcon={(props: { eventKey: string; [key: string]: any}) => +getLocationDetails(props.eventKey)?.child_count ? <ImageValidation isImgValid defaultImg={'arw-ddwn'} customName="Arrow" customClassname="switch-arr" /> : null}
              onExpand={(clickedGroup: string[]) => {
                setExpandedKeys(clickedGroup);
              }}
              checkedKeys={checkedKeys}
              onCheck={onCheck}
              checkStrictly={checkStrictly}
              treeData={tmpTreeData}
              selectable={false}
            />
          ) : loading && !search ? (
            <Loading />
          ) : (
            <NoDataFound size={NoDataFoundProps.SMALL} />
          )}
        </div>
      </div>
    </div>
  );
};
