import ReactTags, { Tag } from 'react-tag-autocomplete';

import { commonWidgetTypes } from 'types';

export const CustomReactTags = ({
  tags,
  suggestions,
  handleAddition,
  handleDelete,
  inputAttributes,
  handleInputChange,
  placeholder,
  autofocus = false,
  classNames,
  suggestionsFilter,
  allowNew,
  maxSuggestionsLength,
  minQueryLength
}: commonWidgetTypes.ICustomReactTags) => (
  <ReactTags
    tags={tags}
    suggestions={suggestions}
    onAddition={(tag: Tag) => handleAddition(tag)}
    onDelete={(index: number) => {
      if (handleDelete) {
        handleDelete(index);
      }
    }}
    inputAttributes={inputAttributes}
    onInput={(search: string) => handleInputChange(search)}
    placeholderText={placeholder}
    autofocus={autofocus}
    classNames={classNames}
    suggestionsFilter={suggestionsFilter}
    allowNew={allowNew}
    maxSuggestionsLength={maxSuggestionsLength}
    minQueryLength={minQueryLength}
  />
);
