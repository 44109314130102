import { put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  exceptionHandlerRequest,
  communicationsListGetFailure,
  communicationsListGetResponse,
  communicationCreateUpdateResponse,
  communicationCreateUpdateFailure,
  logoutRequest,
  communicationDeleteResponse,
  communicationDeleteFailure,
  communicationMarkAsSeenResponse,
  communicationMarkAsSeenFailure
} from 'actions';
import { commonApiTypes } from 'types';
import { apiCall, defaultHeader, API, HttpMethods } from 'utils/helpers';
import {
  ALERT_BOX_TYPES,
  COMMUNICATION_CREATE_FAILURE_MSG,
  COMMUNICATION_CREATE_SUCCESS_MSG,
  COMMUNICATION_DELETE_FAILURE_MSG,
  COMMUNICATION_DELETE_SUCCESS_MSG,
  COMMUNICATION_UPDATE_FAILURE_MSG,
  COMMUNICATION_UPDATE_SUCCESS_MSG,
  FRANCHISORS_EXCEPTION_ERROR_MSG,
  SETTINGS_FAILURE_MSG,
  SETTINGS_SUCCESS_MSG,
  USER_OWNERSHIP
} from 'utils/constants';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendGetCommunicationListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj: { apiPath: string; action: HttpMethods } =
      payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { ...API.getBrandLevelCommunicationsList } : { ...API.getLocationLevelCommunicationsList };
    const replacedPath = urlPathObj.apiPath.replace(':id', payload.id);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(communicationsListGetResponse(response.data.communications));
      if (urlPathObj.action === HttpMethods.PUT) {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, SETTINGS_SUCCESS_MSG);
      }
    } else {
      yield put(communicationsListGetFailure(response.data.error));
      if (urlPathObj.action === HttpMethods.PUT) {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, SETTINGS_FAILURE_MSG);
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(FRANCHISORS_EXCEPTION_ERROR_MSG));
  }
}

function* sendCommunicationCreateUpdateRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      // subject: payload.subject,
      body: payload.body,
      account_ids: payload.account_ids,
      send_to_all_accounts: payload.send_to_all_accounts,
      send_email_notification: payload.send_email_notification
    };
    const urlPathObj: { apiPath: string; action: HttpMethods } = payload.id ? { ...API.updateCommunication } : { ...API.createCommunication };
    const replacedPath = payload?.id ? urlPathObj.apiPath.replace(':id', payload.id) : urlPathObj.apiPath.replace(':franchisor_id', payload.franchisor_id);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, data, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        communicationCreateUpdateResponse({
          communication: response.data.communications,
          message: urlPathObj.action === HttpMethods.PUT ? COMMUNICATION_UPDATE_SUCCESS_MSG : COMMUNICATION_CREATE_SUCCESS_MSG
        })
      );
      if (urlPathObj.action === HttpMethods.PUT) {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, COMMUNICATION_UPDATE_SUCCESS_MSG);
      } else {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, COMMUNICATION_CREATE_SUCCESS_MSG);
      }
    } else {
      yield put(communicationCreateUpdateFailure(response.data.error));
      if (urlPathObj.action === HttpMethods.PUT) {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, COMMUNICATION_UPDATE_FAILURE_MSG);
      } else {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, COMMUNICATION_CREATE_FAILURE_MSG);
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(FRANCHISORS_EXCEPTION_ERROR_MSG));
  }
}

function* sendCommunicationDeleteRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj: { apiPath: string; action: HttpMethods } = { ...API.deleteCommunication };
    const replacedPath = urlPathObj.apiPath.replace(':id', payload);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(communicationDeleteResponse(COMMUNICATION_DELETE_SUCCESS_MSG));
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, COMMUNICATION_DELETE_SUCCESS_MSG);
    } else {
      yield put(communicationDeleteFailure(response.data.error));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, COMMUNICATION_DELETE_FAILURE_MSG);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(FRANCHISORS_EXCEPTION_ERROR_MSG));
  }
}

function* sendCommunicationMarkAsSeenRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj: { apiPath: string; action: HttpMethods } = { ...API.markAsSeenCommunication };
    const replacedPath = urlPathObj.apiPath.replace(':id', payload);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(communicationMarkAsSeenResponse(response.data.communication));
    } else {
      yield put(communicationMarkAsSeenFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(FRANCHISORS_EXCEPTION_ERROR_MSG));
  }
}

export function* takeCommunicationRequest() {
  yield takeLatest(types.COMMUNICATION_GET_LIST_REQUEST, sendGetCommunicationListRequest);
  yield takeLatest(types.COMMUNICATION_CREATE_UPDATE_REQUEST, sendCommunicationCreateUpdateRequest);
  yield takeLatest(types.COMMUNICATION_DELETE_REQUEST, sendCommunicationDeleteRequest);
  yield takeLatest(types.COMMUNICATION_MARK_AS_SEEN_REQUEST, sendCommunicationMarkAsSeenRequest);
}
