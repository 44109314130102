import { Loading } from 'components';
import { aiContentCreatorContainerTypes } from 'types';
import { AI_CAPTION_RESULTS_MESSAGE } from 'utils/constants';

export const AiResponseSuggestions = ({ handleCancel, handleSuccess, isFetching, data, selectedTextObj, handleUse }: aiContentCreatorContainerTypes.IAiResponseSuggestions) => {
  const PostContent = ({ content, index }: aiContentCreatorContainerTypes.IContentResults) => {
    return (
      <div className={`ai-picks aitc-item ${selectedTextObj.index === index ? 'active' : ''} `}>
        <div className="ait-hover">
          <button
            className="gnrc-btn turquoise"
            onClick={() => {
              const state = index === selectedTextObj.index ? { index: null, text: '' } : { index, text: content };
              handleUse(state);
            }}
          >
            <span>{selectedTextObj.index === index ? 'Un-use' : 'Use'}</span>
          </button>
        </div>
        <div className="ait-front">
          <span className="aitc-count">{index + 1}</span>
          <p>{content}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="common-ai--wrp ai-wrap">
      <div className="auto-btns">
        <button className="gnrc-btn trans-gnr ac-btn" onClick={handleCancel}>
          <span>Cancel</span>
        </button>
        {selectedTextObj.index !== null && (
          <button className="gnrc-btn turquoise ac-btn" onClick={handleSuccess}>
            <span>Done</span>
          </button>
        )}
      </div>
      <div className="ai-bot-results">
        {isFetching && <Loading />}
        {data.length ? (
          <div className="ai-br-tabs">
            <div className="ai-brt-head">
              <h3>AI Results</h3>
            </div>
            <div className="ai-brt-cg">
              <div className="aitc ai-tab-contents">
                {data?.length ? (
                  data.map((it, index) => <PostContent content={it} index={index} key={`${it}_reviews_${index}`} />)
                ) : (
                  <span className="ai-no-results">{AI_CAPTION_RESULTS_MESSAGE}</span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <span className="ai-no-results">{isFetching ? 'Loading...' : AI_CAPTION_RESULTS_MESSAGE}</span>
        )}
      </div>
    </div>
  );
};
