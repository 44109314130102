import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';

import { config } from 'config';
import { ImageValidation } from 'widgets/Media';
import { adminAreaContainerTypes, IStore, loginReducerType } from 'types';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { adminUserDetailsValidation } from 'utils/helpers';
import { USER_DETAILS_FILEDS, ALERT_BOX_TYPES } from 'utils/constants';
import { updateAAUserDetailsRequest, updateAAUserDetailsMsgReset, addAAUserInviteMailRequest, aaUserDetailsDataReset, getAAUserDetailsRequest } from 'actions';
import { alertBoxCall, Loading } from 'components';

export const AdminUserDetailModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { optionalParams, currentRoute } = useAccountSwitcherData();

  const { queryParams } = useParamsDeconstructor({ text: '' });

  const isUserDetailFetching = useSelector((state: IStore) => state.adminArea.userDetailObj.isUserDetailFetching);
  const userDetails = useSelector((state: IStore) => state.adminArea.userDetailObj.userDetails);
  const isUpdateUserDetails = useSelector((state: IStore) => state.adminArea.userDetailObj.isUpdateUserDetails);
  const updateUserSuccessMsg = useSelector((state: IStore) => state.adminArea.userDetailObj.updateUserSuccessMsg);
  const isSendInviteEmail = useSelector((state: IStore) => state.adminArea.userDetailObj.isSendInviteEmail);
  const updateUserFailMsg = useSelector((state: IStore) => state.adminArea.userDetailObj.updateUserFailMsg);

  const [isEditEmail, setIsEditEmail] = useState(false);

  useEffect(() => {
    if (+optionalParams[0]) dispatch(getAAUserDetailsRequest(+optionalParams[0]));
  }, [optionalParams[0]]); // eslint-disable-line

  const { values, touched, errors, setFieldValue, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      name: `${userDetails?.first_name} ${userDetails?.last_name}`,
      email: userDetails?.email || ''
    },
    validationSchema: adminUserDetailsValidation,
    enableReinitialize: true,
    onSubmit: (values: adminAreaContainerTypes.IUserDetailsFieldValue) => {
      if (userDetails?.id && values.email) {
        dispatch(updateAAUserDetailsRequest(getUpdatedUserDetailsData(userDetails)));
      }
    }
  });

  useEffect(() => {
    if (updateUserSuccessMsg) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, updateUserSuccessMsg);
      dispatch(updateAAUserDetailsMsgReset());
      handleUserEmailEdit(false);
    }
    if (updateUserFailMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, updateUserFailMsg);
      dispatch(updateAAUserDetailsMsgReset());
      setFieldValue(USER_DETAILS_FILEDS.EMAIL, userDetails?.email || '');
      handleUserEmailEdit(false);
    }
  }, [updateUserSuccessMsg, updateUserFailMsg]); // eslint-disable-line

  const getUpdatedUserDetailsData = (userData: loginReducerType.ILoginResponseUser) => {
    return {
      id: userData.id,
      admin: userData.admin,
      company_name: userData.company_name,
      content_writer: userData.content_writer,
      detail_id: userData.detail_id,
      direct_message_notification: userData.direct_message_notification,
      disable_scheduled_posting: false,
      email: values.email,
      first_name: userData.first_name,
      franchisor: userData.franchisor,
      franchisor_group_user: userData.franchisor_group_user,
      has_facebook_boost_access: userData.has_facebook_boost_access,
      last_name: userData.last_name,
      mobile_phone: userData.mobile_phone,
      profile_photo_cloudinary_id: userData.profile_photo_cloudinary_id,
      profile_photo_url: userData.profile_photo_url,
      rallio_local_assets_notification_enrolled_at: userData.rallio_local_assets_notification_enrolled_at,
      receive_monthly_enduser_report: userData.receive_monthly_enduser_report,
      receive_notification_emails: userData.receive_notification_emails,
      review_alert_ratings: userData.review_alert_ratings,
      rlc_account: true,
      skip_reconfirmation: true
    };
  };

  const getClassNames = (valueItem: keyof adminAreaContainerTypes.IUserDetailsFieldValue) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] !== '' && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'error-disp' : ''}`;
    return className;
  };

  const handleChange = (key: string, value: string) => {
    setFieldValue(key, value);
  };

  const handleSendInviteEmail = () => {
    if (userDetails?.id) dispatch(addAAUserInviteMailRequest(userDetails?.id));
  };

  const disableSaveAction = () => {
    if (isUserDetailFetching) return true;
    else if (userDetails?.email === values.email) return true;
    else if (isUpdateUserDetails) return true;
    else return false;
  };

  const handleUserEmailEdit = (isEdit: boolean) => setIsEditEmail(isEdit);

  const handleModalClose = () => {
    dispatch(aaUserDetailsDataReset());
    navigate({ pathname: `/${currentRoute}`, search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  return (
    <Modal
      className={`prime-modal apmodal pmisorModal mgFavTagsModal op-userlogin admn-usr-dtls`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show
      animation={false}
      onHide={handleModalClose}
    >
      <Modal.Body>
        <div className="userlogin-addnewuser-wrp userlogin-addnewuser__main">
          <div className="card-header--wrp">
            <h2>User Details</h2>
            <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName="close" onClick={handleModalClose} />
          </div>
          {isUserDetailFetching ? (
            <Loading isSmall />
          ) : (
            <div className="addnewuser-modal-content">
              <div className={`form-group firstname`}>
                <input
                  type="text"
                  autoComplete="off"
                  name={USER_DETAILS_FILEDS.NAME}
                  className={getClassNames(USER_DETAILS_FILEDS.NAME)}
                  value={values.name}
                  title={values.name}
                  readOnly
                  onBlur={handleBlur}
                />
                <span className="fltlabels">Name</span>
              </div>
              <div className={`form-group email`}>
                <input
                  type="text"
                  autoComplete="off"
                  name={USER_DETAILS_FILEDS.EMAIL}
                  className={getClassNames(USER_DETAILS_FILEDS.EMAIL)}
                  value={values.email}
                  title={values.email}
                  readOnly={!isEditEmail}
                  onBlur={handleBlur}
                  onChange={(event) => handleChange(event.target.name, event.target.value)}
                />
                {!isEditEmail && (
                  <div className="edit-icon-media">
                    <ImageValidation isImgValid defaultImg="create_post-edit" customName="edit-user-email" onClick={() => handleUserEmailEdit(true)} />
                  </div>
                )}
                <span className="fltlabels">Enter Email</span>
              </div>
            </div>
          )}
        </div>
        <div className="modal-btn-grp-wraps ap-actions send-setup">
          <Button className={`primeblueaction btn btn-primary${isUserDetailFetching || isSendInviteEmail ? ` pointer-events-none` : ``}`} onClick={() => handleSendInviteEmail()}>
            <div className="send-set-icon">
              <img src={`${config.cdnImgUrl}send-blue.svg`} alt="send" />
            </div>
            <span>Send Setup Wizard</span>
          </Button>
        </div>
        <div className={`modal-btn-grp-wraps save-user-details ap-actions${disableSaveAction() ? ` pointer-events-none` : ``}`}>
          <Button
            className={`primeblueaction btn btn-primary`}
            type="submit"
            variant="primary"
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent> | any) => {
              handleSubmit(event);
            }}
          >
            {' '}
            {isUpdateUserDetails ? <Spinner animation="border" variant="primary" /> : 'Save'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
