import { scApiTypes, scContainerTypes, scReducerTypes } from 'types';
import { FeedValue, PAGINATION_PER_PAGE, SIDE_NAV_NAME, USER_OWNERSHIP, COMMON_SOCIAL_PROVIDER_TYPE, INBOX_DELETE_FAILED_MSG } from 'utils/constants';

/* Social contents - list state update */
export const updateScList = (currentPageCount: number, previousList: scApiTypes.ISCList[], currentList: scApiTypes.ISCList[]) => {
  return currentPageCount > 1 ? previousList.concat(currentList) : currentList;
};

/* Social contents - like state update */
export const updateScLike = (socialContentList: scApiTypes.ISCList[], likeResObj: scApiTypes.ISCLikeResponse) => {
  return socialContentList.map((item) => (item.id === likeResObj?.id ? { ...item, liked: likeResObj.liked } : item));
};

/* Social contents - reply state update */
export const updateScReply = (socialContentList: scApiTypes.ISCList[], replyResObj: scApiTypes.ISCReplyResponse) => {
  return socialContentList.map((item) => (item.id === replyResObj?.socialContentId ? { ...item, replied: replyResObj.replied } : item));
};

/* Social contents - delete state update */
export const updateScDelete = (socialContentList: scApiTypes.ISCList[], socialContentId: number) => {
  return socialContentList.filter((data) => data.id !== socialContentId);
};

/* Social contents - stats state update for delete */
export const updateScStats = (socialContentStats: scReducerTypes.ISCStatsValue, platform?: null | string): scReducerTypes.ISCStatsValue => {
  return {
    totalStatCount: platform ? socialContentStats.totalStatCount - 1 : socialContentStats.totalStatCount,
    facebook: platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? socialContentStats.facebook - 1 : socialContentStats.facebook,
    twitter: platform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ? socialContentStats.twitter - 1 : socialContentStats.twitter,
    instagram:
      platform && [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(platform)
        ? socialContentStats.instagram - 1
        : socialContentStats.instagram,
    linkedin: platform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ? socialContentStats.linkedin - 1 : socialContentStats.linkedin,
    tiktok: platform === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? socialContentStats.tiktok - 1 : socialContentStats.tiktok
  };
};

/* Social contents - common payload */
export const scCommonRequestPayload = ({ dateRange, subNavPageName }: scContainerTypes.ISCCommonReqPayloadProps) => {
  return {
    feed: subNavPageName === SIDE_NAV_NAME.COMMUNITY_INBOX ? FeedValue.INBOX : FeedValue.OUTBOX,
    date_range: dateRange,
    ...(subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX ? { in_sandbox: 1 } : null)
  };
};

/* Social contents franchisor - location filter for stats and list payload */
export const franchisorLocationFilter = ({ id, queryParams, subNavPageName, topLevelFranchisorId }: scContainerTypes.ISCFranchisorLoctionFilter) => {
  if (queryParams?.location_id) {
    return { account_id: +queryParams?.location_id };
  } else if (queryParams?.hub_id) {
    return { ancestor_franchisor_id: +queryParams?.hub_id };
  } else if (queryParams?.location_list_id) {
    return { ancestor_franchisor_id: id, account_list_id: +queryParams?.location_list_id };
  } else {
    return { ancestor_franchisor_id: subNavPageName === SIDE_NAV_NAME.REPUTATION_OUTBOX ? id : topLevelFranchisorId };
  }
};

/* Social contents account - stats and list payload */
export const accountLocationFilter = ({ id, subNavPageName, topLevelFranchisorId }: scContainerTypes.ISCAccountLoctionFilter) => {
  if (subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX) {
    return { ancestor_franchisor_id: topLevelFranchisorId };
  } else {
    return { account_id: id };
  }
};

/* Social contents - stats payload */
export const scStatsRequestPayload = ({ id, userOwnership, dateRange, subNavPageName, queryParams, topLevelFranchisorId }: scContainerTypes.ISCStatsReqPayloadProps): scApiTypes.ISCStatsReqPayload => {
  const locationFilter = () => {
    if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
      return accountLocationFilter({ id, subNavPageName, topLevelFranchisorId });
    } else if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      return franchisorLocationFilter({ id, queryParams, subNavPageName, topLevelFranchisorId });
    }
  };
  return {
    ...scCommonRequestPayload({ dateRange, subNavPageName }),
    ...locationFilter()
  };
};

/* Social contents - list payload */
export const scListRequestPayload = ({
  id,
  userOwnership,
  page,
  dateRange,
  subNavPageName,
  queryParams,
  topLevelFranchisorId,
  automaticallyRefreshActivityFeed
}: scContainerTypes.ISCListReqPayloadProps): scApiTypes.ISCListReqPayload => {
  const locationFilter = () => {
    if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
      return accountLocationFilter({ id, subNavPageName, topLevelFranchisorId });
    } else if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      return franchisorLocationFilter({ id, queryParams, subNavPageName, topLevelFranchisorId });
    }
  };
  return {
    page,
    per: PAGINATION_PER_PAGE,
    ...scCommonRequestPayload({ dateRange, subNavPageName }),
    ...locationFilter(),
    ...(queryParams?.engagement && { sociable_type: queryParams?.engagement }),
    ...(queryParams?.platform && { platform: queryParams?.platform }),
    ...(queryParams?.posts && { top_performing: 1 }),
    automaticallyRefreshActivityFeed
  };
};

export const scMessages = (pageType: string) => {
  return {
    SC_FETCHING_LIST_FAILED: `Fetching ${pageType} list failed!`,
    SC_FETCHING_STATS_FAILED: `Fetching ${pageType} Stats failed!`,
    SC_DELETE_ASSET_FAILED: pageType === SIDE_NAV_NAME.COMMUNITY_INBOX ? INBOX_DELETE_FAILED_MSG : `Delete ${pageType} post failed!`,
    SC_LIKE_FAILED: `${pageType} like failed!`,
    SC_REUSE_FAILED: `${pageType} resue failed!`,
    SC_REPLY_FAILED: `${pageType} reply failed!`
  };
};
