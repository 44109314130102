import { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router';
import parse from 'html-react-parser';

import { calendarContainerTypes, IStore, calendarApiTypes } from 'types';
import { CarousalImages, ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import {
  calendarConvertDateBasedOnTimeZone,
  convertLocationTimezone,
  calendarInitScheduledTime,
  dateObjBasedOnTimeZone,
  getTimezoneAbbreviation,
  getFormatBasedOnExactDate,
  getPermittedImgsBasedOnPlatform,
  convertURLAsLink,
  checkSiteRestriction,
  canEditScheduledPostDateAndTime,
  calendarUTCtoLocalHandler
} from 'utils/helpers';
import { useAvailablePlatforms, useAccountSwitcherData, useParamsDeconstructor, useSiteRestriction } from 'utils/hooks';
import {
  LOCATION_TIME_ZONE_DATE_TIME_FORMAT,
  EMAIL_DATE_FORMAT,
  CALENDAR_DATE_FORMAT,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  RIPPLE_COLOR,
  AP_SAVED_POST_INIT_DETAILS,
  POSTS_INIT_FILTER_DATA,
  CALENDAR_EVENT_CHECK_BOX,
  CALENDAR_EVENT_DATE_FORMAT,
  SCHEDULE_PARAM_TYPE,
  COMMON_SOCIAL_PROVIDER_TYPE,
  ALERT_BOX_TYPES,
  CONNECT_SOCIAL_MEDIA_TO_APPROVE_THE_POST,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  USER_OWNERSHIP
} from 'utils/constants';
import { PLATFORM_FILTER } from 'analytics/utils/constants';
import { addPostUpdateSavedPostDetails, calendarDeleteModalOption, calendarLocationUpdates, updateCalendarItemRequest } from 'actions';
import { CalendarIconHandler } from './CalendarIconHandler';
import { Paragraph } from 'widgets/Text';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { FBCallToActionPreview, Loading, alertBoxCall } from 'components';

export const CalenderFilterModal = ({
  editId,
  calenderViewData,
  displayType,
  editClickHandler,
  setScheduleModal,
  handleOnChangePostContent,
  isEventListData,
  isButtonsEnabled,
  canEditAccountPost,
  canApprove
}: calendarContainerTypes.ICalendarFilterModalTypes) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, userOwnership, currentRoute } = useAccountSwitcherData();
  const { calendarRestriction } = useSiteRestriction();
  const { queryParams } = useParamsDeconstructor({ ...POSTS_INIT_FILTER_DATA });

  const availablePlatform = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false).filter((it) => it.value !== 'yelp');

  const timeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');
  const { savedPostDetails } = useSelector((state: IStore) => state.addPostAccount);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const overriddenCorporatePostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_corporate_posts_locked || false);
  const accountName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const isFetching = useSelector((state: IStore) => state.calendar.isFetching);
  const isStreamEnded = useSelector((state: IStore) => state.calendar.isStreamEnded);

  const [statePlatform, setStatePlatform] = useState(calenderViewData?.dataObj?.find((it: calendarContainerTypes.IDataObjType) => it.isEnabled)?.label || '');
  const isHubUser = USER_OWNERSHIP.FRANCHISOR === userOwnership;
  const isLocationUser = USER_OWNERSHIP.ACCOUNT === userOwnership;
  const isHideApprove = !isHubUser ? checkSiteRestriction(USER_OWNERSHIP.ACCOUNT, menuAccess, 'calendar/awaiting_approval') : false;
  const canEditAccountDate =
    !isHubUser && calenderViewData?.id
      ? canEditScheduledPostDateAndTime(calenderViewData.isCorporatePost, isFranchisee, overriddenCorporatePostsLocked, calenderViewData?.abilities?.can_edit_schedule)
      : false;

  const contentText = calenderViewData?.dataObj
    ? !statePlatform
      ? calenderViewData?.dataObj?.find((it: calendarContainerTypes.IDataObjType) => it.isEnabled)
      : calenderViewData?.dataObj?.find((it: calendarContainerTypes.IDataObjType) => it.isEnabled && it.label === statePlatform)
    : null;

  useEffect(() => {
    if (calenderViewData?.dataObj?.length) setStatePlatform(contentText?.label);
  }, [calenderViewData?.dataObj, contentText?.label]);

  const photoUrls = getPermittedImgsBasedOnPlatform(
    statePlatform,
    statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? calenderViewData?.instagramImageUrls || [] : calenderViewData?.imageUrls || []
  );

  const calenderEventUpdateHandler = (dataObj: calendarApiTypes.ICalendarItemsResponse, type: string) => {
    const payload = { ...dataObj, [`${type}`]: !dataObj[type] };
    dispatch(updateCalendarItemRequest(payload));
  };

  const onClose = () => {
    navigate({
      pathname: `/${userOwnership}/${id.toString()}/${currentRoute}${displayType !== 'all' ? `/${displayType}` : ''}`,
      search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ``
    });
  };

  const handleDeleteModal = () => {
    dispatch(calendarDeleteModalOption({ isOpenDeleteModal: true, isConfirmDelete: false }));
    dispatch(calendarLocationUpdates({ type: SCHEDULE_PARAM_TYPE.HIDDEN, bool: true, data: calenderViewData }));
  };

  return (
    <Modal show className="calendar-section-main-wrp cal-preview-social-view-modal-section global-modal--wrp" centered size="lg" onHide={onClose}>
      <div className="mod__close--icon">
        <ImageValidation defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={onClose} />
      </div>

      <Modal.Body>
        {isFetching || !isStreamEnded ? (
          <Loading />
        ) : !calenderViewData && !isFetching && isStreamEnded ? (
          <Navigate to={`/${userOwnership}/${id.toString()}/content/calendar`} />
        ) : !isEventListData ? (
          <div className="liv-right list-item">
            <div className="header-section">
              <div className="left-section">
                <div className="p-header">
                  <div className="p-header-title">
                    <div className="p-avatar">
                      <ImageValidation
                        imgUrl={calenderViewData?.avatarImg || ''}
                        defaultImg={'user-avatar-common.png'}
                        isNotSvgFormat
                        customClassname={'social-media-post'}
                        customName={'calendar user profile img'}
                      />
                    </div>
                    <div className="p-title">
                      <h5>{accountName}</h5>
                      <h6>
                        {isHubUser
                          ? `@ ${
                              calenderViewData?.post_now
                                ? convertLocationTimezone(calenderViewData?.scheduledTime, CALENDAR_DATE_FORMAT)
                                : calendarUTCtoLocalHandler(calenderViewData?.scheduledTime, CALENDAR_DATE_FORMAT)
                            }`
                          : `@${calendarConvertDateBasedOnTimeZone(timeZone, calenderViewData?.scheduledTime, LOCATION_TIME_ZONE_DATE_TIME_FORMAT, true)}`}
                      </h6>
                    </div>
                    <CalendarIconHandler
                      isBoosted={statePlatform === 'facebook' && Boolean(calenderViewData?.isBoosted)}
                      isAwaiting={!isHubUser ? calenderViewData?.draft : false}
                      isCampaign={calenderViewData?.campaign}
                    />
                  </div>
                </div>
              </div>
              <div className="right-section">
                <div className="fltr-imc selectsocial">
                  {calenderViewData?.dataObj?.map((it: calendarContainerTypes.IDataObjType, index: number) => {
                    const postPlatforms = availablePlatform.find((data) => it.isEnabled && data.value === it?.label)?.platformImg;
                    return (
                      postPlatforms && (
                        <button key={index} type="button" className={`roundedbtn btn btn-link img-siz${statePlatform === it?.label ? ` active` : ``}`} onClick={() => setStatePlatform(it?.label)}>
                          <ImageValidation isImgValid defaultImg={postPlatforms} customName={it?.label} />
                        </button>
                      )
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="desc-text">
              <Paragraph
                customClassname="post-message"
                facebookTagList={contentText?.tag ? contentText?.tag : []}
                customText={contentText?.personalizedText ? contentText?.personalizedText?.trim() : contentText?.text?.trim() || ''}
                actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
              />
            </div>

            <div className="am-media-preview">
              {photoUrls?.length && !calenderViewData?.linkUrl ? (
                photoUrls?.length ? (
                  <CarousalImages key={`calendar-detail${calenderViewData?.id}-${statePlatform}`} imagesList={photoUrls} showThumbs={false} showArrows />
                ) : null
              ) : calenderViewData?.video ? (
                <div>
                  {' '}
                  {/*className='video-outer-element' */}
                  <ReactVideoPlayer videoUrl={calenderViewData?.video || ''} light={calenderViewData?.video_thumbnail_url} customClassname={'full-width'} />
                </div>
              ) : null}
            </div>
            {(calenderViewData?.linkTitle || calenderViewData?.linkUrl || calenderViewData?.linkDescription) && statePlatform !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? (
              [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN].includes(statePlatform) ? (
                <FBCallToActionPreview
                  imageUrl={calenderViewData?.linkPreviewPhoto || photoUrls[0] || null}
                  title={calenderViewData?.linkTitle || ''}
                  linkUrl={calenderViewData?.linkUrl || ''}
                  description={calenderViewData?.linkDescription || ''}
                  actionType={calenderViewData?.facebook_call_to_action_type || ''}
                  socialMedia={statePlatform}
                  isLinkCustomized={calenderViewData?.link_preview_customized || false}
                />
              ) : (
                <div className="coupon-link-preview-wrp">
                  <div className="grid-section">
                    <div className="left-section flex-removed">
                      {calenderViewData?.linkPhotos?.length || photoUrls[0] ? (
                        <div className="img-thumb">
                          <ImageValidation imgUrl={calenderViewData?.linkPreviewPhoto || photoUrls[0]} customName="Link preview" />
                        </div>
                      ) : null}
                    </div>
                    <div className="right-section flex-fullview">
                      <div className="learn-more-section b-r-none">
                        <div className="url-head">{calenderViewData?.linkUrl}</div>
                        <a href={calenderViewData?.linkUrl} target="_blank" rel="noopener noreferrer">
                          <div className="url-subhead-txt">{calenderViewData?.linkTitle}</div>
                        </a>
                        <Paragraph customText={calenderViewData?.linkDescription} />
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : null}
            {statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && calenderViewData.google_call_to_action_url && calenderViewData.google_call_to_action_type && (
              <div className="insta-social-sec-wrp">
                <div className="lefticon">
                  {parse(
                    convertURLAsLink(
                      calenderViewData.google_call_to_action_url,
                      GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === calenderViewData.google_call_to_action_type)?.label || ''
                    )
                  )}
                </div>
              </div>
            )}

            {/* --------------------------LOCATION USER----------------------------- */}
            {!isHubUser ? (
              <>
                <div className={`header-section cal-mt-btns ${isButtonsEnabled ? '' : 'pointer-events-none'}`}>
                  <div className="left-section">
                    {!calendarRestriction.schedule && (
                      <div
                        className={`remove-btn-cal ${!canEditAccountDate ? 'pointer-events-none' : ''}`}
                        onClick={() => {
                          setScheduleModal(true);
                          dispatch(
                            addPostUpdateSavedPostDetails({
                              ...AP_SAVED_POST_INIT_DETAILS,
                              schedule_date_time: calendarInitScheduledTime(timeZone, calenderViewData?.scheduledTime),
                              account_schedule_date_time: dateObjBasedOnTimeZone(timeZone, calenderViewData?.start_date)
                            })
                          );
                        }}
                      >
                        <span className="inner-text">
                          <>{calendarConvertDateBasedOnTimeZone(timeZone, calenderViewData?.scheduledTime, EMAIL_DATE_FORMAT, true)}</>
                        </span>
                        <span className="inner-next-text"> {`(${getTimezoneAbbreviation(timeZone)})`}</span>
                      </div>
                    )}
                  </div>
                  <div className="right-section">
                    <div className="calendar-flr-btn-wrp">
                      {!calendarRestriction.delete && (
                        <>
                          <CustomRippleButton rippleClass={`ac-danger-box ${!calenderViewData?.abilities?.can_remove ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.danger}>
                            <button className="ac-btn ac-danger h-default ac-block r-flx r-flx-jc r-flx-ac" onClick={handleDeleteModal}>
                              Remove
                            </button>
                          </CustomRippleButton>
                          <span className="clander-filter-divider">
                            <hr />
                          </span>
                        </>
                      )}

                      {isHideApprove || isLocationUser ? null : (
                        <CustomRippleButton rippleClass={`ac-secondary-box edit-ripple__wrp r-ml2 ${canApprove ? 'disabled' : ''}`} custColor={RIPPLE_COLOR.whiteGrey}>
                          <button
                            className={`ac-btn ac-secondary-white h-default ac-outline border-0 ac-block r-flx r-flx-jc r-flx-ac ${canApprove ? 'disabled' : ''}`}
                            onClick={() => {
                              handleOnChangePostContent('draft', true, calenderViewData);
                            }}
                          >
                            Cancel
                          </button>
                        </CustomRippleButton>
                      )}
                      {!calendarRestriction.edit && (
                        <CustomRippleButton rippleClass={`ac-secondary-box edit-ripple__wrp r-ml2 ${!canEditAccountPost ? 'disabled' : ''}`} custColor={RIPPLE_COLOR.whiteGrey}>
                          <button
                            className={`ac-btn edit-btn ac-secondary-white h-default ac-outline border-0 ac-block r-flx r-flx-jc r-flx-ac ${!canEditAccountPost ? 'disabled' : ''}`}
                            onClick={() => {
                              editClickHandler(calenderViewData);
                            }}
                          >
                            {calenderViewData?.id === editId && (!postsDetails || !savedPostDetails) ? <Spinner animation="border" /> : 'Edit'}
                          </button>
                        </CustomRippleButton>
                      )}
                    </div>
                  </div>
                </div>
                {isHideApprove
                  ? null
                  : !calendarRestriction.approve && (
                      <div className={`full-width-btn ${isButtonsEnabled ? '' : 'pointer-events-none'}`}>
                        <div
                          className={`btn approve-btn ${!canApprove ? 'cursor-disable' : ''}`}
                          onClick={() => {
                            if (calenderViewData?.activePlatform) {
                              handleOnChangePostContent('draft', false, calenderViewData);
                            } else {
                              alertBoxCall(ALERT_BOX_TYPES.ERROR, CONNECT_SOCIAL_MEDIA_TO_APPROVE_THE_POST);
                            }
                          }}
                        >
                          {!canApprove ? 'Approved' : 'Approve'}
                        </div>
                      </div>
                    )}
              </>
            ) : (
              <>
                {/* --------------------------HUB USER----------------------------- */}
                <div className="header-section cal-mt-btns calendarlist-hubuserfootersec">
                  <div className="right-section">
                    {!calendarRestriction.schedule && (
                      <div className={`sche-text`} onClick={() => setScheduleModal(true)}>
                        <ImageValidation isImgValid defaultImg="location-grey" customName="add-img-btn" />
                        Scheduled To <span>{calenderViewData?.includedAccountsCount}</span>
                      </div>
                    )}
                    <div className={`calendar-flr-btn-wrp`}>
                      {!calendarRestriction.delete && (
                        <CustomRippleButton rippleClass={`ac-danger-box ${isButtonsEnabled ? '' : 'pointer-events-none'}`} custColor={RIPPLE_COLOR.danger}>
                          <button className={`ac-btn ac-danger ac-block ${isButtonsEnabled ? '' : 'disabled'}`} onClick={handleDeleteModal}>
                            Remove
                          </button>
                        </CustomRippleButton>
                      )}
                      {!calendarRestriction.edit && (
                        <CustomRippleButton rippleClass={`ac-secondary-box edit-ripple__wrp r-ml2 ${isButtonsEnabled ? '' : 'pointer-events-none'}`} custColor={RIPPLE_COLOR.whiteGrey}>
                          <button
                            className={`ac-btn ac-secondary-white ac-block ac-outline border-0   ${isButtonsEnabled ? '' : 'disabled'}`}
                            onClick={() => {
                              editClickHandler(calenderViewData);
                            }}
                          >
                            {calenderViewData?.id === editId && (!postsDetails || !savedPostDetails) ? <Spinner animation="border" /> : 'Edit'}
                          </button>
                        </CustomRippleButton>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="liv-right list-item">
            <div className="header-section">
              <div className="left-section">
                <div className="p-header">
                  <div className="p-header-title">
                    <div className="p-avatar">
                      <ImageValidation
                        isImgValid
                        imgUrl={calenderViewData?.modified_by_user?.avatar_url}
                        defaultImg="user-avatar-common.png"
                        isNotSvgFormat
                        customClassname="p-avatar"
                        customName="User profile"
                      />
                    </div>
                    <div className="p-title">
                      <h5>{accountName}</h5>
                      <h6>{`@${getFormatBasedOnExactDate(calenderViewData?.start_datetime, LOCATION_TIME_ZONE_DATE_TIME_FORMAT)}`}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="desc-title">{calenderViewData?.title || ''}</div>
            <div className="desc-text">{calenderViewData?.description || ''}</div>
            <div className="cal-event-notes-wrap">
              <div className="event-section-main-wrp">
                <div className="left-section">
                  <div className="calendr-notify-section">
                    <div className="first-itm">
                      <div>Frequency</div>
                      <div>Start Time</div>
                      <div>End Time</div>
                      <div>Location</div>
                    </div>
                    <div className="last-itm">
                      <div className="last-item-text">{calenderViewData?.recurrence_type}</div>
                      <div className="last-item-text">{getFormatBasedOnExactDate(calenderViewData?.start_datetime, CALENDAR_EVENT_DATE_FORMAT)} </div>
                      <div className="last-item-text">{getFormatBasedOnExactDate(calenderViewData?.end_datetime, CALENDAR_EVENT_DATE_FORMAT)}</div>
                      <div className="last-item-text">{calenderViewData?.address}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider" />
              <div className="calendar-flr-btn-wrp internal-notes-btn-wrp">
                {CALENDAR_EVENT_CHECK_BOX.map((it, index) => {
                  return (
                    <div key={`calendar-detail-events${index}`} className="btn cancel-btn">
                      <div className="form-check">
                        <label className="checkbox-item">
                          <input type="checkbox" name={it.response} checked={calenderViewData[it.response]} onChange={(event) => calenderEventUpdateHandler(calenderViewData, event.target.name)} />
                          {it.label}
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
