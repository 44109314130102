import { useEffect, useState, useMemo } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { CustomReactTags } from 'widgets/CustomReactTags';
import { apAccountContainerTypes, commonImgExistsTypes, commonModalPopup, IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { NoDataFoundProps, CREATOR_USER_OWNERSHIP, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { addPostCouponListRequest, addPostCreateLinkForCouponRequest, addPostCreateLinkForAccCouponRequest } from 'actions';
import { Loading, NoDataFound } from 'components';
import { dateFormatterForAddPost, imageExists, couponIsExpired } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const AddCouponModal = (props: commonModalPopup.IDetailModalPopup) => {
  const dispatch = useDispatch();

  const { isModalShow, handleModalClose } = props;

  const { id, userOwnership } = useAccountSwitcherData();

  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const couponFranchisorId = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.coupon_franchisor_id || 0);
  const isListFetching = useSelector((state: IStore) => state.addPostAccount.couponData.isListFetching);
  const couponList = useSelector((state: IStore) => state.addPostAccount.couponData.couponList);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);

  const [couponImageProps, setCouponImageProps] = useState<commonImgExistsTypes.IImageExistsWithPayload<commonImgExistsTypes.IAPCouponImageExistsObj>[]>([]);
  const [selectCouponData, setSelectCouponData] = useState<null | apAccountContainerTypes.IAddPostCouponData>(null);
  const [couponSearchValue, setCouponSearchValue] = useState('');

  useEffect(() => {
    if (isModalShow) {
      dispatch(addPostCouponListRequest(userOwnership === USER_OWNERSHIP.FRANCHISOR ? id : couponFranchisorId));
    }
  }, [isModalShow]); // eslint-disable-line

  useEffect(() => {
    if (couponList.length) {
      const imagePropsPromise = couponList.map((couponDatum) => {
        const imageExistsPayload: commonImgExistsTypes.IAPCouponImageExistsObj = {
          id: couponDatum.id,
          couponUrl: couponDatum.coupon_image_url
        };
        return imageExists(couponDatum.coupon_image_url || null, imageExistsPayload);
      });
      Promise.all(imagePropsPromise).then((results: any) => setCouponImageProps(results));
    }
  }, [couponList.length]); // eslint-disable-line

  const handleInputChange = (searchValue: string) => {
    setCouponSearchValue(searchValue);
  };

  const filteredCouponList = useMemo(() => {
    const selectedCoupons =
      creatorCompName && [CREATOR_USER_OWNERSHIP.ACCOUNT, CREATOR_USER_OWNERSHIP.LOCATION_CONTENT].includes(creatorCompName)
        ? couponList.filter((it) => !couponIsExpired(it.expiration))
        : creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR
        ? couponList
        : [];
    return selectedCoupons.filter((datum) => datum.coupon_name.toLowerCase().indexOf(couponSearchValue.toLowerCase()) > -1 || datum.id.toLowerCase().indexOf(couponSearchValue.toLowerCase()) > -1);
  }, [creatorCompName, couponList, couponSearchValue]); // eslint-disable-line

  return (
    <>
      <Modal className="add-assets coupon-asset-modal-main" centered show={isModalShow} animation={false} onHide={handleModalClose}>
        <Modal.Body>
          <div className="creator-create-coupon-wrp-modal-sec creator-create-coupon__main">
            <div className="ap-wrap">
              <div className="ap-head">
                <h3 className="aph-back">Add Coupon</h3>
                <div className="mod__close--icon">
                  <ImageValidation defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={handleModalClose} />
                </div>
              </div>
              <div className="uploadmultiassets addImagesMain addCouponMain">
                <div className="ai-cnts">
                  <Form.Group className="form-group search-tk">
                    <CustomReactTags
                      key={`${id}-${creatorCompName}-coupon-search`}
                      handleInputChange={(search: string) => handleInputChange(search)}
                      handleAddition={() => false}
                      handleDelete={() => false}
                      placeholder={'Search'}
                      autofocus={false}
                    />
                  </Form.Group>

                  <div className="apInfinity adcInfnty">
                    {isListFetching && !filteredCouponList.length ? (
                      <Loading />
                    ) : filteredCouponList.length ? (
                      filteredCouponList.map((couponDatum, index) => {
                        const filterPostUserImgData = couponImageProps.find(
                          (filterData: commonImgExistsTypes.IImageExistFilterTypes) => filterData?.payload?.id === couponDatum.id && filterData.isImageExists
                        );
                        return (
                          <div
                            key={`create-post-coupon-${index}`}
                            className={`adc-item${selectCouponData?.id === couponDatum.id ? ` active` : ``}${!filterPostUserImgData?.isImageExists ? ` no-coupon-img` : ``}`}
                            onClick={() =>
                              setSelectCouponData({
                                id: couponDatum.id,
                                couponName: couponDatum.coupon_name,
                                couponImageUrl: couponDatum.coupon_image_url,
                                isImageExists: filterPostUserImgData?.isImageExists || false,
                                couponUrl: couponDatum.coupon_url,
                                startDate: dateFormatterForAddPost(couponDatum.start_date),
                                expireDate: dateFormatterForAddPost(couponDatum.expiration)
                              })
                            }
                          >
                            {filterPostUserImgData?.isImageExists && (
                              <div className="adc-img">
                                <ImageValidation isImgValid={filterPostUserImgData?.isImageExists || false} imgUrl={filterPostUserImgData.payload.couponUrl} customName={'Coupon Upload'} />
                              </div>
                            )}
                            <div className="adc-data">
                              <div className="adcd-item">
                                <div className="adcl-item">
                                  <h3>ID</h3>
                                </div>
                                <div className="adcr-item">
                                  <span>{couponDatum.id}</span>
                                </div>
                              </div>
                              <div className="adcd-item">
                                <div className="adcl-item">
                                  <h3>Name</h3>
                                </div>
                                <div className="adcr-item">
                                  <span>{couponDatum.coupon_name}</span>
                                </div>
                              </div>
                              <div className="adcd-item">
                                <div className="adcl-item">
                                  <h3>Start</h3>
                                </div>
                                <div className="adcr-item">
                                  <span>{dateFormatterForAddPost(couponDatum.start_date)}</span>
                                </div>
                              </div>
                              <div className="adcd-item adc-expire">
                                <div className="adcl-item">
                                  <h3>Expires</h3>
                                </div>
                                <div className="adcr-item">
                                  <span>{dateFormatterForAddPost(couponDatum.expiration)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <NoDataFound size={NoDataFoundProps.MEDIUM} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="coupon__modal--btn">
          <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box edit-ripple__wrp ${!selectCouponData ? 'pointer-event-none' : ''}`} custColor={RIPPLE_COLOR.whiteGrey}>
            <button className={`ac-btn ac-secondary-white ac-outline size-sm border-0 ${!selectCouponData ? 'disabled' : ''}`} disabled={!selectCouponData} onClick={() => setSelectCouponData(null)}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ripple-unset ac-primary-box  ${!selectCouponData ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
            <button
              className={`ac-btn ac-primary size-sm ${!selectCouponData ? 'disabled' : ''}`}
              disabled={!selectCouponData}
              onClick={() => {
                if (creatorCompName && [CREATOR_USER_OWNERSHIP.ACCOUNT, CREATOR_USER_OWNERSHIP.LOCATION_CONTENT].includes(creatorCompName) && selectCouponData?.id) {
                  dispatch(
                    addPostCreateLinkForAccCouponRequest({
                      accountId: creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT ? editSavedPost?.account?.id : id,
                      couponId: selectCouponData?.id
                    })
                  );
                } else if (creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR && selectCouponData?.id) {
                  dispatch(addPostCreateLinkForCouponRequest(selectCouponData?.id));
                }
                handleModalClose();
              }}
            >
              Add
            </button>
          </CustomRippleButton>
        </div>
      </Modal>
    </>
  );
};
