import { useSpring, animated } from 'react-spring';

import { numberFormatter, revvNumberFormatter } from 'analytics/utils';
import { commonWidgetTypes } from 'types';

export const ReactSpringCountUp = ({ end, round = true, isRevv = false }: commonWidgetTypes.IReactSpringCountUp) => {
  const spring = useSpring({
    from: { value: 0 },
    to: { value: end },
    config: { duration: 5000 }
  });
  if (!isRevv) {
    return <animated.div>{spring.value.to((it: number) => `${numberFormatter(round ? Math.floor(it) : it)}`)}</animated.div>;
  } else {
    return <animated.div>{spring.value.to((it: number) => `${revvNumberFormatter(round ? Math.floor(it) : it)}`)}</animated.div>;
  }
};
