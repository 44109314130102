import { put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  addPostDeleteContentResponse,
  addPostDeleteContentFailure,
  addPostMassDeleteContentResponse,
  addPostMassDeleteContentFailure,
  addPostGetInternalNotesListResponse,
  addPostGetInternalNotesListFailure,
  addPostAddInternalNotesListResponse,
  addPostAddInternalNotesListFailure,
  addPostAddLinkResponse,
  addPostAddLinkFailure,
  addPostDeleteLinkResponse,
  addPostDeleteLinkFailure,
  addPostCreateLinkForCouponResponse,
  addPostCreateLinkForCouponFailure,
  addPostUpdateLinkResponse,
  addPostUpdateLinkFailure,
  addPostCreateLinkForAccCouponResponse,
  addPostCreateLinkForAccCouponFailure,
  addPostGetInternalNotesListRequest,
  addPostGetHashtagsListFailure,
  addPostGetHashtagsListResponse,
  addPostCreateHashtagResponse,
  addPostCreateHashtagFailure,
  addPostDeleteHashtagResponse,
  addPostDeleteHashtagFailure,
  addPostCaptionTranslateFailure,
  addPostCaptionTranslateResponse,
  addPostLocalizePreviewsLinkResponse,
  addPostLocalizePreviewsLinkFailure
} from 'actions';
import { API, NODE_API, apiCall, defaultHeader } from 'utils/helpers';
import {
  ADD_POST_EXCEPTION_ERROR,
  ADD_POST_DELETE_CONTENT_DATA_FAILED,
  ADD_POST_INTERNAL_NOTES_FAILED,
  ADD_POST_ADD_LINK_FAILED,
  ADD_POST_DELETE_LINK_FAILED,
  ADD_POST_UPDATE_LINK_FAILED,
  ALERT_BOX_TYPES,
  DONE_SUCCESS_MSG,
  API_TYPES
} from 'utils/constants';
import { ISagaPayload } from 'types/common/api';
import { alertBoxCall } from 'components';

function* sendAddPostDeleteContentRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.deleteAdminPosts.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.deleteAdminPosts.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostDeleteContentResponse(response.data.content));
    } else {
      yield put(addPostDeleteContentFailure(ADD_POST_DELETE_CONTENT_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostMassDeleteContentRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      content: payload.reqPayload
    };
    const path = API.contentMassDelete.apiPath.replace(':id', payload.postId);
    const response = yield apiCall({ headers, data, apiPath: path, action: API.contentMassDelete.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostMassDeleteContentResponse(response.data.content));
      if (payload.isShowAlert) alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DONE_SUCCESS_MSG);
    } else {
      yield put(addPostMassDeleteContentFailure(ADD_POST_DELETE_CONTENT_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendGetInternalNotesListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      content_id: payload,
      order: 'id'
    };
    const response = yield apiCall({ headers, params, ...API.getContentIntNotes });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostGetInternalNotesListResponse(response.data.content_comments));
    } else {
      yield put(addPostGetInternalNotesListFailure(ADD_POST_INTERNAL_NOTES_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddInternalNotesListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const bodyFormData = new FormData();
    bodyFormData.append('content_comment[body]', payload.message);
    bodyFormData.append('content_comment[content_id]', payload.contentId);
    const response = yield apiCall({ headers, data: bodyFormData, ...API.addContentIntNotes });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostAddInternalNotesListResponse(response.data.content_comment));
      yield put(addPostGetInternalNotesListRequest(payload.contentId));
    } else {
      yield put(addPostAddInternalNotesListFailure(ADD_POST_INTERNAL_NOTES_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddLinkRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = payload?.url
      ? { link: payload }
      : {
          link: {
            link_preview_description: null,
            link_preview_image_url: null,
            link_preview_title: null,
            short_url: null,
            tags_list: '',
            tracpoint_coupon_id: null,
            url: payload,
            verticals_list: ''
          }
        };
    const response = yield apiCall({ headers, data, ...API.addLink });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostAddLinkResponse(response.data.link));
    } else {
      yield put(addPostAddLinkFailure(ADD_POST_ADD_LINK_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendLocalizePreviewsLinkRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = payload;
    const response = yield apiCall({ headers, params, ...API.localizePreviewsLink });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostLocalizePreviewsLinkResponse(response.data.personalized_url_previews));
    } else {
      yield put(addPostLocalizePreviewsLinkFailure(ADD_POST_ADD_LINK_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendDeleteLinkRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.deleteLink.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.deleteLink.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostDeleteLinkResponse(null));
    } else {
      yield put(addPostDeleteLinkFailure(ADD_POST_DELETE_LINK_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendUpdateLinkRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.updateLink.apiPath.replace(':id', payload.linkId);
    const data = {
      link: payload.reqPayload
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.updateLink.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostUpdateLinkResponse(response.data.link));
    } else {
      yield put(addPostUpdateLinkFailure(ADD_POST_UPDATE_LINK_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAPCreateLinkForCouponRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.createLinkForCoupon.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.createLinkForCoupon.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostCreateLinkForCouponResponse(response.data.link));
    } else {
      yield put(addPostCreateLinkForCouponFailure(ADD_POST_DELETE_LINK_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAPCreateLinkForAccCouponRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      account_id: payload.accountId
    };
    const path = API.createLinkForAccCoupon.apiPath.replace(':id', payload.couponId);
    const response = yield apiCall({ headers, data, apiPath: path, action: API.createLinkForAccCoupon.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostCreateLinkForAccCouponResponse({ ...response.data, link_id: +payload.couponId }));
    } else {
      yield put(addPostCreateLinkForAccCouponFailure(ADD_POST_ADD_LINK_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostGetHashtagsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.getHashtagsList.apiPath.replace(':id', payload.id).replace(':userOwnerShip', `${payload.userOwnership}s`);
    const response = yield apiCall({ headers, apiPath: path, action: API.getHashtagsList.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostGetHashtagsListResponse(response.data.hashtags));
    } else {
      yield put(addPostGetHashtagsListFailure(ADD_POST_DELETE_CONTENT_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostCreateHashtagRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.createHashtag.apiPath.replace(':id', payload.id).replace(':userOwnerShip', `${payload.userOwnership}s`);
    const response = yield apiCall({ headers, apiPath: path, data: { name: payload.data }, action: API.createHashtag.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 201) {
      yield put(addPostCreateHashtagResponse(response.data.hashtags));
    } else {
      yield put(addPostCreateHashtagFailure(ADD_POST_DELETE_CONTENT_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostDeleteHashtagRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.deleteHashtag.apiPath.replace(':id', payload.id).replace(':userOwnerShip', `${payload.userOwnership}s`);
    const data = {
      tag_names: payload.data
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.deleteHashtag.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostDeleteHashtagResponse(response.data.hashtags));
    } else {
      yield put(addPostDeleteHashtagFailure(ADD_POST_DELETE_CONTENT_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}
function* sendTranslateTextRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { ...payload.payload };
    delete data.socialMedia;
    const response = yield apiCall({ headers, data, ...NODE_API.translateCaption, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload?.onSuccess) {
        payload?.onSuccess(response?.data?.data[0]?.translateCaption);
      }
      yield put(addPostCaptionTranslateResponse({ fromLanguage: data.fromLanguage, toLanguage: data.toLanguage, socialMedia: payload.payload.socialMedia }));
    } else {
      yield put(addPostCaptionTranslateFailure(response.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

export function* takeAddPostFranchisorActionsRequest() {
  yield takeLatest(types.AP_DELETE_CONTENT_REQUEST, sendAddPostDeleteContentRequest);
  yield takeLatest(types.AP_MASS_DELETE_CONTENT_REQUEST, sendAddPostMassDeleteContentRequest);
  yield takeLatest(types.AP_GET_INTERNAL_NOTES_LIST_REQUEST, sendGetInternalNotesListRequest);
  yield takeLatest(types.AP_ADD_INTERNAL_NOTES_LIST_REQUEST, sendAddInternalNotesListRequest);
  yield takeLatest(types.AP_ADD_LINK_REQUEST, sendAddLinkRequest);
  yield takeLatest(types.AP_LOCALIZE_PREVIEWS_LINK_REQUEST, sendLocalizePreviewsLinkRequest);
  yield takeLatest(types.AP_DELETE_LINK_REQUEST, sendDeleteLinkRequest);
  yield takeLatest(types.AP_UPDATE_LINK_REQUEST, sendUpdateLinkRequest);
  yield takeLatest(types.AP_CREATE_LINK_FOR_COUPON_REQUEST, sendAPCreateLinkForCouponRequest);
  yield takeLatest(types.AP_CREATE_LINK_FOR_ACC_COUNPON_REQUEST, sendAPCreateLinkForAccCouponRequest);
  yield takeLatest(types.AP_GET_HASHTAGS_LIST_REQUEST, sendAddPostGetHashtagsListRequest);
  yield takeLatest(types.AP_CREATE_HASHTAG_REQUEST, sendAddPostCreateHashtagRequest);
  yield takeLatest(types.AP_DELETE_HASHTAG_REQUEST, sendAddPostDeleteHashtagRequest);
  yield takeLatest(types.AP_CAPTION_TRANSLATE_REQUEST, sendTranslateTextRequest);
}
