import { Dispatch } from 'react';

import {
  addPostGetActionTypeForPost,
  addPostGetEditSavedPostObj,
  addPostGetSelectedContentObj,
  addPostGetSelectedFBTagList,
  addPostSetCustomTextEditorData,
  addPostSetFranPageType,
  addPostSetInstaPhotoPreviewObj,
  addPostSetLinkData,
  addPostSetPersonalizedTextEditorData,
  addPostSetSelectedMediaList,
  addPostSetTags,
  addPostUpdateContentObj,
  getActivePlatormInScheduledPost,
  getCalendarItemsListRequest,
  getCalendarItemsListResponse,
  getScheduledMultiPostsRequest,
  getScheduledMultiPostsResponse,
  getScheduledPostRequest,
  getScheduledPostResponse
} from 'actions';
import { accountApiTypes, apFranReducerTypes, calendarApiTypes, calendarContainerTypes, mgApiTypes, postsApiTypes, postsReducerTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, MG_CLOUD_MEDIA_TYPE, FRAN_SCHEDULE_POST_TYPE, AP_ACTION_TYPES, CreatorInstaPhotoType, CALENDAR_EXCEPTION_ERROR, SCHEDULE_PARAM_TYPE } from 'utils/constants';
import {
  API,
  apiEventSourcePolyfillHandler,
  getAccountUpdatedPostDetails,
  getAddMediaListForCreator,
  getCreatorLinkData,
  getInitialSelectedContentData,
  getPostContentTextField,
  getPostPersonalizedTextField,
  getUpdatedTagsList,
  getValidContentData,
  isSocialMediaActive
} from 'utils/helpers';

export const getScheduledPostText = (descriptionData: calendarContainerTypes.IDataObjType[], socialMedia: string) => {
  return descriptionData.find((datum) => datum.label === socialMedia)?.text || '';
};

export const getScheduledPostStatus = (descriptionData: calendarContainerTypes.IDataObjType[], socialMedia: string) => {
  return descriptionData.find((datum) => datum.label === socialMedia)?.isEnabled ? true : false;
};

export const getCalendarSchReqPayload = (selectedData: calendarContainerTypes.ICalendarDataType, accountId: number) => {
  const imageList = selectedData.imageUrls || [];
  return {
    account_id: String(accountId),
    facebook_text: getScheduledPostText(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK),
    twitter_text: getScheduledPostText(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
    linkedin_text: getScheduledPostText(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN),
    tiktok_text: getScheduledPostStatus(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK),
    instagram_text: getScheduledPostText(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM),
    google_text: getScheduledPostText(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE),
    use_facebook: getScheduledPostStatus(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK),
    use_twitter: getScheduledPostStatus(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
    use_linkedin: getScheduledPostStatus(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN),
    use_tiktok: getScheduledPostStatus(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK),
    use_instagram: getScheduledPostStatus(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM),
    use_google: getScheduledPostStatus(selectedData.dataObj, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE),
    time_zone: selectedData.timeZone || '',
    time_zone_abbreviation: selectedData.timeZoneAbbrivation,
    link_url: selectedData.linkUrl || null,
    link_title: selectedData.linkTitle || null,
    link_description: selectedData.linkDescription || null,
    photo_urls: imageList,
    instagram_photo_urls: selectedData.instagramImageUrls || null,
    cloudinary_resource_type: imageList?.length ? MG_CLOUD_MEDIA_TYPE.IMAGE : selectedData.cloudinaryVideoId ? MG_CLOUD_MEDIA_TYPE.VIDEO : null,
    cloudinary_video_id: selectedData.cloudinaryVideoId || null,
    has_facebook_boost_enduser_campaign: selectedData.isBoosted || false,
    draft: selectedData?.draft || false,
    hidden: selectedData?.hidden || false,
    saved_post_id: selectedData.savedPostId,
    corporate_post: selectedData.isCorporatePost,
    start_date: null,
    end_date: null,
    facebook_boost_enduser_campaign_id: null,
    locked: false,
    pad_instagram_photos: true,
    posted: false,
    redeem_boost_offer: false
  };
};

/** Get corporate post detail - Location - Edit view */
export const getCorporatePostDetail = (activePlatforms: string[], data: calendarApiTypes.IGetScheduledPostResponse): postsApiTypes.IPostDetailSavedPost => {
  const isFacebook = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);
  const isTwitter = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER);
  const isLinkedin = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN);
  const isTiktok = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK);
  const isInstagram = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM);
  const isGoogle = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);
  return {
    id: data?.id,
    photo_url: data?.photo_url || '',
    photo_urls: data?.photo_urls,
    cloudinary_video_id: data?.cloudinary_video_id || null,
    url: data?.url || null,
    link_preview_title: data?.link_title || '',
    link_preview_description: data?.link_description || '',
    link_preview_image_url: data?.link_url || '',
    link_preview_image_url_choices: data?.link_url ? [data?.link_url] : [],
    link_preview_customized: data?.link_preview_customized || '',
    use_facebook: Boolean(isFacebook && data?.use_facebook),
    use_twitter: Boolean(isTwitter && data?.use_twitter),
    use_linkedin: Boolean(isLinkedin && data?.use_linkedin),
    use_google: Boolean(isGoogle && data?.use_google),
    use_instagram: Boolean(isInstagram && data?.use_instagram),
    use_tiktok: Boolean(isTiktok && data?.use_tiktok),
    facebook_text: isFacebook ? (data?.facebook_story ? '' : data?.facebook_text || '') : '',
    twitter_text: isTwitter ? data?.twitter_text || '' : '',
    linkedin_text: isLinkedin ? data?.linkedin_text || '' : '',
    google_text: isGoogle ? data?.google_text || '' : '',
    tiktok_text: isTiktok ? data?.tiktok_text || '' : '',
    instagram_text: isInstagram ? (data?.instagram_story ? '' : data?.instagram_text || '') : '',
    use_twitter_photo: data?.use_twitter_photo,
    created_at: '',
    updated_at: '',
    account: {
      id: data?.account_id || 0,
      name: data?.account_name
    },
    status_id: data?.draft ? 1 : 3,
    rejected: false,
    syndicated_from_content_id: null,
    start_date: null,
    end_date: null,
    trashed: false,
    tags_list: '',
    cloudinary_resource_type: data?.cloudinary_resource_type,
    mobile_app_only_enabled: false,
    created_user: {
      id: data?.user?.id,
      name: `${data?.user?.first_name} ${data?.user?.last_name}`,
      profile_photo_url: data?.user?.profile_photo_url || null
    },
    video_url: data?.video_url,
    post_published_count: 0,
    franchisor: {
      id: 0,
      name: ''
    },
    locked: data?.locked,
    boost_offer_id: data?.facebook_boost_enduser_campaign_id,
    page_tag_details: {},
    pad_instagram_photos: data?.pad_instagram_photos,
    instagram_photo_url: data?.instagram_photo_url,
    instagram_reel: data?.instagram_reel,
    facebook_reel: data?.facebook_reel,
    google_call_to_action_type: data?.google_call_to_action_type || null,
    google_call_to_action_url: data?.google_call_to_action_url || null,
    facebook_call_to_action_type: data?.facebook_call_to_action_type || null,
    facebook_call_to_action_link: data?.facebook_call_to_action_type || null,
    video_cover_cloudinary_id: data?.video_cover_cloudinary_id || null,
    facebook_story: data?.facebook_story || false,
    instagram_story: data?.instagram_story || false
  };
};

/** Scheduled Post */
export const canEditScheduledPost = (
  isLocked: boolean,
  isCorporatePost: boolean,
  isFranchisee: boolean,
  overriddenCorporatePostsLocked: boolean,
  overriddenCustomPostsLocked: boolean,
  canEdit: boolean
) => {
  if (isLocked) {
    return false;
  } else if (isFranchisee && isCorporatePost && overriddenCorporatePostsLocked) {
    return false;
  } else if (isFranchisee && !isCorporatePost && overriddenCustomPostsLocked) {
    return false;
  } else if (!canEdit) {
    return false;
  }
  return true;
};

export const canEditScheduledPostDateAndTime = (isCorporatePost: boolean, isFranchisee: boolean, overriddenCorporatePostsLocked: boolean, canEditSchedule: boolean) => {
  if (isFranchisee && isCorporatePost && overriddenCorporatePostsLocked) {
    return false;
  } else if (!canEditSchedule) {
    return false;
  }
  return true;
};

export const canApprovePermission = (isCorporatePost: boolean, isFranchisee: boolean, overriddenCorporatePostsAutoApproved: boolean, overriddenCustomPostsLocked: boolean) => {
  if (isFranchisee && isCorporatePost && overriddenCorporatePostsAutoApproved) {
    return false;
  } else if (isFranchisee && !isCorporatePost && overriddenCustomPostsLocked) {
    return false;
  } else {
    return true;
  }
};

export const canApproveScheduledPost = (isDraft: boolean, isCorporatePost: boolean, isFranchisee: boolean, overriddenCorporatePostsAutoApproved: boolean, overriddenCustomPostsLocked: boolean) => {
  return isDraft && canApprovePermission(isCorporatePost, isFranchisee, overriddenCorporatePostsAutoApproved, overriddenCustomPostsLocked);
};

export const calendarDeleteConfirmMsg = (postType?: string) => {
  return `Are you sure you want to unschedule and remove this${postType ? ` ${postType}` : ''} post?`;
};

export const getInitialLocationScheduleType = ({
  totalLocationCount,
  corporateAccountId,
  includedAccounts,
  franOrAccountListIds
}: {
  totalLocationCount: number;
  corporateAccountId: null | number;
  includedAccounts: postsApiTypes.IPostListDetailData[];
  franOrAccountListIds: number[];
}) => {
  return franOrAccountListIds?.length
    ? FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS
    : includedAccounts?.length && totalLocationCount === includedAccounts.length
    ? FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
    : corporateAccountId && includedAccounts?.length && includedAccounts.every((it) => it.id === corporateAccountId)
    ? FRAN_SCHEDULE_POST_TYPE.CORPORATE_ONLY
    : includedAccounts?.length
    ? FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS
    : FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS;
};

export const editOrSchedulePostDispatch = (
  activePlatforms: string[],
  postsDetails: postsReducerTypes.IPostDetailsData,
  accountDetails: accountApiTypes.IAccountOrPieReload | null,
  dispatch: Dispatch<any>
) => {
  if (postsDetails) {
    dispatch(addPostGetActionTypeForPost(AP_ACTION_TYPES.EDIT));
    dispatch(addPostGetEditSavedPostObj(getAccountUpdatedPostDetails(activePlatforms, postsDetails)));
    dispatch(addPostSetCustomTextEditorData(getPostContentTextField(activePlatforms, postsDetails)));
    dispatch(addPostGetSelectedFBTagList(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? postsDetails.page_tag_details?.facebook || [] : []));
    dispatch(addPostSetTags(getUpdatedTagsList(postsDetails.tags_list)));
    dispatch(addPostSetSelectedMediaList(getAddMediaListForCreator(postsDetails, accountDetails?.account?.id || 0)));
    dispatch(addPostSetLinkData(postsDetails?.url ? getCreatorLinkData(postsDetails) : null));
  }
};

export const handleEditContentPostDispatch = (
  activePlatforms: string[],
  postsDetails: postsReducerTypes.IPostDetailsData,
  postsMediaList: mgApiTypes.IMGListResponseObj[],
  postsLinks: calendarApiTypes.ICouponLinks[],
  contentObj: apFranReducerTypes.IAddPostFranContentObj,
  dispatch: Dispatch<any>
) => {
  if (postsDetails) {
    dispatch(addPostSetFranPageType(AP_ACTION_TYPES.EDIT));
    dispatch(addPostUpdateContentObj(getValidContentData(activePlatforms, postsDetails)));
    const selectedContentObj = getInitialSelectedContentData(activePlatforms, postsDetails, contentObj, AP_ACTION_TYPES.EDIT, postsLinks[0]);
    dispatch(addPostGetSelectedContentObj(selectedContentObj));
    dispatch(addPostSetCustomTextEditorData(getPostContentTextField(activePlatforms, postsDetails)));
    dispatch(addPostSetPersonalizedTextEditorData(getPostPersonalizedTextField(activePlatforms, postsDetails)));
    dispatch(addPostGetSelectedFBTagList(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? postsDetails?.page_tag_details?.facebook || [] : []));
    dispatch(addPostSetTags(getUpdatedTagsList(postsDetails.tags_list)));
    dispatch(addPostSetSelectedMediaList(postsMediaList));
    dispatch(addPostSetLinkData(postsLinks.length ? postsLinks[0] : null));
    if (postsDetails?.use_instagram && postsDetails?.photo_urls?.length) {
      dispatch(
        addPostSetInstaPhotoPreviewObj({
          actionType: postsDetails?.pad_instagram_photos ? CreatorInstaPhotoType.PAD : CreatorInstaPhotoType.CROP,
          photoUrl: { originalUrls: [], previewUrls: [] }
        })
      );
    }
  }
};

export const getScheduledMultiPostEventStreamHandler = (id: number, activePlatforms: string[], platform: string, dispatch: Dispatch<any>, contentType?: string) => {
  return apiEventSourcePolyfillHandler(
    API.getScheduledMultiPost.apiPath,
    { franchisor_id: id, future: 1, trashed: 0, ...(contentType && { content_type: contentType }) },
    'scheduled_multiposts',
    () => dispatch(getScheduledMultiPostsRequest()),
    (data) => {
      dispatch(getScheduledMultiPostsResponse(data));
      if (!data?.length) {
        dispatch(getActivePlatormInScheduledPost({ activePlatforms, selectedPlatform: platform }));
      }
    },
    CALENDAR_EXCEPTION_ERROR
  );
};

export const getScheduledPostEventStreamHandler = (id: number, activePlatforms: string[], platform: string, dispatch: Dispatch<any>, contentType?: string) => {
  return apiEventSourcePolyfillHandler(
    API.getScheduledPost.apiPath,
    {
      hidden: 0,
      order: SCHEDULE_PARAM_TYPE.SCHEDULE_FOR,
      account_id: id,
      future: 1,
      ...(contentType && { content_type: contentType })
    },
    'scheduled_posts',
    () => dispatch(getScheduledPostRequest()),
    (data) => {
      dispatch(getScheduledPostResponse(data));
      if (!data?.length) {
        dispatch(getActivePlatormInScheduledPost({ activePlatforms, selectedPlatform: platform }));
      }
    },
    CALENDAR_EXCEPTION_ERROR
  );
};

export const getCalendarItemsListEventStreamHandler = (id: number, dispatch: Dispatch<any>) => {
  return apiEventSourcePolyfillHandler(
    API.getRequestedCalendarItem.apiPath,
    {
      account_id: id,
      future: 1
    },
    'calendar_items',
    () => dispatch(getCalendarItemsListRequest()),
    (data) => {
      dispatch(getCalendarItemsListResponse(data));
    },
    CALENDAR_EXCEPTION_ERROR
  );
};
