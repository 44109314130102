import { Card, ListGroup } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { ITotalPercentagePie } from 'analytics/types';
import { CustomIndicator, HighChartReact } from 'analytics/components';
import { numberFormatter, pieToolTipFormatter, convertMsTimeFormat, ChartType, PIE_CHART_OPTIONS } from 'analytics/utils';
import { getFormattedNumber } from 'utils/helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE, NOT_APPLICABLE } from 'utils/constants';

export const TotalPercentagePie = ({
  title,
  data,
  pieArray,
  seriesArray,
  selectedTile,
  pieChartColours = ['#3870FF', '#F00075', '#000000', '#2867B2'],
  platformArray,
  showInLegend = false,
  pieLabelFormatter,
  selectedTileArray,
  isTimeFormat = false,
  isDataLabelEnabled = true,
  isRotate = false,
  isDelta = true,
  chartTitle = '',
  isPercentage = false,
  defaultValue = NOT_APPLICABLE
}: ITotalPercentagePie) => {
  const isSelectionValid = [...seriesArray].map((it) => data[it?.value]).filter((it) => it !== 0 && it !== undefined && it !== null).length > 0;

  const pieChartOptions = () => {
    const series: Highcharts.SeriesOptionsType[] = [
      {
        type: ChartType.PIE,
        // colorByPoint: true,
        innerSize: '20%',
        data: seriesArray.every((it) => !data[it.value])
          ? []
          : seriesArray.map((it, index) => {
              const isSelected = (selectedTileArray !== undefined ? selectedTileArray?.includes(it.ratings) : selectedTile === it.value) && isSelectionValid;
              const isOpacity =
                selectedTileArray === undefined
                  ? selectedTile === COMMON_SOCIAL_PROVIDER_TYPE.ALL
                    ? 1
                    : isSelected
                    ? 1
                    : 0.2
                  : !selectedTileArray?.length
                  ? 1
                  : selectedTileArray?.includes(it.ratings)
                  ? 1
                  : 0.2;
              return {
                name: it.name,
                y: +data[it?.value] || 0,
                sliced: isSelected,
                selected: isSelected,
                opacity: isOpacity,
                color: pieChartColours[index],
                z: isSelected ? 1000 : undefined
              };
            })
      }
    ];

    const options: Highcharts.Options = {
      ...PIE_CHART_OPTIONS,
      ...(PIE_CHART_OPTIONS.plotOptions?.pie && {
        plotOptions: {
          pie: {
            ...PIE_CHART_OPTIONS.plotOptions.pie,
            colors: pieChartColours,
            slicedOffset: selectedTileArray ? 5 : 20,
            showInLegend,
            dataLabels: {
              ...PIE_CHART_OPTIONS.plotOptions.pie.dataLabels,
              enabled: isDataLabelEnabled
            }
          }
        }
      }),
      tooltip: {
        ...PIE_CHART_OPTIONS.tooltip,
        formatter() {
          return pieToolTipFormatter(this, platformArray);
        }
      },
      legend: {
        ...PIE_CHART_OPTIONS.legend,
        enabled: showInLegend,
        labelFormatter() {
          return pieLabelFormatter ? pieLabelFormatter(this) : this.name;
        }
      },
      series
    };
    return options;
  };

  const valueFormatter = (value: number) => (!isTimeFormat ? numberFormatter(+value) : convertMsTimeFormat(+value, true));

  return (
    <div className="flex-item-xs lpx">
      <div className="total-follow tfs">
        <Card>
          <Card.Body className="tf-col-holder">
            <div className="tf-left-col">
              <h3>{title}</h3>
              <Card>
                <ListGroup>
                  {pieArray.map((it, index) => {
                    const isActive = !selectedTile || selectedTile === COMMON_SOCIAL_PROVIDER_TYPE.ALL || selectedTile === it.value;
                    const value = data[it?.value] !== undefined ? valueFormatter(+data[it?.value]) : defaultValue;
                    const deltaValue = data[`${it?.value}_delta`] !== undefined ? valueFormatter(+data[`${it?.value}_delta`]) : defaultValue;
                    return (
                      <ListGroup.Item key={index} className={isActive ? 'active-tile' : ''}>
                        <div className="tf-header">
                          <div className="social-profile--img">
                            <ImageValidation isImgValid defaultImg={it.image} isNotSvgFormat customName={`${it.name}-comments`} customClassname={'social-media'} />
                          </div>
                          <span className={it.class}>{it.name}</span>
                        </div>
                        <div className="delta-wrp">
                          <span
                            className="tf-total-count"
                            title={
                              isTimeFormat && data[it?.value]
                                ? convertMsTimeFormat(+data[it?.value])
                                : data[it?.value] !== undefined && Math.abs(+data[it?.value]) >= 10000
                                ? getFormattedNumber(+data[it?.value]) || ''
                                : undefined
                            }
                          >
                            {isPercentage ? `${value}%` : showInLegend && value !== NOT_APPLICABLE ? Number(value)?.toFixed(1) : value}
                          </span>
                          {isDelta && (
                            <>
                              {' '}
                              <CustomIndicator value={isRotate ? -1 * +data[`${it?.value}_delta`] : +data[`${it?.value}_delta`]} isRotate={isRotate} />
                              <span
                                className="delta-small-text"
                                title={
                                  isTimeFormat && data[`${it?.value}_delta`]
                                    ? convertMsTimeFormat(+data[`${it?.value}_delta`])
                                    : data[`${it?.value}_delta`] !== undefined && !isTimeFormat && Math.abs(+data[`${it?.value}_delta`]) >= 10000
                                    ? getFormattedNumber(+data[`${it?.value}_delta`]) || ''
                                    : undefined
                                }
                              >
                                {deltaValue}
                              </span>
                            </>
                          )}
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Card>
            </div>
            <div className="tf-right-col">
              <h3>{chartTitle || `Percentage of ${title} by Platform`}</h3>
              <HighChartReact options={pieChartOptions()} immutable />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
