import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import {
  mgDeleteAssetRequest,
  mgDetailDataRequest,
  commonSetContentModifiedStatus,
  mgDetailDataReset,
  mgAddedAssetDetailReset,
  getFranchisorSyndicationRequest,
  franchisorSyndicationResetAll
} from 'actions';
import { DeleteAssetModalPopup } from 'components/Common/Modal/DeleteAssetModalPopup';
import { MgImage } from './MgImage';
import { MgImageDetail } from './MgImageDetail';
import { MgTagsAndNotes } from './MgTagsAndNotes';
import { MgDetailName } from './MgDetailName';
import {
  // mgEditEnabled,
  compareTwoObjects,
  mgEditEnabled,
  scrollToTop,
  scrollToView
} from 'utils/helpers';
import { useAccountSwitcherData, useHideFilter } from 'utils/hooks';
import { Loading } from 'components';
import { FranchisorSyndicationMediaType, MgMediaType } from 'utils/constants';

export const MgDetailView = () => {
  useHideFilter();
  const dispatch = useDispatch();

  const { id, userOwnership, optionalParams, isValidId } = useAccountSwitcherData();

  const isDetailFetching = useSelector((state: IStore) => state.mediaGallery.isDetailFetching);
  const mgDetails = useSelector((state: IStore) => state.mediaGallery.mgDetails);
  const editedMgDetails = useSelector((state: IStore) => state.mediaGallery.editedMgDetails);
  const isUpdateMGDetailData = useSelector((state: IStore) => state.mediaGallery.isUpdateMGDetailData);
  const isCloudinaryUpload = useSelector((state: IStore) => state.mediaGallery.isCloudinaryUpload);
  const accountId = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.id || null);

  const editEnabled = mgEditEnabled(userOwnership, mgDetails, accountId);

  const [showModal, setShowModal] = useState(false);

  const mediaType = optionalParams[0];
  const mediaGalleryId = +optionalParams[1];

  useEffect(() => {
    if (mediaType && mediaGalleryId && id && userOwnership && isValidId) {
      dispatch(franchisorSyndicationResetAll());
      dispatch(mgDetailDataRequest({ mediaType, assetId: mediaGalleryId, id, userOwnership }));
    }
  }, [mediaType, mediaGalleryId, id, userOwnership, isValidId]); // eslint-disable-line

  useEffect(() => {
    if (mgDetails?.account_id === null && mediaType && mediaGalleryId && isValidId) {
      dispatch(
        getFranchisorSyndicationRequest({
          id: mediaGalleryId,
          mediaType:
            mediaType === MgMediaType.PHOTOS ? FranchisorSyndicationMediaType.PHOTO : mediaType === MgMediaType.VIDEOS ? FranchisorSyndicationMediaType.VIDEO : FranchisorSyndicationMediaType.DOCUMENT
        })
      );
    }
  }, [mediaType, mediaGalleryId, mgDetails?.account_id, isValidId, dispatch]);

  useEffect(() => {
    scrollToTop();
    return () => {
      scrollToView(`mg-item-${mediaGalleryId}`);
      dispatch(mgDetailDataReset());
      dispatch(mgAddedAssetDetailReset());
    };
  }, [mediaGalleryId, dispatch]);

  const isDataEdited = useMemo(() => {
    if (mgDetails && editedMgDetails) {
      return !compareTwoObjects(mgDetails, editedMgDetails);
    }
    return false;
  }, [mgDetails, editedMgDetails]); // eslint-disable-line

  useEffect(() => {
    dispatch(commonSetContentModifiedStatus(isDataEdited));
  }, [isDataEdited]); // eslint-disable-line

  return (
    <>
      {isDetailFetching ? (
        <Loading />
      ) : (
        <div
          className={`mainContent animate__animated animate__fadeIn mg-detailed mg-detailed__main mbl-head--margin lpx vpy-20 media-ncn${
            isCloudinaryUpload || isUpdateMGDetailData ? ` pointer-events-none` : ''
          }`}
        >
          {isCloudinaryUpload || isUpdateMGDetailData ? <Loading /> : null}
          <MgDetailName editEnabled={editEnabled} />
          <MgImage />
          <MgImageDetail />
          <MgTagsAndNotes editEnabled={editEnabled} showDeleteModal={setShowModal} />
        </div>
      )}
      <DeleteAssetModalPopup
        showModal={showModal}
        handleModalClose={() => {
          setShowModal(false);
        }}
        handleDelete={() => {
          if (mgDetails && id && userOwnership) {
            dispatch(
              mgDeleteAssetRequest({
                mediaType,
                assetId: mgDetails.id,
                reqPayload: {
                  ...mgDetails,
                  trashed: true
                }
              })
            );
            setShowModal(false);
          }
        }}
      />
    </>
  );
};
