import { Fragment, useCallback } from 'react';

import { IReviewStats, ITimeSplitterComponent } from 'analytics/types';
import {
  AnalyticsPlatoformCombinedGraphFillColors,
  AnalyticsPlatoformFadeCombinedGraphFillColors,
  AnalyticsPlatoformGraphColors,
  ChartType,
  convertMsTimeFormat,
  GRAPH_STAT_CHART_OPTION,
  inValidData,
  numberFormatter,
  PERCENT_REPLIED_IN_NUMBER,
  reviewAnalyticsTooltipAvgText,
  reviewAnalyticsTooltipDeltaText,
  reviewAnalyticsTooltipText,
  REVIEW_ANALYTICS_BAR_CHART_OPTIONS,
  validData,
  ReviewAnalyticsStatsValue
} from 'analytics/utils';
import { TotalReviewsStats } from './TotalReviewsStats';
import { KeywordsStatsChart } from './KeywordsStatsChart';
import { CustomTooltip } from 'widgets/Tooltip';
import { NOT_APPLICABLE, TOOLTIP_PLACEMENT } from 'utils/constants';
import { getFormattedNumber } from 'utils/helpers';
import { AreaSplineChart, HighChartReact, CustomIndicator } from 'analytics/components';

export const ReviewStats = ({
  title,
  onStatsClick,
  isHubUser,
  brandAverage,
  chartType,
  graphSeries = [],
  graphColor = [AnalyticsPlatoformGraphColors.ALL],
  graphFadeColor = [AnalyticsPlatoformFadeCombinedGraphFillColors.ALL],
  graphFillColor = [AnalyticsPlatoformCombinedGraphFillColors.ALL],
  count,
  delta,
  titleValue,
  hubAverage,
  fromDate,
  toDate,
  isDelta = true,
  isToFixed,
  isPercentage
}: IReviewStats) => {
  const valueFormatter = (value: number) =>
    titleValue !== ReviewAnalyticsStatsValue.RESPONSE_TIME
      ? isToFixed
        ? Number(value).toFixed(1)
        : titleValue === PERCENT_REPLIED_IN_NUMBER.value
        ? numberFormatter(+Math.round(value), 10000)
        : numberFormatter(+value, 10000)
      : convertMsTimeFormat(+value, true);

  const textFormater = (value: string | number | null | undefined, fallback: string | number = 0) => {
    return value && validData(value) ? valueFormatter(+value) : fallback;
  };

  const value = textFormater(count);
  const deltaValue = textFormater(delta);
  const avg = textFormater(brandAverage);
  const hubAvg = textFormater(hubAverage);

  const isDeltaDisable = inValidData(count) && inValidData(delta);

  const isDisableTooltip = isDeltaDisable && inValidData(brandAverage) && inValidData(hubAverage);

  const TimeSplitterComponent = ({ time }: ITimeSplitterComponent) => {
    if (time === NOT_APPLICABLE) {
      return <>{time}</>;
    } else {
      const dateString = time.split(/([y,d,h,m,s])/);
      return (
        <>
          {dateString.map((it: string | number, index: number) => (
            <span key={index}>
              <Fragment key={index}>{+it >= 0 ? it : <span className="unit-text-small">{it}</span>} </Fragment>
            </span>
          ))}
        </>
      );
    }
  };

  const barColumnChart = useCallback(() => {
    if (chartType === ChartType.BAR || chartType === ChartType.COLUMN) {
      const options: Highcharts.Options = {
        ...REVIEW_ANALYTICS_BAR_CHART_OPTIONS,
        chart: {
          ...REVIEW_ANALYTICS_BAR_CHART_OPTIONS.chart,
          type: chartType,
          height: !isHubUser && chartType === ChartType.COLUMN ? '116' : '50',
          width: !isHubUser && chartType === ChartType.COLUMN ? '180' : '140'
        },
        plotOptions: {
          ...REVIEW_ANALYTICS_BAR_CHART_OPTIONS.plotOptions,
          [chartType === ChartType.BAR ? 'series' : ChartType.COLUMN]: {
            stacking: chartType === ChartType.BAR ? 'percent' : 'normal'
          }
        },
        series: graphSeries || []
      };
      return <HighChartReact options={options} />;
    }
    return null;
  }, [chartType, graphSeries, isHubUser]);

  return (
    <div
      className={`stats-item total-count stats-level-02 ${!isHubUser ? 'loc-stat' : 'hub-stat'}`}
      onClick={() => {
        if (onStatsClick) onStatsClick();
      }}
    >
      {titleValue !== ReviewAnalyticsStatsValue.KEYWORDS && !isDisableTooltip ? (
        <CustomTooltip
          customPlacement={TOOLTIP_PLACEMENT.TOP}
          customClassname={`custom-tooltip-long-text ov-an-tooltip social-tooltip big-box-width`}
          customTooltipText={
            <div>
              <>
                {' '}
                <span className="cust-tooltip-txt">
                  {titleValue === ReviewAnalyticsStatsValue.REPLY || titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME ? `For reviews that were received during` : `From`} {fromDate} to {toDate}:{' '}
                </span>
                <br />
              </>
              {count !== undefined && count !== null ? (
                <>
                  {' '}
                  <span className="cust-tooltip-txt">
                    {reviewAnalyticsTooltipText(
                      titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME
                        ? convertMsTimeFormat(Math.abs(+count))
                        : getFormattedNumber(titleValue === PERCENT_REPLIED_IN_NUMBER.value ? Math.round(+count) : +count),
                      titleValue || ''
                    )}
                  </span>
                  <br />
                </>
              ) : null}

              {delta !== undefined && delta !== null ? (
                <div>
                  <CustomIndicator value={titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME ? +delta * -1 : +delta} isRotate={titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME} />
                  <span className="cust-tooltip-txt delta">
                    {reviewAnalyticsTooltipDeltaText(
                      titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME
                        ? convertMsTimeFormat(Math.abs(+delta))
                        : getFormattedNumber(titleValue === PERCENT_REPLIED_IN_NUMBER.value ? Math.abs(Math.round(+delta)) : Math.abs(+delta)),
                      titleValue || '',
                      +delta
                    )}
                  </span>
                </div>
              ) : null}

              {brandAverage !== undefined && brandAverage !== null ? (
                <>
                  <span className="cust-tooltip-txt">
                    {reviewAnalyticsTooltipAvgText(
                      titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME
                        ? convertMsTimeFormat(+brandAverage)
                        : getFormattedNumber(titleValue === PERCENT_REPLIED_IN_NUMBER.value ? Math.round(+brandAverage) : +brandAverage),
                      titleValue || ''
                    )}
                  </span>
                  <br />
                </>
              ) : null}
              {hubAverage !== undefined && hubAverage !== null ? (
                <>
                  <span className="cust-tooltip-txt">
                    {reviewAnalyticsTooltipAvgText(
                      titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME
                        ? convertMsTimeFormat(+hubAverage)
                        : getFormattedNumber(titleValue === PERCENT_REPLIED_IN_NUMBER.value ? Math.round(+hubAverage) : +hubAverage),
                      titleValue || '',
                      false
                    )}
                  </span>
                  <br />
                </>
              ) : null}
            </div>
          }
          customInput={() => (
            <div className="chart-module-top">
              <span className="stats-title-text">{title}</span>
              <div className="an-stat--b1">
                <span className="big-number-text">
                  {isPercentage && value !== NOT_APPLICABLE ? `${value}%` : titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME ? <TimeSplitterComponent time={`${value}`} /> : value}
                </span>
                {isDelta && !isDeltaDisable && (
                  <>
                    {' '}
                    <CustomIndicator
                      value={delta !== undefined && delta !== null ? (titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME ? +delta * -1 : +delta) : 0}
                      isRotate={titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME}
                    />
                    <span className="delta-small-text">{isPercentage && deltaValue !== NOT_APPLICABLE ? `${deltaValue}%` : deltaValue}</span>
                  </>
                )}
              </div>
              {!isHubUser && validData(brandAverage) && (
                <div className="an-stat--b2">
                  <span className="ba-label">Brand Average</span>
                  <span className="small-title-text"> {isPercentage && avg !== NOT_APPLICABLE ? `${avg}%` : avg} </span>
                </div>
              )}
            </div>
          )}
        />
      ) : (
        <div className="chart-module-top">
          <span className="stats-title-text">{title}</span>
          <div className="an-stat--b1">
            <span className="big-number-text">{isPercentage && value !== NOT_APPLICABLE ? `${value}%` : value}</span>
            {isDelta && !isDeltaDisable && (
              <>
                {' '}
                <CustomIndicator
                  value={delta !== undefined && delta !== null ? (titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME ? +delta * -1 : +delta) : 0}
                  isRotate={titleValue === ReviewAnalyticsStatsValue.RESPONSE_TIME}
                />
                <span className="delta-small-text">{isPercentage && deltaValue !== NOT_APPLICABLE ? `${deltaValue}%` : deltaValue}</span>
              </>
            )}
          </div>
          {!isHubUser && validData(brandAverage) && (
            <div className="an-stat--b2">
              <span className="ba-label">Brand Average</span>
              <span className="small-title-text"> {isPercentage && avg !== NOT_APPLICABLE ? `${avg}%` : avg} </span>
            </div>
          )}
        </div>
      )}
      <div
        className={`${
          !isHubUser || chartType === 'wordCloud' ? `chart-container ${chartType === 'wordCloud' ? 'keyword-chart' : ''}` : `hub-chart__container ${chartType === 'wordCloud' ? 'keyword-chart' : ''}`
        }`}
      >
        {
          chartType === 'wordCloud' ? (
            <KeywordsStatsChart keywordsSummary={{ pos: 1, neut: 12, neg: 20 }} />
          ) : chartType === ChartType.BAR || chartType === ChartType.COLUMN ? (
            <div className="avg-ratings__bar review-stats--charts-hub">{barColumnChart()}</div>
          ) : chartType === 'totalCount' ? (
            <TotalReviewsStats />
          ) : (
            <div className="review-stats--charts-hub">
              <AreaSplineChart
                title={''}
                series={{
                  data: graphSeries
                }}
                color={graphColor}
                fillColor={graphFillColor}
                fadeColor={graphFadeColor}
                chartType={ChartType.AREASPLINE}
                isAxisEnabled={false}
                isTooltipDisabled
                isMultipleAxisDisabled
                isMarkerDisable
                height={`${!isHubUser ? '116' : '50'}`}
                width={`${!isHubUser ? '180' : '140'}`}
                isMouseTracking={false}
                xMinPadding={0}
                xMaxPadding={0}
                chartOptions={GRAPH_STAT_CHART_OPTION}
              />
            </div>
          )
          // <NoDataFound size={NoDataFoundProps.MEDIUM} />
        }
      </div>
      {isHubUser && chartType !== 'wordCloud' && (
        <div className="brand-hub__wrp">
          <span className="title">Average</span>

          <div className="inner-sec responsetime-tooltip-wrp">
            {avg === NOT_APPLICABLE || titleValue !== ReviewAnalyticsStatsValue.RESPONSE_TIME ? (
              <div>
                <span className="title">Brand</span>
                <br />
                <span className="value">{isPercentage && avg !== NOT_APPLICABLE ? `${avg}%` : avg}</span>
              </div>
            ) : (
              <CustomTooltip
                customPlacement={TOOLTIP_PLACEMENT.BOTTOM}
                customTooltipText={`Brand: ${brandAverage !== undefined && brandAverage !== null ? convertMsTimeFormat(+brandAverage) : NOT_APPLICABLE}`}
                customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an'}
                customInput={() => (
                  <div>
                    <span className="title">Brand</span>
                    <br />
                    <span className="value">{avg}</span>
                  </div>
                )}
              />
            )}
            <div className="v_line" />
            {hubAvg === NOT_APPLICABLE || titleValue !== ReviewAnalyticsStatsValue.RESPONSE_TIME ? (
              <div>
                <span className="title">Hub</span>
                <br />
                <span className="value">{isPercentage && hubAvg !== NOT_APPLICABLE ? `${hubAvg}%` : hubAvg}</span>
              </div>
            ) : (
              <CustomTooltip
                customPlacement={TOOLTIP_PLACEMENT.BOTTOM}
                customTooltipText={`Hub: ${hubAverage !== undefined && hubAverage !== null ? convertMsTimeFormat(+hubAverage) : NOT_APPLICABLE}`}
                customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an'}
                customInput={() => (
                  <div>
                    <span className="title">Hub</span>
                    <br />
                    <span className="value">{hubAvg}</span>
                  </div>
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
