import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ReviewsList, ReviewsStats } from 'containers/Reputation/Reviews/Subpages';
import { IStore } from 'types';
import { getReviewsListRequest, getReviewsStatsRequest, reviewsListDataReset, reviewsResetAll, addPostResetAll, filterStatus, addPostActionsResetAll, getReviewResponderRequest } from 'actions';
import { Loading, NoDataFound } from 'components/Common';
import {
  NoDataFoundProps,
  SCROLL_THRESHOLD,
  REVIEWS_ADD_REPLY_SUCCESS_MSG,
  USER_OWNERSHIP,
  INBOX_FILTER_INITIAL_VALUE,
  REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  ReviewsStatus,
  ReviewsInteraction,
  REVIEWS_BRAND_PLATFORMS,
  FeedValue
} from 'utils/constants';
import { getReviewsReqParamObj, weekOrMonthBeforeTodayFilter, scrollToTop, stringifyValidQueryParams } from 'utils/helpers';
import { useAccountSwitcherData, useAvailablePlatforms, useParamsDeconstructor } from 'utils/hooks';

export const ReputationReviews = ({ isStats = true, isCoachMark = true }: { isStats?: boolean; isCoachMark?: boolean }) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { id, userOwnership, isValidId } = useAccountSwitcherData();
  const uniqueKeyWord = useSelector((state: IStore) => state.reviewAnalytics.uniqueKeyWord);
  const yelpBusinessId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.yelp_business_id);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const isListFetching = useSelector((state: IStore) => state.reviews.isListFetching);
  const reviewsList = useSelector((state: IStore) => state.reviews.reviewsList);
  const reviewsSuccessMsg = useSelector((state: IStore) => state.reviews.messages.reviewsSuccessMsg);

  const [currentPageCount, setCurrentPageCount] = useState(1);

  const reviewsDaterange = weekOrMonthBeforeTodayFilter(FeedValue.INBOX);
  const initialFilterValue = {
    ...INBOX_FILTER_INITIAL_VALUE,
    locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ,
    ratings: [],
    status: ReviewsStatus.ALL_STATUS,
    interaction: ReviewsInteraction.ALL_INTERACTION,
    dateRange: reviewsDaterange
  };
  const { queryParams, filter, selectedHubId, selectedHubKey } = useParamsDeconstructor(initialFilterValue);
  const reviewsFilter = {
    ...filter,
    interaction: queryParams.interaction,
    status: queryParams.status,
    ratings: queryParams?.ratings?.split(',') || []
  };

  const availablePlatforms = useAvailablePlatforms(REVIEWS_BRAND_PLATFORMS, userOwnership, yelpBusinessId || null, false);
  // const activePlatforms = useMemo(() => {
  //   if (id && userOwnership === USER_OWNERSHIP.ACCOUNT) {
  //     return getAccReviewsPlatformList(accountDetails?.social_media || [], accountDetails?.account_profiles[0]?.yelp_profile_url || null);
  //   } else {
  //     return REVIEWS_BRAND_PLATFORMS;
  //   }
  // }, [id, userOwnership, accountDetails?.social_media, accountDetails?.account_profiles[0]?.yelp_profile_url]); // eslint-disable-line
  const isCallApi =
    (userOwnership === USER_OWNERSHIP.FRANCHISOR || (userOwnership === USER_OWNERSHIP.ACCOUNT && (accountDetails?.social_media?.length || accountDetails?.account_profiles[0]?.yelp_profile_url))) &&
    (availablePlatforms.length === 1 ? queryParams.platform : true)
      ? true
      : false;

  const validQueryParams = stringifyValidQueryParams(queryParams);

  // api request for getting ai review responder details
  useEffect(() => {
    dispatch(getReviewResponderRequest({ userOwnership, id }));
  }, [id, userOwnership, dispatch]);

  useEffect(() => {
    scrollToTop();
    dispatch(filterStatus(true));
    return () => {
      dispatch(filterStatus(false));
      dispatch(reviewsResetAll());
      dispatch(addPostActionsResetAll());
    };
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership && isCallApi) {
      setCurrentPageCount(1);
      dispatch(reviewsListDataReset());
      if (id && userOwnership === USER_OWNERSHIP.FRANCHISOR && isCallApi) {
        dispatch(addPostResetAll());
      }
    }
  }, [id, userOwnership, isCallApi]); // eslint-disable-line

  useEffect(() => {
    if (reviewsSuccessMsg === REVIEWS_ADD_REPLY_SUCCESS_MSG) {
      handleReviewsDataRequest(0, true);
    }
  }, [reviewsSuccessMsg]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership && isValidId) {
      setCurrentPageCount(1);
      handleReviewsDataRequest(1, true);
    }
  }, [validQueryParams, isValidId]); // eslint-disable-line

  const handleLoadMoreItems = () => {
    setCurrentPageCount(currentPageCount + 1);
  };

  useEffect(() => {
    if (currentPageCount > 1) {
      handleReviewsDataRequest(currentPageCount, false);
    }
  }, [currentPageCount]); // eslint-disable-line

  const handleReviewsDataRequest = (currentPage: number, isStatsCall: boolean) => {
    if (id && userOwnership && isCallApi) {
      const reviewsFilterReqParams = getReviewsReqParamObj(reviewsFilter);
      const reqParams = {
        id,
        userOwnership,
        [selectedHubKey]: selectedHubId,
        dateRange: [reviewsFilter.dateRange.fromDate.timeStamp, reviewsFilter.dateRange.toDate.timeStamp],
        ...reviewsFilterReqParams,
        ...(uniqueKeyWord && { searchText: uniqueKeyWord })
      };
      if (currentPage) dispatch(getReviewsListRequest({ ...reqParams, page: currentPage }));
      if (isStatsCall && isStats) {
        dispatch(getReviewsStatsRequest(reqParams));
      }
    }
  };

  return (
    <>
      {isStats && <ReviewsStats />}
      {isListFetching && currentPageCount === 1 ? (
        <Loading />
      ) : reviewsList.length ? (
        <div className="infinite-list" id="reviews-list-ifs">
          <InfiniteScroll
            scrollThreshold={SCROLL_THRESHOLD}
            next={handleLoadMoreItems}
            hasMore
            dataLength={reviewsList.length}
            loader={isListFetching ? <Loading /> : null}
            className="local-ini"
            scrollableTarget="scrollTarget"
            // height={!isStats ? '100vh' : ''}
            height={!isStats ? '95vh' : ''}
          >
            <ReviewsList isCoachMarkEnable={isCoachMark} />
          </InfiniteScroll>
        </div>
      ) : !isListFetching && !reviewsList.length ? (
        <NoDataFound size={NoDataFoundProps.FULL} />
      ) : (
        <Loading />
      )}
    </>
  );
};
