import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import BreakAxis from 'highcharts/modules/broken-axis';

import { IAreaSplineChart } from 'analytics/types';
import { ChartType, SPLINE_CHART_ADDITIONAL_OPTIONS, SPLINE_CHART_OPTIONS, ToolTipLabel } from 'analytics/utils/constants';
import { HighChartReact } from './HighChartReact';

NoDataToDisplay(Highcharts);
BreakAxis(Highcharts);

export const AreaSplineChart = ({
  title,
  isLegendEnabled = false,
  series,
  color,
  fillColor = ['#fffefc'],
  fadeColor = ['#fffff'],
  yMin,
  yMax,
  yTickInterval,
  isMarkerDisable = false,
  toolTipformatter,
  onLoadHandler,
  breaks,
  yLabelFormatter,
  plotLines,
  immutable = false,
  height = 400,
  width,
  chartType = ChartType.AREASPLINE,
  isAxisEnabled = true,
  isTooltipDisabled = false,
  isMultipleAxisDisabled = false,
  xMinPadding = 0.08,
  xMaxPadding = 0.08,
  isMouseTracking = true,
  chartOptions = {},
  yTickAmount,
  xCategories
}: IAreaSplineChart) => {
  const areaSplineSeries = () => {
    const seriesNames = Object.keys(series);
    const areaSplineOptions: Highcharts.SeriesOptionsType[] = seriesNames.map((it, index) => {
      const name = it === 'average' ? ToolTipLabel.BRAND_AVERAGE : it === 'hubAverage' ? ToolTipLabel.HUB_AVERAGE : it;
      return {
        type: series[it][0]?.chartType || chartType,
        name,
        data: series[it] || [],
        ...(color[index] && { color: color[index] }),
        ...(fillColor[index] && fadeColor[index]
          ? {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 1,
                  y2: 1
                },
                stops: [
                  [0, fillColor[index]],
                  [1, fadeColor[index]]
                ]
              }
            }
          : null),
        dashStyle: series[it][0]?.dashStyle || 'Solid',
        showInLegend: series[it][0]?.dashStyle ? true : false,
        marker: SPLINE_CHART_ADDITIONAL_OPTIONS.marker
      };
    });
    return areaSplineOptions;
  };

  const areaSplineSeriesData = areaSplineSeries();

  const areaSplineOptions: Highcharts.Options = {
    chart: {
      height,
      width,
      ...chartOptions,
      type: ChartType.AREAS,
      reflow: true,
      events: {
        load() {
          if (onLoadHandler) onLoadHandler(this);
        }
      }
    },
    ...SPLINE_CHART_OPTIONS,
    title: {
      ...SPLINE_CHART_ADDITIONAL_OPTIONS.title,
      floating: isLegendEnabled,
      text: title
    },
    tooltip: {
      ...SPLINE_CHART_ADDITIONAL_OPTIONS.tooltip,
      enabled: !isTooltipDisabled,
      formatter() {
        let toolTip = '';
        if (this && this?.points?.length && toolTipformatter) {
          toolTip = toolTipformatter(this);
        }
        return toolTip;
      }
    },
    xAxis: {
      ...SPLINE_CHART_ADDITIONAL_OPTIONS.xAxis,
      categories: xCategories,
      minPadding: xMinPadding,
      maxPadding: xMaxPadding,
      visible: areaSplineSeriesData.length > 0 && isAxisEnabled,
      labels: {
        style: {
          ...SPLINE_CHART_ADDITIONAL_OPTIONS.style,
          allowOverlap: false
        }
      }
    },
    yAxis: [
      {
        visible: isAxisEnabled,
        title: {
          text: ''
        },
        min: yMin,
        max: yMax,
        tickInterval: yTickInterval,
        lineWidth: 1,
        plotLines,
        tickAmount: yTickAmount,
        labels: {
          style: {
            ...SPLINE_CHART_ADDITIONAL_OPTIONS.style
          },
          ...(yLabelFormatter && {
            useHTML: true,
            formatter: (it) => {
              return yLabelFormatter(it.value);
            }
          })
        },
        gridLineDashStyle: 'Dash',
        breaks
      },
      ...(!isMultipleAxisDisabled ? SPLINE_CHART_ADDITIONAL_OPTIONS.yAxis : [])
    ],

    plotOptions: {
      series: {
        ...SPLINE_CHART_ADDITIONAL_OPTIONS.plotOptionsSeries,
        enableMouseTracking: isMouseTracking,
        marker: {
          enabled: !isMarkerDisable,
          radius: 3.5,
          states: {
            hover: {
              radius: 3.5
            }
          }
        }
      },
      area: {
        marker: {
          symbol: 'circle'
        }
      }
    },
    series: areaSplineSeriesData
  };

  return <HighChartReact options={areaSplineOptions} immutable={immutable} />;
};
