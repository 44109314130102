import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { MediaGalleryAction } from 'actions';
import { mgReducerTypes } from 'types/media-gallery';
import {
  MG_STATS_INITIAL_VALUE,
  MgMediaType,
  MG_UPDATE_DETAIL_DATA_SUCCES,
  MG_UPDATE_DETAIL_DATA_FAILED,
  IMAGE_CROP_PROPS,
  MG_ADD_MEDIA_ASSET_SUCCESS_MSG,
  MG_FILTER_STATS_INIT_OBJ,
  MG_UPLOAD_MEDIA_IN_CLOUD_INIT_OBJ,
  MG_ADD_ASSETS_INIT_OBJ,
  MG_UPLOAD_PROGRESS_INFO_INIT_OBJ,
  MG_CLONE_ASSET_SUCCESS_MSG,
  MediaDropPages,
  AddMediaExpirationValues,
  MG_MEDIA_RELEASE_VALUES,
  MG_MEDIA_SETTINGS_VALUES,
  MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES,
  CreatorMediaTypeForUser,
  MG_ADD_MULTIPLE_MEDIA_ASSETS_SUCCESS_MSG
} from 'utils/constants';
import { getMultipleAssetsUploadedInCloud, mgLikeOrDisLikeStatusUpdate, mgGetUpdatedAssetsList, getMGFilterStatsData, getCloudinaryAssetInitialResponse } from 'utils/helpers';

const initialState: mgReducerTypes.IMgReducer = {
  isCloudinaryUpload: false,
  currentPageCount: 1,
  isTagFetching: false,
  isLikeFetching: false,
  isDislikeFetching: false,
  isMediaReleaseFetching: false,
  isListFetching: true,
  isStatsFetching: true,
  isUpdateMGDetailData: false,
  isDetailFetching: true,
  error: null,
  assetsList: [],
  uploadCloudinaryMedia: MG_UPLOAD_MEDIA_IN_CLOUD_INIT_OBJ,
  addMediaAssets: MG_ADD_ASSETS_INIT_OBJ,
  mgStats: MG_STATS_INITIAL_VALUE,
  mgTagsList: [],
  favTagsList: [],
  deleteMgResponse: null,
  deleteMgError: null,
  mediaReleases: [],
  mediaReleasesError: null,
  mgDetails: null,
  mgUpdatedResponse: null,
  mgUpdatedError: null,
  mgMediaCropperPorps: IMAGE_CROP_PROPS,
  assetUploadedId: null,
  editedMgDetails: null,
  filterStats: MG_FILTER_STATS_INIT_OBJ,
  watermarkUpload: {
    showModal: false,
    isUpload: false,
    response: null,
    watermarkImageObj: null,
    isSaveRequest: false
  },
  uploadProgressInfo: MG_UPLOAD_PROGRESS_INFO_INIT_OBJ,
  isMediaCloned: false,
  detailViewError: null,
  isAIImageUpdating: false,
  videoThumbnailRes: [],
  isVideoThumbnailUploading: false,
  isMediaReleaseSignatureUploading: false,
  mediaReleaseSignatures: [],
  mediaExpiration: { type: AddMediaExpirationValues.NEVER, date: null },
  mediaGallerySettings: {
    mgAvailMediaForUser: CreatorMediaTypeForUser.PERSONAL,
    mgIsImageLockPost: false,
    have_media_release: MG_MEDIA_RELEASE_VALUES.NO,
    media_apply_settings: MG_MEDIA_SETTINGS_VALUES.ALL,
    have_custom_thumbnail: MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES.DEFAULT
  },
  selectedMediaIds: [],
  multiSelectMediaList: [],
  multiSelectCurrentPageCount: 1,
  isMultiSelectMGListFetching: false
};

const mediaGalleryReducer: Reducer<mgReducerTypes.IMgReducer, MediaGalleryAction> = (state: mgReducerTypes.IMgReducer = initialState, action: MediaGalleryAction): mgReducerTypes.IMgReducer => {
  switch (action.type) {
    // Get filter stats
    case types.MG_FILTER_STATS_REQUEST:
      return {
        ...state,
        isStatsFetching: true,
        filterStats: !action.payload.usage ? MG_FILTER_STATS_INIT_OBJ : state.filterStats
      };
    case types.MG_FILTER_STATS_RESPONSE:
      return {
        ...state,
        isStatsFetching: false,
        filterStats: getMGFilterStatsData(state.filterStats, action.payload)
      };
    case types.MG_FILTER_STATS_FAILURE:
      return {
        ...state,
        isStatsFetching: false,
        // filterStats: MG_FILTER_STATS_INIT_OBJ,
        error: action.payload || 'Unexpected error'
      };
    // Get media gallery favourite tags list based on search
    case types.MG_TAGS_REQUEST:
      return {
        ...state,
        isTagFetching: true,
        error: null
      };
    case types.MG_TAGS_RESPONSE:
      return {
        ...state,
        isTagFetching: false,
        mgTagsList: action.payload
      };
    case types.MG_TAGS_FAILURE:
      return {
        ...state,
        isTagFetching: false,
        mgTagsList: [],
        error: action.payload || 'Unexpected error'
      };
    // Get all media gallery favourite tags list from db
    case types.MG_GET_FAV_TAGS_REQUEST:
      return {
        ...state,
        isTagFetching: true,
        error: null
      };
    case types.MG_GET_FAV_TAGS_RESPONSE:
      return {
        ...state,
        isTagFetching: false,
        favTagsList: action.payload
      };
    case types.MG_GET_FAV_TAGS_FAILURE:
      return {
        ...state,
        isTagFetching: false,
        favTagsList: [],
        error: action.payload || 'Unexpected error'
      };
    // Set media gallery Tags List
    case types.MG_SET_TAGS_LIST:
      return {
        ...state,
        favTagsList: action.payload
      };
    // Get Media gallery assets list
    case types.MG_LIST_REQUEST:
      return {
        ...state,
        isListFetching: true,
        currentPageCount: action.payload.page || 1,
        error: null,
        isAIImageUpdating: false
      };
    case types.MG_LIST_RESPONSE:
      return {
        ...state,
        isListFetching: false,
        assetsList: action.payload.page > 1 ? state.assetsList.concat(action.payload.assets) : action.payload.assets || []
      };
    case types.MG_LIST_FAILURE:
      return {
        ...state,
        isListFetching: false,
        assetsList: [],
        error: action.payload || 'Unexpected error'
      };
    // Get MultiSelect Media gallery assets list
    case types.GET_MULTISELECT_MG_LIST_REQUEST:
      return {
        ...state,
        isMultiSelectMGListFetching: true,
        multiSelectCurrentPageCount: action.payload.page || 1,
        error: null
      };
    case types.GET_MULTISELECT_MG_LIST_RESPONSE:
      return {
        ...state,
        isMultiSelectMGListFetching: false,
        multiSelectMediaList: action.payload.page > 1 ? state.multiSelectMediaList.concat(action.payload.assets) : action.payload.assets || []
      };
    case types.GET_MULTISELECT_MG_LIST_FAILURE:
      return {
        ...state,
        isMultiSelectMGListFetching: false,
        multiSelectMediaList: [],
        error: action.payload || 'Unexpected error'
      };

    // Upload media asset to cloudinary
    case types.MG_CLOUD_MEDIA_UPLOAD_REQUEST:
      const videoThumbnails = action.payload.videoCloudinaryId
        ? [...state.videoThumbnailRes].filter((thumb) => thumb.videoCloudinaryId !== action.payload.videoCloudinaryId)
        : [...state.videoThumbnailRes].filter((thumb) => thumb.fileName !== action.payload.fileName);

      const updatedThumbnailRes = action.payload.videoCloudinaryId
        ? [...videoThumbnails, getCloudinaryAssetInitialResponse(action.payload.fileName)]
        : [getCloudinaryAssetInitialResponse(action.payload.fileName)];
      return {
        ...state,
        isCloudinaryUpload: true,
        ...(action.payload?.from === MediaDropPages.CUSTOMIZE_THUMBNAIL
          ? { videoThumbnailRes: updatedThumbnailRes }
          : {
              uploadCloudinaryMedia: {
                ...state.uploadCloudinaryMedia,
                response:
                  action.payload.isReset === true
                    ? [getCloudinaryAssetInitialResponse(action.payload.fileName)]
                    : !action.payload.isMediaRelease
                    ? [...state.uploadCloudinaryMedia.response, getCloudinaryAssetInitialResponse(action.payload.fileName)]
                    : state.uploadCloudinaryMedia.response
              }
            }) /* IMgUploadMediaInCloud */,
        isAIImageUpdating: action.payload.isAIImage ? true : false,
        isVideoThumbnailUploading: true
      };
    case types.MG_S3_CLOUD_MEDIA_UPLOAD_REQUEST:
      return {
        ...state,
        isCloudinaryUpload: true,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          response: [...state.uploadCloudinaryMedia.response, getCloudinaryAssetInitialResponse(action.payload.filename)]
        }
      };
    case types.MG_CLOUD_MEDIA_UPLOAD_RESPONSE:
      const cloudResponseData = getMultipleAssetsUploadedInCloud(action.payload, state.uploadCloudinaryMedia.response);
      const modifiedCloudResponse = action.payload?.order?.length
        ? cloudResponseData.sort((x, y) => action.payload.order.indexOf(x.fileName) - action.payload.order.indexOf(y.fileName))
        : cloudResponseData;
      return {
        ...state,
        isCloudinaryUpload: false,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          response: modifiedCloudResponse,
          addedAssetsCount: cloudResponseData.length,
          cloudinaryData: action.payload.cloudinaryResponse
        },
        isVideoThumbnailUploading: false
      };
    case types.MG_CLOUD_MEDIA_UPLOAD_FAILURE:
      return {
        ...state,
        isCloudinaryUpload: false,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          response: [],
          addedAssetsCount: 0
        },
        error: action.payload,
        isAIImageUpdating: false
      };
    case types.MG_VALID_CLOUD_MEDIA_UPLOAD_LIST:
      const validMediaList = state.uploadCloudinaryMedia.response.filter((it) => it.fileName !== action.payload);
      return {
        ...state,
        isCloudinaryUpload: false,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          response: validMediaList,
          addedAssetsCount: validMediaList.length
        },
        uploadProgressInfo: MG_UPLOAD_PROGRESS_INFO_INIT_OBJ
      };
    // Media gallery delete Asset
    case types.MG_DELETE_ASSET_REQUEST:
      return {
        ...state,
        deleteMgResponse: null,
        deleteMgError: null
      };
    case types.MG_DELETE_ASSET_RESPONSE:
      let assetsListArray = [...state.assetsList];
      if (action.payload.id) {
        assetsListArray = assetsListArray.filter((dataItem) => dataItem.id !== action.payload.id);
      }
      return {
        ...state,
        deleteMgResponse: action.payload.message,
        assetsList: assetsListArray,
        mgStats: {
          totalAssets: state.mgStats.totalAssets - 1,
          totalPhotos: action.payload.mediaType === MgMediaType.PHOTOS ? state.mgStats.totalPhotos - 1 : state.mgStats.totalPhotos,
          totalVideos: action.payload.mediaType === MgMediaType.PHOTOS ? state.mgStats.totalVideos - 1 : state.mgStats.totalVideos
        }
      };
    case types.MG_DELETE_ASSET_FAILURE:
      return {
        ...state,
        deleteMgResponse: null,
        deleteMgError: action.payload
      };
    // Get added assets count
    case types.MG_GET_ADDED_ASSETS_COUNT:
      return {
        ...state,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          addedAssetsCount: action.payload
        }
      };
    case types.MG_GET_CLOUD_RESPONSE_LIST:
      return {
        ...state,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          response: action.payload
        }
      };
    // Add media asset
    case types.MG_ADD_MEDIA_ASSET_REQUEST:
      return {
        ...state,
        addMediaAssets: {
          ...state.addMediaAssets
        },
        assetUploadedId: null,
        isListFetching: true,
        isAIImageUpdating: action.payload.isAIImage ? true : false
      };
    case types.MG_ADD_MEDIA_ASSET_RESPONSE:
      return {
        ...state,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia
        },
        addMediaAssets: {
          assetsList: [...state.addMediaAssets.assetsList, action.payload.mediaType],
          assetsSuccessMessage: action.payload?.isMediaClone
            ? MG_CLONE_ASSET_SUCCESS_MSG
            : state.uploadCloudinaryMedia.addedAssetsCount === 1
            ? MG_ADD_MEDIA_ASSET_SUCCESS_MSG
            : MG_ADD_MULTIPLE_MEDIA_ASSETS_SUCCESS_MSG,
          assetsErrorMessage: null
        },
        assetUploadedId: state.uploadCloudinaryMedia.addedAssetsCount === 1 ? action.payload.mediaId : null,
        isAIImageUpdating: false
      };
    case types.MG_ADD_MEDIA_ASSET_FAILURE:
      return {
        ...state,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia
        },
        addMediaAssets: {
          ...state.addMediaAssets,
          assetsErrorMessage: action.payload || 'Unexpected error'
        },
        assetUploadedId: null,
        isAIImageUpdating: false
      };
    case types.MG_ADDED_ASSET_DETAIL_RESET:
      return {
        ...state,
        uploadCloudinaryMedia: MG_UPLOAD_MEDIA_IN_CLOUD_INIT_OBJ,
        addMediaAssets: MG_ADD_ASSETS_INIT_OBJ,
        error: initialState.error,
        isAIImageUpdating: false,
        uploadProgressInfo: MG_UPLOAD_PROGRESS_INFO_INIT_OBJ
      };
    case types.MG_DELETE_ASSET_DATA_RESET:
      return {
        ...state,
        deleteMgError: null,
        deleteMgResponse: null
      };
    // Get media gallery media releases list
    case types.MG_MEDIA_RELEASE_REQUEST:
      return {
        ...state,
        isMediaReleaseFetching: true,
        error: null
      };
    case types.MG_MEDIA_RELEASE_RESPONSE:
      return {
        ...state,
        isMediaReleaseFetching: false,
        mediaReleases: action.payload
      };
    case types.MG_MEDIA_RELEASE_FAILURE:
      return {
        ...state,
        isMediaReleaseFetching: false,
        mediaReleases: [],
        mediaReleasesError: action.payload || 'Unexpected error'
      };
    // Get media gallery Like
    case types.MG_LIKE_REQUEST:
      return {
        ...state,
        isLikeFetching: true,
        error: null
      };
    case types.MG_LIKE_RESPONSE:
      const likeResponse = mgLikeOrDisLikeStatusUpdate(state.assetsList, action.payload);
      return {
        ...state,
        isLikeFetching: false,
        assetsList: likeResponse
      };
    case types.MG_LIKE_FAILURE:
      return {
        ...state,
        isLikeFetching: false,
        assetsList: [],
        error: action.payload || 'Unexpected error'
      };
    // Get media gallery Dis like
    case types.MG_DISLIKE_REQUEST:
      return {
        ...state,
        isDislikeFetching: true,
        error: null
      };
    case types.MG_DISLIKE_RESPONSE:
      const disLikeResponse = mgLikeOrDisLikeStatusUpdate(state.assetsList, action.payload);
      return {
        ...state,
        isDislikeFetching: false,
        assetsList: disLikeResponse
      };
    case types.MG_DISLIKE_FAILURE:
      return {
        ...state,
        isDislikeFetching: false,
        assetsList: [],
        error: action.payload || 'Unexpected error'
      };
    // Get media gallery detail data
    case types.MG_DETAIL_DATA_REQUEST:
      return {
        ...state,
        isDetailFetching: true,
        mgDetails: action.payload?.isDataRetain ? state.mgDetails : null,
        editedMgDetails: action.payload?.isDataRetain ? state.editedMgDetails : null
      };
    case types.MG_DETAIL_DATA_RESPONSE:
      return {
        ...state,
        isDetailFetching: false,
        mgDetails: action.payload?.id ? action.payload : null,
        editedMgDetails: action.payload?.id ? action.payload : null,
        mgMediaCropperPorps: { ...IMAGE_CROP_PROPS, image: action.payload?.medium_size_url || '' }
      };
    case types.MG_DETAIL_DATA_FAILURE:
      return {
        ...state,
        isDetailFetching: false,
        mgDetails: null,
        editedMgDetails: null,
        error: action.payload || 'Unexpected error',
        detailViewError: action.payload || 'No data found'
      };
    // Update media gallery detail data
    case types.MG_UPDATE_DETAIL_DATA_REQUEST:
      return {
        ...state,
        isUpdateMGDetailData: true,
        mgUpdatedResponse: null,
        mgUpdatedError: null
      };
    case types.MG_UPDATE_DETAIL_DATA_RESPONSE:
      return {
        ...state,
        isUpdateMGDetailData: false,
        mgUpdatedResponse: MG_UPDATE_DETAIL_DATA_SUCCES,
        mgUpdatedError: null,
        assetsList: mgGetUpdatedAssetsList(state.assetsList, action.payload) || [...state.assetsList],
        mgDetails: action.payload,
        editedMgDetails: action.payload?.id ? action.payload : null,
        mgMediaCropperPorps: { ...IMAGE_CROP_PROPS, image: action.payload?.medium_size_url || '' }
      };
    case types.MG_UPDATE_DETAIL_DATA_FAILURE:
      return {
        ...state,
        isUpdateMGDetailData: false,
        mgUpdatedResponse: null,
        mgUpdatedError: MG_UPDATE_DETAIL_DATA_FAILED
      };
    case types.MG_UPDATE_ASSET_DATA_RESET:
      return {
        ...state,
        mgUpdatedResponse: null,
        mgUpdatedError: null
      };
    case types.MG_DETAIL_VIEW_RESET:
      return {
        ...state,
        mgDetails: initialState.mgDetails,
        assetUploadedId: initialState.assetUploadedId,
        editedMgDetails: initialState.editedMgDetails,
        mgMediaCropperPorps: initialState.mgMediaCropperPorps,
        isMediaCloned: initialState.isMediaCloned
      };
    case types.SET_EDIT_MG_DETAILS:
      return {
        ...state,
        editedMgDetails: { ...state.editedMgDetails, ...action.payload }
      };
    case types.SHOW_HIDE_WATERMARK_MODAL:
      return {
        ...state,
        watermarkUpload: { ...state.watermarkUpload, showModal: action.payload.show, response: !action.payload.show ? null : state.watermarkUpload.response }
      };
    case types.CANCEL_UPLOAD:
      return {
        ...state,
        watermarkUpload: { ...state.watermarkUpload, isUpload: false }
      };
    case types.UPLOAD_WATERMARK_IMAGE_CLOUDINARY_REQUEST:
      return {
        ...state,
        watermarkUpload: { ...state.watermarkUpload, isUpload: true, response: null }
      };
    case types.UPLOAD_WATERMARK_IMAGE_CLOUDINARY_RESPONSE:
      return {
        ...state,
        watermarkUpload: { ...state.watermarkUpload, isUpload: false, response: action.payload.response }
      };
    case types.GET_WATERMARK_IMAGE_RESPONSE:
      return {
        ...state,
        watermarkUpload: { ...state.watermarkUpload, watermarkImageObj: action.payload, response: null, isUpload: false }
      };
    case types.SAVE_WATERMARK_IMAGE_REQUEST:
      return {
        ...state,
        watermarkUpload: { ...state.watermarkUpload, isSaveRequest: true }
      };
    case types.SAVE_WATERMARK_IMAGE_RESPONSE:
      return {
        ...state,
        watermarkUpload: { ...state.watermarkUpload, isSaveRequest: false }
      };
    case types.MG_SET_UPLOAD_PROGRESS_INFO:
      return {
        ...state,
        uploadProgressInfo: action.payload
      };
    case types.MG_CLONE_ASSET_REQUEST:
      return {
        ...state,
        isListFetching: true,
        isMediaCloned: false
      };
    case types.MG_CLONE_ASSET_RESPONSE:
      return {
        ...state,
        addMediaAssets: {
          assetsList: !action.payload?.id && !action.payload?.userOwnership ? [action.payload.data.media_type] : [],
          assetsSuccessMessage: MG_CLONE_ASSET_SUCCESS_MSG,
          assetsErrorMessage: null
        },
        assetUploadedId: !action.payload?.id && !action.payload?.userOwnership ? action.payload.data.id : null,
        isMediaCloned: true
      };
    case types.MG_CLONE_ASSET_FAILURE:
      return {
        ...state,
        addMediaAssets:
          !action.payload?.id && !action.payload?.userOwnership
            ? {
                ...state.addMediaAssets,
                assetsErrorMessage: action.payload.errorMsg || 'Unexpected error'
              }
            : initialState.addMediaAssets,
        assetUploadedId: null,
        isMediaCloned: false
      };
    case types.MEDIA_DETAIL_VIEW_ERROR:
      return {
        ...state,
        detailViewError: action.payload
      };

    case types.MG_AI_IMAGE_UPLOADING_REQUEST:
      return {
        ...state,
        isAIImageUpdating: action.payload
      };

    case types.MG_ADD_CUSTOM_VIDEO_THUMBNAIL_REQUEST:
      const response = state.uploadCloudinaryMedia.response;
      const selectedMediaIndex = response.findIndex((media) => media.cloudinaryId! === action.payload.cloudinaryId);
      const updatedVideoResponse = [...response];
      updatedVideoResponse[selectedMediaIndex] = { ...response[selectedMediaIndex], ...action.payload };
      return {
        ...state,
        isCloudinaryUpload: true,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          response: updatedVideoResponse
        }
      };
    case types.MG_ADD_CUSTOM_VIDEO_THUMBNAIL_RESPONSE:
      const videoThumbnailRes = getMultipleAssetsUploadedInCloud(action.payload, state.videoThumbnailRes, action.payload.videoCloudinaryId);
      const selectedCloudinaryMediaIndex = state.uploadCloudinaryMedia.response.findIndex((cloudinaryData) => cloudinaryData.cloudinaryId === action.payload.videoCloudinaryId);
      const selectedVideoThumbnailIndex = videoThumbnailRes.findIndex((thumbCloudinary) => thumbCloudinary.videoCloudinaryId === action.payload.videoCloudinaryId);
      let updatedResponse = [...state.uploadCloudinaryMedia.response];
      if (action.payload.videoCloudinaryId && updatedResponse.length && selectedCloudinaryMediaIndex >= 0) {
        updatedResponse[selectedCloudinaryMediaIndex] = {
          ...state.uploadCloudinaryMedia.response?.[selectedCloudinaryMediaIndex],
          thumbnail_url: videoThumbnailRes[selectedVideoThumbnailIndex].secureUrl,
          cover_cloudinary_id: videoThumbnailRes[selectedVideoThumbnailIndex].cloudinaryId
        };
      } else {
        updatedResponse = updatedResponse.map((res) => {
          return {
            ...res,
            thumbnail_url: videoThumbnailRes[0].secureUrl,
            cover_cloudinary_id: videoThumbnailRes[0].cloudinaryId
          };
        });
      }
      return {
        ...state,
        isCloudinaryUpload: false,
        videoThumbnailRes,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          addedAssetsCount: state.uploadCloudinaryMedia.addedAssetsCount - 1,
          response: updatedResponse
        },
        isVideoThumbnailUploading: false
      };
    case types.MG_RESET_CUSTOM_VIDEO_THUMBNAIL_RESPONSE:
      let cloudinaryMediaRes = [...state.uploadCloudinaryMedia.response];
      if (cloudinaryMediaRes.length) {
        cloudinaryMediaRes = cloudinaryMediaRes.map((mediaRes) => ({ ...mediaRes, thumbnail_url: null, cover_cloudinary_id: null }));
      }
      return {
        ...state,
        isCloudinaryUpload: false,
        videoThumbnailRes: [],
        ...(state.uploadCloudinaryMedia.response.length && {
          uploadCloudinaryMedia: {
            ...state.uploadCloudinaryMedia,
            response: cloudinaryMediaRes
          }
        })
      };
    case types.MG_REMOVE_CUSTOM_VIDEO_THUMBNAIL_RESPONSE:
      const filteredVideoThumbnailRes = state.videoThumbnailRes.filter((thumbnail) => thumbnail?.videoCloudinaryId !== action.payload);
      return {
        ...state,
        isCloudinaryUpload: false,
        videoThumbnailRes: filteredVideoThumbnailRes
      };

    case types.MG_ADD_MEDIA_RELEASE_REQUEST:
      return {
        ...state,
        mediaReleaseSignatures: action.payload
      };
    case types.MG_DELETE_MEDIA_RELEASE_REQUEST:
      const mediaReleaseSignatureFilterArr = state.mediaReleaseSignatures.filter((signature) => signature?.fileName !== action.payload);
      return {
        ...state,
        mediaReleaseSignatures: mediaReleaseSignatureFilterArr
      };

    case types.MG_ADD_MEDIA_RELEASE_RESPONSE:
      const updatedMediaReleaseSignatures = [...state.mediaReleaseSignatures];
      const selectedMediaReleaseIndex = state.mediaReleaseSignatures.findIndex((signature) => signature.fileName === action.payload.fileName);
      if (selectedMediaReleaseIndex >= 0) {
        updatedMediaReleaseSignatures[selectedMediaReleaseIndex].id = action.payload.id;
        updatedMediaReleaseSignatures[selectedMediaReleaseIndex].signature_url = action.payload.signature_url;
      }
      return {
        ...state,
        mediaReleaseSignatures: updatedMediaReleaseSignatures,
        isCloudinaryUpload: false
      };
    case types.MG_RESET_MEDIA_RELEASE:
      return {
        ...state,
        mediaReleaseSignatures: []
      };
    case types.MG_UPDATE_CLOUD_MEDIA_RESPONSE:
      const updatedCloudinaryRes = [...state.uploadCloudinaryMedia.response];
      updatedCloudinaryRes[action.payload.index] = action.payload.response;
      return {
        ...state,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          response: updatedCloudinaryRes
        }
      };
    case types.MG_UPDATE_MEDIA_EXPIRATION:
      return {
        ...state,
        mediaExpiration: action.payload
      };
    case types.MEDIA_GALLERY_UPDATE_SETTINGS:
      return {
        ...state,
        mediaGallerySettings: {
          ...state.mediaGallerySettings,
          ...action.payload
        }
      };
    case types.MEDIA_GALLERY_RESET_SETTINGS:
      const cloudinaryRes = state.uploadCloudinaryMedia.response?.map((res) => ({
        ...res,
        have_mediaRelease: false
      }));
      return {
        ...state,
        mediaGallerySettings: initialState.mediaGallerySettings,
        mediaExpiration: initialState.mediaExpiration,
        mediaReleaseSignatures: initialState.mediaReleaseSignatures,
        videoThumbnailRes: initialState.videoThumbnailRes,
        uploadCloudinaryMedia: {
          ...state.uploadCloudinaryMedia,
          response: cloudinaryRes
        }
      };
    case types.MG_RESET_MEDIA_EXPIRATION:
      return {
        ...state,
        mediaExpiration: initialState.mediaExpiration
      };
    case types.MG_UPDATE_SELECTED_MULTIPLE_MEDIA_LIST:
      return {
        ...state,
        selectedMediaIds: action.payload
      };
    case types.MG_RESET_SELECTED_MULTIPLE_MEDIA_LIST:
      return {
        ...state,
        selectedMediaIds: initialState.selectedMediaIds
      };
    case types.MG_RESET_ALL:
      return { ...initialState };
    default: {
      return state;
    }
  }
};

export { mediaGalleryReducer };
