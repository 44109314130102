import { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IStore } from 'types';
import { REVIEW_ANALYTICS_FILTER_INITIAL_VALUE, DateFormatType, ChartColors, squigglyLineSvgPath, KEYWORDS_CATEGORY_CHART_DATA } from 'analytics/utils/constants';
import { dateFormatter, multiSeriesToolTipFormatter, yLabelFormatter, onLoadHandler, validData } from 'analytics/utils/helpers';
import { useChartTheme } from 'analytics/utils/hooks';
import { getKeyWordsCategoryAnalyticsRequest, getKeyWordsCategoryChartData } from 'analytics/actions';
import { IKeywordsCAChartData, IKeywordsCAGraphicsData, IKeywordsCategoryAnalyticsRequest } from 'analytics/types';
import { AreaSplineChart, CustomIndicator } from 'analytics/components';
import { USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { getFormattedNumber } from 'utils/helpers';
import { Loading } from 'components';
import { ImageValidation } from 'widgets/Media';

export const KeywordsCategoryAnalyticsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { queryParams } = useParamsDeconstructor();
  const { id, userOwnership } = useAccountSwitcherData();
  const chartData = useChartTheme(KEYWORDS_CATEGORY_CHART_DATA, 'KeywordsCAGraphColors', 'KeywordCAGraphFillColors', 'KeywordCAGraphFadeColors');

  const keywordsCategoryList = useSelector((state: IStore) => state.reviewAnalytics.keywordsCategoryList);
  const keywordsCAChartData = useSelector((state: IStore) => state.reviewAnalytics.keywordsCAChartData);
  const keywordsCALeaderboard = useSelector((state: IStore) => state.reviewAnalytics.keywordsCALeaderboard);
  const isKeywordsCategoryAnalyticsFetching = useSelector((state: IStore) => state.reviewAnalytics.isKeywordsCategoryAnalyticsFetching);

  const [activeCategory, setActiveCategory] = useState<number | null>(keywordsCategoryList.findIndex((category) => category.id === Number(queryParams?.category)) + 1 || null);
  const [graphSeries, setGraphSeries] = useState<IKeywordsCAGraphicsData>({ data: {} });

  const chartColorOptions = [...chartData].filter((it: { [key: string]: string }) => Object.keys(graphSeries?.data).includes(it.value));
  const primarySvgPath = squigglyLineSvgPath.replaceAll('hpx', '113px');

  const maxArray = keywordsCAChartData ? keywordsCAChartData?.map((it) => it.review_keyword_category_cumulative_score) : [];
  const maxValue = maxArray.sort((a, b) => a - b)[maxArray.length - 1];

  useLayoutEffect(() => {
    if (keywordsCAChartData && keywordsCAChartData.length) {
      const series = {
        data: {
          location_score: keywordsCAChartData.map((it: IKeywordsCAChartData) => {
            return {
              y: it.review_keyword_category_cumulative_score || 0,
              toolTipY: it.review_keyword_category_cumulative_score || 0
            };
          })
        },
        tickAmount: 6
      };
      setGraphSeries(series);
    }
  }, [keywordsCAChartData, queryParams?.filter]);

  useEffect(() => {
    const payload: IKeywordsCategoryAnalyticsRequest = {
      account_id: id,
      start_date: dateFormatter(queryParams?.start_date || REVIEW_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.fromDate.selected, DateFormatType.API),
      end_date: dateFormatter(queryParams?.end_date || REVIEW_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.toDate.selected, DateFormatType.API),
      network: queryParams?.platform || ''
    };
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && !isKeywordsCategoryAnalyticsFetching) {
      dispatch(getKeyWordsCategoryAnalyticsRequest(payload));
    }
  }, [userOwnership, queryParams?.platform, dispatch, id, queryParams?.start_date, queryParams?.end_date]); // eslint-disable-line

  useEffect(() => {
    if (!isKeywordsCategoryAnalyticsFetching) {
      dispatch(getKeyWordsCategoryChartData(activeCategory));
    }
  }, [activeCategory, isKeywordsCategoryAnalyticsFetching, dispatch]); // eslint-disable-line

  const routePush = (category: string) => {
    if (category) {
      queryParams.category = category;
    } else {
      delete queryParams.category;
    }
    navigate({ search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const reviewCategory = `review_keyword_category_${activeCategory}`;
  const deltaRank = keywordsCALeaderboard?.[`${reviewCategory}_delta_rank`];
  const deltaScore = keywordsCALeaderboard?.[`${reviewCategory}_score_delta`];

  return (
    <div className="an-reviews-highlights lpx">
      <div className="ra-card ns">
        <ImageValidation isImgValid defaultImg="triangle-w" customName="indicator" customClassname={`an-chart--indicator keywords`} />
      </div>
      <div className="rewards-details-tab-section-wrp mb-20">
        <div className="header-item__wrp">
          <div className="item-02">
            <ul>
              <li
                key={`category-all`}
                className={`ripple${!activeCategory ? ` active` : ``}`}
                onClick={() => {
                  routePush('');
                  setActiveCategory(null);
                }}
              >
                <span className="sub-nav-item-txt">{'All'}</span>
              </li>
              {keywordsCategoryList.map((category, index) => (
                <li
                  key={`category-${index}`}
                  className={`ripple${index + 1 === activeCategory ? ` active` : ``}`}
                  title={category.name}
                  onClick={() => {
                    queryParams.category = category.id.toString();
                    routePush(category.id.toString());
                    setActiveCategory(index + 1);
                  }}
                >
                  <span className="sub-nav-item-txt">{category.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex-item-xs pb-20">
        <div className="pa-card">
          <div className="ra-card over-view-chart ascntnt">
            <div className="keywords-sr-wrap">
              <div className="dv-head dv-big">
                <span className="categoryPropValue">
                  <span className="cpv-left">Category :</span>
                  <span className="cpv-right" title={`${activeCategory === null ? 'All' : keywordsCALeaderboard?.[`review_keyword_category_${activeCategory}_name`]}`}>
                    {`${activeCategory === null ? 'All' : keywordsCALeaderboard?.[`review_keyword_category_${activeCategory}_name`]}`}
                  </span>
                </span>
                <div className="deltaHolder">
                  <span className="ksr-head-big">{maxValue}</span>
                  {activeCategory && validData(deltaScore) ? (
                    <>
                      <CustomIndicator value={deltaScore ? +deltaScore : 0} />
                      <span className="ksr-head-small" title={`${deltaScore && (+deltaScore >= 10000 || +deltaScore <= -10000) ? getFormattedNumber(+deltaScore) : ''}`}>
                        {deltaScore}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {activeCategory && (
                <div className="dv-head dv-small">
                  <div className="ranking">
                    <div className="rank-title">Ranking</div>
                    <div className="rank-value__wrp">
                      <div className="rank-value">{keywordsCALeaderboard?.[`review_keyword_category_${activeCategory}_rank`]}</div>
                      {validData(deltaRank) ? (
                        <div className="delta-wrp">
                          <CustomIndicator value={deltaRank ? +deltaRank : 0} />
                          <span className="delta-small-text">{deltaRank}</span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isKeywordsCategoryAnalyticsFetching ? (
              <Loading />
            ) : (
              <div className="topChartContainer">
                <AreaSplineChart
                  title={''}
                  series={graphSeries?.data}
                  toolTipformatter={(it: Highcharts.TooltipFormatterContextObject) => multiSeriesToolTipFormatter(it, chartColorOptions, graphSeries?.data, false, false, false, false, false)}
                  color={[...chartColorOptions].map((it) => it.color as string).concat([ChartColors.BLACK])}
                  fillColor={[...chartColorOptions].map((it) => it.fillColor)}
                  fadeColor={[...chartColorOptions].map((it) => it.fadeColor)}
                  immutable
                  isLegendEnabled
                  yLabelFormatter={(value) => yLabelFormatter(value, '', graphSeries, graphSeries, graphSeries?.tickAmount, graphSeries?.secondaryMax)}
                  onLoadHandler={(data) => onLoadHandler(data, primarySvgPath, '')}
                  xCategories={keywordsCAChartData?.map((it: IKeywordsCAChartData) => it.title || '')}
                  yTickInterval={maxValue === 0 ? 10 : graphSeries?.tickInterval}
                  yMax={maxValue === 0 ? 10 : maxValue}
                  yTickAmount={graphSeries?.tickAmount}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
