import { addPostMassUpdateForScheduledPostRequest, editPostDetailsRequest, updatePostDetailRequest } from 'actions';
import { postsContainerTypes } from 'types';
import { AP_POST_EDITING, /* FranchisorSyndicationMediaType, */ PostOrImageEditStatus, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const PostDetailEditing = ({ postsDetails, editedPostDetails, userOwnership, dispatch }: postsContainerTypes.IPostDetailEditingProps) => {
  const handleChangeLocked = (locked: boolean) => {
    if (editedPostDetails) {
      dispatch(
        editPostDetailsRequest({
          ...editedPostDetails,
          locked
        })
      );
    }
  };

  const handleUpdatePostDetails = () => {
    if (editedPostDetails?.id) {
      dispatch(
        updatePostDetailRequest({
          contentId: editedPostDetails.id,
          // actionType: FranchisorSyndicationMediaType.CONTENT,
          userOwnership,
          reqBody: {
            locked: editedPostDetails?.locked
          }
        })
      );
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        dispatch(addPostMassUpdateForScheduledPostRequest(editedPostDetails?.id));
      }
    }
  };

  return (
    <>
      <div className="st-tc-item pd-fields pd-items pdi-postEdit">
        <div className="wb-top post__white--box bradius-lt-rt-none">
          <div className="pd-field-group">
            <div className="wb-label">
              <div className="wbl-head">Post Editing</div>
            </div>
            <div className="wb-f-main">
              <div className="wb-fields">
                <div className="wb-fields bExp smExpiration pa-se-datesWrap">
                  <div className="wbf-item">
                    {editedPostDetails?.syndicated_from_content_id || editedPostDetails?.content_origins ? (
                      <div className={`wbf-inputs wbfi-txt`}>{postsDetails?.locked ? PostOrImageEditStatus.NOT_ALLOWED : PostOrImageEditStatus.ALLOWED}</div>
                    ) : (
                      <div className="wbf-inputs">
                        <div className="input-fields">
                          <div className={`if-main if-h avail-radio__group`}>
                            {AP_POST_EDITING.map((item, idx: number) => {
                              const isChecked = editedPostDetails?.locked === item.value;
                              return (
                                <label className={`${isChecked ? `active` : `in-active`}`} key={`lock-post-${idx}`}>
                                  <input type="radio" className="option-input radio" value={item.name} checked={isChecked} onChange={() => handleChangeLocked(!editedPostDetails?.locked)} />
                                  <span className="labelText">{item.label}</span>
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {postsDetails?.locked !== editedPostDetails?.locked ? (
                <div className="btn-wrp">
                  <div>
                    <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                      <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={() => handleChangeLocked(postsDetails?.locked || false)}>
                        Cancel
                      </button>
                    </CustomRippleButton>
                    <CustomRippleButton rippleClass="ripple-unset ac-primary-box r-ml2" custColor={RIPPLE_COLOR.primary}>
                      <button className="ac-btn ac-primary size-xs" onClick={handleUpdatePostDetails}>
                        Save
                      </button>
                    </CustomRippleButton>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
