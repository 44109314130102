import { useState, useEffect, useRef, useMemo } from 'react';
import { Card, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { commonModalPopup, IStore } from 'types';
import {
  ICommonDateTypes,
  AP_CONTENT_ACTION_TYPE,
  ADD_POST_APPROVE_AND_SYNDICATE_MSG,
  FranCreatorScheduleType,
  RIPPLE_COLOR,
  FranScheduledLocName,
  AP_SELECTED_LOC_INIT_DATA,
  LOCATION_LISTS,
  ALL_LOCATION_LISTS,
  FranScheduledLocLabel,
  FranchisorSyndicationMediaType,
  CORP_HUBS,
  FranCreatorScheduleDateType,
  CALENDAR_YR_MN_DATE_FORMAT
} from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import {
  addPostGetSelectedContentObj,
  addPostUpdateLinkRequest,
  addPostUpdateContentRequest,
  addPostSetSelectedLocData,
  addPostMassUpdateForScheduledPostRequest,
  addFranchisorSyndicationRequest,
  selectFranchisorToSyndicate,
  franchisorSyndicationResetAll,
  postSyndicateSuccessResponse,
  addFranchisorSyndicationResponse,
  addFranchisorMultiSyndicationRequest
} from 'actions';
import {
  getStartOfDate,
  getEndOfDate,
  getUTCDateAndTimeForSelectedData,
  getLinkDataReqPayload,
  getReqPayloadForUpdateContent,
  atLeastApproved,
  getInitialSelectedContentData,
  getFormattedDate
} from 'utils/helpers';
import { PostSyndicatedModal } from './PostSyndicatedModal';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData, useActivePlatform } from 'utils/hooks';
import { CustomDropdownWithSearch } from 'widgets/CustomDropdown';
import { FranSyndicateScheduleTiming } from './FranSyndicateScheduleTiming';

export const FranSyndicatePostModal = ({ isModalShow, handleModalClose, mediaType, postOrMediaId, isMultiSelect, handleMainModalClose }: commonModalPopup.IFranSyndicateModalProps) => {
  const dispatch = useDispatch();

  const { id } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const isApproveAndSyndicateFetching = useSelector((state: IStore) => state.addPostFranchisor.isApproveAndSyndicateFetching);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const contentSuccessMsg = useSelector((state: IStore) => state.addPostFranchisor.updatedMessage.contentSuccessMsg);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const instaType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const selectedLocData = useSelector((state: IStore) => state.addPostFranchisorAction.selectedLocData);
  const locationLists = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList?.locationList || []);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const franchisorSyndication = useSelector((state: IStore) => state.franchisors.currentFranDetails?.franchisors_for_syndication);
  const selectedSyndicateFranchisors = useSelector((state: IStore) => state.addPostFranchisor.selectedSyndicateFranchisors);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const postsLinks = useSelector((state: IStore) => state.posts.postsLinks);
  const postSyndicationSuccessMsg = useSelector((state: IStore) => state.posts.postSyndicationSuccessMsg);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const corpHubs = useSelector((state: IStore) => state.franchisors.corpHubs);
  const translationConfig = useSelector((state: IStore) => state.addPostFranchisorAction.translationConfig);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const selectedPosts = useSelector((state: IStore) => state.campaigns.selectedPosts);
  const selectedMediaIds = useSelector((state: IStore) => state.mediaGallery.selectedMediaIds);

  const [locationType, setLocationType] = useState<FranScheduledLocName>(
    mediaType === FranchisorSyndicationMediaType.CONTENT && !isContentSupplier ? FranScheduledLocName.ALL_LOCATIONS : FranScheduledLocName.ALL_FRANCHISE_CONSULTANT
  ); // FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS

  const dropdownRef = useRef(null);
  const isContentWriter = franchisorSyndication?.length && (locationType === FranScheduledLocName.FRANCHISE_CONSULTANT || locationType === FranScheduledLocName.ALL_FRANCHISE_CONSULTANT);
  const isSyndicateStatusModalOpen = contentSuccessMsg === ADD_POST_APPROVE_AND_SYNDICATE_MSG || Boolean(postSyndicationSuccessMsg);

  useEffect(() => {
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        scheduledBy: FranCreatorScheduleType.SYNDICATE_TO_ACCOUNT_DB
      })
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (selectedLocData?.id) setLocationType(FranScheduledLocName.SPECIFIC_LOCATION_LIST);
  }, [selectedLocData?.id]); // eslint-disable-line

  useEffect(() => {
    if (contentSuccessMsg === ADD_POST_APPROVE_AND_SYNDICATE_MSG || postSyndicationSuccessMsg) {
      setLocationType(mediaType === FranchisorSyndicationMediaType.CONTENT ? FranScheduledLocName.ALL_LOCATIONS : FranScheduledLocName.ALL_FRANCHISE_CONSULTANT);
      // setOpenSyndicateModal(true);
    }
  }, [contentSuccessMsg, postSyndicationSuccessMsg]); // eslint-disable-line

  const handleLocationTabSelection = (selectedKey: null | FranScheduledLocName) => {
    setLocationType(selectedKey || FranScheduledLocName.ALL_LOCATIONS);
    if (selectedLocData?.id) dispatch(addPostSetSelectedLocData(AP_SELECTED_LOC_INIT_DATA));
    if (selectedSyndicateFranchisors) dispatch(selectFranchisorToSyndicate([]));
  };

  const handleDateChange = (date: null | Date, type: string) => {
    const selectedDate = type === ICommonDateTypes.FROM_DATE ? getStartOfDate(date) : getEndOfDate(date);
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        customDate: {
          ...contentObj.customDate,
          [type]: { selected: selectedDate, utcFormat: date ? getUTCDateAndTimeForSelectedData(selectedDate) : null }
        }
      })
    );
  };

  const handleUpdateContentData = (actionName: string) => {
    const contentObjForPostDetailView = getInitialSelectedContentData(
      activePlatforms,
      postsDetails
        ? {
            ...postsDetails,
            ...((!isContentWriter || isContentSupplier) && {
              start_date:
                contentObj?.dateType === FranCreatorScheduleDateType.CUSTOM && contentObj?.customDate?.fromDate.selected
                  ? getUTCDateAndTimeForSelectedData(contentObj?.customDate?.fromDate.selected)
                  : null,
              end_date:
                contentObj?.dateType === FranCreatorScheduleDateType.CUSTOM && contentObj?.customDate?.toDate.selected
                  ? getUTCDateAndTimeForSelectedData(contentObj?.customDate?.toDate.selected)
                  : null
            }),
            syndicate_to_account_databases: true
          }
        : null,
      contentObj,
      actionName,
      postsLinks[0]
    ); // content obj for post detail view
    const contentDetails = contentData?.id ? contentData : postsDetails;
    const contentValueObj = contentData?.id ? contentObj : contentObjForPostDetailView;

    if (linkData?.id && linkData?.link_preview_image_url_choices?.length > 1) {
      dispatch(addPostUpdateLinkRequest({ linkId: linkData.id, reqPayload: getLinkDataReqPayload(linkData, contentObj.linkIndex, contentObj.isThumbnailShow) }));
    }
    if (isMultiSelect) {
      dispatch(
        addFranchisorMultiSyndicationRequest({
          mediaType,
          isContentSupplier: isContentSupplier || false,
          [`${mediaType}_ids`]: mediaType === FranchisorSyndicationMediaType.CONTENT ? selectedPosts : selectedMediaIds,
          ...(!isContentSupplier && { syndicate_to_account_databases: true }),
          ...(isContentSupplier || [FranScheduledLocName.FRANCHISE_CONSULTANT, FranScheduledLocName.ALL_FRANCHISE_CONSULTANT].includes(locationType)
            ? { franchisor_ids: locationType === FranScheduledLocName.FRANCHISE_CONSULTANT ? selectedSyndicateFranchisors : franchisorSyndication?.map((franchisor) => franchisor.id) || [] }
            : { account_list_id: locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST ? selectedLocData?.id : null }),
          ...(contentObj.dateType === FranCreatorScheduleDateType.CUSTOM && {
            delete_from_platforms_after_expiry: contentValueObj.isDeleteAutomatically,
            start_date: contentObj.customDate.fromDate.utcFormat ? getFormattedDate(contentObj.customDate.fromDate.utcFormat, CALENDAR_YR_MN_DATE_FORMAT) : null,
            end_date: contentObj.customDate.toDate.utcFormat ? getFormattedDate(contentObj.customDate.toDate.utcFormat, CALENDAR_YR_MN_DATE_FORMAT) : null
          })
        })
      );
    } else if (contentDetails?.id && activeUserId && actionName && mediaType === FranchisorSyndicationMediaType.CONTENT) {
      const filteredTranslationConfig = Object.keys(translationConfig).reduce((acc: any, curr) => {
        if (translationConfig[curr]?.isSocialMediaTranslated) {
          acc[curr] = {
            from_language: translationConfig[curr].from_language,
            to_language: translationConfig[curr].to_language,
            original_text: translationConfig[curr].original_text
          };
        }
        return acc;
      }, {});
      const saveDraftReqPayload = getReqPayloadForUpdateContent(
        id,
        activeUserId,
        contentDetails?.created_at,
        contentValueObj,
        selectedMediaList,
        selectedTags,
        selectedFBTagList,
        actionName,
        selectedLocData,
        linkData || postsLinks[0],
        instaType,
        [],
        contentDetails.content_origins,
        reducerTextEditorState?.facebook,
        postsDetails,
        contentDetails?.ai_post || isAiPost,
        Object.keys(filteredTranslationConfig)?.length ? filteredTranslationConfig : null
        // getSyndicatedAccountIds(locationType, totalLocations, selectedLocations, corporateAccountId)
      );
      dispatch(
        addPostUpdateContentRequest({
          actionType: actionName,
          postId: contentDetails.id,
          reqPayload: saveDraftReqPayload,
          ...(isContentWriter && {
            syndicateFranchisor: locationType === FranScheduledLocName.FRANCHISE_CONSULTANT ? selectedSyndicateFranchisors : franchisorSyndication?.map((franchisor) => franchisor.id)
          })
        })
      );
      dispatch(postSyndicateSuccessResponse(ADD_POST_APPROVE_AND_SYNDICATE_MSG));
      handleContentMassUpdate();
    } else if (postOrMediaId && (isContentWriter || isContentSupplier)) {
      dispatch(
        addFranchisorSyndicationRequest({
          mediaType,
          id: postOrMediaId,
          franchisor_ids: locationType === FranScheduledLocName.FRANCHISE_CONSULTANT ? selectedSyndicateFranchisors : franchisorSyndication?.map((franchisor) => franchisor.id) || []
        })
      );
    }
  };

  const handleContentMassUpdate = () => {
    if (contentData?.id && atLeastApproved(contentData?.status_id)) {
      dispatch(addPostMassUpdateForScheduledPostRequest(contentData?.id));
    }
  };

  const disableSyndicateAction = () => {
    if (isApproveAndSyndicateFetching || (locationType === FranScheduledLocName.FRANCHISE_CONSULTANT && !selectedSyndicateFranchisors.length)) return true;
    else if (locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST && !selectedLocData.id) return true;
    else if (contentObj.dateType === FranCreatorScheduleDateType.CUSTOM && !contentObj?.customDate?.toDate?.selected) return true;
    return false;
  };

  const dropdownOptions = useMemo(() => {
    return isContentSupplier
      ? [
          {
            label: CORP_HUBS,
            options: corpHubs
          }
        ]
      : [
          {
            label: LOCATION_LISTS,
            options: locationLists
          }
        ];
  }, [locationLists, corpHubs, isContentSupplier]);

  const handleSyndicateResetData = () => {
    // setLocationType(FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS);
    // dispatch(franchisorScheduleTreeReset());
    dispatch(franchisorSyndicationResetAll());
    handleModalClose();
  };

  const handleSelectSyndicateFranchisors = (id: number) => {
    dispatch(
      selectFranchisorToSyndicate(selectedSyndicateFranchisors.includes(id) ? selectedSyndicateFranchisors.filter((franchisorId) => franchisorId !== id) : [...selectedSyndicateFranchisors, id])
    );
  };

  return (
    <>
      <Modal
        // className="creator-tag-modal-wrp schedule-post-modal-hubuser schedule-post__main syndicate-post-modal-hubuser"
        className={`glbl__modal--wrp syndicate-post-modal-hubuser syndicate__modal--wrp ${isContentSupplier ? 'fssTiming-off' : 'fssTiming-on'}`}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModalShow && !isSyndicateStatusModalOpen}
        animation={false}
        onHide={handleSyndicateResetData}
      >
        <div className="mod__close--icon">
          <ImageValidation isImgValid defaultImg={'close-creator'} customName={'close'} customClassname="mod__close--img" onClick={handleSyndicateResetData} />
        </div>

        <Modal.Body>
          <h3 className="glbl__title--txt">Syndicate</h3>

          <Card className="glbl__modal--card">
            <Card.Header>Where</Card.Header>
            <Card.Body>
              <Tabs className="card-header-tabs" activeKey={locationType} onSelect={(selectedKey) => handleLocationTabSelection(selectedKey as FranScheduledLocName)}>
                {mediaType === FranchisorSyndicationMediaType.CONTENT && !isContentSupplier && (
                  <Tab
                    eventKey={FranScheduledLocName.ALL_LOCATIONS}
                    title={
                      <div>
                        <Form.Group className="form-group" controlId="formBasicCheckbox1">
                          <Form.Check type="radio" label={FranScheduledLocLabel.ALL_LOCATIONS} checked={locationType === FranScheduledLocName.ALL_LOCATIONS} readOnly />
                        </Form.Group>
                      </div>
                    }
                  />
                )}

                {mediaType === FranchisorSyndicationMediaType.CONTENT && !isContentSupplier && (
                  <Tab
                    eventKey={FranScheduledLocName.SPECIFIC_LOCATION_LIST}
                    title={
                      <div>
                        <Form.Group className="form-group" controlId="formBasicCheckbox2">
                          <Form.Check type="radio" label={FranScheduledLocLabel.SPECIFIC_LOCATION_LIST} checked={locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST} readOnly />
                        </Form.Group>
                      </div>
                    }
                  >
                    <div className="form-group form-field-item">
                      <CustomDropdownWithSearch
                        dropdownRef={dropdownRef}
                        classNamePrefix="rs-drp"
                        id="syndicate-dropdown"
                        placeholder={'Select List'}
                        onChange={(event: any) => {
                          if (selectedLocData.id !== +event.id) dispatch(addPostSetSelectedLocData({ id: +event.id, name: event.label }));
                          else dispatch(addPostSetSelectedLocData(AP_SELECTED_LOC_INIT_DATA));
                        }}
                        value={
                          selectedLocData.id
                            ? { id: +selectedLocData.id, value: selectedLocData.name, label: selectedLocData.name }
                            : { id: AP_SELECTED_LOC_INIT_DATA.id, value: ALL_LOCATION_LISTS, label: ALL_LOCATION_LISTS }
                        }
                        options={dropdownOptions}
                        isSearchable={locationLists.length === 1 ? false : true}
                        menuIsOpen
                      />
                    </div>
                  </Tab>
                )}

                {isContentSupplier || franchisorSyndication?.length ? (
                  <Tab
                    eventKey={FranScheduledLocName.ALL_FRANCHISE_CONSULTANT}
                    title={
                      <div>
                        <Form.Group className="form-group" controlId="formBasicCheckbox3">
                          <Form.Check
                            type="radio"
                            label={isContentSupplier ? FranScheduledLocLabel.ALL_BRANDS : FranScheduledLocLabel.ALL_FRANCHISE_CONSULTANT}
                            checked={locationType === FranScheduledLocName.ALL_FRANCHISE_CONSULTANT}
                            readOnly
                          />
                        </Form.Group>
                      </div>
                    }
                  />
                ) : null}

                {isContentSupplier || franchisorSyndication?.length ? (
                  <Tab
                    eventKey={FranScheduledLocName.FRANCHISE_CONSULTANT}
                    title={
                      <div>
                        <Form.Group className="form-group" controlId="formBasicCheckbox4">
                          <Form.Check
                            type="radio"
                            label={isContentSupplier ? FranScheduledLocLabel.SPECIFIC_BRANDS : FranScheduledLocLabel.FRANCHISE_CONSULTANT}
                            checked={locationType === FranScheduledLocName.FRANCHISE_CONSULTANT}
                            readOnly
                          />
                        </Form.Group>
                      </div>
                    }
                  >
                    <div className="form-group form-field-item">
                      <CustomDropdownWithSearch
                        dropdownRef={dropdownRef}
                        classNamePrefix="rs-drp"
                        id="syndicate-dropdown-content-writer"
                        placeholder={isContentSupplier ? 'Select Brands' : 'Select Franchise Consultants'}
                        onChange={(event: any) => {
                          handleSelectSyndicateFranchisors(event.id);
                        }}
                        value={franchisorSyndication?.find((syndicate) => selectedSyndicateFranchisors.includes(syndicate.id)) || ''}
                        options={isContentSupplier ? dropdownOptions : franchisorSyndication}
                        isSearchable={isContentSupplier || (franchisorSyndication && franchisorSyndication?.length > 1) ? true : false}
                        menuIsOpen
                      />
                    </div>
                  </Tab>
                ) : null}
              </Tabs>

              {(locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST && selectedLocData.id) ||
              (locationType === FranScheduledLocName.FRANCHISE_CONSULTANT && selectedSyndicateFranchisors.length) ? (
                <div className="select-location-section-wrp">
                  <h3 className="glbl__title--txt">
                    Selected{' '}
                    <span>
                      {locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST
                        ? selectedLocData.id
                          ? `(1 Location)`
                          : null
                        : selectedSyndicateFranchisors.length
                        ? `(${selectedSyndicateFranchisors.length} Franchise)`
                        : null}
                    </span>
                  </h3>
                  <Card>
                    <Card.Body>
                      {locationType === FranScheduledLocName.FRANCHISE_CONSULTANT && franchisorSyndication ? (
                        selectedSyndicateFranchisors.map((franchisorId) => (
                          <div className="grid-section mb-3" key={`syndicateFranchior-${franchisorId}`}>
                            <div className="g-left">
                              <span className="labelText"> {franchisorSyndication.find((syndicate) => franchisorId === syndicate.id)?.value} </span>
                            </div>
                            <div className="g-right">
                              <div className="glbl__round--delete">
                                <ImageValidation isImgValid defaultImg={'minus-red'} customName={'minus-red'} onClick={() => handleSelectSyndicateFranchisors(franchisorId)} />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="grid-section">
                          <div className="g-left">
                            <div className="icons-loc">
                              <ImageValidation isImgValid defaultImg={'location-grey'} customName={'hub-item-img'} />
                            </div>
                            <span className="labelText"> {selectedLocData.name} </span>
                          </div>
                          <div className="g-right">
                            <div className="glbl__round--delete">
                              <ImageValidation isImgValid defaultImg={'minus-red'} customName={'minus-red'} onClick={() => dispatch(addPostSetSelectedLocData(AP_SELECTED_LOC_INIT_DATA))} />
                            </div>
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              ) : null}
              {/* <ScheduleLocationSection locationType={locationType} setLocationType={setLocationType} /> */}
            </Card.Body>
          </Card>
          {(isContentSupplier || !isContentWriter) && mediaType === FranchisorSyndicationMediaType.CONTENT ? (
            <FranSyndicateScheduleTiming contentObj={contentObj} handleDateChange={handleDateChange} dispatch={dispatch} />
          ) : null}
          <div className="footer-section">
            <CustomRippleButton rippleClass={`ac-primary-box  ${disableSyndicateAction() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary ac-block ${disableSyndicateAction() ? `disabled` : ``}`} onClick={() => handleUpdateContentData(AP_CONTENT_ACTION_TYPE.APPROVE_AND_SYNDICATE)}>
                Syndicate
              </button>
            </CustomRippleButton>
          </div>
        </Modal.Body>
      </Modal>
      <PostSyndicatedModal
        isModalShow={isSyndicateStatusModalOpen}
        handleModalClose={() => {
          dispatch(postSyndicateSuccessResponse(''));
          dispatch(addFranchisorSyndicationResponse(null));
        }}
        handleMainModalClose={() => {
          handleModalClose();
          if (handleMainModalClose) handleMainModalClose();
        }}
        postOrMediaId={postOrMediaId}
        mediaType={mediaType}
      />
    </>
  );
};
