import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { settingsAIPlayBookReducerTypes } from 'types';

// UPDATE AI PLAYBOOK STATES
export const settingsAIPlayBookUpdateRequest = (payload: settingsAIPlayBookReducerTypes.IAIPlayBookUpdateReqPayload) => createAction(actionTypes.AI_PLAYBOOK_UPDATE_REQUEST, payload);
export const settingsAIPlayBookGetUpdateResponse = (payload: Partial<settingsAIPlayBookReducerTypes.IAIPlayBook>) => createAction(actionTypes.AI_PLAYBOOK_GET_UPDATE_RESPONSE, payload);
export const settingsAIPlayBookUpdateFailure = (payload: string | null) => createAction(actionTypes.AI_PLAYBOOK_UPDATE_FAILURE, payload);

// GET AI PLAYBOOK VERTICAL
export const aiPlaybookGetVerticalRequest = () => createAction(actionTypes.AI_PLAYBOOK_GET_VERTICAL_REQUEST);
export const aiPlaybookGetVerticalResponse = (response: settingsAIPlayBookReducerTypes.IAIPlayBookVerticals[]) => createAction(actionTypes.AI_PLAYBOOK_GET_VERTICAL_RESPONSE, response);
export const aiPlaybookGetVerticalFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_GET_VERTICAL_FAILURE, err);

// GET AI PLAYBOOK DOCUMENTS LIST
export const aiPlaybookGetDocumentsListRequest = (payload: settingsAIPlayBookReducerTypes.IDocumentsListRequst) => createAction(actionTypes.AI_PLAYBOOK_GET_DOCUMENTS_LIST_REQUEST, payload);
export const aiPlaybookGetDocumentsListResponse = (response: { data: settingsAIPlayBookReducerTypes.IAIPlaybookDocuments[]; isAIPlaybook: boolean }) =>
  createAction(actionTypes.AI_PLAYBOOK_GET_DOCUMENTS_LIST_RESPONSE, response);
export const aiPlaybookGetDocumentsListFailure = (err: { errMsg: string | null; isAIPlaybook: boolean }) => createAction(actionTypes.AI_PLAYBOOK_GET_DOCUMENTS_LIST_FAILURE, err);

// CREATE AI PLAYBOOK DOCUMENT
export const aiPlaybookCreateDocumentRequest = (payload: settingsAIPlayBookReducerTypes.ICreateDocumentRequest) => createAction(actionTypes.AI_PLAYBOOK_CREATE_DOCUMENT_REQUEST, payload);
export const aiPlaybookCreateDocumentResponse = (response: settingsAIPlayBookReducerTypes.ICreateDocumentResponse) => createAction(actionTypes.AI_PLAYBOOK_CREATE_DOCUMENT_RESPONSE, response);
export const aiPlaybookCreateDocumentFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_CREATE_DOCUMENT_FAILURE, err);

// DELETE AI PLAYBOOK DOCUMENT
export const aiPlaybookDeleteDocumentRequest = (payload: settingsAIPlayBookReducerTypes.IDeleteDocumentPayload) => createAction(actionTypes.AI_PLAYBOOK_DELETE_DOCUMENT_REQUEST, payload);
export const aiPlaybookDeleteDocumentResponse = (response: settingsAIPlayBookReducerTypes.IDeleteDocumentPayload) => createAction(actionTypes.AI_PLAYBOOK_DELETE_DOCUMENT_RESPONSE, response);
export const aiPlaybookDeleteDocumentFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_DELETE_DOCUMENT_FAILURE, err);

// AI PLAYBOOK CUSTOMER PERSONA
export const aiPlaybookCustomerPersonaRequest = (payload: settingsAIPlayBookReducerTypes.ICustomerPersonaReq) => createAction(actionTypes.AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_REQUEST, payload);
export const aiPlaybookCustomerPersonaResponse = (response: string) => createAction(actionTypes.AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_RESPONSE, response);
export const aiPlaybookCustomerPersonaFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_FAILURE, err);

// AI PLAYBOOK GET ACTIVITY GOALS LIST
export const aiPlaybookGetActivityGoalsListRequest = (payload: settingsAIPlayBookReducerTypes.IGetActivityGoalReqPayload) =>
  createAction(actionTypes.AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_REQUEST, payload);
export const aiPlaybookGetActivityGoalsListResponse = (response: settingsAIPlayBookReducerTypes.IActivityGoalsListRes[]) =>
  createAction(actionTypes.AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_RESPONSE, response);
export const aiPlaybookGetActivityGoalsListFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_FAILURE, err);

// AI PLAYBOOK CREATE ACTIVITY GOALS
export const aiPlaybookCreateUpdateActivityGoalsRequest = (payload: settingsAIPlayBookReducerTypes.ICreateActivityGoalReqPayload) =>
  createAction(actionTypes.AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_REQUEST, payload);
export const aiPlaybookCreateUpdateActivityGoalsResponse = (response: settingsAIPlayBookReducerTypes.ICreateOrUpdateActivityGoalRes) =>
  createAction(actionTypes.AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_RESPONSE, response);
export const aiPlaybookCreateUpdateActivityGoalsFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_FAILURE, err);

// AI PLAYBOOK DELETE ACTIVITY GOALS
export const aiPlaybookDeleteActivityGoalsRequest = (payload: number) => createAction(actionTypes.AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_REQUEST, payload);
export const aiPlaybookDeleteActivityGoalsResponse = (response: string) => createAction(actionTypes.AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_RESPONSE, response);
export const aiPlaybookDeleteActivityGoalsFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_FAILURE, err);

// AI PLAYBOOK GET ACTIVITY GOAL TYPES
export const aiPlaybookGetActivityGoalTypesRequest = () => createAction(actionTypes.AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_REQUEST);
export const aiPlaybookGetActivityGoalTypesResponse = (response: settingsAIPlayBookReducerTypes.IActivityGoalTypes[]) =>
  createAction(actionTypes.AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_RESPONSE, response);
export const aiPlaybookGetActivityGoalTypesFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_FAILURE, err);

// AI PLAYBOOK GENERATE COMPLAINCE SUMMARY
export const aiPlaybookGenerateComplianceSummaryRequest = (payload: settingsAIPlayBookReducerTypes.IGenerateComplianceSummaryReq) =>
  createAction(actionTypes.AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_REQUEST, payload);
export const aiPlaybookGenerateComplianceSummaryResponse = (response: settingsAIPlayBookReducerTypes.IGenerateComplianceSummaryRes) =>
  createAction(actionTypes.AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_RESPONSE, response);
export const aiPlaybookGenerateComplianceSummaryFailure = (err: string | null) => createAction(actionTypes.AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_FAILURE, err);

// AI PLAYBOOK RESET MESSAGE
export const aiPlaybookResetMessage = () => createAction(actionTypes.AI_PLAYBOOK_RESET_MESSAGE);

// RESET AI PLAYBOOK
export const aiPlaybookReset = () => createAction(actionTypes.AI_PLAYBOOK_RESET);
export const aiPlaybookSetDocUploadType = (payload: string) => createAction(actionTypes.AI_PLAYBOOK_SET_DOC_UPLOAD_TYPE, payload);

const SETTINGS_AI_PLAYBOOK = {
  settingsAIPlayBookUpdateRequest,
  settingsAIPlayBookGetUpdateResponse,
  settingsAIPlayBookUpdateFailure,
  aiPlaybookGetVerticalRequest,
  aiPlaybookGetVerticalResponse,
  aiPlaybookGetVerticalFailure,
  aiPlaybookGetDocumentsListRequest,
  aiPlaybookGetDocumentsListResponse,
  aiPlaybookGetDocumentsListFailure,
  aiPlaybookCreateDocumentRequest,
  aiPlaybookCreateDocumentResponse,
  aiPlaybookCreateDocumentFailure,
  aiPlaybookDeleteDocumentRequest,
  aiPlaybookDeleteDocumentResponse,
  aiPlaybookDeleteDocumentFailure,
  aiPlaybookCustomerPersonaRequest,
  aiPlaybookCustomerPersonaResponse,
  aiPlaybookCustomerPersonaFailure,
  aiPlaybookGetActivityGoalsListRequest,
  aiPlaybookGetActivityGoalsListResponse,
  aiPlaybookGetActivityGoalsListFailure,
  aiPlaybookCreateUpdateActivityGoalsRequest,
  aiPlaybookCreateUpdateActivityGoalsResponse,
  aiPlaybookCreateUpdateActivityGoalsFailure,
  aiPlaybookDeleteActivityGoalsRequest,
  aiPlaybookDeleteActivityGoalsResponse,
  aiPlaybookDeleteActivityGoalsFailure,
  aiPlaybookGetActivityGoalTypesRequest,
  aiPlaybookGetActivityGoalTypesResponse,
  aiPlaybookGetActivityGoalTypesFailure,
  aiPlaybookGenerateComplianceSummaryRequest,
  aiPlaybookGenerateComplianceSummaryResponse,
  aiPlaybookGenerateComplianceSummaryFailure,
  aiPlaybookResetMessage,
  aiPlaybookSetDocUploadType,
  aiPlaybookReset
};

export type SettingsAIPlayBookActions = ActionsUnion<typeof SETTINGS_AI_PLAYBOOK>;
