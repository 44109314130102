import { useState, useEffect } from 'react';
// import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { setEditedMgDetails } from 'actions';
import { IStore, mgContainerTypes } from 'types';
import { TOOLTIP_PLACEMENT, ALERT_BOX_TYPES, MG_CONTENT_ALREADY_LOCKED, INBOX_COMMENTS_DELAY_SECONDS, MG_FILTER_INITIAL_VALUE, COMMON_PAGE_TYPE } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { CustomTooltip } from 'widgets/Tooltip';
import { alertBoxCall } from 'components';

export const MgDetailName = ({ editEnabled }: mgContainerTypes.IMgDetailNameProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(MG_FILTER_INITIAL_VALUE);

  const editedMgDetails = useSelector((state: IStore) => state.mediaGallery.editedMgDetails);

  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    if (alertShown) {
      setTimeout(() => {
        setAlertShown(false);
      }, INBOX_COMMENTS_DELAY_SECONDS);
    }
  }, [alertShown]); // eslint-disable-line

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  return (
    <div className="blue-trans-box mb-20 r-mt0 r-flx r-flx-ac r-flx-jb">
      <div className="rel-head-label r-flx r-flx-ac">
        <div className="rel-icons">
          <ImageValidation isImgValid defaultImg="asset" customName="Asset Detail" />
        </div>
        <CustomTooltip
          customPlacement={TOOLTIP_PLACEMENT.BOTTOM}
          customTooltipText={optionalParams[2] === COMMON_PAGE_TYPE.CLONE ? `Clone (${editedMgDetails?.name || '---'})` : editedMgDetails?.name ? editedMgDetails.name : ''}
          customClassname={'custom-tooltip-long-text'}
          customInput={() => (
            <input
              type="text"
              name="fileName"
              className="img-edit glbl__title--txt"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (editEnabled) {
                  dispatch(setEditedMgDetails({ name: e.target.value }));
                }
              }}
              value={optionalParams[2] === COMMON_PAGE_TYPE.CLONE ? `Clone (${editedMgDetails?.name || '---'})` : editedMgDetails?.name ? editedMgDetails.name : ''}
              readOnly={optionalParams[2] === COMMON_PAGE_TYPE.CLONE}
              onClick={() => {
                if (optionalParams[2] !== COMMON_PAGE_TYPE.CLONE && !editEnabled) {
                  setAlertShown(true);
                  alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_CONTENT_ALREADY_LOCKED);
                }
              }}
            />
          )}
        />
      </div>
      {/* <div className="scl-controls"> */}
      {/* <Button className="plain-btn x-mark" variant="link" onClick={() => routePush(`/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}`, queryParams)}> */}
      <div className="blue__trans--icon cu-pointer" onClick={() => routePush(`/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}`, queryParams)}>
        <ImageValidation isImgValid defaultImg="xmark" customName="close" />
      </div>
      {/* </Button> */}
      {/* </div> */}
    </div>
  );
};
