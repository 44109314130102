import { Fragment, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';

import { IStore, postsContainerTypes } from 'types';
import { Loading, NoDataFound } from 'components';
import { NoDataFoundProps, SCROLL_THRESHOLD, POSTS_INIT_FILTER_DATA, PAGINATION_PER_PAGE, USER_OWNERSHIP, POSTS_STATUS_TYPE } from 'utils/constants';
import { PostsList } from './PostsList';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { getMultiSelectPostsListRequest, getPostsStatsRequest } from 'actions';
import { MultiSelectPostOrMediaSearch } from './MultiSelectPostOrMediaSearch';

export const MultiSelectPostInfinityScrollList = ({ isCallApi, startDateTimeStamp, endDateTimeStamp, type, height }: Omit<postsContainerTypes.IPostListProps, 'handlePostListBasedOnFilters'>) => {
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);

  const isFetching = useSelector((state: IStore) => state.posts.isMultiSelectPostsFetching);
  const isAiCreateSimilarPostFetching = useSelector((state: IStore) => state.aiContentCreator.isAiCreateSimilarPostFetching);
  const isPostsStatsFetching = useSelector((state: IStore) => state.posts.isPostsStatsFetching);
  const postsList = useSelector((state: IStore) => state.posts.multiSelectPostsList);
  const postsStats = useSelector((state: IStore) => state.posts.postsStats);
  const currentPage = useSelector((state: IStore) => state.posts.multiSelectListCurrentPage);

  const [postStats, setPostStats] = useState({ totalLength: 0, totalPagecount: 0 });
  const [searchText, setSearchText] = useState('');

  const handlePostListBasedOnFilters = (lastPostId?: number | null, search?: string) => {
    if (id && userOwnership && isCallApi && isValidId) {
      const reqPayload = {
        id,
        userOwnership,
        status_id: [3],
        page: lastPostId ? currentPage + 1 : 1,
        ...(search && { text: search }),
        ...(lastPostId && { after: lastPostId }),
        ...(queryParams?.multi_syndicated_to_any_franchisor && { syndicated_to_any_franchisor: queryParams?.multi_syndicated_to_any_franchisor }),
        ...(queryParams?.multi_syndicated_to_any_account && { syndicated_to_any_account: queryParams?.multi_syndicated_to_any_account })
      };

      dispatch(getMultiSelectPostsListRequest({ ...reqPayload, isMultiSelect: true }));
    }
  };

  const handlePostsStats = () => {
    if (id && userOwnership && isCallApi && isValidId) {
      const payload = {
        id,
        userOwnership,
        ...(searchText && { text: searchText })
      };
      dispatch(getPostsStatsRequest(payload));
    }
  };

  // Get post stats
  useEffect(() => {
    if (id && userOwnership && isValidId && isCallApi) {
      handlePostsStats();
    }
  }, [id, userOwnership, isCallApi, isValidId]); // eslint-disable-line

  // Get post list based on filter changes
  useEffect(() => {
    if (id && userOwnership && isValidId && isCallApi) {
      handlePostListBasedOnFilters();
    }
  }, [id, userOwnership, isValidId, isCallApi, queryParams?.multi_syndicated_to_any_franchisor, queryParams?.multi_syndicated_to_any_account]); // eslint-disable-line

  useEffect(() => {
    if (postsStats && Object.keys(postsStats).length) {
      setPostStats({ ...postStats, totalLength: postsStats.totalPosts });
    }
  }, [postsStats]); // eslint-disable-line

  useEffect(() => {
    const count = Math.ceil(postStats.totalLength / PAGINATION_PER_PAGE);
    setPostStats({ ...postStats, totalPagecount: count });
  }, [postStats.totalLength]); // eslint-disable-line

  const handleLoadMoreItems = () => {
    if (id && userOwnership && isValidId && isCallApi) {
      const lastPostId =
        postsList.length && userOwnership === USER_OWNERSHIP.ACCOUNT
          ? postsList[postsList.length - 1]?.saved_post_id
          : postsList.length && userOwnership === USER_OWNERSHIP.FRANCHISOR && queryParams.status === POSTS_STATUS_TYPE.AA_LOCATION_CONTENT
          ? postsList[postsList.length - 1]?.saved_post_id
          : postsList[postsList.length - 1]?.content_id;
      handlePostListBasedOnFilters(lastPostId);
    }
  };

  return (
    <Fragment>
      <MultiSelectPostOrMediaSearch searchText={searchText} setSearchText={setSearchText} handlePostsStats={handlePostsStats} handlePostListBasedOnFilters={handlePostListBasedOnFilters} />

      {isFetching && currentPage === 1 ? (
        <Loading />
      ) : postsList.length ? (
        <div className={`infinite-list post-grid-wrp${isAiCreateSimilarPostFetching ? ` pointer-events-none` : ''}`} id="post-list-ifs">
          <InfiniteScroll
            scrollThreshold={SCROLL_THRESHOLD}
            next={handleLoadMoreItems}
            hasMore={isPostsStatsFetching ? true : postStats.totalPagecount >= currentPage}
            dataLength={postsList.length}
            loader={isFetching ? <Loading /> : null}
            className="local-ini"
            scrollableTarget={'scrollTarget'}
            height={height}
          >
            <PostsList type={type} isMultiSelect />
          </InfiniteScroll>
        </div>
      ) : !isFetching && !postsList.length ? (
        <NoDataFound size={NoDataFoundProps.FULL} />
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};
