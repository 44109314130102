import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  addPostContentPostDataResponse,
  addPostContentPostDataFailure,
  addPostUpdateContentResponse,
  addPostUpdateContentFailure,
  addPostGetLocIdsBasedOnHubResponse,
  addPostGetLocIdsBasedOnHubFailure,
  addPostGetLocDetailBasedOnLocResponse,
  addPostGetLocDetailBasedOnLocFailure,
  addPostAddScheduledMultipostsResponse,
  addPostAddScheduledMultipostsFailure,
  addPostMassUpdateForScheduledPostResponse,
  addPostMassUpdateForScheduledPostFailure,
  getScheduledMultiPostsResponse,
  getScheduledMultiPostsRequest,
  addFranchisorSyndicationRequest,
  addFranchisorSyndicationFailure,
  getFranchisorSyndicationResponse,
  getFranchisorSyndicationFailure,
  postSyndicateSuccessResponse,
  getVideoCompatibilityResponse,
  getVideoCompatibilityFailure,
  addFranchisorUpdateMultiTagsResponse,
  addFranchisorUpdateMultiTagsFailure
} from 'actions';
import { API, apiCall, apiEventSourcePolyfillHandler, defaultHeader } from 'utils/helpers';
import {
  ADD_POST_EXCEPTION_ERROR,
  ADD_POST_CONTENT_DATA_FAILED,
  ADD_POST_GET_LOC_LIST_FAILED,
  ADD_POST_UPDATE_CONTENT_FAILED,
  ADD_POST_ADD_SCHEDULED_MULTIPOSTS_FAILED,
  ADD_POST_MASS_UPDATE_FOR_SCH_POST_FAILED,
  FranchisorSyndicationMediaType,
  POST_SYNDICATION_EXCEPTION_ERROR,
  ADD_MEDIA_APPROVE_AND_SYNDICATE_MSG,
  ADD_POST_APPROVE_AND_SYNDICATE_MSG,
  ALERT_BOX_TYPES,
  MASS_UPDATE_TAGS_SUCCESS_MSG,
  MASS_UPDATE_TAGS_FAILURE_MSG
} from 'utils/constants';
import { ISagaPayload } from 'types/common/api';
import { store } from 'store';
import { alertBoxCall } from 'components';

function* sendAddPostGetContentPostDataRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      ...(payload && { content: payload })
    };
    const response = yield apiCall({ headers, data, ...API.saveContentDraftPost });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostContentPostDataResponse(response.data.content));
    } else {
      yield put(addPostContentPostDataFailure(ADD_POST_CONTENT_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostUpdateContentRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      content: payload.reqPayload
    };
    const apiObj = { ...API.editContentDraftPost };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.postId);
    const response = yield apiCall({ headers, data, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload.syndicateFranchisor) {
        yield put(addFranchisorSyndicationRequest({ id: payload.postId, franchisor_ids: payload.syndicateFranchisor, mediaType: FranchisorSyndicationMediaType.CONTENT }));
      }
      yield put(addPostUpdateContentResponse(response.data.content));
      if (payload.franchisorId && payload?.refetch) {
        apiEventSourcePolyfillHandler(
          API.getScheduledMultiPost.apiPath,
          {
            franchisor_id: payload.franchisorId,
            ...(payload.dateRange && payload.dateRange?.startDate && payload.dateRange?.endDate ? { brand_date_range: [payload.dateRange.startDate, payload.dateRange.endDate] } : { future: 1 }),
            trashed: 0
          },
          'scheduled_multiposts',
          () => store.dispatch(getScheduledMultiPostsRequest()),
          (data) => {
            store.dispatch(getScheduledMultiPostsResponse(data));
          },
          ADD_POST_EXCEPTION_ERROR
        );
      }
    } else {
      yield put(addPostUpdateContentFailure(ADD_POST_UPDATE_CONTENT_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostGetLocIdsBasedOnHubRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      franchisor_id: payload
    };
    const response = yield apiCall({ headers, params, ...API.getLocIdsBasedOnHub });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostGetLocIdsBasedOnHubResponse(response.data));
    } else {
      yield put(addPostGetLocIdsBasedOnHubFailure(ADD_POST_GET_LOC_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostGetLocDetailsBasedOnLocRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.getSelectedLocList.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.getSelectedLocList.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostGetLocDetailBasedOnLocResponse(response.data.account_list_detail));
    } else {
      yield put(addPostGetLocDetailBasedOnLocFailure(ADD_POST_GET_LOC_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddScheduledMultipostsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      scheduled_multipost: payload.reqPayload
    };
    const response = yield apiCall({ headers, data, ...API.addScheduledMultiposts });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostAddScheduledMultipostsResponse(response.data.scheduled_multipost.id));
    } else if (response.status === 422) {
      yield put(addPostAddScheduledMultipostsFailure(response.data.errors.created_at[0]));
    } else {
      yield put(addPostAddScheduledMultipostsFailure(ADD_POST_ADD_SCHEDULED_MULTIPOSTS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendMassUpdateForScheduledPost({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {};
    let path;
    path = API.massUpdateForSchPost.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, data, apiPath: path, action: API.massUpdateForSchPost.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostMassUpdateForScheduledPostResponse(response.data));
    } else {
      yield put(addPostMassUpdateForScheduledPostFailure(ADD_POST_MASS_UPDATE_FOR_SCH_POST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddFranchisorSyndication({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { franchisor_ids: payload.franchisor_ids };
    const apiObj = {
      ...(payload.mediaType === FranchisorSyndicationMediaType.CONTENT
        ? API.addFranchisorSyndPost
        : payload.mediaType === FranchisorSyndicationMediaType.PHOTO
        ? API.addFranchisorSyndPhoto
        : payload.mediaType === FranchisorSyndicationMediaType.VIDEO
        ? API.addFranchisorSyndVideo
        : API.addFranchisorSyndDocuments)
    };
    apiObj.apiPath = apiObj.apiPath.replace(`:${payload.mediaType}_id`, payload.id);
    const response = yield apiCall({ headers, data, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(postSyndicateSuccessResponse(payload.mediaType === FranchisorSyndicationMediaType.CONTENT ? ADD_POST_APPROVE_AND_SYNDICATE_MSG : ADD_MEDIA_APPROVE_AND_SYNDICATE_MSG));
    } else {
      yield put(addFranchisorSyndicationFailure());
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_SYNDICATION_EXCEPTION_ERROR));
  }
}

function* sendGetSyndicationPost({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = {
      ...(payload.mediaType === FranchisorSyndicationMediaType.CONTENT
        ? API.getFranchisorSyndPost
        : payload.mediaType === FranchisorSyndicationMediaType.PHOTO
        ? API.getFranchisorSyndPhoto
        : payload.mediaType === FranchisorSyndicationMediaType.VIDEO
        ? API.getFranchisorSyndVideo
        : API.getFranchisorSyndDocuments)
    };
    apiObj.apiPath = apiObj.apiPath.replace(`:${payload.mediaType}_id`, payload.id);
    const response = yield apiCall({ headers, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        getFranchisorSyndicationResponse(
          payload.mediaType === FranchisorSyndicationMediaType.CONTENT
            ? response.data.franchisor_syndications
            : payload.mediaType === FranchisorSyndicationMediaType.PHOTO
            ? response.data.franchisor_photo_syndications
            : payload.mediaType === FranchisorSyndicationMediaType.VIDEO
            ? response.data.franchisor_video_syndications
            : response.data.franchisor_document_syndications
        )
      );
    } else {
      yield put(getFranchisorSyndicationFailure());
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_SYNDICATION_EXCEPTION_ERROR));
  }
}

function* sendGetVideoCompatibility({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const APIObj = API.getVideoCompatibility;
    const path = APIObj.apiPath.replace(`:id`, payload);
    const response = yield apiCall({ headers, apiPath: path, action: APIObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getVideoCompatibilityResponse(response.data));
    } else {
      yield put(getVideoCompatibilityFailure());
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_SYNDICATION_EXCEPTION_ERROR));
  }
}

function* sendAddFranchisorMultiSyndication({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const { mediaType, isContentSupplier, ...data } = payload;
    const apiObj = {
      ...(payload.mediaType === FranchisorSyndicationMediaType.CONTENT
        ? payload?.isContentSupplier
          ? API.addFranchisorMultiSyndPost
          : API.addAccountMultiSyndPost
        : payload.mediaType === FranchisorSyndicationMediaType.PHOTO
        ? API.addFranchisorMultiSyndPhoto
        : payload.mediaType === FranchisorSyndicationMediaType.VIDEO
        ? API.addFranchisorMultiSyndVideo
        : API.addFranchisorMultiSyndDocuments)
    };
    const response = yield apiCall({ headers, data, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(postSyndicateSuccessResponse(payload.mediaType === FranchisorSyndicationMediaType.CONTENT ? ADD_POST_APPROVE_AND_SYNDICATE_MSG : ADD_MEDIA_APPROVE_AND_SYNDICATE_MSG));
    } else {
      yield put(addFranchisorSyndicationFailure());
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_SYNDICATION_EXCEPTION_ERROR));
  }
}

function* sendUpdateMultiSelectTags({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const { mediaType, ...data } = payload;
    const apiObj = {
      ...(payload.mediaType === FranchisorSyndicationMediaType.CONTENT
        ? API.updateMultiSelectContentTags
        : payload.mediaType === FranchisorSyndicationMediaType.PHOTO
        ? API.updateMultiSelectPhotoTags
        : payload.mediaType === FranchisorSyndicationMediaType.VIDEO
        ? API.updateMultiSelectVideoTags
        : API.updateMultiSelectDocumentTags)
    };
    const response = yield apiCall({ headers, data, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addFranchisorUpdateMultiTagsResponse(MASS_UPDATE_TAGS_SUCCESS_MSG));
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, MASS_UPDATE_TAGS_SUCCESS_MSG);
    } else {
      yield put(addFranchisorUpdateMultiTagsFailure(MASS_UPDATE_TAGS_FAILURE_MSG));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, MASS_UPDATE_TAGS_FAILURE_MSG);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(POST_SYNDICATION_EXCEPTION_ERROR));
  }
}

export function* takeAddPostFranchisorRequest() {
  yield takeLatest(types.AP_GET_CONTENT_POST_DATA_REQUEST, sendAddPostGetContentPostDataRequest);
  yield takeEvery(types.AP_UPDATE_CONTENT_REQUEST, sendAddPostUpdateContentRequest);
  yield takeEvery(types.AP_GET_LOC_IDS_BASED_ON_HUB_REQUEST, sendAddPostGetLocIdsBasedOnHubRequest);
  yield takeEvery(types.AP_GET_LOC_DETAIL_BASED_ON_LOC_REQUEST, sendAddPostGetLocDetailsBasedOnLocRequest);
  yield takeLatest(types.AP_ADD_SCHEDULED_MULTIPOSTS_REQUEST, sendAddScheduledMultipostsRequest);
  yield takeLatest(types.AP_MASS_UPDATE_FOR_SCHEDULED_POST_REQUEST, sendMassUpdateForScheduledPost);
  yield takeLatest(types.ADD_FRANCHISOR_SYNDICATION_REQUEST, sendAddFranchisorSyndication);
  yield takeLatest(types.GET_FRANCHISOR_SYNDICATION_REQUEST, sendGetSyndicationPost);
  yield takeLatest(types.AP_GET_VIDEO_COMPATIBILITY_REQUEST, sendGetVideoCompatibility);
  yield takeLatest(types.ADD_FRANCHISOR_MULTI_SYNDICATION_REQUEST, sendAddFranchisorMultiSyndication);
  yield takeLatest(types.UPDATE_MULTI_SELECT_TAGS_REQUEST, sendUpdateMultiSelectTags);
}
