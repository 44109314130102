import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';

import { mgApiTypes, mgReducerTypes } from 'types/media-gallery';

// MEDIA GALLERY FILTER STATS
export const getMGFilterStatsRequest = (payload: mgApiTypes.IMGListRequest) => createAction(actionTypes.MG_FILTER_STATS_REQUEST, payload);
export const getMGFilterStatsResponse = (data: mgApiTypes.IMgPhotoFilterStatsObj | mgApiTypes.IMgVideoFilterStatsObj | mgApiTypes.IMgDocumentsFilterStatsObj) =>
  createAction(actionTypes.MG_FILTER_STATS_RESPONSE, data);
export const getMGFilterStatsFailure = (error: null | string) => createAction(actionTypes.MG_FILTER_STATS_FAILURE, error);

// MEDIA GALLERY LIST
export const getMGListRequest = (payload: mgApiTypes.IMGListRequest) => createAction(actionTypes.MG_LIST_REQUEST, payload);
export const getMGListResponse = (data: mgApiTypes.IMGListResponseData) => createAction(actionTypes.MG_LIST_RESPONSE, data);
export const getMGListRequestFailure = (error: null | string) => createAction(actionTypes.MG_LIST_FAILURE, error);

// MEDIA GALLERY - UPLOAD CLOUDINARY MEDIA
export const mgUploadCloudMediaRequest = (payload: mgApiTypes.IMGUploadCloudMediaRequest) => createAction(actionTypes.MG_CLOUD_MEDIA_UPLOAD_REQUEST, payload);
export const mgUploadCloudMediaResponse = (payload: mgApiTypes.IMGUploadCloudMediaResponse) => createAction(actionTypes.MG_CLOUD_MEDIA_UPLOAD_RESPONSE, payload);
export const mgUploadCloudMediaReqFailure = (payload: null | string) => createAction(actionTypes.MG_CLOUD_MEDIA_UPLOAD_FAILURE, payload);

export const mgValidUploadedCloudMediaList = (payload: string) => createAction(actionTypes.MG_VALID_CLOUD_MEDIA_UPLOAD_LIST, payload);

// MEDIA GALLERY - ADD MEDIA ASSET
export const mgSaveAssetRequest = (payload: mgApiTypes.IMGAddAssetsRequest) => createAction(actionTypes.MG_ADD_MEDIA_ASSET_REQUEST, payload);
export const mgSaveAssetResponse = (data: mgApiTypes.IMGAddAssestsResponse) => createAction(actionTypes.MG_ADD_MEDIA_ASSET_RESPONSE, data);
export const mgSaveAssetFailure = (error: null | string) => createAction(actionTypes.MG_ADD_MEDIA_ASSET_FAILURE, error);

// MEDIA GALLERY - DELETE ASSET
export const mgDeleteAssetRequest = (payload: mgApiTypes.IMGDeleteReqParams) => createAction(actionTypes.MG_DELETE_ASSET_REQUEST, payload);
export const mgDeleteAssetResponse = (data: mgApiTypes.IMGDeleteResponseData) => createAction(actionTypes.MG_DELETE_ASSET_RESPONSE, data);
export const mgDeleteAssetFailure = (error: null | string) => createAction(actionTypes.MG_DELETE_ASSET_FAILURE, error);

// MEDIA GALLERY - GET ADDED ASSETS COUNT
export const mgSetAddedAssetsCount = (payload: number) => createAction(actionTypes.MG_GET_ADDED_ASSETS_COUNT, payload);

// MEDIA GALLERY - GET CLOUD RESPONSE LIST
export const mgGetCloudResponseList = (payload: mgReducerTypes.IMediaGalleryCloudData[]) => createAction(actionTypes.MG_GET_CLOUD_RESPONSE_LIST, payload);

// MEDIA GALLERY - GET FAVOURITE TAGS
export const mgTagsRequest = (payload: mgApiTypes.IMediaFavTagRequest) => createAction(actionTypes.MG_TAGS_REQUEST, payload);
export const mgTagsResponse = (data: mgReducerTypes.IMediaGalleryTagsData[]) => createAction(actionTypes.MG_TAGS_RESPONSE, data);
export const mgTagsRequestFailure = (error: null | string) => createAction(actionTypes.MG_TAGS_FAILURE, error);

// MEDIA GALLERY - GET ALL FAV TAGS FROM DB
export const mgGetFavTagsRequest = (payload: mgApiTypes.IMgGetFavTagRequest) => createAction(actionTypes.MG_GET_FAV_TAGS_REQUEST, payload);
export const mgGetFavTagsResponse = (data: mgReducerTypes.IMediaGalleryTagsData[]) => createAction(actionTypes.MG_GET_FAV_TAGS_RESPONSE, data);
export const mgGetFavTagsRequestFailure = (error: null | string) => createAction(actionTypes.MG_GET_FAV_TAGS_FAILURE, error);

// MEDIA GALLERY - UPDATE EDITED FAV TAGS TO DB
export const mgUpdateFavTagsRequest = (payload: mgApiTypes.IMgUpdateFavTagRequest) => createAction(actionTypes.MG_UPDATE_FAV_TAGS_REQUEST, payload);
export const mgUpdateFavTagsResponse = () => createAction(actionTypes.MG_UPDATE_FAV_TAGS_RESPONSE);
export const mgUpdateFavTagsRequestFailure = () => createAction(actionTypes.MG_UPDATE_FAV_TAGS_FAILURE);

// MEDIA GALLERY - UPDATE TAG LISTS TO REDUCER STATE
export const mgSetTagsList = (data: mgReducerTypes.IMediaGalleryTagsData[]) => createAction(actionTypes.MG_SET_TAGS_LIST, data);

// MEDIA GALLERY - MEDIA RELEASE
export const mgMediaReleaseRequest = (payload: mgApiTypes.IMGMediaReleasesReqParams) => createAction(actionTypes.MG_MEDIA_RELEASE_REQUEST, payload);
export const mgMediaReleaseResponse = (data: mgReducerTypes.IMediaReleases[]) => createAction(actionTypes.MG_MEDIA_RELEASE_RESPONSE, data);
export const mgMediaReleaseRequestFailure = (error: null | string) => createAction(actionTypes.MG_MEDIA_RELEASE_FAILURE, error);

// MEDIA GALLERY - LIKE
export const mgLikeRequest = (payload: mgApiTypes.IMGMediaReleasesReqParams) => createAction(actionTypes.MG_LIKE_REQUEST, payload);
export const mgLikeResponse = (data: mgApiTypes.IMGLikeResponseData) => createAction(actionTypes.MG_LIKE_RESPONSE, data);
export const mgLikeRequestFailure = (error: null | string) => createAction(actionTypes.MG_LIKE_FAILURE, error);

// MEDIA GALLERY - DISLIKE
export const mgDislikeRequest = (payload: mgApiTypes.IMGMediaReleasesReqParams) => createAction(actionTypes.MG_DISLIKE_REQUEST, payload);
export const mgDislikeResponse = (data: mgApiTypes.IMGLikeResponseData) => createAction(actionTypes.MG_DISLIKE_RESPONSE, data);
export const mgDislikeRequestFailure = (error: null | string) => createAction(actionTypes.MG_DISLIKE_FAILURE, error);

// MEDIA GALLERY GET DETAIL DATA
export const mgDetailDataRequest = (payload: mgApiTypes.IMGDetailsDataRequest) => createAction(actionTypes.MG_DETAIL_DATA_REQUEST, payload);
export const mgDetailDataResponse = (data: null | mgApiTypes.IMGListResponseObj) => createAction(actionTypes.MG_DETAIL_DATA_RESPONSE, data);
export const mgDetailDataRequestFailure = (error: null | string) => createAction(actionTypes.MG_DETAIL_DATA_FAILURE, error);

// MEDIA GALLERY UPDATE DETAIL DATA
export const mgUpdateDetailDataRequest = (payload: mgApiTypes.IMGUpdateDetailsDataRequest) => createAction(actionTypes.MG_UPDATE_DETAIL_DATA_REQUEST, payload);
export const mgUpdateDetailDataResponse = (data: null | mgApiTypes.IMGListResponseObj) => createAction(actionTypes.MG_UPDATE_DETAIL_DATA_RESPONSE, data);
export const mgUpdateDetailDataRequestFailure = (error: null | string) => createAction(actionTypes.MG_UPDATE_DETAIL_DATA_FAILURE, error);

// MEDIA GALLERY RESET
export const mgAddedAssetDetailReset = () => createAction(actionTypes.MG_ADDED_ASSET_DETAIL_RESET);
export const mgDeleteAssetDataReset = () => createAction(actionTypes.MG_DELETE_ASSET_DATA_RESET);
export const mgUpdateAssetDataRest = () => createAction(actionTypes.MG_UPDATE_ASSET_DATA_RESET);
export const mgDetailDataReset = () => createAction(actionTypes.MG_DETAIL_VIEW_RESET);
export const mgResetAll = () => createAction(actionTypes.MG_RESET_ALL);
export const setEditedMgDetails = (payload: any) => createAction(actionTypes.SET_EDIT_MG_DETAILS, payload);

// MEDIA GALLERY - UPLOAD PROGRESS INFO
export const mgSetUploadProgressInfo = (payload: mgReducerTypes.IMgMediaUploadProgressInfo) => createAction(actionTypes.MG_SET_UPLOAD_PROGRESS_INFO, payload);

// Watermark image upload actions.
export const showHideWatermarkModal = (payload: { show: boolean }) => createAction(actionTypes.SHOW_HIDE_WATERMARK_MODAL, payload);
export const uploadWatermarkImageToCloudRequest = (payload: any) => createAction(actionTypes.UPLOAD_WATERMARK_IMAGE_CLOUDINARY_REQUEST, payload);
export const uploadWatermarkImageToCloudResponse = (payload: any) => createAction(actionTypes.UPLOAD_WATERMARK_IMAGE_CLOUDINARY_RESPONSE, payload);
export const cancelWatermarkUpload = () => createAction(actionTypes.CANCEL_UPLOAD);
export const saveWatermarkImageRequest = (payload: {
  watermark_photo: {
    cloudinary_id: string;
    franchisor_id: number;
    user_id: number;
    photo_url: string;
  };
  isUpdate?: boolean;
}) => createAction(actionTypes.SAVE_WATERMARK_IMAGE_REQUEST, payload);
export const saveWatermarkImageResponse = () => createAction(actionTypes.SAVE_WATERMARK_IMAGE_RESPONSE);
export const getWatermarkImageRequest = (payload: { brandId: string }) => createAction(actionTypes.GET_WATERMARK_IMAGE, payload);
export const getWatermarkImageResponse = (payload: any) => createAction(actionTypes.GET_WATERMARK_IMAGE_RESPONSE, payload);

// MEDIA GALLERY CLONE ASSET
export const mgCloneAssetRequest = (data: mgApiTypes.IMgCloneAssetRequest) => createAction(actionTypes.MG_CLONE_ASSET_REQUEST, data);
export const mgCloneAssetResponse = (payload: mgApiTypes.IMgCloneAssetResponse) => createAction(actionTypes.MG_CLONE_ASSET_RESPONSE, payload);
export const mgCloneAssetFailure = (error: mgApiTypes.IMgCloneAssetError) => createAction(actionTypes.MG_CLONE_ASSET_FAILURE, error);

export const mediaDetailViewError = (error: null | string) => createAction(actionTypes.MEDIA_DETAIL_VIEW_ERROR, error);

export const mgAIImageUploadingRequest = (payload: boolean) => createAction(actionTypes.MG_AI_IMAGE_UPLOADING_REQUEST, payload);

export const mgs3UploadCloudMediaRequest = (payload: mgApiTypes.IMGs3UploadReqPayload) => createAction(actionTypes.MG_S3_CLOUD_MEDIA_UPLOAD_REQUEST, payload);

// MEDIA GALLERY - ADD CUSTOM THUMBNAIL
export const mgAddCustomizeVideoThumbnailRequest = (payload: any) => createAction(actionTypes.MG_ADD_CUSTOM_VIDEO_THUMBNAIL_REQUEST, payload);

// MEDIA GALLERY - ADD CUSTOM THUMBNAIL
export const mgAddCustomizeVideoThumbnailResponse = (payload: mgApiTypes.IMGUploadCloudMediaResponse) => createAction(actionTypes.MG_ADD_CUSTOM_VIDEO_THUMBNAIL_RESPONSE, payload);

// MEDIA GALLERY - RESET CUSTOM THUMBNAIL
export const mgResetCustomVideoThumbnailResponse = () => createAction(actionTypes.MG_RESET_CUSTOM_VIDEO_THUMBNAIL_RESPONSE);

// MEDIA GALLERY - REMOVE CUSTOM THUMBNAIL
export const mgRemoveCustomVideoThumbnailResponse = (payload: string) => createAction(actionTypes.MG_REMOVE_CUSTOM_VIDEO_THUMBNAIL_RESPONSE, payload);

// MEDIA GALLERY - ADD MEDIA RELEASE
export const mgAddMediaReleaseRequest = (payload: mgReducerTypes.IMediaReleaseSignatures[]) => createAction(actionTypes.MG_ADD_MEDIA_RELEASE_REQUEST, payload);
export const mgAddMediaReleaseResponse = (payload: mgReducerTypes.IMediaReleaseSignatures) => createAction(actionTypes.MG_ADD_MEDIA_RELEASE_RESPONSE, payload);

// MEDIA GALLERY - ADD MEDIA RELEASE
export const mgDeleteMediaReleaseRequest = (payload: string) => createAction(actionTypes.MG_DELETE_MEDIA_RELEASE_REQUEST, payload);

// MEDIA GALLERY - RESET MEDIA RELEASE
export const mgResetMediaRelease = () => createAction(actionTypes.MG_RESET_MEDIA_RELEASE);

// MEDIA GALLERY - UPDATE MEDIA CLOUD RESPONSE
export const mgUpdateMediaCloudResponse = (payload: { index: number; response: mgReducerTypes.IMGCloudinaryDataObj }) => createAction(actionTypes.MG_UPDATE_CLOUD_MEDIA_RESPONSE, payload);
export const mgUpdateMediaExpiration = (payload: mgReducerTypes.IMGMediaExpiration) => createAction(actionTypes.MG_UPDATE_MEDIA_EXPIRATION, payload);
export const mgResetMediaExpiration = () => createAction(actionTypes.MG_RESET_MEDIA_EXPIRATION);
export const mediaGalleryUpdateSettings = (payload: Record<string, string | boolean>) => createAction(actionTypes.MEDIA_GALLERY_UPDATE_SETTINGS, payload);
export const mediaGalleryResetSettings = () => createAction(actionTypes.MEDIA_GALLERY_RESET_SETTINGS);

// MEDIA GALLERY LIST
export const getMultiSelectMGListRequest = (payload: mgApiTypes.IMGListRequest) => createAction(actionTypes.GET_MULTISELECT_MG_LIST_REQUEST, payload);
export const getMultiSelectMGListResponse = (data: mgApiTypes.IMGListResponseData) => createAction(actionTypes.GET_MULTISELECT_MG_LIST_RESPONSE, data);
export const getMultiSelectMGListRequestFailure = (error: null | string) => createAction(actionTypes.GET_MULTISELECT_MG_LIST_FAILURE, error);

// MEDIA GALLERY - UPDATE SELECTED MEDIA LIST
export const mgUpdateSelectedMediaList = (payload: number[]) => createAction(actionTypes.MG_UPDATE_SELECTED_MULTIPLE_MEDIA_LIST, payload);
export const mgResetSelectedMediaList = () => createAction(actionTypes.MG_RESET_SELECTED_MULTIPLE_MEDIA_LIST);

const MEDIA_GALLERY_ACTIONS = {
  getMGFilterStatsRequest,
  getMGFilterStatsResponse,
  getMGFilterStatsFailure,
  getMGListRequest,
  getMGListResponse,
  getMGListRequestFailure,
  mgUploadCloudMediaRequest,
  mgUploadCloudMediaResponse,
  mgUploadCloudMediaReqFailure,
  mgValidUploadedCloudMediaList,
  mgSaveAssetRequest,
  mgSaveAssetResponse,
  mgSaveAssetFailure,
  mgDeleteAssetRequest,
  mgDeleteAssetResponse,
  mgDeleteAssetFailure,
  mgSetAddedAssetsCount,
  mgGetCloudResponseList,
  mgTagsRequest,
  mgTagsResponse,
  mgTagsRequestFailure,
  mgGetFavTagsRequest,
  mgUpdateFavTagsResponse,
  mgUpdateFavTagsRequestFailure,
  mgUpdateFavTagsRequest,
  mgGetFavTagsResponse,
  mgGetFavTagsRequestFailure,
  mgSetTagsList,
  mgMediaReleaseRequest,
  mgMediaReleaseResponse,
  mgMediaReleaseRequestFailure,
  mgLikeRequest,
  mgLikeResponse,
  mgLikeRequestFailure,
  mgDislikeRequest,
  mgDislikeResponse,
  mgDislikeRequestFailure,
  mgDetailDataRequest,
  mgDetailDataResponse,
  mgDetailDataRequestFailure,
  mgUpdateDetailDataRequest,
  mgUpdateDetailDataResponse,
  mgUpdateDetailDataRequestFailure,
  mgAddedAssetDetailReset,
  mgDeleteAssetDataReset,
  mgUpdateAssetDataRest,
  mgDetailDataReset,
  mgResetAll,
  mgSetUploadProgressInfo,
  setEditedMgDetails,
  showHideWatermarkModal,
  uploadWatermarkImageToCloudRequest,
  uploadWatermarkImageToCloudResponse,
  saveWatermarkImageRequest,
  saveWatermarkImageResponse,
  cancelWatermarkUpload,
  getWatermarkImageRequest,
  getWatermarkImageResponse,
  mgCloneAssetRequest,
  mgCloneAssetResponse,
  mgCloneAssetFailure,
  mediaDetailViewError,
  mgAIImageUploadingRequest,
  mgs3UploadCloudMediaRequest,
  mgAddCustomizeVideoThumbnailRequest,
  mgAddCustomizeVideoThumbnailResponse,
  mgResetCustomVideoThumbnailResponse,
  mgAddMediaReleaseRequest,
  mgAddMediaReleaseResponse,
  mgDeleteMediaReleaseRequest,
  mgUpdateMediaCloudResponse,
  mgUpdateMediaExpiration,
  mediaGalleryUpdateSettings,
  mediaGalleryResetSettings,
  mgResetMediaExpiration,
  mgRemoveCustomVideoThumbnailResponse,
  mgUpdateSelectedMediaList,
  getMultiSelectMGListRequest,
  getMultiSelectMGListResponse,
  getMultiSelectMGListRequestFailure,
  mgResetSelectedMediaList,
  mgResetMediaRelease
};

export type MediaGalleryAction = ActionsUnion<typeof MEDIA_GALLERY_ACTIONS>;
