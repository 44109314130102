import { useState, useMemo, useEffect } from 'react';
import { Modal, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { commonModalPopup, IStore, campaignsContainerTypes } from 'types';
import { ImageValidation } from 'widgets/Media';
import { Loading, ScheduleLocationSection } from 'components';
import {
  convertStringToDate,
  getCampaignWeeklyScheduleList,
  postStartCampaignValidation,
  getCurrentDate,
  getCampaignWeeklyScheduleObj,
  getCampaignPostsLocationDetails,
  dateFormatByRequestedFormat,
  getEndOfDate,
  getInitialLocationScheduleType,
  getParsedDate,
  getFormattedDate
} from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import {
  USER_OWNERSHIP,
  RIPPLE_COLOR,
  ICommonDateTypes,
  START_CAMPAIGN_POSTS_INIT_DATA,
  START_CAMPAIGN_POSTS_FILEDS,
  DATE_FORMAT,
  DATEPICKER_DATE_ONLY_FORMAT,
  CAMPAIGN_POSTS_DURATION_TYPE,
  CampaignDurationType,
  FRAN_SCHEDULE_POST_TYPE,
  WEEKLY_SCHEDULE_CAMPAIGN_DATA,
  CAMPAIGN_POSTS_INIT_ACTIVE_SLOT,
  CAMPAIGN_INIT_FILTER_DATA,
  CALENDAR_YR_MN_DATE_FORMAT,
  FRANCHISORS_SELECTIONS,
  LOCATION_LISTS
} from 'utils/constants';
import {
  createOrUpdateCampaignEventRequest,
  franchisorScheduleTreeReset,
  getCampaignsEventRequest,
  campaignEventDetailReset,
  setFranchisorScheduleLocType,
  getFranScheduleTreeStats,
  getDirectLocsForHubOrLocListRequest,
  getDirectLocsForHubOrLocListResponse
} from 'actions';
import { CustomDatePicker } from 'widgets/DatePicker';
import { CampaignWeeklyScheduleSection } from 'containers/Content/Campaigns/SubPages';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';

export const StartOrEditCampaignEventModal = ({ isShowModal, onModalClose }: commonModalPopup.ICommonModalProps) => {
  const dispatch = useDispatch();

  const { queryParams } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);
  const { id, userOwnership, isValidId, optionalParams } = useAccountSwitcherData();

  const validCampaignId = optionalParams[0];

  const isDetailFetching = useSelector((state: IStore) => state.campaigns.isDetailFetching);
  const campaignEndDate = useSelector((state: IStore) => state.campaigns.campaignDetail?.end_date || null);
  const isEventFetching = useSelector((state: IStore) => state.campaigns.isEventFetching);
  const campaignEvent = useSelector((state: IStore) => state.campaigns.campaignEvent);
  const campaignDetailId = useSelector((state: IStore) => state.campaigns.campaignDetail?.id || 0);
  const campaignDetailName = useSelector((state: IStore) => state.campaigns.campaignDetail?.name || '');
  const allAccountIds = useSelector((state: IStore) => state.campaigns.campaignDetail?.all_account_ids || []);
  const campaignDetailStatus = useSelector((state: IStore) => state.campaigns.campaignDetail?.status);
  const locationsCount = useSelector((state: IStore) => state.accountSwitcher.childCount);
  const tzinfoName = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');
  const corporateAccountId = useSelector((state: IStore) => state.franchisors.franchisorDetails?.corporate_account_id || 0);
  const totalLocations = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList?.onlyLocationList || []);
  const locationType = useSelector((state: IStore) => state.franchisors.franScheduleTree.locationType);
  const schLocationTree = useSelector((state: IStore) => state.franchisors.franScheduleTree.schLocationTree);

  const [activeSlot, setActiveSlot] = useState(CAMPAIGN_POSTS_INIT_ACTIVE_SLOT);

  const allSchAccountIds = queryParams?.add_event === '1' ? allAccountIds : [];
  const editEventId = +queryParams?.edit_event || 0;

  // Edit campaign - Get campaign event data
  useEffect(() => {
    if (id && userOwnership && isValidId && validCampaignId && editEventId) {
      dispatch(
        getCampaignsEventRequest({
          campaignId: +validCampaignId,
          eventId: editEventId
        })
      );
    }
  }, [id, userOwnership, isValidId, validCampaignId, editEventId, dispatch]);

  const initialCampaignEvent = useMemo(() => {
    return campaignEvent?.campaign_id
      ? {
          campaign_id: campaignEvent?.campaign_id,
          campaign_event_id: campaignEvent?.id,
          campaign_post_per_week: campaignEvent.campaign_post_per_week,
          end_date: convertStringToDate(campaignEvent.end_date),
          weekly_schedule: getCampaignWeeklyScheduleList({
            endDate: convertStringToDate(campaignEvent.end_date),
            weeklySchedule: campaignEvent?.config?.weekly_schedule
          }),
          duration_type: !campaignEvent?.end_date ? CampaignDurationType.ONGOING : CampaignDurationType.END_DATE
        }
      : validCampaignId
      ? {
          campaign_id: +validCampaignId,
          campaign_event_id: 0,
          campaign_post_per_week: 0,
          end_date: null,
          weekly_schedule: getCampaignWeeklyScheduleList({}),
          duration_type: !campaignEndDate ? CampaignDurationType.ONGOING : CampaignDurationType.END_DATE
        }
      : START_CAMPAIGN_POSTS_INIT_DATA;
  }, [campaignEvent, validCampaignId, campaignEndDate]);

  const { values, touched, setValues, setFieldValue, errors, handleBlur, handleSubmit, setTouched, handleReset } = useFormik({
    initialValues: initialCampaignEvent,
    validationSchema: postStartCampaignValidation,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (val) => {
      if (val?.campaign_id) {
        dispatch(
          createOrUpdateCampaignEventRequest({
            campaignId: val?.campaign_id || 0,
            campaignEventId: val?.campaign_event_id || 0,
            campaignEvent: {
              campaign_id: values.campaign_id,
              campaign_post_per_week: values.campaign_post_per_week,
              end_date: val.end_date ? dateFormatByRequestedFormat(val.end_date, CALENDAR_YR_MN_DATE_FORMAT) : null,
              ...(userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: id } : { account_id: id }),
              config: {
                weekly_schedule: getCampaignWeeklyScheduleObj(val.weekly_schedule, tzinfoName),
                ...getCampaignPostsLocationDetails(userOwnership, locationType, totalLocations, schLocationTree, allSchAccountIds, corporateAccountId)
              }
            }
          })
        );
        handleGoBack();
      }
    }
  });

  // While editing campaign event, get the hub, account list and individual locations
  useEffect(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && campaignEvent?.id) {
      handleGetSelectedLocation();
    }
  }, [userOwnership, campaignEvent?.id]); // eslint-disable-line

  const handleGetSelectedLocation = () => {
    if (campaignEvent) {
      const isLocationScheduled = Boolean('location' in campaignEvent?.config && campaignEvent.config?.location && campaignEvent?.account_id);
      const includedAccounts = isLocationScheduled
        ? totalLocations.map((it) => ({ id: it.id, name: it.label })).filter((it) => it.id === campaignEvent?.account_id)
        : campaignEvent?.config?.included_accounts || [];
      const franchisorIds = campaignEvent?.config?.schedule_to_franchisor_ids || [];
      const accountListIds = campaignEvent?.config?.schedule_to_account_list_ids || [];
      dispatch(
        setFranchisorScheduleLocType(
          getInitialLocationScheduleType({
            totalLocationCount: locationsCount,
            corporateAccountId: isLocationScheduled ? null : corporateAccountId,
            includedAccounts,
            franOrAccountListIds: franchisorIds || accountListIds || []
          })
        )
      );
      dispatch(getFranScheduleTreeStats({ hubs: franchisorIds.length, locationLists: accountListIds.length }));
      if (franchisorIds?.length) franchisorIds.forEach((datum) => dispatch(getDirectLocsForHubOrLocListRequest({ id: datum, type: FRANCHISORS_SELECTIONS, includedAccounts })));
      if (accountListIds?.length) accountListIds.forEach((datum) => dispatch(getDirectLocsForHubOrLocListRequest({ id: datum, type: LOCATION_LISTS, includedAccounts })));
      if (includedAccounts?.length && !franchisorIds?.length && !accountListIds?.length) dispatch(getDirectLocsForHubOrLocListResponse({ includedAccounts }));
    }
  };

  const totalCampaignPostsCountPerWeek = useMemo(() => {
    const totalPostsCount = values.weekly_schedule.reduce((acc, curr) => {
      if (curr.postSlot?.length) acc += curr.postSlot?.length || 0;
      return acc;
    }, 0);
    setFieldValue(START_CAMPAIGN_POSTS_FILEDS.CAMPAIGN_POST_PER_WEEK, totalPostsCount);
    return totalPostsCount;
  }, [values.weekly_schedule]); // eslint-disable-line

  const handleFieldsTouched = () => {
    const errorFields = Object.keys(errors).reduce((acc, curr) => {
      acc = {
        ...acc,
        [curr]: true
      };
      return acc;
    }, {});
    setTouched(errorFields, true);
  };

  const handleFieldChange = (key: string, value: null | number | string | Date) => {
    const campaignData = { ...values, [key]: value };
    if (key === START_CAMPAIGN_POSTS_FILEDS.DURATION_TYPE) {
      setValues({
        ...campaignData,
        [START_CAMPAIGN_POSTS_FILEDS.END_DATE]: null,
        [START_CAMPAIGN_POSTS_FILEDS.WEEKLY_SCHEDULE]: getCampaignWeeklyScheduleList({
          weeklySchedule: getCampaignWeeklyScheduleObj(WEEKLY_SCHEDULE_CAMPAIGN_DATA)
        })
      });
    } else if (value instanceof Date) {
      setValues({
        ...campaignData,
        [START_CAMPAIGN_POSTS_FILEDS.WEEKLY_SCHEDULE]: getCampaignWeeklyScheduleList({
          endDate: key === START_CAMPAIGN_POSTS_FILEDS.END_DATE ? value : values.end_date || null,
          weeklySchedule: getCampaignWeeklyScheduleObj(WEEKLY_SCHEDULE_CAMPAIGN_DATA)
        })
      });
    }
    if (activeSlot.slotId) setActiveSlot(CAMPAIGN_POSTS_INIT_ACTIVE_SLOT);
  };

  const getClassNames = (valueItem: keyof campaignsContainerTypes.IStartCampaignFieldValue) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] && values[valueItem] && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'err-disp' : ''}`;
    return className;
  };

  const handleGoBack = () => {
    dispatch(campaignEventDetailReset());
    dispatch(franchisorScheduleTreeReset());
    handleReset(values);
    onModalClose();
  };

  const disableWeeklyScheduleSection = () => {
    if (values.duration_type === CampaignDurationType.END_DATE && !values.end_date) return true;
    return false;
  };

  const disableSaveAction = () => {
    if (locationType === FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS && !schLocationTree.length) return true;
    else if (disableWeeklyScheduleSection()) return true;
    else if (!totalCampaignPostsCountPerWeek) return true;
    else if (userOwnership === USER_OWNERSHIP.ACCOUNT && campaignEvent?.franchisor_id) return true;
    else if (campaignDetailStatus === 'ACTIVE') {
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR && locationType === FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS && totalLocations.every((it) => allAccountIds.includes(it.id))) return true;
      else if (userOwnership === USER_OWNERSHIP.ACCOUNT && allAccountIds.includes(id)) return true;
      return false;
    }
    return false;
  };

  const selectedLocationsCount = () => {
    return schLocationTree?.reduce((acc, curr) => {
      if (curr.type) acc += (curr.locations || []).filter((it) => it.active).length;
      else acc++;
      return acc;
    }, 0);
  };

  return (
    <Modal
      // className={'creator-tag-modal-wrp schedule-post-modal-hubuser schedule-post__main cr-sm-mdl campaign-mdl-main'}
      className={'glbl__modal--wrp'}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isShowModal}
      animation={false}
      onHide={handleGoBack}
    >
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg={'close-creator'} customName={'close'} customClassname="mod__close--img" onClick={handleGoBack} />
      </div>
      <Modal.Body className={isDetailFetching || isEventFetching ? 'pointer-events-none' : ''}>
        {isDetailFetching || isEventFetching ? <Loading /> : null}
        <form onSubmit={handleSubmit}>
          {campaignDetailId ? <h3 className="glbl__title--txt">{`${queryParams?.add_event === '1' ? 'Start' : 'Edit'} Campaign - ${campaignDetailName}`}</h3> : null}

          {userOwnership === USER_OWNERSHIP.FRANCHISOR ? (
            <Card className="glbl__modal--card">
              <Card.Header>Where</Card.Header>
              <Card.Body>
                <ScheduleLocationSection selectedLocationsCount={selectedLocationsCount} scheduledLocationIds={allSchAccountIds} />
              </Card.Body>
            </Card>
          ) : null}

          <Card className="wf-card glbl__modal--card">
            <Card.Header>When</Card.Header>
            <Card.Body className="wf-pers">
              <div className="when-fields">
                <div className="wf-left-labels">
                  <span>Duration</span>
                </div>
                <div className="wf-right-options">
                  <div className="radio-flex">
                    {CAMPAIGN_POSTS_DURATION_TYPE.map((durationItem, index) => {
                      return (
                        <label key={`campaign-duration-type-${index}`} className={durationItem.value === values.duration_type ? 'active' : 'pointer-events-none'}>
                          <input
                            type={durationItem.inputType}
                            className={`option-input ${durationItem.inputType}`}
                            name={START_CAMPAIGN_POSTS_FILEDS.DURATION_TYPE}
                            value={durationItem.value}
                            checked={durationItem.value === values.duration_type}
                            onBlur={handleBlur}
                            readOnly
                            // onChange={(event) => handleFieldChange(START_CAMPAIGN_POSTS_FILEDS.DURATION_TYPE, event.target.value)}
                          />
                          <span className={`labelText`}>{durationItem.label}</span>
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>

              {values.duration_type === CampaignDurationType.END_DATE && (
                <div className="when-fields ccec-st-wrap">
                  <div className="wf-left-labels">
                    <span>Stop on</span>
                  </div>
                  <div className="wf-right-options">
                    <div className="datime-picker">
                      <div className="wdt-item da-date">
                        <CustomDatePicker
                          name={START_CAMPAIGN_POSTS_FILEDS.END_DATE}
                          selectedDate={values.end_date}
                          dateRangeType={ICommonDateTypes.TO_DATE}
                          onChange={(date) => handleFieldChange(START_CAMPAIGN_POSTS_FILEDS.END_DATE, getEndOfDate(date))}
                          customClassname={`fltr-date-item fltr-from-date ${getClassNames(START_CAMPAIGN_POSTS_FILEDS.END_DATE)}`}
                          minDate={getCurrentDate()}
                          maxDate={getParsedDate(getFormattedDate(campaignEndDate))}
                          autoComplete="off"
                          placeholderText={DATE_FORMAT}
                          dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <CampaignWeeklyScheduleSection
                disabled={disableWeeklyScheduleSection()}
                weeklySchedule={values.weekly_schedule}
                startDate={null}
                endDate={values.end_date}
                setFieldValue={setFieldValue}
                activeSlot={activeSlot}
                setActiveSlot={setActiveSlot}
              />
            </Card.Body>
          </Card>
          <Card className="tcp">
            <div className="tcp-cnt">
              <h3>
                Total number of campaign post per week:<span>{totalCampaignPostsCountPerWeek}</span>
              </h3>
            </div>
          </Card>
          <div className="mac-btns">
            <div className="ac-footer r-flx r-flx-ac g-20">
              <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button className={`ac-btn ac-secondary-white ac-outline size-xs border-0`} type="button" onClick={handleGoBack}>
                  <span>Go Back</span>
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-btn ac-primary ac-outline size-xs${disableSaveAction() ? ` events-none pointer-events-none` : ''}`} type="submit" onClick={handleFieldsTouched}>
                  <span>{queryParams?.add_event === '1' ? 'Start Campaign' : 'Update Schedule'}</span>
                </button>
              </CustomRippleButton>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
