import { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { IStore, commonModalPopup, postsApiTypes, commonWidgetTypes } from 'types';
import { addPostSetCampaignData, campaignsListRequest, campaignResponseMsgReset, addPostIsConfirmActiveCampaignUpdate } from 'actions';
import { CREATOR_USER_OWNERSHIP, RIPPLE_COLOR, USER_OWNERSHIP, ALERT_BOX_TYPES, ACTIVE_CAMPAIGN_WARN_MSG, NoDataFoundProps, INPUT_TYPE } from 'utils/constants';
import { alertBoxCall, CreateOrEditCampaignModal, Loading, NoDataFound } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { AlphabetList } from 'widgets/AlphabetList';

export const AddOrSearchCampaignModal = ({ isShowModal, onModalClose, onSaveChanges }: commonModalPopup.IAddOrSearchCampaignProps) => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();

  const isListFetching = useSelector((state: IStore) => state.campaigns.isListFetching);
  const campaignsList = useSelector((state: IStore) => state.campaigns.campaignsList);
  const isCreateOrEditCampaign = useSelector((state: IStore) => state.campaigns.isCreateOrEditCampaign);
  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id);
  const campaignData = useSelector((state: IStore) => state.addPostFranchisorAction.campaignData);
  const savePostCampaigns = useSelector((state: IStore) => state.addPostAccount.savedPostDetails?.campaigns || []);
  const contentCampaigns = useSelector((state: IStore) => state.addPostFranchisor.contentData?.campaigns || []);
  const successMsg = useSelector((state: IStore) => state.campaigns.responseMsg.successMsg);
  const failureMsg = useSelector((state: IStore) => state.campaigns.responseMsg.failureMsg);
  const isActiveCampaignUpdate = useSelector((state: IStore) => state.addPostFranchisorAction.isActiveCampaignUpdate);

  const [searchValue, setSearchValue] = useState('');
  const [openCampaignLogList, setOpenCampaignLogList] = useState(true);
  const [isShowCreateCampaign, setIsShowCreateCampaign] = useState(false);

  useEffect(() => {
    handleCampaignFolderList();
  }, []); // eslint-disable-line

  // If the campaign is created, show success msg and reload campaign list.
  // If the campaign already exist, show failure msg.
  useEffect(() => {
    if (successMsg) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, successMsg);
      handleCampaignFolderList();
      dispatch(campaignResponseMsgReset());
    }
    if (failureMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, failureMsg);
      dispatch(campaignResponseMsgReset());
    }
  }, [successMsg, failureMsg]); // eslint-disable-line

  const filteredCampaignList = useMemo(() => {
    return campaignsList.filter((it) => it.name.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) > -1);
  }, [campaignsList, searchValue]);

  const handleActiveCampaignUpdateReset = () => {
    if (isActiveCampaignUpdate) dispatch(addPostIsConfirmActiveCampaignUpdate(false));
  };

  const handleCampaignFolderList = () => {
    if (id && userOwnership && creatorCompName) {
      const activeUserOwnership = creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT ? USER_OWNERSHIP.ACCOUNT : userOwnership;
      dispatch(
        campaignsListRequest({
          id: creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT && accountId ? accountId : id,
          userOwnership: activeUserOwnership,
          ...(activeUserOwnership === USER_OWNERSHIP.ACCOUNT && { source: 'local' })
        })
      );
    }
  };

  const handleAddCampaign = (campaignTag: postsApiTypes.IPostListDetailData) => {
    if (!campaignTag.id) {
      setIsShowCreateCampaign(true);
    }
  };

  const handleCampaignSelection = (selectedData: commonWidgetTypes.IAlphabetListData) => {
    const selectedCampaignData = filteredCampaignList.find((it) => selectedData.id === it.id);
    if (selectedCampaignData?.id) {
      dispatch(
        addPostSetCampaignData(
          !campaignData.map((it) => it.id).includes(selectedCampaignData.id)
            ? [...campaignData, { id: selectedCampaignData.id, name: selectedCampaignData.name, active: selectedCampaignData.active }]
            : campaignData.filter((it) => it.id !== selectedCampaignData.id)
        )
      );
      handleActiveCampaignUpdateReset();
    }
  };

  const handleDeleteCampaign = (campaignId: string | number) => {
    if (campaignId) {
      dispatch(addPostSetCampaignData(campaignData.filter((it) => it.id !== campaignId)));
      handleActiveCampaignUpdateReset();
    }
  };

  const getClassname = () => {
    return `ripple-unset ac-primary-box r-ml2${!campaignData?.length ? ` events-none pointer-events-none` : ''}`;
  };

  const handleCancelCampaignChanges = () => {
    dispatch(addPostSetCampaignData(creatorCompName && [CREATOR_USER_OWNERSHIP.ACCOUNT, CREATOR_USER_OWNERSHIP.LOCATION_CONTENT].includes(creatorCompName) ? savePostCampaigns : contentCampaigns));
    onModalClose();
  };

  const isActiveCampaign = () => Boolean(!isActiveCampaignUpdate && campaignData.filter((it) => it.active)?.length);

  return (
    <Modal show={true || isShowModal} centered size="lg" className={'connection-secondary add-location ralprofile-hl add-booster addSearchCampaign'} onHide={onModalClose}>
      <Modal.Body>
        <div className="sec-conn__wrp">
          <div className="sec-header">
            <div className="header-item__wrp">
              <span className="title">Campaign</span>
              <ImageValidation isImgValid defaultImg="mpcIcon" customName="close" customClassname="close-icon close mod__close--img wh-30" onClick={onModalClose} />
            </div>
          </div>
          <div className="add-search__campaign">
            <h3>Add / Search Campaign</h3>
            <div className="ads-tag">
              <CustomReactTags
                key={'search-campaign-name'}
                suggestions={!filteredCampaignList.length && searchValue ? [{ id: 0, name: `Add ${searchValue}` }] : []}
                handleInputChange={(search: string) => {
                  setSearchValue(search);
                }}
                handleAddition={(tag: any) => handleAddCampaign(tag)}
                handleDelete={() => false}
                placeholder={'Search Campaign'}
                autofocus={false}
              />
              <div className="asc-options">
                <div className="asc-sel-alert" onClick={() => setOpenCampaignLogList(!openCampaignLogList)}>
                  {!openCampaignLogList ? <span>Close</span> : campaignData?.length ? <span className="active">{`Selected (${campaignData.length})`}</span> : null}
                </div>
                {openCampaignLogList ? (
                  isListFetching || isCreateOrEditCampaign ? (
                    <Loading isSmall />
                  ) : filteredCampaignList?.length ? (
                    <AlphabetList
                      data={filteredCampaignList.map((it) => ({ id: it.id, name: it.name }))}
                      selectedData={campaignData}
                      onSelect={(data) => handleCampaignSelection(data)}
                      inputType={INPUT_TYPE.CHECKBOX}
                      className={'asc-list'}
                    />
                  ) : (
                    <NoDataFound size={NoDataFoundProps.MEDIUM} />
                  )
                ) : (
                  <div className={`tags-wrp${isCreateOrEditCampaign ? ` pointer-events-none` : ''}`}>
                    {isCreateOrEditCampaign ? <Loading isSmall /> : null}
                    {campaignData.map((it, index) => {
                      return (
                        <div className="list-expanded-tag-item" key={`selected-campaign-${index}`}>
                          <span className="list-expanded-tag-text" title={it.name}>
                            {it.name}
                          </span>
                          <span className="list-expanded-tag-remove" onClick={() => handleDeleteCampaign(it.id)} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {isActiveCampaign() && <div className="error-txt-login">{ACTIVE_CAMPAIGN_WARN_MSG}</div>}
            <div className="ac-footer">
              <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button className={`ac-btn ac-secondary-white border-0 ac-outline size-xs`} type="button" onClick={handleCancelCampaignChanges}>
                  <span>Cancel</span>
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`edit-ripple__wrp ${getClassname()}`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className="ac-btn ac-primary border-0 ac-outline size-xs"
                  type="submit"
                  onClick={() => {
                    onSaveChanges(searchValue);
                    onModalClose();
                  }}
                >
                  <span>Save</span>
                </button>
              </CustomRippleButton>
            </div>
          </div>
        </div>
      </Modal.Body>
      <CreateOrEditCampaignModal
        isShowModal={isShowCreateCampaign}
        onModalClose={() => {
          setIsShowCreateCampaign(false);
          setSearchValue('');
        }}
        campaignName={searchValue}
      />
    </Modal>
  );
};
