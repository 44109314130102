import { useSelector, useDispatch } from 'react-redux';
import { ButtonGroup, ToggleButton, Card, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';

import { IStore, accountContainerTypes, commonModalPopup } from 'types';
import { useAccountSwitcherData } from 'utils/hooks';

import {
  RP_TIME_PICKER_MERIDIAN,
  ACCOUNT_SCHEDULE_DURATION_TYPE,
  ACCOUNT_LIST_SCHEDULE_TAB_FIELDS,
  CampaignDurationType,
  ICommonDateTypes,
  DATEPICKER_DATE_ONLY_FORMAT,
  START_CAMPAIGN_POSTS_FILEDS,
  RIPPLE_COLOR,
  ACCOUNT_LIST_SCHEDULE_INITIAL_VALUES,
  POST_MERIDIAN,
  ANTE_MERIDIAN,
  CALENDAR_YR_MN_DATE_FORMAT,
  CONNECTION_TABS,
  CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER,
  USER_OWNERSHIP,
  SCHEDULER_DAY_SLOT_OPTIONS,
  CALENDAR_SELECT_FUTURE_DATE_WARNING,
  YEAR_MONTH_DAY_WITH_TIME_FORMAT_HYPHEN,
  CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS,
  // ContentType,
  ContentTypeIdentifier
} from 'utils/constants';
import {
  getCurrentDate,
  accSchedulerValidation,
  compareTwoObjects,
  getParsedDate,
  getFormattedDate,
  updateFranchisorSyndicateWithLabel,
  getDateTimeByHourAndMin,
  startDateMoreThanEndDate,
  getCurrentDateWithoutMS,
  getCurrentWeekDates,
  checkCurrentDateLessThan,
  formatDate
} from 'utils/helpers';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomDatePicker } from 'widgets/DatePicker';
import { ImageValidation } from 'widgets/Media';
import { accountCreateOrUpdateScheduleRequest, accountDeleteScheduleRequest } from 'actions';
import { ScheduleSlotDeleteConfirmationModal } from './ScheduleSlotDeleteConfirmationModal';

export const CreateEditSlotDetailModal = ({ isShowModal, onModalClose, slotDetail, isFromCalendar }: commonModalPopup.ICreateEditTimeSlotModal) => {
  const dispatch = useDispatch();

  const { id, userOwnership, optionalParams } = useAccountSwitcherData();

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const schedulerContentSupplier = useSelector((state: IStore) => state.operationsList.scheduler_content_supplier);
  const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);

  const [isDeleteScheduleConfirmationModalOpen, setIsDeleteScheduleConfirmationModalOpen] = useState(false);

  const schedulerContentSupplierWithAll = [
    {
      value: 'Any',
      label: 'All enrolled feeds',
      id: null,
      name: 'Any'
    },
    ...updateFranchisorSyndicateWithLabel(schedulerContentSupplier)
  ];

  const convertTimeIntoStringLabel = (type: string, value: number) => {
    let updatedValue = value;
    if (type === ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.HOUR) {
      updatedValue = !updatedValue ? 12 : updatedValue > 12 ? updatedValue - 12 : updatedValue;
    }

    return updatedValue < 10 ? `0${updatedValue}` : updatedValue.toString();
  };

  const getInitialValues = () => {
    let initialValues = ACCOUNT_LIST_SCHEDULE_INITIAL_VALUES;
    if (slotDetail) {
      initialValues = {
        account_list_id: slotDetail.account_list_id,
        day_of_week: slotDetail.day_of_week,
        hour: convertTimeIntoStringLabel(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.HOUR, slotDetail.hour),
        minute: convertTimeIntoStringLabel(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.MINUTE, slotDetail.minute),
        start_date: slotDetail?.start_date,
        end_date: slotDetail?.end_date,
        vertical_id: slotDetail.vertical_id,
        duration_type: slotDetail?.start_date && slotDetail?.end_date ? CampaignDurationType.END_DATE : slotDetail?.end_date ? CampaignDurationType.ONCE : CampaignDurationType.ONGOING,
        meridian: slotDetail.hour < 12 ? ANTE_MERIDIAN : POST_MERIDIAN,
        once_date: slotDetail?.once_date,
        content_type: CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS.find((selectOption) => selectOption.identifier === slotDetail?.content_type)?.value || 0
      };
    }
    return initialValues;
  };

  const { values, setFieldValue, errors, handleBlur, handleSubmit, touched, setValues, resetForm } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: accSchedulerValidation(userOwnership),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (val) => {
      const startAndEndDateForOnce = val?.once_date
        ? !isSameOrGreaterDate || !isSchedulerDateIsAfterCurrentDate
          ? getParsedDate(formatDate(val?.once_date, 'addWeek', 1, CALENDAR_YR_MN_DATE_FORMAT))
          : getParsedDate(formatDate(val?.once_date, 'addWeek', 0, CALENDAR_YR_MN_DATE_FORMAT))
        : null;
      const updatedValues = {
        account_list_id: val.account_list_id,
        day_of_week: val.day_of_week,
        hour: values.meridian === ANTE_MERIDIAN ? (+val.hour === 12 ? 0 : +val.hour) : +val.hour === 12 ? 12 : +val.hour + 12,
        minute: +val.minute,
        start_date: getParsedDate(getFormattedDate(val.start_date, CALENDAR_YR_MN_DATE_FORMAT)),
        end_date: getParsedDate(getFormattedDate(val.end_date, CALENDAR_YR_MN_DATE_FORMAT)),
        vertical_id: val.vertical_id,
        content_type: val.content_type,
        ...(val.duration_type === CampaignDurationType.ONCE &&
          val?.once_date && {
            start_date: startAndEndDateForOnce,
            end_date: startAndEndDateForOnce
          })
      };
      dispatch(accountCreateOrUpdateScheduleRequest({ id: accountDetails?.account.id || id, userOwnership, list_id: slotDetail?.id ? +slotDetail?.id! : null, reqPayload: updatedValues }));
      resetForm();
      handleModalClose();
    }
  });

  const isSameOrGreaterDate = checkCurrentDateLessThan(values?.once_date!);

  const isSchedulerDateIsAfterCurrentDate = startDateMoreThanEndDate(
    getCurrentDateWithoutMS(),
    getDateTimeByHourAndMin(
      values?.once_date ?? getCurrentDate(),
      values.meridian === POST_MERIDIAN && +values.hour < 12 ? +values.hour + 12 : values.meridian === ANTE_MERIDIAN && +values.hour === 12 ? 0 : +values.hour,
      +values.minute
    )
  );

  const activeAccListDetail = useMemo(() => {
    if (values.account_list_id) {
      return schedulerContentSupplier.find((contentSupplier) => contentSupplier.id === values.account_list_id);
    }
    return null;
  }, [values.account_list_id, schedulerContentSupplier]);

  const getClassNames = (valueItem: keyof accountContainerTypes.IAccListInitialValues) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] && values[valueItem] && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'err-disp' : ''}`;
    return className;
  };

  const handleCancel = () => {
    onModalClose();
  };

  const handleModalClose = () => {
    onModalClose();
    resetForm();
  };

  const convertTimeIntoLabel = (key: string, value: string) => {
    const validValue = value?.length === 2 ? String(value) : `0${value}`;

    setFieldValue(key, validValue);
  };

  const cancelDeleteScheduleSlot = () => {
    setIsDeleteScheduleConfirmationModalOpen(false);
    // handleModalClose();
  };

  const handleDeleteScheduleSlot = () => {
    if (slotDetail?.id) {
      dispatch(accountDeleteScheduleRequest({ id: accountDetails?.account.id || id, userOwnership, list_id: +slotDetail?.id }));
      setIsDeleteScheduleConfirmationModalOpen(false);
      handleModalClose();
    }
  };

  const handleMeridianChange = (meridian: string) => {
    setValues({
      ...values,
      meridian
    });
  };

  const handleOnceDateUpdate = (dayOfWeek: number) => {
    const onceDate = getCurrentWeekDates(dayOfWeek);
    const isSameOrGreaterDate = checkCurrentDateLessThan(onceDate);
    const updatedOnceDate = !isSameOrGreaterDate ? getParsedDate(formatDate(onceDate, 'addWeek', 1, YEAR_MONTH_DAY_WITH_TIME_FORMAT_HYPHEN)) : getParsedDate(onceDate);
    setFieldValue('once_date', updatedOnceDate);
  };

  return (
    <Modal
      backdrop={false}
      show={isShowModal}
      onHide={handleModalClose}
      centered
      size="lg"
      // className="glbl__modal--wrp connection-secondary add-location kcmWrap add-booster cfcm-ncs modal-backdrop-static"
      className={`glbl__modal--wrp connection-secondary add-location kcmWrap add-booster cfcm-ncs modal-backdrop-static ${
        optionalParams[2] === CONNECTION_TABS.SCHEDULER ? 'stepScheduler' : 'newEditCustomscheduler'
      }`}
      animation={false}
    >
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={handleModalClose} />
      </div>
      <Modal.Body>
        <Card className="wf-card glbl__modal--card">
          <Card.Header>{slotDetail?.id ? 'Edit' : 'New'} Custom Schedule</Card.Header>
          <Card.Body className="wf-pers">
            <div className="when-fields">
              <div className={`wf-right-options`}>
                <div className="wf-schedule">
                  <div className="sdt-options">
                    {isFromCalendar && values?.duration_type === CampaignDurationType.ONCE ? null : (
                      <div className="selectSchedulerSC">
                        <div className="ssc-item">
                          <Card.Title className="static_label">
                            <span className="sl-txt">Day</span>
                          </Card.Title>
                          <CustomDropdown
                            classNamePrefix="glbl__dropdown"
                            id="boost-duration-dropdown"
                            placeholder={'Select Day'}
                            options={SCHEDULER_DAY_SLOT_OPTIONS}
                            name={'day_of_week'}
                            value={SCHEDULER_DAY_SLOT_OPTIONS.find((list) => list.value === values.day_of_week) || SCHEDULER_DAY_SLOT_OPTIONS[0]}
                            onChange={(eventObj: any) => {
                              setFieldValue('day_of_week', eventObj?.value);
                              if (values?.duration_type === CampaignDurationType.ONCE && values?.once_date) {
                                handleOnceDateUpdate(eventObj?.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="cr-timePicker">
                      <div className="lbls ssc-item">
                        <div className="static_label h5">
                          <span className="sl-txt">Time</span>
                        </div>

                        <ButtonGroup className="crt-item crt-top btn-group-toggle">
                          <div className="tgb-item">
                            <ToggleButton id={`campaign-ws-hour`} type="radio" variant="secondary" name="hours" value={'slotHours'}>
                              <input
                                name={ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.HOUR}
                                type="number"
                                className="iText"
                                placeholder={'00'}
                                step={1}
                                min={1}
                                max={12}
                                value={values.hour}
                                autoComplete="off"
                                onChange={(event) => convertTimeIntoLabel(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.HOUR, String(+event.target.value > 12 ? 12 : +event.target.value))}
                                onBlur={handleBlur}
                              />
                            </ToggleButton>
                          </div>

                          <div className="ctSep">
                            <ImageValidation isImgValid defaultImg={`ctSep`} customName="ctSep" />
                          </div>

                          <div className="tgb-item">
                            <ToggleButton id={`campaign-ws-minute`} type="radio" variant="secondary" name="minutes" value={'slotMinutes'}>
                              <input
                                name={ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.MINUTE}
                                type="number"
                                className="iText"
                                placeholder={'00'}
                                step={1}
                                min={0}
                                max={59}
                                value={values.minute}
                                autoComplete="off"
                                onChange={(event) => convertTimeIntoLabel(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.MINUTE, String(+event.target.value > 59 ? 59 : +event.target.value))}
                              />
                            </ToggleButton>
                          </div>
                          <div className="crt-item crt-base">
                            <div className="switch6">
                              <label className="switch6-light">
                                <input type="checkbox" />
                                <span>
                                  {RP_TIME_PICKER_MERIDIAN.map((timeItem, timeIdx) => {
                                    return (
                                      <span key={`campaign-time-meridian-${timeIdx}`} className={timeItem === values.meridian ? 'active' : ''} onClick={() => handleMeridianChange(timeItem)}>
                                        {timeItem}
                                      </span>
                                    );
                                  })}
                                </span>
                                <button type="button" className="btn btn-primary" />
                              </label>
                            </div>
                          </div>
                        </ButtonGroup>
                      </div>
                      <div className="selectSchedulerSC">
                        <div className="ssc-item">
                          <Card.Title className="static_label">
                            <span className="sl-txt">Source {userOwnership === USER_OWNERSHIP.ACCOUNT ? '(Optional)' : ''}</span>
                            {/* <span className="mandatory-sign">*</span> */}
                          </Card.Title>
                          <CustomDropdown
                            classNamePrefix="glbl__dropdown"
                            id="source-dropdown"
                            placeholder={'Select Content Source'}
                            options={schedulerContentSupplierWithAll}
                            name={'account_list_id'}
                            value={schedulerContentSupplierWithAll.find((list) => list.id === values.account_list_id) || schedulerContentSupplierWithAll[0]}
                            onChange={(eventObj: any) => {
                              const selectedFeedList = schedulerContentSupplier.find((list) => list.id === +eventObj?.id) || null;
                              if (selectedFeedList?.id) {
                                setFieldValue(
                                  'content_type',
                                  CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS.find((option) => option.identifier === selectedFeedList?.content_type)?.value ||
                                    CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS?.[0]?.value
                                );
                              }
                              setFieldValue('account_list_id', +eventObj?.id || null);
                            }}
                          />
                        </div>

                        {values.account_list_id && activeAccListDetail?.id ? (
                          <div className="sourceLcc">
                            <ul>
                              <li>
                                <h3>Content Source:</h3>
                                <span>
                                  {activeAccListDetail?.content_source === CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER.CONTENT_SUPPLIER
                                    ? activeAccListDetail?.content_suppliers?.length
                                      ? activeAccListDetail?.content_suppliers?.map((contentSupplier) => contentSupplier.name).join(', ')
                                      : ''
                                    : activeAccListDetail.content_source === CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER.ALL
                                    ? CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER.ALL
                                    : topLevelFranchisor}
                                </span>
                              </li>
                              <li>
                                <h3>Content Tags:</h3>
                                <span>{activeAccListDetail?.tags_list}</span>
                              </li>
                              {/*  <li>
                                <h3>Content Type:</h3>
                                <span>{activeAccListDetail?.content_type}</span>
                              </li> */}
                            </ul>
                          </div>
                        ) : null}

                        <div className="ssc-item">
                          <Card.Title className="static_label">
                            <span className="sl-txt">Content Type</span>
                          </Card.Title>
                          <div className={`${activeAccListDetail?.content_type && activeAccListDetail?.content_type !== ContentTypeIdentifier.ALL ? 'pointer-events-none' : ''}`}>
                            <CustomDropdown
                              classNamePrefix="glbl__dropdown"
                              id="source-dropdown"
                              placeholder={'Select Content Type'}
                              options={CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS}
                              name={'content_type'}
                              value={CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS.find((selectOption) => selectOption.value === values?.content_type) || CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS[0]}
                              onChange={(eventObj: any) => {
                                setFieldValue('content_type', eventObj.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="when-fields duration-rb-btns">
                          <div className="lbls ssc-item">
                            <div className="static_label h5">
                              <span className="sl-txt">Duration</span>
                            </div>
                          </div>
                          <div className="wf-right-options">
                            <div className="radio-flex">
                              {ACCOUNT_SCHEDULE_DURATION_TYPE.map((durationItem, index) => {
                                return (
                                  <label key={`campaign-duration-type-${index}`} className={durationItem.value === values.duration_type ? 'active' : ''}>
                                    <input
                                      type={durationItem.inputType}
                                      className={`option-input ${durationItem.inputType}`}
                                      name={ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.DURATION_TYPE}
                                      value={durationItem.value}
                                      checked={durationItem.value === values.duration_type}
                                      onBlur={handleBlur}
                                      readOnly
                                      onChange={() => {
                                        setFieldValue(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.DURATION_TYPE, durationItem.value);
                                        setFieldValue(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.START_DATE, null);
                                        setFieldValue(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.END_DATE, null);
                                        if (isFromCalendar && values?.once_date) {
                                          setFieldValue('day_of_week', getParsedDate(getFormattedDate(values?.once_date))?.getDay());
                                        }
                                        if (durationItem.value === CampaignDurationType.ONCE) {
                                          handleOnceDateUpdate(values.day_of_week);
                                        }
                                      }}
                                    />
                                    <span className={`labelText`}>{durationItem.label}</span>
                                  </label>
                                );
                              })}
                            </div>
                            {values.duration_type === CampaignDurationType.END_DATE && (
                              <div className="sc-cdtWrap">
                                <div className="when-fields ccec-st-wrap">
                                  <div className="wf-left-labels">
                                    <span>Start on</span>
                                  </div>
                                  <div className="wf-right-options">
                                    <div className="datime-picker">
                                      <div className="wdt-item da-date">
                                        <CustomDatePicker
                                          name={ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.START_DATE}
                                          selectedDate={values.start_date}
                                          dateRangeType={ICommonDateTypes.TO_DATE}
                                          onChange={(date) => {
                                            setFieldValue(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.START_DATE, date);
                                          }}
                                          customClassname={`fltr-date-item fltr-from-date ${getClassNames(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.START_DATE)}`}
                                          minDate={getCurrentDate()}
                                          maxDate={getParsedDate(getFormattedDate(values.end_date))}
                                          autoComplete="off"
                                          placeholderText={'Start date'}
                                          dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <span className="toText">to</span>
                                <div className="when-fields ccec-st-wrap">
                                  <div className="wf-left-labels">
                                    <span>Stop on</span>
                                  </div>
                                  <div className="wf-right-options">
                                    <div className="datime-picker">
                                      <div className="wdt-item da-date">
                                        <CustomDatePicker
                                          name={START_CAMPAIGN_POSTS_FILEDS.END_DATE}
                                          selectedDate={values.end_date}
                                          dateRangeType={ICommonDateTypes.TO_DATE}
                                          onChange={(date) => {
                                            setFieldValue(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.END_DATE, date);
                                          }}
                                          customClassname={`fltr-date-item fltr-from-date ${getClassNames(ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.END_DATE)}`}
                                          minDate={values.start_date || null}
                                          autoComplete="off"
                                          placeholderText={'End date'}
                                          dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {isFromCalendar && !isSchedulerDateIsAfterCurrentDate && values?.duration_type === CampaignDurationType.ONCE && <p className="alert-ns">{CALENDAR_SELECT_FUTURE_DATE_WARNING}</p>}
                    </div>
                    <div className="ac-footer r-flx r-flx-ac g-20 ac-w-btns">
                      <div className="ac-l-Btns">
                        {slotDetail?.id && (
                          <CustomRippleButton rippleClass={`ripple-unset ac-primary-box deleteBtn`} custColor={RIPPLE_COLOR.whiteGrey}>
                            <button className={`ac-btn ac-danger ac-outline size-xs`} onClick={() => setIsDeleteScheduleConfirmationModalOpen(true)}>
                              <span>Delete</span>
                            </button>
                          </CustomRippleButton>
                        )}
                      </div>
                      <div className="ac-r-Btns">
                        <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                          <button className={`ac-btn ac-secondary-white ac-outline size-xs border-0`} type="button" onClick={() => handleCancel()}>
                            <span>Cancel</span>
                          </button>
                        </CustomRippleButton>
                        <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                          <button
                            className={`ac-btn ac-primary ac-outline size-xs${
                              Object.values(errors).length ||
                              (slotDetail?.id && compareTwoObjects(getInitialValues(), values)) ||
                              (isFromCalendar && !isSchedulerDateIsAfterCurrentDate && values?.duration_type === CampaignDurationType.ONCE)
                                ? ` pointer-events-none`
                                : ``
                            }`}
                            type="submit"
                            onClick={() => handleSubmit()}
                          >
                            <span>{!slotDetail?.id ? 'Add' : 'Update'}</span>
                          </button>
                        </CustomRippleButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <ScheduleSlotDeleteConfirmationModal isModalShow={isDeleteScheduleConfirmationModalOpen} handleModalCancel={cancelDeleteScheduleSlot} handleModalClose={handleDeleteScheduleSlot} />
    </Modal>
  );
};
