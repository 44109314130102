import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { CustomDropdown } from 'widgets/CustomDropdown';
import {
  CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS,
  CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER,
  CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES,
  CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS,
  ContentType,
  FEED_TYPE,
  USER_OWNERSHIP
} from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { contentFeedAddTags, getEligibleContentsCountRequest, getPostsTagsStatsRequest, postsTagsRequest } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { KeywordsCard } from 'analytics/containers/ReviewAnalytics/SubPages';
import { IKeywordCardData } from 'analytics/types';

export const DefineContentElements = ({ contentFeedModifiedObj, handleChange, dispatch }: { contentFeedModifiedObj: any; handleChange: any; dispatch: any }) => {
  const { id, userOwnership } = useAccountSwitcherData();

  const contentSupplierHubs = useSelector((state: IStore) => state.operationsList.cs_franchisors);
  const internalTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const eligibleContentsCount = useSelector((state: IStore) => state.operationsList.eligible_contents_counts);
  const postsTagsStats = useSelector((state: IStore) => state.posts.postsTagsStats);
  const isTagFetching = useSelector((state: IStore) => state.posts.isTagFetching);
  const isAllContentSource = contentFeedModifiedObj?.content_source === CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.ALL;

  const updatedTagsList: IKeywordCardData[] = internalTagsList.map((tag) => {
    const tooltipText = tag?.usage?.length
      ? tag?.usage.reduce((acc, curr, index) => {
          return acc + curr.name + (index < (tag?.usage?.length || 0) - 1 ? ', ' : '');
        }, '')
      : '';
    return {
      name: tag.name,
      tooltipText: tooltipText ? `used by: ${tooltipText}` : '',
      ...(tag.hasOwnProperty('usage') && { count: tag?.usage?.length || 0 })
    };
  });
  const csFranchisorIds = contentSupplierHubs.map((hub) => hub.id);

  const retrieveEligibleCount = (countType: 'post' | 'story') => {
    switch (contentFeedModifiedObj?.content_source) {
      case CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.BRAND: {
        return eligibleContentsCount[`eligible_brand_${countType}_contents_count`];
      }
      case CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.CONTENT_SUPPLIER: {
        return eligibleContentsCount[`eligible_content_supplier_${countType}_contents_count`];
      }
      default: {
        return eligibleContentsCount[`eligible_total_${countType}_contents_count`];
      }
    }
  };

  const getEligibleContentsCount = useCallback(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      dispatch(
        getEligibleContentsCountRequest({
          account_list: {
            franchisor_id: id,
            content_source:
              CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS.find((source) => source.identifier === contentFeedModifiedObj?.content_source)?.identifier ||
              CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER.ALL,
            content_supplier_franchisor_ids: contentFeedModifiedObj?.content_suppliers,
            tags_list: contentFeedModifiedObj?.tags_list?.join(','),
            content_type: contentFeedModifiedObj?.content_type
          }
        })
      );
    }
  }, [id, userOwnership, contentFeedModifiedObj?.content_source, contentFeedModifiedObj?.tags_list, contentFeedModifiedObj?.content_suppliers, contentFeedModifiedObj?.content_type, dispatch]);

  useEffect(() => {
    if (contentFeedModifiedObj?.feed_type === FEED_TYPE.CONTENT_SUPPLIER) getEligibleContentsCount();
  }, [contentFeedModifiedObj?.tags_list, contentFeedModifiedObj?.content_source, getEligibleContentsCount, contentFeedModifiedObj?.content_suppliers, contentFeedModifiedObj?.feed_type]);

  useEffect(() => {
    dispatch(getPostsTagsStatsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      postsTagsRequest({
        searchTag: '',
        page: 1,
        include_usage_by_original_franchisor_ids: isAllContentSource ? csFranchisorIds : contentFeedModifiedObj?.content_suppliers,
        franchisor_id: id,
        ...(isContentSupplier && { contentSupplierId: id }),
        per: 10
      })
    );
  }, [dispatch, isContentSupplier, id, contentFeedModifiedObj?.content_source]); // eslint-disable-line

  const handleAddTags = (tag: string) => {
    const updatedFeedTags = [...contentFeedModifiedObj?.tags_list, tag];
    dispatch(contentFeedAddTags(updatedFeedTags));
    handleChange('tags_list', updatedFeedTags);
  };

  const handleTagSearchPostTags = (search: string, page: number = 1) => {
    dispatch(
      postsTagsRequest({
        searchTag: search,
        page,
        franchisor_id: id,
        include_usage_by_original_franchisor_ids: isAllContentSource ? csFranchisorIds : contentFeedModifiedObj?.content_suppliers,
        ...(isContentSupplier && { contentSupplierId: id }),
        per: 10
      })
    );
  };
  const handleDeleteTag = (tagName: string) => {
    const updatedFeedTags = contentFeedModifiedObj?.tags_list.filter((datum: any) => datum !== tagName);
    dispatch(contentFeedAddTags(updatedFeedTags));
    handleChange('tags_list', updatedFeedTags);
    getEligibleContentsCount();
  };

  return (
    <>
      <div className="bs-cnl">
        <div className="csfcWrap">
          <div className="csf-left">
            <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean`}>
              <CustomDropdown
                classNamePrefix="glbl__dropdown"
                id="boost-duration-dropdown"
                placeholder={'Select Content Type'}
                options={CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS}
                value={CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS.find((selectOption) => selectOption.value === contentFeedModifiedObj?.content_type) || CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS[0]}
                onChange={(eventObj: any) => {
                  handleChange('content_type', eventObj.value);
                }}
                name={'feed_type'}
              />
              <span className={`fltlabels`}>
                {'Select Content Type'}
                <span className="mandatory cursor-help">*</span>
              </span>
            </div>
            {/* <div className={`cscl-selTags`}>
              <div className={`form-group form-field-item`}>
                <CustomReactTags
                  key={`${id}${userOwnership}`}
                  suggestions={tagsList}
                  handleInputChange={(search: string) => {
                    tagsApiTimeout = debounceMethod(search, tagsApiTimeout, handleInputChange);
                  }}
                  handleDelete={() => false}
                  maxSuggestionsLength={tagsList.length}
                  handleAddition={(tag: Tag) => handleAddTags(tag?.name)}
                  placeholder="Search for Tags"
                  autofocus={false}
                  allowNew
                  minQueryLength={1}
                />
                <span className={`fltlabels`}>{'Select Tags to filter content (Optional)'}</span>
              </div>
              {contentFeedModifiedObj?.tags_list?.length ? (
                <div className="tags-wrp">
                  {contentFeedModifiedObj?.tags_list?.length &&
                    contentFeedModifiedObj?.tags_list.map((tagDatum: string, index: number) => {
                      return (
                        <div className="list-expanded-tag-item" key={`loc-creator-tag-${index}`}>
                          <span className="list-expanded-tag-text">{tagDatum}</span>
                          <span className="list-expanded-tag-remove" onClick={() => handleDeleteTag(tagDatum)} />
                        </div>
                      );
                    })}
                </div>
              ) : null}
            </div> */}
          </div>
          <div className="csf-right">
            {contentFeedModifiedObj?.content_type !== ContentType.STORIES ? (
              <div className="apc-wrap">
                <div className="apc-top">
                  <h3>Available Posts Count</h3>
                  <div className="apc-count">
                    <span>{retrieveEligibleCount('post')}</span>
                  </div>
                </div>
                <div className="apc-base">
                  <ImageValidation isImgValid isNotSvgFormat defaultImg="apCountWavesBig.png" customClassname={'apcWave'} customName="Available Posts" />
                </div>
              </div>
            ) : null}
            {contentFeedModifiedObj?.content_type !== ContentType.POSTS ? (
              <div className="apc-wrap">
                <div className="apc-top">
                  <h3>Available Stories Count</h3>
                  <div className="apc-count">
                    <span>{retrieveEligibleCount('story')}</span>
                  </div>
                </div>
                <div className="apc-base">
                  <ImageValidation isImgValid isNotSvgFormat defaultImg="apCountWavesBig.png" customClassname={'apcWave'} customName="Available Posts" />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="stfcWrap">
          <h4>
            Select Tags to filter content <span>(optional)</span>
          </h4>
          <div className="create-as d-flex">
            <KeywordsCard
              title="Available Tags"
              subtitle={``}
              data={updatedTagsList}
              onChange={(value) => {
                if (contentFeedModifiedObj?.tags_list?.includes(value)) {
                  handleDeleteTag(value);
                } else {
                  handleAddTags(value);
                }
              }}
              checkable
              checkedKeys={contentFeedModifiedObj?.tags_list || []}
              handleSearch={(search: string) => handleTagSearchPostTags(search)}
              hasMore={internalTagsList.length < postsTagsStats?.response.total_tags_count}
              isFetching={isTagFetching && postsTagsStats?.page < 1}
              handleScroll={(search?: string) => {
                handleTagSearchPostTags(search || '', postsTagsStats?.page + 1);
              }}
              isShowLoader={isTagFetching}
            />
            <KeywordsCard
              title="Selected Tags"
              subtitle={``}
              data={contentFeedModifiedObj?.tags_list ? contentFeedModifiedObj?.tags_list?.map((tags: any) => ({ name: tags })) : []}
              onChange={(value) => {
                handleDeleteTag(value);
              }}
              isFetching={isTagFetching && postsTagsStats?.page < 1}
              isShowLoader={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
