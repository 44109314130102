import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  createUpdateKeyWordsCategoryRequest,
  deleteKeyWordsCategoryRequest,
  getKeyWordsCategoryListRequest,
  getKeyWordsCategoryRequest,
  openKeywordsCategoryModal,
  setKeyWordData,
  keywordsCategoryModalReset
} from 'analytics/actions';
import { ImageValidation } from 'widgets/Media';
import { COMMON_PAGE_TYPE, KEYWORDS_CATEGORY_CHECKFILEDS, KEYWORDS_CATEGORY_TAB_FIELDS, KEYWORDS_DELETE_CATEGORY_DELETE_CONFIRM, MODAL_BOX_TYPES, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { keywordsCategoryValidation, compareTwoObjects } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { KeywordsManagementContainer } from './KeywordsManagementContainer';
import { IStore, reviewsContainerTypes } from 'types';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { REVIEW_ANALYTICS_FILTER_INITIAL_VALUE } from 'analytics/utils';
import { Loading, ModalPopup } from 'components/Common';

export const KeywordsCategoryManagementModal = ({ type, isShowModal, categoryId, dispatchKeyWords }: reviewsContainerTypes.IKeywordsCategoryManagementModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(REVIEW_ANALYTICS_FILTER_INITIAL_VALUE);

  const keywordsCategoryList = useSelector((state: IStore) => state.reviewAnalytics.keywordsCategoryList);
  const keywordsCategory = useSelector((state: IStore) => state.reviewAnalytics.keywordsCategory);
  const allKeywordsList = useSelector((state: IStore) => state.reviewAnalytics.allKeywordsList);
  const isKeywordCategoryFetching = useSelector((state: IStore) => state.reviewAnalytics.isKeywordsCategoryFetching);

  const [isDeleteCategory, setDeleteCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  const initialValues = { name: keywordsCategory?.name || '', description: keywordsCategory?.description || '', keywords: keywordsCategory?.keywords || [] };

  const updatedKeywordsList = useMemo(
    () =>
      type === COMMON_PAGE_TYPE.EDIT && queryParams?.category && keywordsCategoryList ? keywordsCategoryList.filter((keyword) => keyword.id === Number(queryParams.category)) : keywordsCategoryList,
    [type, queryParams?.category, keywordsCategoryList]
  );

  const { values, touched, errors, handleBlur, setFieldValue, setTouched, handleSubmit } = useFormik({
    initialValues,
    validationSchema: keywordsCategoryValidation,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (values: reviewsContainerTypes.IKeywordsCategoryInitialValues) => {
      const payload = {
        ...values,
        ...(userOwnership === USER_OWNERSHIP.ACCOUNT ? { account_id: id } : { franchisor_id: id }),
        ...(type === COMMON_PAGE_TYPE.EDIT ? { id: selectedCategory } : {}),
        navigate: () => handleModalClose(true)
      };
      dispatch(createUpdateKeyWordsCategoryRequest(payload));
    }
  });

  useEffect(() => {
    if (type === COMMON_PAGE_TYPE.EDIT) {
      setSelectedCategory(categoryId || keywordsCategoryList?.[0]?.id || null);
    }
  }, [categoryId, keywordsCategoryList, type]);

  const getClassNames = (valueItem: keyof reviewsContainerTypes.IKeywordsCategoryInitialValues) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] && values[valueItem] !== '' && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'err-disp' : ''}`;
    return className;
  };

  const getInputBoxClassName = (valueItem: keyof reviewsContainerTypes.IKeywordsCategoryInitialValues) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'erroritem' : ''}`;
    return className;
  };

  const getInputTitleClassName = (valueItem: keyof reviewsContainerTypes.IKeywordsCategoryInitialValues) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'fltlabels-error-text' : ''}`;
    return className;
  };

  const handleChange = (field: string, value: string | string[]) => {
    const fieldValue = value;
    setFieldValue(field, fieldValue);
  };
  const resetFieldTouched = () => {
    setTouched({ ...KEYWORDS_CATEGORY_CHECKFILEDS });
  };

  const resetFormValues = () => {
    setFieldValue(KEYWORDS_CATEGORY_TAB_FIELDS.NAME, initialValues.name);
    setFieldValue(KEYWORDS_CATEGORY_TAB_FIELDS.DESCRIPTION, initialValues.description);
    setFieldValue(KEYWORDS_CATEGORY_TAB_FIELDS.KEYWORDS, initialValues.keywords);
    resetFieldTouched();
  };

  const deleteCategory = () => {
    dispatch(deleteKeyWordsCategoryRequest({ id: Number(selectedCategory), navigate: () => handleModalClose(true) }));
  };

  const handleModalClose = (isCategoryChanged?: boolean) => {
    dispatch(setKeyWordData(null));
    dispatch(keywordsCategoryModalReset());
    resetFormValues();
    resetFieldTouched();
    setSelectedCategory(null);
    if (isDeleteCategory) {
      delete queryParams?.category;
    }
    if (isCategoryChanged) {
      dispatch(getKeyWordsCategoryListRequest({ userOwnership, id }));
      dispatchKeyWords();
    }
    if (type === COMMON_PAGE_TYPE.NEW || !isDeleteCategory || (type === COMMON_PAGE_TYPE.EDIT && updatedKeywordsList.length === 1)) {
      dispatch(openKeywordsCategoryModal({ isShowModal: false, type: '' }));
    }
    navigate({ pathname: `/${userOwnership}/${id}/analytics/analytics_reviews`, search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  return (
    <>
      <Modal show={isShowModal} centered size="lg" className="connection-secondary add-location kcmWrap add-booster kw-anc" animation={false} onHide={handleModalClose}>
        <Modal.Body>
          <div className="sec-conn__wrp">
            <div className="sec-header">
              <div className="rewards-details-tab-section-wrp">
                <div className="header-item__wrp">
                  <div className="item-01">
                    <span className="title">{type === COMMON_PAGE_TYPE.NEW ? 'Add New Category' : 'Edit Category'}</span>
                  </div>
                  {type === COMMON_PAGE_TYPE.EDIT && updatedKeywordsList && updatedKeywordsList.length ? (
                    <div className="item-02">
                      <ul>
                        {updatedKeywordsList.map((category, index) => (
                          <li
                            key={`category-${index}`}
                            className={`ripple${category.id === selectedCategory ? ` active` : ``}`}
                            onClick={() => {
                              setSelectedCategory(category.id);
                              dispatch(getKeyWordsCategoryRequest(Number(category.id)));
                            }}
                          >
                            <span className="sub-nav-item-txt">{category.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}

                  <div className="mod__close--icon header__close--icon">
                    <ImageValidation isImgValid defaultImg="mpcIcon" customName="close" customClassname="close-icon close mod__close--img wh-30" onClick={() => handleModalClose()} />
                  </div>
                </div>
              </div>
            </div>

            <div className="kc-cnt-wrap">
              {isKeywordCategoryFetching ? (
                <Loading />
              ) : (
                <KeywordsManagementContainer
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  getInputTitleClassName={getInputTitleClassName}
                  getInputBoxClassName={getInputBoxClassName}
                  getClassNames={getClassNames}
                  allKeywordsList={allKeywordsList.map((keyword) => ({ name: keyword }))}
                />
              )}
              <div className="right-button-section">
                <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className={`ac-btn ac-primary ac-block kc-save ${!values.keywords.length || compareTwoObjects(values, initialValues) ? 'disabled' : ''}`}
                    type="submit"
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </button>
                </CustomRippleButton>

                {type === COMMON_PAGE_TYPE.EDIT ? (
                  <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.whiteBlue}>
                    <button className={`ac-btn ac-primary ac-block kc-del`} onClick={() => setDeleteCategory(true)}>
                      Delete
                    </button>
                  </CustomRippleButton>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalPopup
        isModalShow={isDeleteCategory}
        modalHeaderType={MODAL_BOX_TYPES.DANGER}
        containerClassName={'modal-confirmation confirm-delete'}
        modalBody={() => (
          <div>
            <div className="modal-message-wraps">{KEYWORDS_DELETE_CATEGORY_DELETE_CONFIRM}</div>
          </div>
        )}
        modalCloseButton
        handleModalClose={() => {
          setDeleteCategory(false);
        }}
        isModalFooterShow
        modalFooter={() => (
          <div className="modal-btn-grp-wraps">
            <button
              className="modal-btn-action-itm modal-cancel-btn"
              onClick={() => {
                setDeleteCategory(false);
              }}
            >
              Cancel
            </button>
            <button
              className="modal-btn-action-itm modal-delete-btn"
              onClick={() => {
                if (isDeleteCategory) {
                  deleteCategory();
                  setDeleteCategory(false);
                }
              }}
            >
              Delete
            </button>
          </div>
        )}
      />
    </>
  );
};
