import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { commonModalPopup, fbAdsReducerTypes, IStore } from 'types';
import { USER_OWNERSHIP, MG_MEDIA_TYPE, RIPPLE_COLOR } from 'utils/constants';
import { getValidFBTagPreview, getFacebookBoostConfigData } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';
import { BoostConnection, ViewBoostGoal, ViewBoostButton, BoostAudience, BoostAdPreview, BoostDuration } from '../Boost';
import { addFacebookAdPreviewRequest, updateFBBoostConfigData, addPostAddBoostToScheduledPostRequest } from 'actions';
import { Loading } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { getAddBoostForContentPostReqPayload } from 'analytics/utils/helpers';

export const BoostMainModal = ({ isModalShow, handleModalClose }: commonModalPopup.IDetailModalPopup) => {
  const dispatch = useDispatch();

  const { id: accountId, userOwnership, currentRoute } = useAccountSwitcherData();
  const { isFetching, fbTargetingChoice } = useSelector((state: IStore) => state.fbAdsTargeting);
  const fbBoostConfig = useSelector((state: IStore) => state.fbAdsTargeting.facebookBoostConfig);
  const facebookPostDetails = useSelector((state: IStore) => state.fbAdsTargeting.facebookPostDetails);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const linkObj = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj);
  const { linkData } = linkObj;
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);

  const [updatedFBBoostConfig, setUpdatedFBBoostConfig] = useState<null | fbAdsReducerTypes.IUpdateFacebookBoostConfig>(null);

  useEffect(() => {
    if (!fbBoostConfig?.isAddBoost) {
      const initialDataForFBBoost = getFacebookBoostConfigData(fbTargetingChoice);
      dispatch(updateFBBoostConfigData(initialDataForFBBoost));
      setUpdatedFBBoostConfig(initialDataForFBBoost);
    } else {
      setUpdatedFBBoostConfig(fbBoostConfig);
    }
  }, [fbBoostConfig?.isAddBoost, fbTargetingChoice]); // eslint-disable-line

  useEffect(() => {
    if ((accountId || postsDetails?.account?.id) && userOwnership && fbTargetingChoice?.facebook_ad_account?.id) {
      dispatch(
        addFacebookAdPreviewRequest({
          accountId: userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails?.account?.id ? postsDetails?.account?.id : accountId,
          reqPayload: getRequestPayloadForFBAdPreview()
        })
      );
    }
  }, [accountId, userOwnership, postsDetails?.account?.id, fbTargetingChoice?.facebook_ad_account?.id]); // eslint-disable-line

  const getRequestPayloadForFBAdPreview = () => {
    return {
      scheduled_post: {
        facebook_text: getValidFBTagPreview(savedPostDetails?.facebook_text || '', selectedFBTagList || []) || '',
        cloudinary_video_id: selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO ? selectedMediaList[0]?.cloudinary_id || null : null,
        cloudinary_resource_type: selectedMediaList[0]?.media_type || '',
        link_title: linkData?.link_preview_title || '',
        link_description: linkData?.link_preview_description || '',
        facebook_call_to_action_type: '',
        facebook_call_to_action_link: '',
        photo_urls: selectedMediaList.map((datum) => datum.medium_size_url || '')
      }
    };
  };

  const handleUpdateAllFBBoostConfig = (boostConfig: null | fbAdsReducerTypes.IUpdateFacebookBoostConfig) => {
    setUpdatedFBBoostConfig(updatedFBBoostConfig ? { ...updatedFBBoostConfig, ...boostConfig } : null);
  };

  const handleUpdateFBBoostConfig = (key: string, value: null | string | number | boolean) => {
    setUpdatedFBBoostConfig(updatedFBBoostConfig ? { ...updatedFBBoostConfig, [key]: value } : null);
  };

  const handleSaveBoostConfigChanges = (isUpdateBoost: boolean) => {
    if (isUpdateBoost) {
      if (currentRoute === 'analytics/analytics_content' && updatedFBBoostConfig) {
        dispatch(addPostAddBoostToScheduledPostRequest(getAddBoostForContentPostReqPayload(facebookPostDetails, updatedFBBoostConfig)));
      } else {
        dispatch(updateFBBoostConfigData(updatedFBBoostConfig ? { ...updatedFBBoostConfig, lifetimeSpend: updatedFBBoostConfig.lifetimeSpend * 100, isAddBoost: true } : null));
      }
    }
    handleModalClose();
  };

  const disableSaveBoostChanges = () => {
    if (updatedFBBoostConfig && updatedFBBoostConfig?.lifetimeSpend < 1.5) return true;
    else if (updatedFBBoostConfig && !updatedFBBoostConfig?.locations.length && !updatedFBBoostConfig?.coordinates.length) return true;
    else return false;
  };

  return (
    <Modal
      show={isModalShow}
      centered
      size="lg"
      className="loc__booster--modal z-index__type--one connection-secondary add-location ralprofile-hl add-booster"
      backdropClassName="z-index__type--one"
      onHide={handleModalClose}
    >
      <Modal.Body>
        {isFetching ? (
          <Loading />
        ) : (
          <div className="sec-conn__wrp">
            <div className="sec-header">
              <div className="header-item__wrp">
                <span className="title">Boost</span>
                {/* <ImageValidation isImgValid defaultImg="close" customName="close" customClassname="close" onClick={() => handleSaveBoostConfigChanges(false)} /> */}
                <div className="mod__close--icon header__close--icon">
                  <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="mod__close--img" customName={'close'} onClick={() => handleSaveBoostConfigChanges(false)} />
                </div>
              </div>
            </div>
            <div className="sec-main__content">
              <div className="r-flx g-20 r-flx-jc">
                <div className="r-grid-6 left-sec">
                  {updatedFBBoostConfig && Object.keys(updatedFBBoostConfig).length && (
                    <>
                      <ViewBoostGoal goalObjective={updatedFBBoostConfig?.objective || ''} handleUpdateFBBoostConfig={handleUpdateFBBoostConfig} />
                      {false && <ViewBoostButton />}
                      <BoostAudience boostConfig={updatedFBBoostConfig} handleUpdateFBBoostConfig={handleUpdateFBBoostConfig} handleUpdateAllFBBoostConfig={handleUpdateAllFBBoostConfig} />
                    </>
                  )}
                  <BoostConnection />
                </div>
                {updatedFBBoostConfig && Object.keys(updatedFBBoostConfig).length && (
                  <div className="r-grid-6 r-mt0 right-sec">
                    <BoostAdPreview />
                    <BoostDuration
                      lifetimeSpend={updatedFBBoostConfig?.lifetimeSpend || 0}
                      duration={updatedFBBoostConfig?.duration || 0}
                      facebookDarkPost={updatedFBBoostConfig?.facebookDarkPost || false}
                      handleUpdateFBBoostConfig={handleUpdateFBBoostConfig}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="boost__btn--wrp">
              {updatedFBBoostConfig && Object.keys(updatedFBBoostConfig).length && (
                <>
                  <CustomRippleButton rippleClass={`boost-actions ac-primary-box ${disableSaveBoostChanges() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                    <button className={`ac-btn ac-primary mdl-ftr-btn btrigs ${disableSaveBoostChanges() ? `disabled` : ``}`} onClick={() => handleSaveBoostConfigChanges(true)}>
                      Save
                    </button>
                  </CustomRippleButton>
                </>
              )}
              {fbBoostConfig ? (
                <CustomRippleButton rippleClass="boost-actions ac-danger-box" custColor={RIPPLE_COLOR.danger}>
                  <button className="ac-btn ac-danger mdl-ftr-btn btrigs" onClick={() => dispatch(updateFBBoostConfigData(null))}>
                    <ImageValidation isImgValid defaultImg="remove-boost" customName="remove boost" />
                    <span>Remove Boost</span>
                  </button>
                </CustomRippleButton>
              ) : null}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
