import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';

import { ImageValidation } from 'widgets/Media';
import { salesIsDemoModalOpen, salesUpdateQueryField, scheduleDemoContactEmailRequest } from 'actions';
import { IStore, salesReducerTypes } from 'types';
import { SALES_QUERY_OBJ_FIELD, SALES_REGARDING_LIST, VALID_PHONE_NUMBER_REGEX, ALLOW_ONLY_NUMBERS_REGEX, SALES_INIT_QUERY_OBJ } from 'utils/constants';
import { parsePhoneNumbers, salesDemoValidation, formatPhoneNumber } from 'utils/helpers';
import { TextArea } from 'widgets/Text';

export const ScheduleDemoModalPopup = () => {
  const dispatch = useDispatch();

  const isDemoModalOpen = useSelector((state: IStore) => state.sales.isDemoModalOpen);
  const isFetching = useSelector((state: IStore) => state.sales.isFetching);

  const { setFieldValue, values, handleBlur, touched, errors, handleSubmit, handleReset } = useFormik({
    initialValues: { ...SALES_INIT_QUERY_OBJ },
    validationSchema: salesDemoValidation,
    onSubmit: (values: salesReducerTypes.ISalesQueryObj) => {
      handleReset(values);
      dispatch(salesUpdateQueryField(values));
      dispatch(scheduleDemoContactEmailRequest(values));
      dispatch(salesIsDemoModalOpen(false));
    }
  });

  const handleFieldChange = (key: string, value: string | string[]) => {
    const fieldValue = key === SALES_QUERY_OBJ_FIELD.PHONE_NUMBER ? `${value}`.replace(ALLOW_ONLY_NUMBERS_REGEX, '') : value;
    setFieldValue(key, fieldValue);
  };

  const getClassNames = (valueItem: keyof salesReducerTypes.ISalesQueryFields) => {
    return `form-control${values[valueItem] !== '' && values[valueItem] !== null ? ` used` : ``}${touched[valueItem] && errors[valueItem] ? ` error-disp` : ``}`;
  };

  const getLabelClassNames = (valueItem: keyof salesReducerTypes.ISalesQueryFields) => {
    return `fltlabels${touched[valueItem] && errors[valueItem] ? ` error-text` : ``}`;
  };

  const handleRegardingfield = (key: string, value: string) => {
    if (values.regarding.includes(value)) {
      handleFieldChange(
        key,
        values.regarding.filter((datum) => datum !== value)
      );
    } else {
      handleFieldChange(key, [...values.regarding, value]);
    }
  };

  const handleCloseModal = () => {
    handleReset(values);
    dispatch(salesUpdateQueryField(SALES_INIT_QUERY_OBJ));
    dispatch(salesIsDemoModalOpen(false));
  };

  return (
    <Modal
      className={`prime-modal apmodal pmisorModal mgFavTagsModal op-userlogin revsso schedule__demo--modal revExp`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isDemoModalOpen}
      animation={false}
      onHide={handleCloseModal}
    >
      <div className="mod__close--icon" onClick={handleCloseModal}>
        <ImageValidation isImgValid defaultImg="cancel" customName="cancel" customClassname="mod__close--img" />
      </div>
      <Modal.Body>
        <div className="userlogin-addnewuser-wrp userlogin-addnewuser__main">
          <div className="rs-top">
            <div className="card-header--wrp">
              <h2>WE'VE BEEN EXPECTING YOU.</h2>
            </div>
            <p className="rs-para">
              So, you like what you see? Whether you simply have a few questions or you want us to take a full look under the hood of your brand, we’re ready when you are. Use the form below, and we
              promise, we’ll get right back to you.
            </p>
          </div>

          <div className="addnewuser-modal-content">
            <div className="main-secion-split">
              <div className="left-section">
                <div className={`form-group rs-fields rs-name`}>
                  <input
                    type="text"
                    autoComplete="off"
                    value={values.name}
                    onBlur={handleBlur}
                    name={SALES_QUERY_OBJ_FIELD.NAME}
                    className={getClassNames(SALES_QUERY_OBJ_FIELD.NAME)}
                    onChange={(event) => handleFieldChange(event.target.name, event.target.value)}
                  />
                  <span className={getLabelClassNames(SALES_QUERY_OBJ_FIELD.NAME)}>
                    Name<span className="mandatory">*</span>
                  </span>
                </div>

                <div className={`form-group rs-fields rs-name`}>
                  <input
                    type="text"
                    autoComplete="off"
                    value={values.company}
                    onBlur={handleBlur}
                    name={SALES_QUERY_OBJ_FIELD.COMPANY}
                    className={getClassNames(SALES_QUERY_OBJ_FIELD.COMPANY)}
                    onChange={(event) => handleFieldChange(event.target.name, event.target.value)}
                  />
                  <span className={getLabelClassNames(SALES_QUERY_OBJ_FIELD.COMPANY)}>Company</span>
                </div>

                <div className={`form-group rs-fields email`}>
                  <input
                    type="text"
                    autoComplete="off"
                    value={values.email}
                    onBlur={handleBlur}
                    name={SALES_QUERY_OBJ_FIELD.EMAIL}
                    className={getClassNames(SALES_QUERY_OBJ_FIELD.EMAIL)}
                    onChange={(event) => handleFieldChange(event.target.name, event.target.value)}
                  />
                  <span className="fltlabels">
                    Email<span className="mandatory">*</span>
                  </span>
                </div>
                <div className={`form-group rs-fields mobile`}>
                  <input
                    type="tel"
                    maxLength={values && parsePhoneNumbers(values?.phone_number)?.length === 10 ? 10 : undefined}
                    name={SALES_QUERY_OBJ_FIELD.PHONE_NUMBER}
                    onBlur={handleBlur}
                    className={getClassNames(SALES_QUERY_OBJ_FIELD.PHONE_NUMBER)}
                    autoComplete="off"
                    value={values.phone_number ? formatPhoneNumber(values.phone_number) : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(event.target.name, event.target.value)}
                    onKeyPress={(event) => {
                      const regexData = new RegExp(VALID_PHONE_NUMBER_REGEX);
                      const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                      if (!regexData.test(enteredKey)) {
                        event.preventDefault();
                        return false;
                      }
                    }}
                  />
                  <span className="fltlabels">Phone Number</span>
                </div>

                <div className={`form-group rs-fields rs-name`}>
                  <input
                    type="number"
                    autoComplete="off"
                    min={0}
                    value={values.location_count}
                    onBlur={handleBlur}
                    name={SALES_QUERY_OBJ_FIELD.LOCATIONS_COUNT}
                    className={getClassNames(SALES_QUERY_OBJ_FIELD.LOCATIONS_COUNT)}
                    onChange={(event) => handleFieldChange(event.target.name, event.target.value)}
                  />
                  <span className={getLabelClassNames(SALES_QUERY_OBJ_FIELD.LOCATIONS_COUNT)}>Number of Locations</span>
                </div>
              </div>
              <div className="right-section">
                <div className="slider-img-bg">
                  <ImageValidation isImgValid defaultImg="revvsso-support" customName="support" />
                </div>
              </div>
            </div>

            <div className={`form-group rs-fields rslocations${touched[SALES_QUERY_OBJ_FIELD.REGARDING] && errors[SALES_QUERY_OBJ_FIELD.REGARDING] ? ` error-disp` : ``}`}>
              <div className="sub-head">
                Tell us what you want (what you really really want). <span className="mandatory">*</span>
              </div>
              {SALES_REGARDING_LIST.map((platformItem, index) => {
                return (
                  <label className="checkbox-item" key={`sales-demo-regard${index}`}>
                    <span className="checkbox-hover">
                      <input
                        type="checkbox"
                        className="option-input checkbox"
                        checked={values.regarding.includes(platformItem.label)}
                        value={platformItem.label}
                        onBlur={handleBlur}
                        name={SALES_QUERY_OBJ_FIELD.REGARDING}
                        onChange={(event) => handleRegardingfield(event.target.name, event.target.value)}
                      />
                    </span>
                    <span className="labelText">{platformItem.label}</span>
                  </label>
                );
              })}
            </div>
            <div className={`form-group rs-fields rscomment`}>
              <TextArea
                autoComplete="off"
                value={values.comment}
                onBlur={handleBlur}
                name={SALES_QUERY_OBJ_FIELD.COMMENT}
                className={getClassNames(SALES_QUERY_OBJ_FIELD.COMMENT)}
                onChange={(event) => handleFieldChange(event.target.name, event.target.value)}
              />
              <span className={getLabelClassNames(SALES_QUERY_OBJ_FIELD.COMMENT)}>Comment or Message</span>
            </div>
          </div>
        </div>
        <div className="modal-btn-grp-wraps ap-actions r-flx r-flx-je">
          <Button
            // className={`primeblueaction btn btn-primary${!isFetching ? `` : ` pointer-events-none`}`}
            className={`ac-btn ac-primary ${!isFetching ? `` : ` pointer-events-none`}`}
            type="submit"
            variant="primary"
            onClick={(event: React.FormEvent<HTMLFormElement> | any) => {
              handleSubmit(event);
            }}
          >
            {isFetching ? <Spinner animation="border" variant="primary" /> : 'Submit'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
