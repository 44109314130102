import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { CustomDatePicker } from 'widgets/DatePicker';
import { checkIsToday, dateToTimeStampToLocal, endDateChecked, getCurrentDate, getEndOfDate, getParsedDate, getStartAndEndOfCurrentMonth } from 'utils/helpers';
import { ICommonDateTypes, DATEPICKER_DATE_ONLY_FORMAT, BUTTON_PROPS, DATE_FORMAT, COMMON_DATE_RANGE_INIT_VALUE, DATEPICKER_MONTH_ONLY_FORMAT } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { checkDayIsEqual } from 'analytics/utils/helpers';
import { LocationFilterModal } from './LocationFilterModal';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ICheckFilter, IFilter, IPlatformFilter } from 'types/common/filter';
import { RevvLocationFilterModal } from 'revv/components';
import { ContentSuppliersFilterModal } from './ContentSuppliersFilterModal';
import { IStore } from 'types';

export const CommonFilter = ({
  filter,
  handleChange,
  analyticsPlatformFilter = [],
  isLocation = false,
  // isContentSupplierFranchisor = false,
  filterPageType = '',
  isRevvLocation = false,
  checkBoxFilter = [],
  multiSelectCheckBoxFilter = [],
  handleExportCsv,
  isSecondaryDateRangePlaceholder = false,
  sectionVariant = 'ra-filter-sec anlFilter',
  secondaryCheckBoxFilter = [],
  primaryButtons = [],
  isDateRangeFilterEnable = true,
  filterActionButtons = [],
  isClearDateEnabled,
  renderPrefixData,
  renderSuffixData,
  secondaryCheckboxCustomClass,
  showMonthYearPicker,
  isRevvCustomers = false
}: IFilter) => {
  const [activeValue, setActiveValue] = useState('');
  const [isModalOpens, setModalState] = useState(false);

  const franchisorDetails = useSelector((state: IStore) => state.franchisors.franchisorDetails);

  const handleMultiSelectCheckbox = (key: string, value: string) => {
    return !filter[key].includes(value) ? [...filter[key], value] : filter[key].filter((datum: any) => datum !== value);
  };

  const isToDateToday = checkIsToday(filter?.dateRange?.toDate?.selected);

  const renderCSSelector = () => {
    return (
      <div className="filter-item fltr-drp">
        {isModalOpens ? <ContentSuppliersFilterModal initialObj={filter.contentSupplierFilterObj} setModalState={setModalState} handleChange={handleChange} /> : null}
        <div className="locAction" onClick={() => setModalState(!isModalOpens)}>
          {isRevvLocation ? (
            <span className="la-sel-action">
              {isRevvLocation ? (filter?.selectedLocation.length > 0 ? `${filter?.selectedLocation.length} Location Selected` : 'All') : filter?.contentSupplierFilterObj?.label}
            </span>
          ) : (
            <div className="la-sel-action">
              {filter?.contentSupplierFilterObj?.locationsCount !== undefined ? (
                <div className="lsl-counts-wrap">
                  <span className="lcs-name" title={filter?.contentSupplierFilterObj?.label}>
                    {filter?.contentSupplierFilterObj?.label}
                  </span>{' '}
                  (<span className="lca-sel-label bl-count">{filter?.contentSupplierFilterObj?.locationsCount}</span>)
                </div>
              ) : (
                <div className="lsl-counts-wrap">
                  <span className="lcs-name" title={filter?.contentSupplierFilterObj?.label}>
                    {filter?.contentSupplierFilterObj?.label}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <section className={`item-g filter ${sectionVariant}`}>
      {filterActionButtons?.length > 0 && (
        <div className={`${isRevvCustomers ? `filter-item` : ``} custom-button d-flex flex-column gap-20`}>
          {filterActionButtons.map((it, index) => (
            <CustomRippleButton key={index} rippleClass={`ac-primary-box ${it.isDisable ? ` pointer-events-none` : ``}`} custColor={it.rippleColor}>
              <button className={it.buttonVariant} onClick={it.onClick}>
                {it.customImage && (
                  <div>
                    <ImageValidation isImgValid defaultImg={it.customImage} isNotSvgFormat customName={it.customImage} customClassname="upload-white__img" />
                  </div>
                )}
                <span>{it.isFetching ? <Spinner animation="border" variant="light" /> : it.label}</span>
              </button>
            </CustomRippleButton>
          ))}
        </div>
      )}

      {primaryButtons?.length > 0 &&
        primaryButtons.map((it, index) => (
          <div className={it.parentVariant} key={index}>
            <CustomRippleButton rippleClass={`ac-primary-box ${it.isDisable ? ` pointer-events-none` : ``}`} custColor={it.rippleColor}>
              <button
                className={it.buttonVariant}
                onClick={() => {
                  if (it.value === BUTTON_PROPS.PRIMARY_EXPORT_CSV.value && handleExportCsv) {
                    handleExportCsv();
                  } else if (it.value === BUTTON_PROPS.CLEAR_FILTER.value) {
                    handleChange({}, true);
                  } else if (!it.isDisable) {
                    handleChange({ [it.value]: it.value });
                  }
                }}
              >
                {it.customImage && <ImageValidation isImgValid defaultImg={it.customImage} customName={it.customImage} />}
                <span>{it.label}</span>
              </button>
            </CustomRippleButton>
          </div>
        ))}

      {secondaryCheckBoxFilter?.length > 0 &&
        secondaryCheckBoxFilter.map((it, ind) => (
          <div className={`filter-item ${secondaryCheckBoxFilter?.length > 0 && isDateRangeFilterEnable ? 'border-none' : ''} ${secondaryCheckboxCustomClass || ''}`} key={ind}>
            {it.title ? <h3>{it.title}</h3> : null}
            <div className={`form-group`}>
              {it.filter.map((item: ICheckFilter, index: number) => {
                const isChecked = item.value === filter[it.value];
                return (
                  <label key={`${it.value}-${index}`} className={isChecked ? 'active' : ''}>
                    <input
                      value={item.value}
                      type="radio"
                      className="option-input radio"
                      name={`${it.value}-${it.title}`}
                      checked={isChecked}
                      onChange={(event) => {
                        handleChange({ [it.value]: event.target.value });
                      }}
                    />
                    <span className="labelText">{item.label}</span>
                  </label>
                );
              })}
            </div>
          </div>
        ))}

      {isDateRangeFilterEnable && (
        <div className={`filter-item post-filter__wrp date-picker ${activeValue === 'from' ? 'dpf-on dpf-top-over' : 'dpt-on dpt-top-over'}`}>
          {isClearDateEnabled ? (
            <button className="cdr-btn" onClick={() => handleChange({ dateRange: COMMON_DATE_RANGE_INIT_VALUE })}>
              <span>Clear Date Range</span>
            </button>
          ) : null}

          {showMonthYearPicker ? (
            <div className={`dp-item date-end__wrp dp-from ${showMonthYearPicker ? 'monthPicker-on' : 'monthPicker-off'}`}>
              <CustomDatePicker
                open={activeValue === 'from'}
                selectedDate={filter?.dateRange?.toDate?.selected}
                dateRangeType={ICommonDateTypes.FROM_DATE}
                dateFormat={DATEPICKER_MONTH_ONLY_FORMAT}
                onChange={(date) => {
                  if (date) {
                    const fromDate = { selected: date, timeStamp: dateToTimeStampToLocal(date) };
                    const endDate = getStartAndEndOfCurrentMonth(getEndOfDate(date), ICommonDateTypes.END_TIME);
                    const changedDate = {
                      dateRange: {
                        fromDate,
                        toDate: { selected: endDate, timeStamp: dateToTimeStampToLocal(date) }
                      }
                    };
                    handleChange(changedDate);
                  }
                }}
                maxDate={getStartAndEndOfCurrentMonth(getCurrentDate(), ICommonDateTypes.END_TIME)}
                selectsStart
                startDate={showMonthYearPicker ? null : filter?.dateRange?.fromDate?.selected}
                endDate={filter?.dateRange?.toDate?.selected}
                onInputClick={() => setActiveValue((prevState) => (prevState === 'from' ? '' : 'from'))}
                onSelect={() => setActiveValue('')}
                onBlur={() => setActiveValue('')}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                placeholderText={DATE_FORMAT}
                showMonthYearPicker
                minDate={showMonthYearPicker && franchisorDetails?.created_at ? getStartAndEndOfCurrentMonth(getParsedDate(franchisorDetails?.created_at), ICommonDateTypes.START_TIME) : null}
              />
            </div>
          ) : (
            <>
              <div className={`dp-item date-end__wrp dp-from`}>
                <CustomDatePicker
                  open={activeValue === 'from'}
                  selectedDate={filter?.dateRange?.fromDate?.selected}
                  dateRangeType={ICommonDateTypes.FROM_DATE}
                  dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                  onChange={(date) => {
                    if (date) {
                      const fromDate = { selected: date, timeStamp: dateToTimeStampToLocal(date) };
                      const endDate = getEndOfDate(date);
                      const changedDate = {
                        dateRange: {
                          fromDate,
                          toDate: endDateChecked(date, filter?.dateRange?.toDate.selected) ? filter?.dateRange?.toDate || null : { selected: endDate, timeStamp: dateToTimeStampToLocal(endDate) }
                        }
                      };
                      handleChange(changedDate);
                    }
                  }}
                  maxDate={getCurrentDate()}
                  selectsStart
                  startDate={filter?.dateRange?.fromDate?.selected}
                  endDate={filter?.dateRange?.toDate?.selected}
                  onInputClick={() => setActiveValue((prevState) => (prevState === 'from' ? '' : 'from'))}
                  onSelect={() => setActiveValue('')}
                  onBlur={() => setActiveValue('')}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode={'select'}
                  placeholderText={DATE_FORMAT}
                />
              </div>
              <div className="dp-right-arrow" />
              <div className={`dp-item date-end__wrp dp-to`}>
                <CustomDatePicker
                  open={activeValue === 'to'}
                  selectedDate={isToDateToday ? null : filter?.dateRange?.toDate?.selected}
                  dateRangeType={ICommonDateTypes.TO_DATE}
                  dateFormat={showMonthYearPicker ? DATEPICKER_MONTH_ONLY_FORMAT : DATEPICKER_DATE_ONLY_FORMAT}
                  onChange={(date) => {
                    const endDate = getEndOfDate(date);
                    const isDayEqual = checkDayIsEqual(endDate, filter?.dateRange?.toDate?.selected);
                    if (!isDayEqual) {
                      const changedDate = {
                        dateRange: {
                          fromDate: filter?.dateRange?.fromDate || null,
                          toDate: { selected: endDate, timeStamp: dateToTimeStampToLocal(endDate) }
                        }
                      };
                      handleChange(changedDate);
                    }
                  }}
                  minDate={filter?.dateRange?.fromDate?.selected || null}
                  maxDate={getCurrentDate()}
                  selectsEnd
                  startDate={filter?.dateRange?.fromDate?.selected}
                  endDate={filter?.dateRange?.toDate?.selected}
                  onInputClick={() => {
                    setActiveValue((prevState) => (prevState === 'to' ? '' : 'to'));
                  }}
                  onSelect={() => setActiveValue('')}
                  onBlur={() => setActiveValue('')}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode={'select'}
                  placeholderText={isSecondaryDateRangePlaceholder && !isToDateToday ? DATE_FORMAT : 'Most Recent'}
                />
              </div>
            </>
          )}
        </div>
      )}

      {renderPrefixData ? renderPrefixData() : null}

      {analyticsPlatformFilter?.length ? (
        <div className="filter-item">
          <h3>Platform</h3>
          <div className="fltr-imc selectsocial">
            {analyticsPlatformFilter?.map((it: IPlatformFilter, index: number) => (
              <button type="button" key={index} className={`roundedbtn btn btn-link${filter.platform === it.value ? ` active` : ``}`} onClick={() => handleChange({ platform: it.value })}>
                <ImageValidation isImgValid defaultImg={it.platformImg ? it.platformImg : ''} customName={'Platform'} />
              </button>
            ))}
          </div>
        </div>
      ) : null}

      {isLocation ? (
        <div className="filter-item fltr-drp">
          <h3>Location Selector</h3>
          {isModalOpens ? (
            isRevvLocation ? (
              <RevvLocationFilterModal setModalState={setModalState} handleChange={handleChange} isModalOpen={isModalOpens} filter={filter} />
            ) : (
              <LocationFilterModal initialObj={filter.locationFilterObj} setModalState={setModalState} handleChange={handleChange} />
            )
          ) : null}
          <div className="locAction" onClick={() => setModalState(!isModalOpens)}>
            {isRevvLocation ? (
              <span className="la-sel-action">
                {isRevvLocation ? (filter?.selectedLocation.length > 0 ? `${filter?.selectedLocation.length} Location Selected` : 'All') : filter?.locationFilterObj?.label}
              </span>
            ) : (
              <div className="la-sel-action">
                {filter?.locationFilterObj?.locationsCount !== undefined ? (
                  <div className="lsl-counts-wrap">
                    <span className="lcs-name" title={filter?.locationFilterObj?.label}>
                      {filter?.locationFilterObj?.label}
                    </span>{' '}
                    (<span className="lca-sel-label bl-count">{filter?.locationFilterObj?.locationsCount}</span>)
                  </div>
                ) : (
                  <div className="lsl-counts-wrap">
                    <span className="lcs-name" title={filter?.locationFilterObj?.label}>
                      {filter?.locationFilterObj?.label}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : null}

      {/* {isContentSupplierFranchisor ? renderCSSelector() : null} */}

      {checkBoxFilter?.length > 0 &&
        checkBoxFilter.map((it, ind) => (
          <div className="filter-item" key={ind}>
            {it.title ? <h3>{it.title}</h3> : null}
            <div className={`form-group`}>
              {it.filter.map((item: ICheckFilter, index: number) => {
                const isChecked = item.value === filter[it.value];
                return (
                  <label key={`radio-${it.value}-${index}`} className={isChecked ? 'active' : ''}>
                    <input
                      value={item.value}
                      type="radio"
                      className="option-input radio"
                      name={`${it.value}-${it.title}`}
                      checked={isChecked}
                      onChange={(event) => {
                        handleChange({ [it.value]: event.target.value });
                      }}
                    />
                    <span className="labelText">{item.label}</span>
                  </label>
                );
              })}
            </div>
            {it.isShowCSSelector ? renderCSSelector() : null}
          </div>
        ))}

      {multiSelectCheckBoxFilter?.length > 0 &&
        multiSelectCheckBoxFilter.map((it, index) => (
          <div className="filter-item" key={index}>
            {it.title ? <h3>{it.title}</h3> : null}
            <div className={`form-group`}>
              {it.filter.map((item: ICheckFilter, index: number) => {
                const isChecked = item.value === 'all' ? !filter[it.value]?.length : filter[it.value]?.includes(item.value);
                return (
                  <label key={`checkbox-${it.value}-${index}`} className={`${isChecked ? 'checkbox-item active' : 'checkbox-item'} ${item.secondaryLabel ? 'rnrbgwrap' : ''}`}>
                    {/* <label key={`checkbox-${it.value}-${index}`} className={isChecked ? 'checkbox-item rnrbgwrap active' : 'checkbox-item rnrbgwrap'}> */}
                    <span className="checkbox-hover">
                      <input
                        value={item.value}
                        type="checkbox"
                        className="option-input checkbox"
                        name={`${it.value}-${it.title}`}
                        checked={isChecked}
                        onChange={(event) => {
                          handleChange({
                            [it.value]: event.target.value === 'all' ? [] : handleMultiSelectCheckbox(it.value, event.target.value)
                          });
                        }}
                      />
                    </span>
                    {/* <span className='labelText'>{item.label}</span> */}
                    {!item?.secondaryLabel ? (
                      <span className="labelText">{item.label}</span>
                    ) : (
                      <div className="star-social-rec rnr-bg">
                        <span className="labelText">{item.label}</span>
                        <span className="star-sTxt">{item.secondaryLabel}</span>
                      </div>
                    )}
                    {item?.variant && <span className={`sr-item ${item?.variant || ''}`} />}
                  </label>
                );
              })}
            </div>
          </div>
        ))}
      {renderSuffixData ? renderSuffixData() : null}
    </section>
  );
};
