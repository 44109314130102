import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { getPercentage } from 'utils/helpers';
import { useParamsDeconstructor } from 'utils/hooks';

export const ReviewsTotalCountComponent = () => {
  const { queryParams } = useParamsDeconstructor();

  const facebook = useSelector((state: IStore) => state.reviews.reviewStats.platformStats.facebook);
  const yelp = useSelector((state: IStore) => state.reviews.reviewStats.platformStats.yelp);
  const google = useSelector((state: IStore) => state.reviews.reviewStats.platformStats.google);
  const reviewsCount = useSelector((state: IStore) => state.reviews.reviewStats.reviewsCount);

  const [socialStat, setSocialStat] = useState({ facebook: 0, yelp: 0, google: 0 });

  /** store converted stats value to local state */
  useEffect(() => {
    if (facebook || yelp || google) {
      let facebookStat = getPercentage(reviewsCount, facebook) || 0;
      let yelpStat = getPercentage(reviewsCount, yelp) || 0;
      let googleStat = getPercentage(reviewsCount, google) || 0;
      const totalPercent = facebookStat + yelpStat + googleStat;
      if (totalPercent > 100) {
        const diff = totalPercent - 100;
        const maxStatValue = Math.max(facebookStat, yelpStat, googleStat);
        if (facebookStat === maxStatValue) facebookStat = facebookStat - diff;
        else if (yelpStat === maxStatValue) yelpStat = yelpStat - diff;
        else if (googleStat === maxStatValue) googleStat = googleStat - diff;
      } else if (totalPercent < 100) {
        const diff = 100 - totalPercent;
        const minStatValue = Math.min.apply(null, [facebookStat, yelpStat, googleStat].filter(Boolean));
        if (facebookStat === minStatValue) facebookStat = facebookStat + diff;
        else if (yelpStat === minStatValue) yelpStat = yelpStat + diff;
        else if (googleStat === minStatValue) googleStat = googleStat + diff;
      }
      setSocialStat({ ...socialStat, facebook: facebookStat, yelp: yelpStat, google: googleStat });
    }
  }, [facebook, yelp, google]); // eslint-disable-line

  const getClassName = () => {
    const className =
      queryParams.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
        ? 'cf-facebook'
        : queryParams.platform === COMMON_SOCIAL_PROVIDER_TYPE.YELP
        ? 'cf-yelp'
        : queryParams.platform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
        ? 'cf-google'
        : '';
    return className;
  };

  return (
    <div className={`chart-details ${getClassName()}`}>
      <div className={`progress-vertical`}>
        <div className="bar bar-facebook" style={{ width: '100%', background: '#0E81EB', height: `${socialStat.facebook}%` }} />
        <div className="bar bar-yelp" style={{ width: '100%', background: '#D32323', height: `${socialStat.yelp}%` }} />
        <div className="bar bar-twitter" style={{ width: '100%', background: '#495AA6', height: `${socialStat.google}%` }} />
      </div>
    </div>
  );
};
