import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { IGetKeywordsCategoryRequest } from 'analytics/types';
import { aiReviewResponderApiTypes, aiReviewResponderReducerTypes, reviewsApiTypes } from 'types';

export const getReviewResponderRequest = (payload: IGetKeywordsCategoryRequest) => createAction(actionTypes.GET_AI_REVIEW_RESPONDER_REQUEST, payload);
export const getReviewResponderResponse = (data: aiReviewResponderReducerTypes.IReviewResponder) => createAction(actionTypes.GET_AI_REVIEW_RESPONDER_RESPONSE, data);
export const getReviewResponderFailure = (err: string | null) => createAction(actionTypes.GET_AI_REVIEW_RESPONDER_FAILURE, err);

export const updateReviewResponderRequest = (payload: aiReviewResponderApiTypes.IUpdateAIReviewResponderPayload) => createAction(actionTypes.UPDATE_AI_REVIEW_RESPONDER_REQUEST, payload);
export const updateReviewResponderResponse = (data: aiReviewResponderReducerTypes.IReviewResponder) => createAction(actionTypes.UPDATE_AI_REVIEW_RESPONDER_RESPONSE, data);
export const updateReviewResponderFailure = (err: string | null) => createAction(actionTypes.UPDATE_AI_REVIEW_RESPONDER_FAILURE, err);

// GET RESPONSE PREVIEW
export const getReviewResPreviewRequest = (payload: reviewsApiTypes.IReviewsListReqParams) => createAction(actionTypes.GET_REVIEW_RES_PREVIEW_REQUEST, payload);
export const getReviewResPreviewResponse = (data: reviewsApiTypes.IReviewsListObj[]) => createAction(actionTypes.GET_REVIEW_RES_PREVIEW_RESPONSE, data);
export const getReviewResPreviewFailure = (err: string | null) => createAction(actionTypes.GET_REVIEW_RES_PREVIEW_FAILURE, err);
export const resetReviewResPreview = () => createAction(actionTypes.RESET_REVIEW_RESPONSE_PREVIEW);

// GENERATE AI AUTO REVIEWS
export const generateAIAutoReviewsRequest = (payload: aiReviewResponderApiTypes.IGenerateAIAutoReviewReq) => createAction(actionTypes.GENERATE_AI_AUTO_REVIEWS_REQUEST, payload);
export const generateAIAutoReviewsResponse = (data: aiReviewResponderApiTypes.IGenerateAIAutoReviewRes) => createAction(actionTypes.GENERATE_AI_AUTO_REVIEWS_RESPONSE, data);
export const generateAIAutoReviewsFailure = (err: string | null) => createAction(actionTypes.GENERATE_AI_AUTO_REVIEWS_FAILURE, err);

// GENERATE AI AUTO REVIEWS
export const getAutoReplyAccountPermissionRequest = (payload: number) => createAction(actionTypes.GET_AUTO_REPLY_ACCOUNT_PERMISSION_REQUEST, payload);
export const getAutoReplyAccountPermissionResponse = (data: aiReviewResponderApiTypes.IGetAutoReplyAccountPermissionRes) => createAction(actionTypes.GET_AUTO_REPLY_ACCOUNT_PERMISSION_RESPONSE, data);
export const getAutoReplyAccountPermissionFailure = (err: string | null) => createAction(actionTypes.GET_AUTO_REPLY_ACCOUNT_PERMISSION_FAILURE, err);

const AI_REVIEW_RESPONDER_ACTIONS = {
  getReviewResponderRequest,
  getReviewResponderResponse,
  getReviewResponderFailure,
  updateReviewResponderRequest,
  updateReviewResponderResponse,
  updateReviewResponderFailure,
  getReviewResPreviewRequest,
  getReviewResPreviewResponse,
  getReviewResPreviewFailure,
  generateAIAutoReviewsRequest,
  generateAIAutoReviewsResponse,
  generateAIAutoReviewsFailure,
  getAutoReplyAccountPermissionRequest,
  getAutoReplyAccountPermissionResponse,
  getAutoReplyAccountPermissionFailure,
  resetReviewResPreview
};

export type AIReviewResponderActions = ActionsUnion<typeof AI_REVIEW_RESPONDER_ACTIONS>;
