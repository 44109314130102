import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { ImageValidation } from 'widgets/Media';
import { cancelWatermarkUpload, saveWatermarkImageRequest, showHideWatermarkModal, uploadWatermarkImageToCloudRequest } from 'actions';
import { config } from 'config';
import { IMAGE_SUPPORTED_FORMAT } from 'utils/constants';
import { useSafeSelector } from 'utils/hooks';

export const WatermarkUploadModal = () => {
  const {
    isUpload,
    isSaveRequest,
    watermarkImageObj: watermarkImage,
    response: uploadResponse
  } = useSafeSelector('mediaGallery', ['watermarkUpload.isUpload', 'watermarkUpload.isSaveRequest', 'watermarkUpload.watermarkImageObj', 'watermarkUpload.response']);

  const { cloudinarySignObj } = useSafeSelector('common', ['cloudinarySignObj']);
  const { topLevelFranchisorId } = useSafeSelector('accountSwitcher', ['topLevelFranchisorId']);
  const { activeUserId } = useSafeSelector('login', ['activeUser.id']);
  const { timestamp, apiKey, signature, eager } = cloudinarySignObj;

  const [uploadInfo, setUploadInfo] = useState({
    percentage: 0,
    remainingTime: '0'
  });

  const dispatch = useDispatch();

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: IMAGE_SUPPORTED_FORMAT,
    noClick: false,
    onDrop: async (files) => {
      if (files.length === 1 && apiKey && signature) {
        const droppedFile = files[0];
        const startTime = new Date().getTime();
        dispatch(
          uploadWatermarkImageToCloudRequest({
            timestamp,
            apiKey,
            signature,
            eager: eager.join(),
            file: droppedFile,
            fileName: droppedFile.name,
            uploadProgressCallback: (progressEvent: ProgressEvent) => {
              const uploadSpeed = progressEvent.loaded - (new Date().getTime() - startTime) / 1000;
              setUploadInfo({
                percentage: Math.floor((progressEvent.loaded / progressEvent.total) * 100),
                remainingTime: ((progressEvent.total - progressEvent.loaded) / uploadSpeed).toFixed()
              });
            }
          })
        );
      }
    }
  });

  const handleClose = () => dispatch(showHideWatermarkModal({ show: false }));

  return (
    <>
      <Modal className="add-assets media-gallery__watermark mg-wm" aria-labelledby="contained-modal-title-vcenter" centered size="lg" show animation={false} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="r-flx r-flx-ac">
              <div className="platform-icon">
                <ImageValidation isImgValid defaultImg={'arw-full-left'} customName="Back" onClick={handleClose} />
              </div>
              <span className="wm-title">{`${watermarkImage ? 'Change' : 'Add'} Watermark Image`}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input {...getInputProps()} />
          <div
            className="upload-dotted-box scl-controls"
            {...getRootProps({
              onClick: (event) => event
            })}
          >
            <div className="drag-txt">
              <div className="single-icon">
                <ImageValidation isImgValid defaultImg={'upload-blue'} customName="Upload Asset" />
              </div>
              <span>Drag &amp; Drop your watermark image here to upload</span>
            </div>
            <Button className="primeblueaction cmn-blue-btn" variant="primary" onClick={open}>
              Browse
            </Button>
          </div>
          <div className="wm-upload__prs">
            {isUpload ? (
              <div className="sm-progress progress">
                <div
                  role="progressbar"
                  className="progress-bar bg-success-two"
                  aria-valuenow={uploadInfo.percentage}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: `${uploadInfo.percentage}%` }}
                />
                <div className="wm-lbl__wrp">
                  <div className="r-flx r-flx-ac">
                    <ImageValidation isImgValid defaultImg="upload-image" customName="file-upload" />
                    <span className="wm-prg__lbl">
                      {uploadInfo.percentage}% - {uploadInfo.remainingTime} Sec. remaining
                    </span>
                  </div>
                  <ImageValidation
                    isImgValid
                    defaultImg="close-red"
                    customName="close"
                    customClassname="wm-close"
                    onClick={() => {
                      setUploadInfo({
                        percentage: 0,
                        remainingTime: '0'
                      });
                      dispatch(cancelWatermarkUpload());
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div>
            <div className="upload-area" style={{ backgroundImage: `url(${config.cdnImgUrl}black-white.svg)` }}>
              {watermarkImage || uploadResponse ? <img className="single-icon" src={uploadResponse ? uploadResponse.secure_url : watermarkImage.photo_url} alt="watermark background" /> : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={isUpload}
            variant="primary"
            onClick={() => {
              if (topLevelFranchisorId && activeUserId) {
                const reqPayload = {
                  id: watermarkImage.id,
                  watermark_photo: {
                    cloudinary_id: uploadResponse.public_id,
                    franchisor_id: topLevelFranchisorId,
                    user_id: activeUserId,
                    photo_url: uploadResponse.secure_url
                  },
                  isUpdate: !!watermarkImage
                };
                dispatch(saveWatermarkImageRequest(reqPayload));
              }
            }}
          >
            {isSaveRequest ? <Spinner animation="border" variant="light" /> : 'Save changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
