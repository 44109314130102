import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import { IStore, reviewsApiTypes } from 'types';
import { ImageValidation } from 'widgets/Media';
import {
  MOMENT_DATE_AND_TIME_FORMAT,
  REVIEWS_TOOLTIP_ICONS_NAME,
  COMMON_SOCIAL_PROVIDER_TYPE,
  ReviewsActiveTab,
  REVIEWS_FACEBOOK_STATUS,
  ALERT_BOX_TYPES,
  MOMENT_TIME_FORMAT,
  MOMENT_DATE_FORMAT,
  SocialContentActionType,
  CommonAction,
  TOOLTIP_PLACEMENT,
  USER_OWNERSHIP,
  REVIEWS_ACTION_INIT_OBJ
} from 'utils/constants';
import { alertBoxCall, Loading } from 'components';
import { capitalizeName, dateFormatByRequestedFormat, getCurrentDate, handleLinkNavigation } from 'utils/helpers';
import { CustomShowMoreText } from 'widgets/ShowMoreText';
import {
  reviewsUpdateMsgReset,
  addReviewsInternalNoteRequest,
  getReviewsCompletePostRequest,
  reviewsHandleRequest,
  getReviewsInternalNotesRequest,
  reviewsSetInternalNotesList,
  aiPlaybookGetVerticalRequest,
  getReviewResponderRequest
} from 'actions';
import { TextArea, Paragraph } from 'widgets/Text';
import { CustomTooltip } from 'widgets/Tooltip';
import { ReviewsReplyItem } from './ReviewsReplyItem';
import { GoogleReviewsReplyItem } from './GoogleReviewsReplyItem';
import { useAccountSwitcherData } from 'utils/hooks';

export const ReviewsList = ({ isCoachMarkEnable = true }: { isCoachMarkEnable?: boolean }) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { id, userOwnership } = useAccountSwitcherData();

  const activeUserProfilePhotoUrl = useSelector((state: IStore) => state.login.activeUser?.profile_photo_url || '');
  const reviewsList = useSelector((state: IStore) => state.reviews.reviewsList);
  const reviewsSuccessMsg = useSelector((state: IStore) => state.reviews.messages.reviewsSuccessMsg);
  const reviewsFailureMsg = useSelector((state: IStore) => state.reviews.messages.reviewsFailureMsg);
  const isNotesFetching = useSelector((state: IStore) => state.reviews.reviewsInternalNotes.isNotesFetching);
  const currentReviewId = useSelector((state: IStore) => state.reviews.reviewsInternalNotes.currentReviewId);
  const reviewsInternalNotesList = useSelector((state: IStore) => state.reviews.reviewsInternalNotes.reviewsInternalNotesList);
  const franchisorReviewsApprovalFeature = useSelector((state: IStore) => state.franchisors?.franchisorDetails?.reviews_approval_feature || false);
  const accountReviewsApprovalFeature = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.reviews_approval_feature_enabled || false);
  const enableManualSettingsReviewResponse = useSelector((state: IStore) => state.aiReviewResponder.reviewResponder.enable_manual_settings);

  const [isExpandedShowMore, setIsExpandedShowMore] = useState(false);
  const [showMoreDialogId, setshowMoreDialogId] = useState<number[]>([]);
  const [reviewsActionObj, setReviewsActionObj] = useState(REVIEWS_ACTION_INIT_OBJ);
  const [replyTabIds, setReplyTabIds] = useState<number[]>([]);
  const [internalNotesTabIds, setInternalNotesTabIds] = useState<number[]>([]);

  const isSendForApprovalEnabled = userOwnership === USER_OWNERSHIP.FRANCHISOR ? franchisorReviewsApprovalFeature : accountReviewsApprovalFeature || false;

  useEffect(() => {
    if (id && userOwnership) {
      handleResetTabIds();
    }
  }, [id, userOwnership]); // eslint-disable-line

  useEffect(() => {
    if (reviewsSuccessMsg) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, reviewsSuccessMsg);
      dispatch(reviewsUpdateMsgReset());
    }
    if (reviewsFailureMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, reviewsFailureMsg);
      dispatch(reviewsUpdateMsgReset());
    }
  }, [reviewsSuccessMsg, reviewsFailureMsg, dispatch]);

  // to get ai playbook setting details
  useEffect(() => {
    dispatch(aiPlaybookGetVerticalRequest());
  }, [dispatch]);

  useEffect(() => {
    if (reviewsList.length) {
      setReplyTabIds(!internalNotesTabIds.length ? reviewsList.map((datum) => datum.id) : reviewsList.map((datum) => datum.id).filter((filterItem) => !internalNotesTabIds.includes(filterItem)));
    } else {
      handleResetTabIds();
    }
  }, [reviewsList.length]); // eslint-disable-line

  useEffect(() => {
    if (userOwnership && id) dispatch(getReviewResponderRequest({ userOwnership, id }));
  }, [userOwnership, id, dispatch]); // eslint-disable-line

  const handleResetTabIds = () => {
    setReplyTabIds([]);
    setInternalNotesTabIds([]);
    dispatch(reviewsSetInternalNotesList([]));
  };

  const handleShowMoreText = (id: number) => {
    setIsExpandedShowMore(!isExpandedShowMore);
    const listDialogId = reviewsList.filter((filterDatum) => filterDatum.id === id)[0];
    setshowMoreDialogId([...showMoreDialogId, listDialogId.id]);
  };

  const handleAddInternalNote = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    if (reviewsActionObj.noteText) {
      dispatch(
        addReviewsInternalNoteRequest({
          reviewId: reviewsObj.id,
          note: reviewsActionObj.noteText,
          createdDate: getCurrentDate()
        })
      );
      setReviewsActionObj(REVIEWS_ACTION_INIT_OBJ);
    }
  };

  const handleCompleteReview = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    dispatch(getReviewsCompletePostRequest({ postId: reviewsObj.id, isComplete: !reviewsObj.completed }));
  };

  const handleReviewsReplied = (reviewId: number, handled: boolean) => {
    dispatch(reviewsHandleRequest({ reviewId, handled }));
  };

  const handleActiveTabSelection = (tabName: string, reviewId: number, isRecommendedReview: boolean, reviewRating: number, networkName: string) => {
    setReviewsActionObj(REVIEWS_ACTION_INIT_OBJ);
    if (tabName === ReviewsActiveTab.INTERNAL_NOTE) {
      setReplyTabIds(replyTabIds.filter((filterDatum) => filterDatum !== reviewId));
      setInternalNotesTabIds([...internalNotesTabIds, reviewId]);
      dispatch(getReviewsInternalNotesRequest({ id: reviewId, actionType: CommonAction.GET }));
    } else {
      setReplyTabIds([...replyTabIds, reviewId]);
      setInternalNotesTabIds(internalNotesTabIds.filter((filterDatum) => filterDatum !== reviewId));
      dispatch(reviewsSetInternalNotesList(reviewsInternalNotesList.filter((datum) => datum.review_id !== reviewId)));
    }
  };

  const firstValidAIIcon = reviewsList.find((dataItem) => dataItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK || dataItem.network === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_PLACES)?.id;

  return (
    <>
      <div id="scrollTarget" className={`list-view review-list__main`}>
        {reviewsList.map((listItem: reviewsApiTypes.IReviewsListObj, index) => {
          const isCoachMark = firstValidAIIcon === listItem.id;
          return (
            <div className="list-item animate__animated animate__fadeIn reputation-wrp" key={`reviews-item-${index}`}>
              <div className="li-top">
                <div className="round-asset platform-icon">
                  <ImageValidation
                    isImgValid
                    defaultImg={listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? 'fb-lv' : listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.YELP ? 'yelp-Iv' : 'google-Iv'}
                    customName="Social Media"
                  />
                </div>

                <div className="lvt-details">
                  <h3>{listItem.user_name && capitalizeName(listItem.user_name)}</h3>
                  <div className={`lvt-brief multi-lvtxt${listItem.network !== COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? `` : ` star-rating-align-review`}`}>
                    {listItem.network !== COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                      <>
                        <span
                          className={`sr-item ${
                            listItem.rating === 5 ? `five-star` : listItem.rating === 4 ? `four-star` : listItem.rating === 3 ? `three-star` : listItem.rating === 2 ? `two-star` : `one-star`
                          }`}
                        />
                        {listItem.account_name && <span className="lvt-txt">{listItem.account_name}</span>}
                      </>
                    ) : (
                      <>
                        <div className="pu-item">
                          <ImageValidation isImgValid defaultImg={!listItem.recommended ? 'recommend-dn' : 'recommend'} customName="Recommends" customClassname="pu-asset lvtb-icon" />
                        </div>

                        {listItem.account_name && (
                          <span className="lvt-txt">
                            {listItem.recommended ? `${REVIEWS_FACEBOOK_STATUS.RECOMMENDED} ${listItem.account_name}` : `${REVIEWS_FACEBOOK_STATUS.NOT_RECOMMENDED} ${listItem.account_name}`}
                          </span>
                        )}

                        <div className="lvt-rating">
                          {[...new Array(5)].map((_1: string, index) => {
                            return <div key={index} className={`star-${index + 1 <= listItem.rating ? 'filled' : 'unfilled'}`} />;
                          })}
                        </div>
                      </>
                    )}
                    <span className="lvt-txt">{dateFormatByRequestedFormat(listItem.posted_at, MOMENT_DATE_AND_TIME_FORMAT)}</span>
                  </div>
                </div>

                <CustomTooltip
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={'Link to Review'}
                  customInput={() => (
                    <span>
                      <div className="more-dots-horizontal cur-pointer">
                        <ImageValidation isImgValid defaultImg="link" customName="More" onClick={() => handleLinkNavigation(listItem.url)} />
                      </div>
                    </span>
                  )}
                />

                {/* <Dropdown className='drop-items'>
                    <Dropdown.Toggle id='dropitems-more-options' className='btn-icon ripple'>
                      <div className='more-dots-horizontal'>
                    <ImageValidation
                        defaultImg='link'
                        customName={'More'} />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleReviewsLink(listItem.url)}>
                      <div className='md-item'>
                    <ImageValidation
                        defaultImg='link-blue'
                        customName={'Link'} />
                      </div>
                        <span>Link to Review</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
              </div>

              <div className="li-base">
                <div className="lv-assets">
                  {listItem.message && (
                    <CustomShowMoreText
                      onClick={() => handleShowMoreText(listItem.id)}
                      expanded={showMoreDialogId.includes(listItem.id) ? isExpandedShowMore : false}
                      customPClassname={'sm-full-text'}
                      customText={listItem.message}
                    />
                  )}
                </div>

                <div className="vc-controls">
                  <div className="vc-item" onClick={() => handleCompleteReview(listItem)}>
                    <ImageValidation key={`reviews-completed-${index}`} defaultImg={listItem.completed ? 'time-green' : 'time-grey'} customName={REVIEWS_TOOLTIP_ICONS_NAME.CLOSED} />
                    <span className="vc-txt">{listItem.completed ? REVIEWS_TOOLTIP_ICONS_NAME.REVIEW_CLOSED : REVIEWS_TOOLTIP_ICONS_NAME.CLOSED}</span>
                  </div>
                  <div className="vc-item" onClick={() => handleActiveTabSelection(ReviewsActiveTab.INTERNAL_NOTE, listItem.id, listItem.recommended, listItem.rating, listItem.network)}>
                    <ImageValidation key={`reviews-comment-${index}`} defaultImg={internalNotesTabIds.includes(listItem.id) ? 'edit-active' : 'edit-grey'} customName="Internal Notes" />
                    <span className={`vc-txt ${internalNotesTabIds.includes(listItem.id) ? 'active' : ''}`}>
                      {[0, 1].includes(listItem.internal_comments_count) ? `(${listItem.internal_comments_count}) Internal Note` : `(${listItem.internal_comments_count}) Internal Notes`}
                    </span>
                  </div>
                  {(listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK || (listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_PLACES && !listItem.comments.length)) && (
                    // !listItem.review_reply
                    <div className="vc-item" onClick={() => handleActiveTabSelection(ReviewsActiveTab.REPLY_TO_REVIEW, listItem.id, listItem.recommended, listItem.rating, listItem.network)}>
                      <ImageValidation
                        key={`reviews-reply-${replyTabIds.includes(listItem.id) ? listItem.id : index}`}
                        defaultImg={`${replyTabIds.includes(listItem.id) ? 'reply-active' : 'reply-grey'}`}
                        customName="Reply"
                      />
                      <span className={`vc-txt ${replyTabIds.includes(listItem.id) ? 'active' : ''}`}>Reply</span>
                    </div>
                  )}
                  {listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.YELP && (
                    <div className="vc-item" onClick={() => handleReviewsReplied(listItem.id, !listItem.handled)}>
                      <ImageValidation key={`reviews-handled-${index}`} defaultImg={`${listItem.handled ? 'mark-replied-a' : 'mark-replied'}`} customName="Handle replied to" />
                      <span className="vc-txt">{listItem.handled ? REVIEWS_TOOLTIP_ICONS_NAME.REVIEW_REPLIED : REVIEWS_TOOLTIP_ICONS_NAME.REVIEW_NOT_REPLIED}</span>
                    </div>
                  )}
                </div>
                {internalNotesTabIds.includes(listItem.id) && (
                  <div className="review-notes-top">
                    {isNotesFetching && currentReviewId === listItem.id ? (
                      <Loading isSmall />
                    ) : reviewsInternalNotesList.length ? (
                      reviewsInternalNotesList.map((noteItem, noteIndex) => {
                        return listItem.id === noteItem.review_id ? (
                          <div className="reviewNote-item" key={`review-note-${noteIndex}`}>
                            <div className="internal-notes-list">
                              <div className="round-asset brand-icon">
                                <ImageValidation
                                  imgUrl={noteItem?.user_photo_url || ''}
                                  defaultImg={'user-avatar-common.png'}
                                  isNotSvgFormat
                                  customClassname={''}
                                  customName={'Reviews internal note asset item'}
                                />
                              </div>

                              <div className="lvt-details">
                                <h3>{noteItem.user_name && capitalizeName(noteItem.user_name)}</h3>
                                <div className={`lvt-brief multi-lvtxt${listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? `` : ` star-rating-align-review`}`}>
                                  {listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                                    <>{listItem.account_name && <span className="lvt-txt"> {`@${dateFormatByRequestedFormat(noteItem.created_at, MOMENT_TIME_FORMAT)} on `}</span>}</>
                                  ) : (
                                    <div className="lvt-rating">
                                      {[...new Array(5)].map((_1: string, index) => {
                                        return <div key={index} className={`star-${index + 1 <= listItem.rating ? 'filled' : 'unfilled'}`} />;
                                      })}
                                    </div>
                                  )}
                                  <span className="lvt-txt lvt-dateyear">{`${dateFormatByRequestedFormat(noteItem.created_at, MOMENT_DATE_FORMAT)}`}</span>
                                </div>
                              </div>

                              <div className="closeIcon d-none">
                                <ImageValidation defaultImg={'xmark'} customClassname={'removeIcon'} customName={'Remove the Comment'} />
                              </div>
                            </div>
                            <div className="vcm-details preline-wrp">
                              <Paragraph customText={noteItem.body} />
                            </div>
                          </div>
                        ) : null;
                      })
                    ) : null}
                  </div>
                )}
                {replyTabIds.includes(listItem.id) && listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (
                  <div className="review-notes-top">
                    {listItem.comments.length
                      ? listItem.comments.map((replyItem, replyIndex) => {
                          return (
                            <div key={`review-reply-${replyIndex}`}>
                              <div className="li-top-internal-notes">
                                <div className="round-asset brand-icon">
                                  <ImageValidation imgUrl={replyItem.user_image} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={''} customName={'Reviews reply asset item'} />
                                </div>
                                <div className="lvt-details">
                                  <h3>{replyItem.user_name && capitalizeName(replyItem.user_name)}</h3>
                                  <div className={`lvt-brief multi-lvtxt${listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? `` : ` star-rating-align-review`}`}>
                                    {listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                                      <>{listItem.account_name && <span className="lvt-txt">{`@${dateFormatByRequestedFormat(replyItem.created_at, MOMENT_TIME_FORMAT)} on `}</span>}</>
                                    ) : (
                                      <div className="lvt-rating">
                                        {[...new Array(5)].map((_1: string, index) => {
                                          return <div key={index} className={`star-${index + 1 <= listItem.rating ? 'filled' : 'unfilled'}`} />;
                                        })}
                                      </div>
                                    )}
                                    <span className="lvt-txt lvt-dateyear">{`${dateFormatByRequestedFormat(replyItem.created_at, MOMENT_DATE_FORMAT)}`}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="vcm-details">
                                <Paragraph customText={replyItem.message} />
                                <ImageValidation imgUrl={replyItem.image_url} defaultImg={null} customClassname={''} customName={'Inbox Comment Image Item'} />
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                )}
                {replyTabIds.includes(listItem.id) && listItem.network === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_PLACES && (
                  <>
                    {/*  <GoogleReviewsReplyItem
                      dataItem={{
                        id: listItem.id,
                        name: listItem.location_name,
                        imageUrl: listItem.location_image_url,
                        createdAt: listItem.review_reply_at,
                        deleteReplyUrl: listItem.delete_reply_url,
                        replyMessage: listItem.review_reply
                      }}
                    /> */}
                    {listItem.comments.length
                      ? listItem.comments.map((it, comIndex) => {
                          return (
                            <GoogleReviewsReplyItem
                              key={`reviews-reply-google-${comIndex}`}
                              dataItem={{
                                id: listItem.id,
                                name: it.user_name,
                                imageUrl: it.image_url,
                                createdAt: it.created_at,
                                replyMessage: it.message
                              }}
                            />
                          );
                        })
                      : null}
                  </>
                )}
                {
                  // permissionReviewAll &&
                  internalNotesTabIds.includes(listItem.id) && (
                    <div className="lv-comment">
                      <div className="lvc-top">
                        <div className="round-asset brand-icon">
                          <ImageValidation imgUrl={activeUserProfilePhotoUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={''} customName={'Reviews Asset Item'} />
                        </div>

                        <TextArea
                          id="internal_note"
                          minRows={1}
                          className="form-control"
                          placeholder="Add Internal Note"
                          value={reviewsActionObj.id === listItem.id ? reviewsActionObj.noteText : ''}
                          onFocus={() => {
                            setReviewsActionObj({ ...reviewsActionObj, id: listItem.id, noteText: '' });
                          }}
                          autoFocus={false}
                          onChange={(event) => {
                            setReviewsActionObj({ ...reviewsActionObj, id: listItem.id, noteText: event.target.value || '' });
                          }}
                        />
                      </div>
                      <div className="lvc-base">
                        <Button
                          className={`txt-btn lv-prime${reviewsActionObj.id === listItem.id ? `` : ` d-none`}`}
                          disabled={!reviewsActionObj.noteText}
                          onClick={() => handleAddInternalNote(listItem)}
                          variant="link"
                        >
                          {SocialContentActionType.POST_INTERNAL_NOTE}
                        </Button>
                      </div>
                    </div>
                  )
                }
                <ReviewsReplyItem
                  userOwnership={userOwnership}
                  replyTabIds={replyTabIds}
                  isSendForApprovalEnabled={isSendForApprovalEnabled}
                  dataItem={listItem}
                  reviewsActionObj={reviewsActionObj}
                  setReviewsActionObj={setReviewsActionObj}
                  isCoachMark={isCoachMark && isCoachMarkEnable}
                  enableManualSettingsReviewResponse={enableManualSettingsReviewResponse}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
