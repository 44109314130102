import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { postsApiTypes, postsReducerTypes } from 'types';

// POSTS STATS
export const getPostsStatsRequest = (payload: postsApiTypes.IPostListRequest) => createAction(actionTypes.POSTS_STATS_REQUEST, payload);
export const getPostsStatsResponse = (data: postsApiTypes.IPostStatsResponse) => createAction(actionTypes.POSTS_STATS_RESPONSE, data);
export const getPostsStatsRequestFailure = (error: null | string) => createAction(actionTypes.POSTS_STATS_REQUEST_FAILURE, error);

// POSTS LIST
export const getPostsRequest = (payload: postsApiTypes.IPostListRequest) => createAction(actionTypes.POSTS_LIST_REQUEST, payload);
export const getPostsResponse = (data: postsApiTypes.IPostListResponse) => createAction(actionTypes.POSTS_LIST_RESPONSE, data);
export const getPostsRequestFailure = (error: null | string) => createAction(actionTypes.POSTS_LIST_REQUEST_FAILURE, error);

// POSTS BRAND VIEW LOCATION CONTENT
export const getPostsLocationContentRequest = (payload: postsApiTypes.IPostListRequest) => createAction(actionTypes.POSTS_LOCATION_CONTENT_REQUEST, payload);
export const getPostsLocationContentResponse = (data: postsApiTypes.IPostListResponse) => createAction(actionTypes.POSTS_LOCATION_CONTENT_RESPONSE, data);
export const getPostsLocationContentFailure = (error: null | string) => createAction(actionTypes.POSTS_LOCATION_CONTENT_FAILURE, error);

// POSTS DETAIL INTERNAL NOTES LIST
export const getPostsInternalNotesListRequest = (payload: postsApiTypes.IPostListRequest) => createAction(actionTypes.POSTS_DETAIL_INT_NOTES_LIST_REQUEST, payload);
export const getPostsInternalNotesListResponse = (data: postsApiTypes.IPostSavedPostCommentsDetails[]) => createAction(actionTypes.POSTS_DETAIL_INT_NOTES_LIST_RESPONSE, data);
export const getPostsInternalNotesListFailure = (error: null | string) => createAction(actionTypes.POSTS_DETAIL_INT_NOTES_LIST_FAILURE, error);

// POSTS DETAIL DATA
export const getPostsDetailsResponse = (data: postsApiTypes.IPostDetailResponse) => createAction(actionTypes.POSTS_DETAIL_RESPONSE, data);
export const getPostsDetailsRequestFailure = (error: null | string) => createAction(actionTypes.POSTS_DETAIL_REQUEST_FAILURE, error);

// POSTS - DELETE POST
export const deletePostsRequest = (payload: postsApiTypes.IPostDeleteReqParam) => createAction(actionTypes.POSTS_DELETE_REQUEST, payload);
export const deletePostsResponse = (data: postsApiTypes.IPostDeleteResponseData) => createAction(actionTypes.POSTS_DELETE_RESPONSE, data);
export const deletePostsResponseFailure = (error: { message: null | string }) => createAction(actionTypes.POSTS_DELETE_FAILURE, error);

// POSTS TAGS
export const postsTagsRequest = (payload: postsApiTypes.IPostsTagReq) => createAction(actionTypes.POSTS_TAGS_REQUEST, payload);
export const postsTagsResponse = (data: { page?: number; response: postsReducerTypes.IPostsTagsData[] }) => createAction(actionTypes.POSTS_TAGS_RESPONSE, data);
export const postsTagsResponseFailure = (error: null | string) => createAction(actionTypes.POSTS_TAGS_FAILURE, error);

// POSTS DETAIL ADD INTERNAL NOTE
export const postsDetailAddInternalNotesRequest = (payload: postsApiTypes.IPostAddInternalNoteReqParam) => createAction(actionTypes.POSTS_DETAIL_ADD_INTERNAL_NOTE_REQUEST, payload);
export const postsDetailAddInternalNotesResponse = (data: null | postsApiTypes.IPostSavedPostCommentsDetails | postsApiTypes.IPostContentInternalNotes) =>
  createAction(actionTypes.POSTS_DETAIL_ADD_INTERNAL_NOTE_RESPONSE, data);
export const postsDetailAddInternalNotesFailure = (error: null | string) => createAction(actionTypes.POSTS_DETAIL_ADD_INTERNAL_NOTE_FAILURE, error);

// POSTS EDIT CONTENT DRAFT
export const postsEditContentDraftRequest = (payload: postsApiTypes.IPostEditContentDraftReqParam) => createAction(actionTypes.POSTS_EDIT_CONTENT_DRAFT_REQUEST, payload);
export const postsEditContentDraftResponse = (data: postsApiTypes.IPostListContentPostSummaries) => createAction(actionTypes.POSTS_EDIT_CONTENT_DRAFT_RESPONSE, data);
export const postsEditContentDraftFailure = (error: null | string) => createAction(actionTypes.POSTS_EDIT_CONTENT_DRAFT_FAILURE, error);

// POSTS - UPDATE SAVED POST LIST
export const postsUpdateSavedPostRequest = (payload: Partial<postsApiTypes.IPostListSavedPostSummaries>) => createAction(actionTypes.POSTS_UPDATE_SAVED_POST_REQUEST, payload);
export const postsUpdateSavedPostResponse = (response: postsApiTypes.IPostListSavedPostSummaries) => createAction(actionTypes.POSTS_UPDATE_SAVED_POST_RESPONSE, response);
export const postsUpdateSavedPostFailure = (err: null | string) => createAction(actionTypes.POSTS_UPDATE_SAVED_POST_FAILURE, err);

// POSTS SYNDICATE EXPIRE DATE UPDATE
export const updatePostDetailRequest = (payload: postsApiTypes.IUpdatePostDetailsReq) => createAction(actionTypes.POSTS_DETAIL_UPDATE_REQUEST, payload);
export const updatePostDetailResponse = (data: postsApiTypes.IPostListContentPostSummaries) => createAction(actionTypes.POSTS_DETAIL_UPDATE_RESPONSE, data);
export const updatePostDetailFailure = (error: null | string) => createAction(actionTypes.POSTS_DETAIL_UPDATE_FAILURE, error);

// // SET POSTS SYNDICATE EXPIRE DATE UPDATE
// export const setPostsSyndicateExpireDate = (data: { start_date: null | string; end_date: null | string }) => createAction(actionTypes.POSTS_SYNDICATE_EXPIRE_DATE, data);

// POSTS - COPY POST
export const copyPostsRequest = (payload: postsApiTypes.IPostsCopyReqParams) => createAction(actionTypes.POSTS_COPY_REQUEST, payload);
export const copyPostsResponse = (data: null | postsApiTypes.IPostCopyContents) => createAction(actionTypes.POSTS_COPY_RESPONSE, data);
export const copyPostsResponseFailure = (error: null | string) => createAction(actionTypes.POSTS_COPY_FAILURE, error);

// POSTS - REJECT SAVED POST FROM HUB
export const rejectSavedPostFromHubRequest = (payload: number) => createAction(actionTypes.POSTS_REJECT_SAVED_POST_REQUEST, payload);
export const rejectSavedPostFromHubResponse = (data: postsApiTypes.IPostDetailSavedPost) => createAction(actionTypes.POSTS_REJECT_SAVED_POST_RESPONSE, data);
export const rejectSavedPostFromHubFailure = (error: null | string) => createAction(actionTypes.POSTS_REJECT_SAVED_POST_FAILURE, error);

export const postDetailViewError = (error: null | string) => createAction(actionTypes.POSTS_DETAIL_VIEW_ERROR, error);

// POSTS - SYNDICATE SUCCESS
export const postSyndicateSuccessResponse = (data: null | string) => createAction(actionTypes.POSTS_SYNDICATE_SUCCESS_RESPONSE, data);

// POSTS - REJECT SAVED POST FROM HUB
export const postPublishedStatusRequest = (payload: postsReducerTypes.IPostPublishedStatusRequest) => createAction(actionTypes.POSTS_PUBLISHED_STATUS_REQUEST, payload);
export const postPublishedStatusResponse = (data: postsReducerTypes.IPostPublishStatus[]) => createAction(actionTypes.POSTS_PUBLISHED_STATUS_RESPONSE, data);
export const postPublishedStatusFailure = (error: null | string) => createAction(actionTypes.POSTS_PUBLISHED_STATUS_FAILURE, error);

// POSTS TAGS STATS
export const getPostsTagsStatsRequest = () => createAction(actionTypes.GET_POSTS_TAGS_STATS_REQUEST);
export const getPostsTagsStatsResponse = (data: postsReducerTypes.IPostTagsStats) => createAction(actionTypes.GET_POSTS_TAGS_STATS_RESPONSE, data);
export const getPostsTagsStatsFailure = (error: null | string) => createAction(actionTypes.GET_POSTS_TAGS_STATS_FAILURE, error);

// POSTS LIST
export const getMultiSelectPostsListRequest = (payload: postsApiTypes.IMultiSelectPostListRequest) => createAction(actionTypes.GET_MULTI_SELECT_POSTS_LIST_REQUEST, payload);
export const getMultiSelectPostsListResponse = (data: postsApiTypes.IPostListResponse) => createAction(actionTypes.GET_MULTI_SELECT_POSTS_LIST_RESPONSE, data);
export const getMultiSelectPostsListFailure = (error: null | string) => createAction(actionTypes.GET_MULTI_SELECT_POSTS_LIST_REQUEST_FAILURE, error);

// UPDATE POST DETAILS
export const editPostDetailsRequest = (payload: postsReducerTypes.IPostDetailsData) => createAction(actionTypes.EDIT_POST_DETAILS_REQUEST, payload);

// POSTS DELETE RESET
export const postDeleteDataReset = () => createAction(actionTypes.POSTS_DELETE_DATA_RESET);
export const postDetailDataReset = () => createAction(actionTypes.POSTS_DETAIL_DATA_RESET);
export const postListsReset = () => createAction(actionTypes.POSTS_LISTS_DATA_RESET);
export const postsCopyDataReset = () => createAction(actionTypes.POSTS_COPY_DATA_RESET);
export const postsResetAll = () => createAction(actionTypes.POSTS_RESET_ALL);

const POSTS_ACTIONS = {
  getPostsStatsRequest,
  getPostsStatsResponse,
  getPostsStatsRequestFailure,
  getPostsRequest,
  getPostsResponse,
  getPostsRequestFailure,
  getPostsLocationContentRequest,
  getPostsLocationContentResponse,
  getPostsLocationContentFailure,
  getPostsDetailsResponse,
  getPostsDetailsRequestFailure,
  deletePostsRequest,
  deletePostsResponse,
  deletePostsResponseFailure,
  postsTagsRequest,
  postsTagsResponse,
  postsTagsResponseFailure,
  copyPostsRequest,
  copyPostsResponse,
  copyPostsResponseFailure,
  getPostsInternalNotesListRequest,
  getPostsInternalNotesListResponse,
  getPostsInternalNotesListFailure,
  postsDetailAddInternalNotesRequest,
  postsDetailAddInternalNotesResponse,
  postsDetailAddInternalNotesFailure,
  postsEditContentDraftRequest,
  postsEditContentDraftResponse,
  postsEditContentDraftFailure,
  postsUpdateSavedPostRequest,
  postsUpdateSavedPostResponse,
  postsUpdateSavedPostFailure,
  updatePostDetailRequest,
  updatePostDetailResponse,
  updatePostDetailFailure,
  rejectSavedPostFromHubRequest,
  rejectSavedPostFromHubResponse,
  rejectSavedPostFromHubFailure,
  postPublishedStatusRequest,
  postPublishedStatusResponse,
  postPublishedStatusFailure,
  postDetailViewError,
  postSyndicateSuccessResponse,
  postDeleteDataReset,
  postDetailDataReset,
  postListsReset,
  postsCopyDataReset,
  getPostsTagsStatsRequest,
  getPostsTagsStatsResponse,
  getPostsTagsStatsFailure,
  getMultiSelectPostsListRequest,
  getMultiSelectPostsListResponse,
  getMultiSelectPostsListFailure,
  editPostDetailsRequest,
  postsResetAll
};

export type PostsAction = ActionsUnion<typeof POSTS_ACTIONS>;
