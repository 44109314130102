import { Reducer } from 'redux';

import { SettingsAIPlayBookActions } from 'actions/modules/settings-ai-playbook';
import { settingsAIPlayBookReducerTypes } from 'types/settings-ai-playbook';
import * as types from 'actions/action-types';
import { settingsAIPlayBookVerticalGetList } from 'utils/helpers';
import { AI_PLAYBOOK_COMPLIANCE_SUMMARY_GENERATE_SUCCESS_MSG, CUSTOMER_PERSONA_GENERATE_SUCCESS_MSG } from 'utils/constants';

const initialState: settingsAIPlayBookReducerTypes.ISettingsAIPlayBookReducer = {
  ai_playbook: {
    business_url: '',
    business_description: '',
    name: '',
    questions: {
      business_search: [],
      customer_alias: []
    },
    vertical_id: 0,
    customer_persona_text: '',
    customer_persona_usage_level: null,
    auto_generate_post: false,
    ai_compliance_words: [],
    ai_compliance_categories: [],
    ai_compliance_image_restrictions: [],
    // ai_compliance_description: '',
    ai_compliance_summary: ''
  },
  verticals: [],
  generalDocuments: [],
  // complianceDocuments: [],
  activityGoalTypes: [],
  activityGoals: [],
  isDocumentsFetching: false,
  isCustomerPersonaGenerating: false,
  isFetching: false,
  error: null,
  message: '',
  docUploadType: '',
  isComplianceSummaryGenerating: false,
  isComplianceSummaryHasWarning: false
};

export const settingsAIPlayBookReducer: Reducer<settingsAIPlayBookReducerTypes.ISettingsAIPlayBookReducer, SettingsAIPlayBookActions> = (
  state: settingsAIPlayBookReducerTypes.ISettingsAIPlayBookReducer = initialState,
  action: SettingsAIPlayBookActions
) => {
  switch (action.type) {
    // GET AI PLAYBOOK STATUS
    case types.AI_PLAYBOOK_GET_UPDATE_RESPONSE:
      return {
        ...state,
        ai_playbook: { ...state.ai_playbook, ...action.payload },
        error: null
      };
    case types.AI_PLAYBOOK_GET_VERTICAL_RESPONSE:
      return {
        ...state,
        verticals: settingsAIPlayBookVerticalGetList(action.payload)
      };
    case types.AI_PLAYBOOK_GET_VERTICAL_FAILURE:
      return {
        ...state,
        error: action.payload,
        verticals: initialState.verticals
      };
    case types.AI_PLAYBOOK_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.AI_PLAYBOOK_GET_DOCUMENTS_LIST_REQUEST:
      return {
        ...state,
        isDocumentsFetching: true
      };
    case types.AI_PLAYBOOK_GET_DOCUMENTS_LIST_RESPONSE:
      return {
        ...state,
        isDocumentsFetching: false,
        generalDocuments: action.payload.data
      };
    case types.AI_PLAYBOOK_GET_DOCUMENTS_LIST_FAILURE:
      return {
        ...state,
        isDocumentsFetching: false,
        generalDocuments: []
      };
    case types.AI_PLAYBOOK_CREATE_DOCUMENT_RESPONSE:
      const selectedDocuments = 'generalDocuments';
      const updatedDocs = [...state[selectedDocuments]];
      const responseDocumentIndex = updatedDocs.findIndex((doc) => doc.fileName === action.payload.data.name);
      updatedDocs[responseDocumentIndex] = action.payload.data;
      return {
        ...state,
        isDocumentsFetching: false,
        [selectedDocuments]: updatedDocs
      };
    case types.AI_PLAYBOOK_DELETE_DOCUMENT_RESPONSE:
      const selectedDocumentType = 'generalDocuments';
      return {
        ...state,
        [selectedDocumentType]: state[selectedDocumentType].filter((document) => document.id !== action.payload.id)
      };
    case types.AI_PLAYBOOK_DELETE_DOCUMENT_FAILURE:
      return {
        ...state
      };
    case types.AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_REQUEST:
      return {
        ...state,
        isCustomerPersonaGenerating: true,
        customerPersona: '',
        message: ''
      };

    case types.AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_RESPONSE:
      return {
        ...state,
        isCustomerPersonaGenerating: false,
        ai_playbook: {
          ...state.ai_playbook,
          customer_persona_text: action.payload
        },
        message: CUSTOMER_PERSONA_GENERATE_SUCCESS_MSG
      };
    case types.AI_PLAYBOOK_CREATE_CUSTOMER_PERSONA_FAILURE:
      return {
        ...state,
        isCustomerPersonaGenerating: false,
        error: action.payload,
        customerPersona: '',
        message: ''
      };
    case types.AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_REQUEST:
      return {
        ...state,
        activityGoals: []
      };

    case types.AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_RESPONSE:
      return {
        ...state,
        activityGoals: action.payload
      };
    case types.AI_PLAYBOOK_GET_ACTIVITY_GOALS_LIST_FAILURE:
      return {
        ...state,
        activityGoals: [],
        error: action.payload
      };
    case types.AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isCustomerPersonaGenerating: true,
        message: ''
      };

    case types.AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_RESPONSE:
      return {
        ...state,
        activityGoals: action.payload.activity_goal,
        message: action.payload.message,
        isFetching: false
      };
    case types.AI_PLAYBOOK_CREATE_UPDATE_ACTIVITY_GOALS_FAILURE:
      return {
        ...state,
        error: action.payload,
        message: '',
        isFetching: false
      };

    case types.AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_RESPONSE:
      return {
        ...state,
        message: action.payload,
        isFetching: false
      };
    case types.AI_PLAYBOOK_DELETE_ACTIVITY_GOALS_FAILURE:
      return {
        ...state,
        error: action.payload,
        message: '',
        isFetching: false
      };
    case types.AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_RESPONSE:
      return {
        ...state,
        activityGoalTypes: action.payload.map((payload) => {
          return { id: payload.id, value: payload.activity_goal_type, label: payload.activity_goal_type };
        }),
        isFetching: false
      };
    case types.AI_PLAYBOOK_GET_ACTIVITY_GOAL_TYPES_FAILURE:
      return {
        ...state,
        activityGoalTypes: []
      };
    case types.AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_REQUEST:
      return {
        ...state,
        ai_playbook: {
          ...state.ai_playbook,
          ai_compliance_summary: '',
          ai_compliance_words: action.payload.words_or_phrases,
          ai_compliance_categories: action.payload.categories_or_concepts
        },
        isComplianceSummaryGenerating: true,
        isComplianceSummaryHasWarning: false
      };
    case types.AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_RESPONSE:
      return {
        ...state,
        ai_playbook: {
          ...state.ai_playbook,
          ai_compliance_summary: action.payload.summary
        },
        isComplianceSummaryGenerating: false,
        message: AI_PLAYBOOK_COMPLIANCE_SUMMARY_GENERATE_SUCCESS_MSG,
        isComplianceSummaryHasWarning: action.payload.hasWarning
      };
    case types.AI_PLAYBOOK_GENERATE_COMPLIANCE_SUMMARY_FAILURE:
      return {
        ...state,
        ai_playbook: {
          ...state.ai_playbook,
          ai_compliance_summary: ''
        },
        isComplianceSummaryGenerating: false,
        isComplianceSummaryHasWarning: false,
        error: action.payload
      };

    case types.AI_PLAYBOOK_RESET_MESSAGE:
      return {
        ...state,
        message: ''
      };
    case types.AI_PLAYBOOK_SET_DOC_UPLOAD_TYPE:
      return {
        ...state,
        docUploadType: action.payload
      };

    case types.AI_PLAYBOOK_RESET:
      return { ...initialState, ai_playbook: state.ai_playbook };
    default:
      return state;
  }
};
