import CryptoJS from 'crypto-js';
import { postcodeValidator } from 'postcode-validator';
import { NavigateFunction } from 'react-router';
import { EditorState, getDefaultKeyBinding } from 'draft-js';

import { commonImgExistsTypes, commonProps, commonLocationTree, menuAccessApiTypes, menuAccessReducerTypes, commonApiTypes } from 'types';
import {
  COMMON_SOCIAL_PROVIDER_TYPE,
  DEBOUNCE_TIMEOUT,
  POST_IMAGES_LIMITATION,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  HELP_URL,
  NAV_BAR,
  ALPHABET_REGEX,
  NUMBER_REGEX,
  SPECIAL_CHARS_REGEX,
  WORD_AT_BEGINNING_REGEX,
  CommonValidQueryParams,
  SIDE_NAV_NAME,
  csLocationHelpUrl,
  NEED_HELP_URL_OBJ,
  contentSupplierHelpUrl
} from 'utils/constants';
import { config } from 'config';
import { PLATFORM_FILTER } from 'analytics/utils/constants';
import { INeedHelpUrlTypes } from 'types/common/account-switcher';

/** Capitalize the first letter of each word of a given string */
export const capitalizeFirstLetter = (name: string) => {
  const validName = name.split(' ');
  for (let i = 0, x = validName.length; i < x; i++) {
    validName[i] = validName[i][0] ? validName[i][0].toUpperCase() : validName[i][0] + validName[i].substr(1);
  }
  return validName.join(' ');
};

export const getShortName = (locationName: string | null, topLevelFranchisor?: null | string) => {
  let shortName = '';
  let remainingWord = locationName;
  let capitalizedLocationName = '';
  if (locationName) {
    capitalizedLocationName = capitalizeFirstLetter(locationName);
  }
  if (capitalizedLocationName && topLevelFranchisor && capitalizedLocationName.includes(topLevelFranchisor)) {
    const validName = capitalizedLocationName.replace('- ', '');
    remainingWord = validName.replace(`${topLevelFranchisor} `, '');
  }
  const nameInitials: Array<string> | string | null = remainingWord && remainingWord.split(' ');
  if (nameInitials) {
    shortName = nameInitials.length > 1 ? nameInitials[0].charAt(0).concat(nameInitials[1].charAt(0)) : nameInitials[0].charAt(0).concat(nameInitials[0].charAt(1));
  }
  return shortName.toLocaleUpperCase();
};

/** Get resolved image exists object */
export const imageExists = async <T = any>(url: string | null, payload?: T): Promise<commonImgExistsTypes.IImageExistsWithPayload<T>> => {
  const imageExistsProps: any = {
    ...(payload && { payload })
  };
  if (!url) {
    imageExistsProps.isImageExists = false;
    return imageExistsProps;
  }
  try {
    // awaits a promise to wait and get status of image on loading
    const imageLoadResponse: any = await new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        // image loaded and will be resolved
        imageExistsProps.isImageExists = true;
        resolve(imageExistsProps);
      };
      img.onerror = reject;
    });
    return imageLoadResponse;
  } catch (error) {
    // if error occures during loading return false
    imageExistsProps.isImageExists = false;
    return imageExistsProps;
  }
};

/** Read file data while promise has been resolved */
export const readFile = (file: any) => {
  return new Promise<any>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

/** Show or hide main nav bar list based on userOwnership */
export const getMainNavBarList = (navBarList: commonProps.IMainPageNavBarData[], menuAvailablity: menuAccessReducerTypes.IMenuAccessInitialValue) => {
  return menuAvailablity
    ? navBarList.reduce((acc: commonProps.IMainPageNavBarData[], curr) => {
        Object.entries(menuAvailablity).forEach((it) => {
          if (curr.name === it[0]) acc = [...acc, curr]; // removed - Object.values(it[1]).find((it) => it)
        });
        return acc;
      }, [])
    : navBarList;
};

/** Get valid side nav bar list based on account switcher */
export const getValidSideNavBarList = (sideNavBarList: commonProps.ISubPageRouteData[], menuAvailablity: commonProps.ICommonObj<boolean>) => {
  return (
    sideNavBarList &&
    menuAvailablity &&
    sideNavBarList
      .reduce((acc: commonProps.ISubPageRouteData[], curr) => {
        Object.entries(menuAvailablity).forEach(([key, value]) => {
          if (curr.name === key) acc = [...acc, { ...curr, isShow: value }];
          else acc = [...acc, { ...curr, isShow: false }];
        });
        return acc;
      }, [])
      .filter((datum) => datum.isShow)
  );
};

// Get valid pathname if the franchisor/account has invalid subpage
export const getValidPathnameBasedOnHubOrLocation = (id: number, userOwnership: string, isAdmin: boolean, menuAvailablity: menuAccessReducerTypes.IMenuAccessInitialValue) => {
  let validRoute = '';
  if (!id && !userOwnership && isAdmin) {
    validRoute = `/admin_area/accounts`;
  } else if (id && userOwnership && menuAvailablity) {
    for (let i = 0; i < NAV_BAR.length; i++) {
      if (Object.keys(menuAvailablity).includes(NAV_BAR[i].name)) {
        for (const item in menuAvailablity[NAV_BAR[i].name]) {
          if (menuAvailablity[NAV_BAR[i].name][item]) {
            validRoute = `/${userOwnership}/${id}/${NAV_BAR[i].name}/${item}`;
            break;
          } else continue;
        }
        if (!validRoute) continue;
        else break;
      }
    }
  }
  return validRoute;
};

export const navigateToValidRoute = (
  id: number,
  userOwnership: string,
  isAdmin: boolean,
  subNavPageName: string,
  menuAvailablity: menuAccessReducerTypes.IMenuAccessInitialValue,
  navigate: NavigateFunction
) => {
  const invalidSubPageName = menuAvailablity
    ? !Object.entries(menuAvailablity)
        .reduce((acc: string[], curr) => {
          if (curr[0]) {
            acc = [
              ...acc,
              ...Object.entries(curr[1]).reduce((subAcc: string[], subCurr) => {
                if (subCurr[1]) subAcc = [...subAcc, subCurr[0]];
                return subAcc;
              }, [])
            ];
          }
          return acc;
        }, [])
        .includes(subNavPageName)
    : false;
  if (invalidSubPageName) navigate({ pathname: getValidPathnameBasedOnHubOrLocation(id, userOwnership, isAdmin, menuAvailablity) }, { replace: true });
};

/** Get initials for the name if there is invalid image URL */
export const getInitials = (data: string): string => {
  if (ALPHABET_REGEX.test(data[0])) return data.match(WORD_AT_BEGINNING_REGEX)?.slice(0, 2).join('') || '';
  else if (NUMBER_REGEX.test(data[0])) return data[0];
  else if (SPECIAL_CHARS_REGEX.test(data[0])) return data[0];
  else return '';
};

/** Convert miles to meters */
export const getMetersFromMiles = (value: number) => {
  return value * 1609.344;
};

/** Location tree - Get location details */
export const getLocationDetails = (locationKey: string): commonLocationTree.ILocationDetailsObj => {
  const locationKeyObj = locationKey.split('%-%');
  const location: commonLocationTree.ILocationDetailsObj = {
    id: locationKeyObj[0],
    title: locationKeyObj[1],
    userType: locationKeyObj[2],
    parentId: locationKeyObj[3],
    latLan: locationKeyObj[4],
    child_count: locationKeyObj[5]
  };
  return location;
};

/** Location tree - Get latitude and longitude */
export const getLatLan = (locationKey: commonLocationTree.ILocationTreeFranchisor | commonLocationTree.ILocationTreeAccount) => {
  let latLan = null;
  if (locationKey.latitude && locationKey.longitude) latLan = `${locationKey.latitude},${locationKey.longitude}`;
  return latLan;
};

let accountsTotalCount = 0;
/** Location tree - Get franchisor field */
export const getParentLocFields = (nodeData: commonLocationTree.ILocationTreeFranchisor, treeObj: any, isOnlyFranchisor: boolean) => {
  const treeParentNodeObj: commonLocationTree.ILocationTree = {
    title: !nodeData.parent_franchisor_id ? `${nodeData.name} (All hubs and locations)` : nodeData.name,
    key: `${nodeData.id}%-%${nodeData.name}%-%franchisors%-%${nodeData.parent_franchisor_id ? nodeData.parent_franchisor_id : 0}%-%${getLatLan(nodeData)}%-%${nodeData.child_count}`,
    isLeaf: isOnlyFranchisor ? nodeData.child_present : !nodeData.child_present,
    className: 'tree-franchisor'
  };
  return { treeObj, treeParentNodeObj };
};

/** Location tree - Get accounts field */
export const locationChildTreeGenerator = <T>(childrenDataArray: T[], parentId: number) => {
  const accountsArray: commonLocationTree.ILocationTree[] = [];
  childrenDataArray.forEach((child: any) => {
    const treeNodeObj: commonLocationTree.ILocationTree = {
      title: child.name,
      key: `${child.id}%-%${child.name}%-%accounts%-%${parentId}%-%${getLatLan(child)}%-%${child.child_count}`,
      address: child.address || null,
      isLeaf: true,
      className: 'tree-location'
    };
    accountsArray.push(treeNodeObj);
  });
  return accountsArray;
};

/** Location tree - Get franchisor and accounts list recursively for specific root tree */
const getNestedLocationTree = (currentLocArr: commonLocationTree.ILocationTreeFranchisor) => {
  let children: commonLocationTree.ILocationTree[] = [];
  if (currentLocArr.franchisors && Array.isArray(currentLocArr.franchisors) && currentLocArr.franchisors.length) {
    currentLocArr.franchisors.forEach((franchisorsNode) => {
      const locationTree = getTopLevelLocationTree(franchisorsNode, { dataArray: [], totalCount: 0, totalLocationKeys: [] }, franchisorsNode.child_present);
      children = [...children, ...locationTree.dataArray];
    });
  }
  if (currentLocArr.accounts && Array.isArray(currentLocArr.accounts) && currentLocArr.accounts.length) {
    const accountsDataArray = locationChildTreeGenerator(currentLocArr.accounts, currentLocArr.id);
    children = [...children, ...accountsDataArray];
    accountsTotalCount += accountsDataArray.length;
  }
  return children;
};

/** Location tree - Get top level franchisor tree */
const getTopLevelLocationTree = (
  currentLocArr: commonLocationTree.ILocationTreeFranchisor,
  accumulatorLocArr: { dataArray: commonLocationTree.ILocationTree[]; totalCount: number; totalLocationKeys: string[] },
  childPresent: boolean
) => {
  const nodeParentObj = childPresent ? getParentLocFields(currentLocArr, accumulatorLocArr, false) : getParentLocFields(currentLocArr, accumulatorLocArr, true);
  const nestedFranAccLocationArray = getNestedLocationTree(currentLocArr);
  if (childPresent) {
    nodeParentObj.treeParentNodeObj.children = nestedFranAccLocationArray;
  }
  accumulatorLocArr.dataArray.push(nodeParentObj.treeParentNodeObj);
  accumulatorLocArr.totalCount = accountsTotalCount;
  return accumulatorLocArr;
};

/** Location tree generator for specific root node */
export const locationTreeGenerator = (locationArray: commonLocationTree.ILocationTreeFranchisor[]) => {
  accountsTotalCount = 0;
  const locationTree = locationArray.reduce(
    (acc: any, curr: commonLocationTree.ILocationTreeFranchisor) => {
      if (!curr.franchisors && !curr.accounts) {
        const nodeParentObj = getParentLocFields(curr, acc, !curr.child_present);
        acc.dataArray.push(nodeParentObj.treeParentNodeObj);
        acc.totalCount = accountsTotalCount;
      } else {
        getTopLevelLocationTree(curr, acc, curr.child_present);
      }
      return acc;
    },
    { dataArray: [], totalCount: 0 }
  );
  return locationTree;
};

/** Location tree expanded functionality */
export const getExpandedTreeArray = (filterTreeArray: commonLocationTree.ILocationTree[], expandedKeys: string[] = []) => {
  if (filterTreeArray.length) {
    filterTreeArray.forEach((locations) => {
      if (locations.isLeaf === false) {
        expandedKeys.push(locations.key);
        if (locations.children && locations.children.length) {
          getExpandedTreeArray(locations.children, expandedKeys);
        }
      }
    });
  }
  return expandedKeys;
};

/** Location tree > Get all filtered keys */
export const getAllFilterTreeKeys = (filteredArray: commonLocationTree.ILocationTree[], filterKeys: string[] = []) => {
  if (filteredArray && filteredArray.length) {
    filteredArray.forEach((datum) => {
      filterKeys.push(datum.key);
      if (datum.children && datum.children.length) {
        getAllFilterTreeKeys(datum.children, filterKeys);
      }
    });
  }
  return filterKeys;
};

/** Location tree > Get all keys from tree structure */
export const getAllLocationTreeKeys = (locationTreeArr: commonLocationTree.ILocationTree[]) =>
  [...locationTreeArr].reduce<string[]>((acc, curr) => {
    if (curr.key) {
      acc = [...acc, curr.key];
    }
    if (curr.children?.length) {
      acc = [...acc, ...getAllLocationTreeKeys(curr.children)];
    }
    return acc;
  }, []);

const decipherKey = () => {
  const key = config.encryptionKey;
  return key
    .split('')
    .map((it: any) => String.fromCharCode(it.charCodeAt(0) - 3))
    .join('');
};

export const decryptEnvData = (encryptedData: string) => {
  const chunkLength = Math.ceil((encryptedData.length - 40) / 4);
  const decryptedArr = [];
  for (let i = 0; i < 4; i++) {
    const maskValue = i > 0 ? 10 : 0;
    const startIndex = i * chunkLength + maskValue * i;
    const endIndex = startIndex + chunkLength;
    decryptedArr.push(encryptedData.substring(startIndex, endIndex));
  }
  const decryptedData = CryptoJS.AES.decrypt(decryptedArr.join(''), decipherKey()).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

/** Insert a specific data into original data based on index */
export const dataInsertion = (data: string, insertedData: string, index: number) => {
  return `${data.substr(0, index)}${insertedData}${data.substr(index)}`;
};

export const showSalesPage = (salesPages: string[], pathname: string) => {
  if (salesPages.find((it) => pathname.substring(1, pathname.length) === it.replace('-sales', ''))) {
    return true;
  }
  return !!salesPages.find((it) => pathname.substring(1, pathname.length).startsWith(it.replace('-sales', '')));
};

/** Get franchisor accessible social media list */
export const getFranAccessibleSocialMedia = (googleFeature: boolean, hubAccess: null | menuAccessApiTypes.IUserDetailsAccess) => {
  const franSocialMediaAccessObj = {
    platform_facebook_access: hubAccess?.platform_facebook_access || false,
    platform_twitter_access: hubAccess?.platform_twitter_access || false,
    platform_linkedin_access: hubAccess?.platform_linkedin_access || false,
    platform_instagram_access: hubAccess?.platform_instagram_access || false,
    platform_tiktok_access: hubAccess?.platform_tiktok_access || false,
    platform_yelp_access: hubAccess?.platform_yelp_access || false,
    gmb_feature: googleFeature || false
  };
  return Object.entries(franSocialMediaAccessObj)
    .reduce((acc: string[], [key, value]) => {
      if (value) key.includes('platform') ? acc.push(key.replace(/platform_|_access/gi, '')) : acc.push(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);
      return acc;
    }, [])
    .sort((a, b) => PLATFORM_FILTER.findIndex((it) => it.value === a) - PLATFORM_FILTER.findIndex((it) => it.value === b));
};

export const isInIFrame = () => {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
};

export const clearStorage = () => indexedDB.deleteDatabase('localforage');

/** Show connect social platform page */
export const showConnectPlatformPage = (subPageName: string) => {
  switch (subPageName) {
    case SIDE_NAV_NAME.ANALYTICS_SOCIAL:
      return true;
    case SIDE_NAV_NAME.ANALYTICS_CONTENT:
      return true;
    case SIDE_NAV_NAME.ANALYTICS_PAGE:
      return true;
    case SIDE_NAV_NAME.ANALYTICS_REVIEWS:
      return true;
    case SIDE_NAV_NAME.ANALYTICS_LEADERBOARD:
      return true;
    case SIDE_NAV_NAME.CONTENT_CREATOR:
      return true;
    /* case SIDE_NAV_NAME.CONTENT_POSTS:
      return true; */
    case SIDE_NAV_NAME.CONTENT_CAMPAIGNS:
      return true;
    case SIDE_NAV_NAME.CONTENT_RSS_FEEDS:
      return true;
    case SIDE_NAV_NAME.CONTENT_CALENDAR:
      return true;
    case SIDE_NAV_NAME.CONTENT_PROFILE_IMAGERY:
      return true;
    case SIDE_NAV_NAME.COMMUNITY_INBOX:
      return true;
    case SIDE_NAV_NAME.COMMUNITY_SANDBOX:
      return true;
    case SIDE_NAV_NAME.REPUTATION_REVIEWS:
      return true;
    case SIDE_NAV_NAME.REPUTATION_OUTBOX:
      return true;
    case SIDE_NAV_NAME.EMP_ADV_OVERVIEW:
      return true;
    case SIDE_NAV_NAME.DL_LISTINGS:
      return true;
    default:
      return false;
  }
};

/** Debounce method for input search */
export const debounceMethod = (searchValue: string, apiTimeout: null | NodeJS.Timeout, handleSearch: (searchValue: string) => void) => {
  if (apiTimeout) {
    clearTimeout(apiTimeout);
  }
  apiTimeout = setTimeout(() => handleSearch(searchValue || ''), DEBOUNCE_TIMEOUT);
  return apiTimeout;
};

/** Permitted images based on social media */
export const getPermittedImgsBasedOnPlatform = (socialMedia: string, imagesList: string[]) => {
  const maxLength =
    socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
      ? POST_IMAGES_LIMITATION.INSTAGRAM
      : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      ? POST_IMAGES_LIMITATION.TWITTER
      : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      ? POST_IMAGES_LIMITATION.LINKEDIN
      : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      ? POST_IMAGES_LIMITATION.GOOGLE
      : imagesList.length;
  return imagesList.slice(0, maxLength);
};

export const isUSAZipCode = (zipCode: string) => {
  return postcodeValidator(zipCode, 'US');
};

export const getExceptionErrorMsg = (errorData: { [key: string]: null | string }) => {
  return Object.entries(errorData).reduce((acc, curr) => {
    if (curr[1]) return `Error occured in ${curr[0]}`;
    return acc;
  }, '');
};

export const getContentTextWithMaximumLimit = (text: string, activeSocialMedia: string) => {
  return text.slice(
    0,
    activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER
      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM
      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN
      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE
      : text.length
    /* activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER
      : */
  );
};

export const comparyTwoArrays = (array1: (number | string)[], array2: (number | string)[]) => {
  return array1.length ? !array1.every((it) => array2.includes(it)) : false;
};

export const getNeedHelpURL = (type: INeedHelpUrlTypes, subNavPageName: string, showNotificationSettings: boolean) => {
  const needHelpUrl: string = `${type === 'csLocation' ? csLocationHelpUrl : type === 'contentSupplier' ? contentSupplierHelpUrl : HELP_URL}`;
  const selectedUrl = NEED_HELP_URL_OBJ[type][showNotificationSettings ? 'notifications' : subNavPageName];
  return selectedUrl || needHelpUrl;
};

export const numberPadding = (value: number) => {
  return `${value}`.length === 1 ? `0${value}` : `${value}`;
};

export const stringifyValidQueryParams = (queryParams: commonProps.ICommonObj<string>) => {
  const validQueryParams = Object.entries(queryParams).reduce((acc: commonApiTypes.ICommonResponseData, [key, value]) => {
    if (
      !Object.values(CommonValidQueryParams)
        .map((it) => it.toString())
        .includes(key)
    ) {
      acc = { ...acc, [key]: value };
    }
    return acc;
  }, {});
  return JSON.stringify(validQueryParams);
};

export const handleSubmitOnEnter = (e: React.KeyboardEvent<{}>, text: string | EditorState, submitFn: () => void) => {
  if (e.key === 'Enter' && !e.shiftKey && text) {
    submitFn();
    return 'my-editor-save';
  }
  return getDefaultKeyBinding(e) ?? '';
};
