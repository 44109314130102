import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { commonModalPopup, fbAdsApiTypes } from 'types';
import { InfiniteScrollTable } from 'components/Common/Table/InfiniteScrollTable';
import { ITableData } from 'types/common/common-props';
import { AVERAGE_LOCATIONS_SPEND_NAMES, FB_ADS_AVG_LOC_SPEND_TABLE_HEADER } from 'utils/constants';

export const AverageLocSpendTable = ({ isModalShow, handleModalClose, data }: commonModalPopup.IAverageLocSpendModalProps) => {
  const tableData: ITableData[] = useMemo(() => {
    const AvgLocSpendHeaderRow = ({ divClass, spanClass, value }: { divClass: string; spanClass: string; value: string | number }) => (
      <div className={divClass}>
        <span className={spanClass} title={value ? `$${value}` : undefined}>
          {`$${value}`}
        </span>
      </div>
    );

    const AvgLocSpendBodyRow = ({ value }: { value: string }) => (
      <span className="adtWrap" title={value || undefined}>
        <span className="adtValue">{value}</span>
      </span>
    );

    const isDecimalPlace = (value: number) => {
      const result = value - Math.floor(value) !== 0;
      if (result) return true;
      else return false;
    };

    const getFormattedValue = (value: number) => {
      return isDecimalPlace(value) ? value.toFixed(2) : `${value}` || '';
    };

    const getIndividualValue = (value: number) => {
      return value / 100;
    };

    const getTotalValue = (key: string) => {
      return data.reduce((acc, curr) => {
        if (curr[key]) acc += curr[key] / 100 || 0;
        return acc;
      }, 0);
    };

    const getValue = (key: string, obj?: fbAdsApiTypes.IAverageLocationsSpendObj, isTotals?: boolean) => {
      switch (key) {
        case AVERAGE_LOCATIONS_SPEND_NAMES.LOCATION_NAME:
          return isTotals ? (
            <div className="head-rcmnd-wraps">
              <span className="total-txt">
                Totals
                <span className="total-txt">{`(${data.length} Location${data.length > 1 ? 's' : ''})`}</span>
              </span>
            </div>
          ) : (
            <div className="initial-status-head-wraps adv-d-flex">
              <div className="head-rcmnd-wraps">
                <div className="state-indications-txt">
                  <div className="lb-empt-dtls">
                    <div className="leaderboard-tcb">
                      <div className="lb-loc-title username" title={obj?.account_name ? obj?.account_name : undefined}>
                        {obj?.account_name ? obj?.account_name : '---'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case AVERAGE_LOCATIONS_SPEND_NAMES.DAILY_SPEND:
          return isTotals ? (
            <AvgLocSpendHeaderRow
              divClass="rp-location-count-int-wraps lbt-item"
              spanClass="rp-location-count-item total-txt"
              value={getFormattedValue(getTotalValue(AVERAGE_LOCATIONS_SPEND_NAMES.DAILY_SPEND)) || 0}
            />
          ) : (
            <AvgLocSpendBodyRow value={obj?.lifetime_spend ? `$${getFormattedValue(getIndividualValue(obj?.daily_spend)) || ''}` : obj?.error ? obj?.error : ''} />
          );
        case AVERAGE_LOCATIONS_SPEND_NAMES.LIFETIME_SPEND:
          return isTotals ? (
            <AvgLocSpendHeaderRow divClass="lbt-item" spanClass="total-txt" value={getFormattedValue(getTotalValue(AVERAGE_LOCATIONS_SPEND_NAMES.LIFETIME_SPEND)) || 0} />
          ) : obj?.error ? null : (
            <AvgLocSpendBodyRow value={obj?.lifetime_spend ? `$${getFormattedValue(getIndividualValue(obj?.lifetime_spend)) || ''}` : ''} />
          );
        default:
          return 'Coming Soon!!';
      }
    };

    if (data?.length > 0) {
      const tableRows: ITableData[] = [];
      let totalsRow: ITableData = {};
      FB_ADS_AVG_LOC_SPEND_TABLE_HEADER.forEach((it) => {
        totalsRow = {
          ...totalsRow,
          [it.key]: {
            value: getValue(it.key, undefined, true),
            identifier: 'totals',
            className: 'lb-total'
          }
        };
      });
      tableRows.push(totalsRow);
      data?.forEach((locationRow, index) => {
        let row: ITableData = {};
        FB_ADS_AVG_LOC_SPEND_TABLE_HEADER.forEach((it) => {
          row = {
            ...row,
            [it.key]: {
              value: getValue(it.key, locationRow),
              identifier: index,
              className: `white-bg-tble${locationRow.error ? ` cb-error` : ''}`,
              dataObj: locationRow
            }
          };
        });
        tableRows.push(row);
      });
      return tableRows;
    }
    return [];
  }, [data]); // eslint-disable-line

  return (
    <Modal
      className="creator-tag-modal-wrp schedule-post-modal-hubuser schedule-post__main cbTable boost-asl"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      animation={false}
      scrollable
      backdropClassName="z-index__type--one basl"
      onHide={handleModalClose}
    >
      <div className="mod__close--icon" onClick={handleModalClose}>
        <ImageValidation isImgValid defaultImg={`mpcIcon`} customClassname="close-icon close mod__close--img wh-30" customName={'close'} />
      </div>
      <div className="cbtm">
        <h2>Average Location Spends</h2>
        <div id="scrollTargetModal" className="adv-leaderboardwrp">
          <InfiniteScrollTable headers={FB_ADS_AVG_LOC_SPEND_TABLE_HEADER} data={tableData} scrollTarget="scrollTargetModal" />
        </div>
        <div className="cbt-wrap" />
      </div>
    </Modal>
  );
};
