import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { useAccountSwitcherData } from '../use-account-switcher-data';
import { getFranAccessibleSocialMedia, getAccessibleSocialMediaList } from 'utils/helpers';

export const useActivePlatform = (isProfileAccess?: boolean) => {
  const { isAdminScreenEnabled } = useAccountSwitcherData();

  const franchisorId = useSelector((state: IStore) => state.franchisors.franchisorDetails?.id && state.franchisors.currentFranDetails?.id);
  const franchisorGMBFeature = useSelector((state: IStore) => state.franchisors.franchisorDetails?.gmb_feature || false);
  const accountGMBFeature = useSelector((state: IStore) => state.accounts.accountDetails?.account?.gmb_feature || false);
  const franchisorAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.access || null);
  const currentAccountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.access);

  const accountId = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.id);
  const accountSocialMedia = useSelector((state: IStore) => state.accounts?.accountDetails?.social_media || []);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const activePlatforms = useMemo(() => {
    return isAdminScreenEnabled
      ? []
      : franchisorId
      ? getFranAccessibleSocialMedia(franchisorGMBFeature, franchisorAccess)
      : accountId
      ? getAccessibleSocialMediaList(accountGMBFeature, accountSocialMedia, currentAccountDetails, accountDetails?.account_profiles[0].yelp_profile_url ? true : false, isProfileAccess)
      : [];
  }, [
    isAdminScreenEnabled,
    franchisorId,
    franchisorGMBFeature,
    accountGMBFeature,
    franchisorAccess,
    currentAccountDetails,
    accountId,
    accountSocialMedia,
    accountDetails?.account_profiles,
    isProfileAccess
  ]);

  return activePlatforms;
};
