import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import { NoDataFoundProps, MEDIA_PAGINATION_PER_PAGE, SCROLL_THRESHOLD } from 'utils/constants';
import { Loading, NoDataFound } from 'components';
import { MediaGalleryList } from './MediaGalleryList';
import { IStore, mgContainerTypes } from 'types';
import { MediaGalleryStats } from './MediaGalleryStats';

export const MediaGalleryInfinityScrollList = ({ handleMediaGalleryDataRequest }: mgContainerTypes.IMgInfinityScrollListProps) => {
  const currentPageCount = useSelector((state: IStore) => state.mediaGallery.currentPageCount);
  const allMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.allMediaCount);
  const isListFetching = useSelector((state: IStore) => state.mediaGallery.isListFetching);
  const assetsList = useSelector((state: IStore) => state.mediaGallery.assetsList);

  const totalPageCount = Math.ceil(allMediaCount / MEDIA_PAGINATION_PER_PAGE);

  const handleLoadMoreItems = () => {
    handleMediaGalleryDataRequest(currentPageCount + 1);
  };

  return (
    <div className="mainContent animate__animated animate__fadeIn mediaGallery mg__main mbl-head--margin">
      <MediaGalleryStats />
      {isListFetching && currentPageCount === 1 ? (
        <Loading />
      ) : assetsList.length ? (
        <InfiniteScroll
          scrollThreshold={SCROLL_THRESHOLD}
          next={handleLoadMoreItems}
          hasMore={totalPageCount >= currentPageCount}
          dataLength={assetsList.length}
          loader={isListFetching ? <Loading /> : null}
          className="local-ini"
          scrollableTarget="scrollTarget"
        >
          <MediaGalleryList />
        </InfiniteScroll>
      ) : !isListFetching && !assetsList.length ? (
        <NoDataFound size={NoDataFoundProps.FULL} />
      ) : (
        <Loading />
      )}
    </div>
  );
};
