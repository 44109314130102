import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'react-bootstrap';

import { IStore } from 'types';
import { onChangeList, operationListsResetModifiedObj, saveLocationGroupRequest } from 'actions';
import { Loading } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { useAccountSwitcherData } from 'utils/hooks';
import { FEED_TYPE, RIPPLE_COLOR } from 'utils/constants';
import { DefineListElements } from './DefineListElements';
import { DefineContentElements } from './DefineContentElements';
import { DefineHubsAndLocations } from './DefineHubsAndLocations';

export const CreateEditFeedList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();

  const isListDetailFetching = useSelector((state: IStore) => state.operationsList.isListDetailFetching);
  const contentFeedModifiedObj = useSelector((state: IStore) => state.operationsList.modifiedObj);
  const selectedId = useSelector((state: IStore) => state.operationsList.modifiedObj?.id);

  const currentStep = optionalParams?.[1];
  const isEditList = selectedId || +optionalParams?.[0];
  const isStandardList = contentFeedModifiedObj?.feed_type === FEED_TYPE.STANDARD;
  const isFinalStep = (optionalParams[1] && optionalParams[1] === 'step-3') || (optionalParams[1] === 'step-2' && isStandardList);
  const handleChange = (key: string, value: unknown) => {
    dispatch(onChangeList({ key, value }));
  };

  const handleNext = (isSave: boolean) => {
    if (isSave) {
      dispatch(
        saveLocationGroupRequest({
          id: contentFeedModifiedObj.id,
          reqPayload: {
            missing_accounts: contentFeedModifiedObj?.missing_accounts,
            included_accounts: contentFeedModifiedObj?.included_accounts,
            franchisor_id: contentFeedModifiedObj?.franchisor_id,
            name: contentFeedModifiedObj?.name,
            feed_type: contentFeedModifiedObj?.feed_type,
            content_supplier_franchisor_ids: contentFeedModifiedObj?.content_suppliers || [],
            content_type: contentFeedModifiedObj?.content_type || [],
            ...(contentFeedModifiedObj?.tags_list && contentFeedModifiedObj?.tags_list?.length
              ? { tags_list: Boolean(Array.isArray(contentFeedModifiedObj?.tags_list)) ? contentFeedModifiedObj?.tags_list.join(',') : contentFeedModifiedObj?.tags_list }
              : { tags_list: '' }),
            ...(contentFeedModifiedObj?.feed_type === FEED_TYPE.CONTENT_SUPPLIER && { content_source: contentFeedModifiedObj?.content_source })
          }
        })
      );
      navigate({ pathname: `/${userOwnership}/${id}/${currentRoute}` });
      dispatch(operationListsResetModifiedObj());
    } else {
      let step = '';
      const updatedOptionalParams = [...optionalParams].filter((param) => !param.includes('step-'));
      if (currentStep === 'step-1') {
        step = 'step-2';
      }
      if (currentStep === 'step-2') {
        step = 'step-3';
      }
      updatedOptionalParams.push(step);
      navigate(`/${userOwnership}/${id}/${currentRoute}/${updatedOptionalParams.join('/')}`);
    }
  };
  const handleBack = () => {
    let step = '';
    const updatedOptionalParams = [...optionalParams].filter((param) => !param.includes('step-'));

    if (currentStep === 'step-3') {
      step = 'step-2';
    }
    if (currentStep === 'step-2') {
      step = 'step-1';
    }
    updatedOptionalParams.push(step);
    navigate(`/${userOwnership}/${id}/${currentRoute}/${updatedOptionalParams.join('/')}`);
  };
  return (
    <Modal show className="nlfWraps">
      <div className="mod__close--icon">
        <ImageValidation
          isImgValid
          defaultImg="mpcIcon"
          customClassname="close-icon close mod__close--img wh-30"
          customName={'close'}
          onClick={() => navigate(`/${userOwnership}/${id}/${currentRoute}`)}
        />
      </div>
      <ModalBody>
        <div className="tml-main tml-createList mbl-head--margin tmLf-nl bsFeedList nlfGroup">
          <h2>{isEditList ? 'My Lists' : 'Create a New Lists'}</h2>
          <div className="nlfContent">
            <div className="nlfSteps">
              {isListDetailFetching ? (
                <Loading />
              ) : (
                <>
                  {currentStep === 'step-1' ? (
                    <div className="nlf-steps nlf-dle nlf-s1">
                      <h3 className="nsHead">
                        <span className="nlf-count-wrap">
                          <span className="nlf-count-id">1</span>
                        </span>
                        <span className="nlf-step">Define List Elements</span>
                      </h3>
                      <DefineListElements contentFeedModifiedObj={contentFeedModifiedObj} handleChange={handleChange} dispatch={dispatch} />
                    </div>
                  ) : currentStep === 'step-2' ? (
                    <div className={`nlf-steps nlf-dle nlf-s2 ${isStandardList ? 'nlf-dhl' : 'nlf-dce'}`}>
                      <h3 className="nsHead">
                        <span className="nlf-count-wrap">
                          <span className="nlf-count-id">2</span>
                        </span>
                        <span className="nlf-step"> {isStandardList ? 'Define Hubs and Locations' : 'Define Content Elements'}</span>
                      </h3>
                      {isStandardList ? (
                        <DefineHubsAndLocations handleChange={handleChange} />
                      ) : (
                        <DefineContentElements contentFeedModifiedObj={contentFeedModifiedObj} handleChange={handleChange} dispatch={dispatch} />
                      )}
                    </div>
                  ) : currentStep === 'step-3' ? (
                    <div className="nlf-steps nlf-dle nlf-s3 nlf-dhl">
                      <h3 className="nsHead">
                        <span className="nlf-count-wrap">
                          <span className="nlf-count-id">3</span>
                        </span>
                        <span className="nlf-step">Define Hubs and Locations</span>
                      </h3>
                      <DefineHubsAndLocations handleChange={handleChange} />
                    </div>
                  ) : null}
                  <div className="right-button-section nlfActions">
                    {optionalParams[1] !== 'step-1' && (
                      <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                        <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleBack}>
                          Back
                        </button>
                      </CustomRippleButton>
                    )}
                    <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                      <button
                        className={`ac-btn ac-primary ac-block kc-save ${!contentFeedModifiedObj?.name.trim() ? 'pointer-events-none' : ''}`}
                        type="submit"
                        onClick={() => handleNext(isFinalStep)}
                      >
                        {isFinalStep ? 'Save' : 'Next'}
                      </button>
                    </CustomRippleButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
