import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import { API, apiCall, defaultHeader, HttpMethods, NODE_API } from 'utils/helpers';
import {
  generateAIAutoReviewsFailure,
  generateAIAutoReviewsResponse,
  getAutoReplyAccountPermissionFailure,
  getAutoReplyAccountPermissionResponse,
  getReviewResponderFailure,
  getReviewResponderResponse,
  logoutRequest,
  updateReviewResponderFailure,
  updateReviewResponderResponse
} from 'actions';
import { commonApiTypes } from 'types';
import { ALERT_BOX_TYPES, API_TYPES, SETTINGS_SUCCESS_MSG, USER_OWNERSHIP } from 'utils/constants';
import { GENERATE_AI_AUTO_REVIEWS_REQUEST, GET_AI_REVIEW_RESPONDER_REQUEST, GET_AUTO_REPLY_ACCOUNT_PERMISSION_REQUEST, UPDATE_AI_REVIEW_RESPONDER_REQUEST } from 'actions/action-types';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendGetAIReviewResponderRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = payload?.userOwnership === USER_OWNERSHIP.FRANCHISOR ? API.getFranchisorReviewResponderConfiguration : API.getLocationReviewResponderConfiguration;
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);

    const response = yield apiCall({ headers, ...apiObj });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getReviewResponderResponse(response.data?.review_auto_reply_configuration));
      if (payload?.onSuccess) {
        payload.onSuccess();
      }
    } else {
      yield put(getReviewResponderFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, error);
    yield put(getReviewResponderFailure(error));
  }
}
function* sendUpdateAIReviewResponderRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = payload?.userOwnership === USER_OWNERSHIP.FRANCHISOR ? API.updateFranchisorReviewResponderConfiguration : API.updateLocationReviewResponderConfiguration;
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);
    apiObj.action = payload?.isPartialUpdate ? HttpMethods.PATCH : apiObj.action;
    const response = yield apiCall({ headers, data: payload?.payload, ...apiObj });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateReviewResponderResponse(response.data?.review_auto_reply_configuration));
      if (payload?.onSuccess) {
        payload.onSuccess();
      }
      if (!payload?.isPartialUpdate) alertBoxCall(ALERT_BOX_TYPES.SUCCESS, SETTINGS_SUCCESS_MSG);
    } else {
      yield put(updateReviewResponderFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, error);
    yield put(getReviewResponderFailure(error));
  }
}
function* sendGenerateAIAutoReviewsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();

    const data = { ...payload };
    delete data?.rating;
    const response = yield apiCall({ headers, data, ...NODE_API.generateAIAutoReviews, type: API_TYPES.NODE });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(generateAIAutoReviewsResponse({ rating: payload?.rating, responseText: response.data?.data?.choices?.[0]?.text, network: payload?.platform }));
    } else {
      yield put(generateAIAutoReviewsFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, error);
    yield put(getReviewResponderFailure(error));
  }
}

function* sendGetAutoReplyAccountPermissionRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = { ...API.getAutoReplyAccountPermissions };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload);

    const response = yield apiCall({ headers, ...apiObj });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAutoReplyAccountPermissionResponse(response.data));
    } else {
      yield put(getAutoReplyAccountPermissionFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, error);
    yield put(getReviewResponderFailure(error));
  }
}

export function* takeAIReviewResponderRequest() {
  yield takeEvery(GENERATE_AI_AUTO_REVIEWS_REQUEST, sendGenerateAIAutoReviewsRequest);
  yield takeLatest(GET_AI_REVIEW_RESPONDER_REQUEST, sendGetAIReviewResponderRequest);
  yield takeLatest(UPDATE_AI_REVIEW_RESPONDER_REQUEST, sendUpdateAIReviewResponderRequest);
  yield takeLatest(GET_AUTO_REPLY_ACCOUNT_PERMISSION_REQUEST, sendGetAutoReplyAccountPermissionRequest);
}
