import { Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { Loading } from 'components';
import { IStore, mgReducerTypes } from 'types';
import { getEndOfDate, getCurrentDate } from 'utils/helpers';
import { mgUpdateMediaExpiration, mgResetCustomVideoThumbnailResponse, mediaGalleryUpdateSettings, mgResetMediaRelease } from 'actions';
import {
  AddMediaExpirationValues,
  AP_ADD_TO_POST_TAB_NAMES,
  AP_AVAIL_MEDIA_FOR_USER,
  AP_MEDIA_EXPIRATION,
  COMMON_PAGE_TYPE,
  CreatorMediaTypeForUser,
  DATEPICKER_DATE_ONLY_FORMAT,
  FILE_SUPPORTED_FORMAT,
  ICommonDateTypes,
  MEDIA_CLONE_AVAILBILITY,
  MEDIA_FRAN_EDIT_OPTIONS,
  MEDIA_RELEASE_INFO_TEXT,
  MG_CUSTOMIZE_VIDEO_THUMBNAIL_OPTIONS,
  MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES,
  MG_MEDIA_RELEASE_OPTIONS,
  MG_MEDIA_RELEASE_VALUES,
  MG_MEDIA_TYPE,
  MgMediaType,
  RIPPLE_COLOR,
  TOOLTIP_PLACEMENT,
  USER_OWNERSHIP
} from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomDatePicker } from 'widgets/DatePicker';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomTooltip } from 'widgets/Tooltip';

export const MgAllAssetsUploadView = ({
  response,
  disableSaveButton,
  setMediaReleaseModalOpen,
  handleRemoveAssetsUpload,
  handleTabAction,
  handleMGSettings
}: {
  response: mgReducerTypes.IMGCloudinaryDataObj[];
  disableSaveButton: () => boolean;
  setMediaReleaseModalOpen: Dispatch<SetStateAction<boolean>>;
  handleRemoveAssetsUpload: (url: string | null) => void;
  handleTabAction: (data: string | null) => void;
  handleMGSettings: (key: string, value: string | boolean) => void;
}) => {
  const dispatch = useDispatch();

  const { userOwnership, optionalParams } = useAccountSwitcherData();

  const uploadProgressInfo = useSelector((state: IStore) => state.mediaGallery.uploadProgressInfo);
  const assetUsedCount = useSelector((state: IStore) => state.mediaGallery.mgDetails?.used_count || null);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const mediaReleaseSignatures = useSelector((state: IStore) => state.mediaGallery.mediaReleaseSignatures);
  // const customMediaReleaseFeature = useSelector(
  //   (state: IStore) => state.franchisors.franchisorDetails?.custom_media_release_feature || state.accounts?.accountDetails?.account?.features?.custom_media_release_feature || false
  // );
  const mediaGallerySettings = useSelector((state: IStore) => state.mediaGallery.mediaGallerySettings);
  const mediaExpiration = useSelector((state: IStore) => state.mediaGallery.mediaExpiration);
  const activeUserProfilePhotoUrl = useSelector((state: IStore) => state.login.activeUser?.profile_photo_url) || null;
  const accountsCount = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorObj?.accounts_count || 0);
  const { mgAvailMediaForUser, mgIsImageLockPost, have_media_release } = mediaGallerySettings;

  const availabilityTabName = optionalParams[2] === COMMON_PAGE_TYPE.CLONE ? COMMON_PAGE_TYPE.CLONE : userOwnership === USER_OWNERSHIP.FRANCHISOR ? COMMON_PAGE_TYPE.NON_CLONE : null;

  return (
    <>
      <div className="masonry-grid">
        {response.map((cloudResponse, index) =>
          !cloudResponse.cloudinaryId && !cloudResponse.s3_md5 ? (
            <div className="m-item mi-skeleton" key={`mg-all-assets-upload-${index}`}>
              <div className="m-ast">
                <div className="m-ast-itm m-ast-img">
                  <div className="mast-green">
                    <div className="mast-loader">
                      <Loading />
                    </div>
                    <div className="mast-skltn-cnt mg-upload-progress-bar">
                      <span className="msc-fileName">{cloudResponse.fileName}</span>
                      <span className="msc-count-percntge-load">
                        {uploadProgressInfo.percentage}% - {uploadProgressInfo.remainingTime} Sec. remaining
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-ast-dtls">
                <div className="mastg-main">
                  <div className="mast-prime-dtls">
                    <div className="mast-prof-pic" />
                    <div className="mast-prof-txt">
                      <span className="sm-txt-top" />
                      <span className="lg-txt-base">
                        <span className="mlg-label" />
                        <span className="mlg-count" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="m-item" key={`mg-all-assets-upload-${index}`}>
              <div className="m-ast">
                {cloudResponse.mediaType === MG_MEDIA_TYPE.PHOTO && !cloudResponse.fileName.includes(FILE_SUPPORTED_FORMAT[0]) && cloudResponse.secureUrl ? (
                  <div className="m-ast-itm m-ast-img">
                    <ImageValidation imgUrl={cloudResponse.secureUrl} customName="Media Gallery Asset" />
                  </div>
                ) : cloudResponse.thumbnail_url ? (
                  <div className="video-outer-element">
                    <ImageValidation imgUrl={cloudResponse.thumbnail_url} customName="Media Gallery Asset" />
                  </div>
                ) : cloudResponse.mediaType === MG_MEDIA_TYPE.VIDEO && cloudResponse.secureUrl ? (
                  <div className="video-outer-element">
                    <ReactVideoPlayer videoUrl={cloudResponse.secureUrl} customClassname="m-ast-itm m-ast-img" />
                  </div>
                ) : (
                  <div className="m-ast-itm m-ast-img">
                    <ImageValidation isImgValid defaultImg={'list_document_icon'} customClassname={'m-ast-itm m-ast-img'} customName={'Media Gallery Asset'} />
                  </div>
                )}
              </div>
              <div className="m-ast-dtls">
                <div className="mastg-main">
                  <div className="mast-prime-dtls">
                    <div className="mast-prof-pic">
                      <ImageValidation imgUrl={activeUserProfilePhotoUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customName="Profile Picture" />
                    </div>
                    <div className="mast-prof-txt">
                      <span className="sm-txt-top" title={cloudResponse.fileName}>
                        {cloudResponse.fileName}
                      </span>
                      {optionalParams[0] !== MgMediaType.DOCUMENTS ? (
                        <span className="lg-txt-base">
                          <span className="mlg-label">Uses</span>
                          <span className="mlg-count">{assetUsedCount || 0}</span>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {optionalParams[1] === COMMON_PAGE_TYPE.NEW && (
                    <div className="mast-controls mast-close">
                      {/* <div className="lb-btn lb-close"> */}
                      <div className="browse__card--close">
                        <ImageValidation isImgValid defaultImg={'xmark-red'} customName="Remove" onClick={() => handleRemoveAssetsUpload(cloudResponse.secureUrl)} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div className="mas-ai-fields">
        {availabilityTabName === COMMON_PAGE_TYPE.NON_CLONE && (!isContentSupplier || (isContentSupplier && accountsCount >= 1)) ? (
          <div className="filter-item mg-media-avaliability-wrp medExpAvail mas-avail mas-aif-item">
            <div className="avalible-title-text">Availability</div>
            <div className="form-group">
              {AP_AVAIL_MEDIA_FOR_USER.map((userItem, idx: number) => {
                return (
                  <label className={`${AP_AVAIL_MEDIA_FOR_USER.length - 1 === idx ? `r-ml0` : ``}${mgAvailMediaForUser === userItem.name ? ` active` : ``}`} key={`mg-media-avail-${idx}`}>
                    <input
                      type="radio"
                      className="option-input radio"
                      value={userItem.name}
                      checked={mgAvailMediaForUser === userItem.name}
                      onChange={(event) => handleMGSettings('mgAvailMediaForUser', event.target.value)}
                    />
                    <span className="labelText">{userItem.label}</span>
                  </label>
                );
              })}
            </div>
          </div>
        ) : availabilityTabName === COMMON_PAGE_TYPE.CLONE && (!isContentSupplier || (isContentSupplier && accountsCount >= 1)) ? (
          <div className="filter-item mg-media-avaliability-wrp medExpAvail mas-avail mas-aif-item">
            <div className="avalible-title-text">Availability</div>
            <div className={`form-group${mgAvailMediaForUser === CreatorMediaTypeForUser.EVERYONE ? ` events-none` : ''}`}>
              <label className={`r-ml30 ${mgAvailMediaForUser === CreatorMediaTypeForUser.EVERYONE ? 'checkbox-item active' : 'checkbox-item'}`}>
                <span className="checkbox-hover">
                  <input
                    type="checkbox"
                    className="option-input checkbox"
                    name={'clone'}
                    checked={mgAvailMediaForUser === CreatorMediaTypeForUser.EVERYONE}
                    value={MEDIA_CLONE_AVAILBILITY.name}
                    disabled
                  />
                </span>
                <span className="labelText">{MEDIA_CLONE_AVAILBILITY.label}</span>
              </label>
            </div>
          </div>
        ) : null}

        {userOwnership === USER_OWNERSHIP.FRANCHISOR &&
        optionalParams[1] === COMMON_PAGE_TYPE.NEW &&
        response.every((datum) => datum.mediaType === MG_MEDIA_TYPE.PHOTO && !datum.fileName.includes(FILE_SUPPORTED_FORMAT[0])) ? (
          <div className="filter-item mg-media-avaliability-wrp avail__img--edit mas-imgEdit mas-aif-item">
            <div className="avalible-title-text">Image Editing and Downloading</div>
            <div className="form-group">
              {MEDIA_FRAN_EDIT_OPTIONS.map((optionItem, idx: number) => {
                return (
                  <label className={`${MEDIA_FRAN_EDIT_OPTIONS.length - 1 === idx ? `` : ``}${mgIsImageLockPost === optionItem.name ? ` active` : ``}`} key={`mg-edit-options-${idx}`}>
                    <input type="radio" className="option-input radio" checked={mgIsImageLockPost === optionItem.name} onChange={() => handleMGSettings('mgIsImageLockPost', optionItem.name)} />
                    <span className="labelText">{optionItem.label}</span>
                  </label>
                );
              })}
            </div>
          </div>
        ) : null}
        <div className="filter-item mg-media-avaliability-wrp medExpDate mas-exp mas-aif-item">
          <div className={`avalible-title-text`}>Expiration</div>
          <div className="mExpMainRight">
            <div className="mExpTop">
              <div className="form-group">
                {AP_MEDIA_EXPIRATION.map((item, idx: number) => {
                  return (
                    <label className={`${AP_MEDIA_EXPIRATION.length - 1 === idx ? `r-ml0` : ``}${mgAvailMediaForUser === item.name ? ` active` : ``}`} key={`mg-media-avail-${idx}`}>
                      <input
                        type="radio"
                        className="option-input radio"
                        value={item.name}
                        checked={mediaExpiration.type === item.name}
                        onChange={(event) => dispatch(mgUpdateMediaExpiration({ type: event.target.value, date: null }))}
                      />
                      <span className="labelText">{item.label}</span>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="mExpBase">
              {mediaExpiration.type === AddMediaExpirationValues.EXPIRE ? (
                <div className="sc-cdtWrap">
                  <div className="wdt-item da-date">
                    <CustomDatePicker
                      selectedDate={mediaExpiration.date}
                      dateRangeType={ICommonDateTypes.TO_DATE}
                      onChange={(date) => dispatch(mgUpdateMediaExpiration({ ...mediaExpiration, date: getEndOfDate(date) }))}
                      customClassname={`fltr-date-item fltr-from-date`}
                      minDate={getCurrentDate()}
                      autoComplete="off"
                      placeholderText={'Select Date here'}
                      dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {response.every((mediaRes) => mediaRes.mediaType === MG_MEDIA_TYPE.VIDEO) && (
          <div className="filter-item mg-media-avaliability-wrp medExpDate mas-ct mas-aif-item">
            <div className={`avalible-title-text`}>Custom Thumbnail</div>
            <div className="mExpMainRight">
              <div className="mExpTop">
                <div className="form-group">
                  {MG_CUSTOMIZE_VIDEO_THUMBNAIL_OPTIONS.map((thumbnailOption, index) => (
                    <label
                      className={`${MG_CUSTOMIZE_VIDEO_THUMBNAIL_OPTIONS.length - 1 === index ? `r-ml0` : ``}${
                        mediaGallerySettings?.have_custom_thumbnail === thumbnailOption?.value ? ` active` : ``
                      }`}
                      key={`mg-media-avail-${index}`}
                    >
                      <input
                        type="radio"
                        className="option-input radio"
                        value={thumbnailOption.value}
                        checked={mediaGallerySettings?.have_custom_thumbnail === thumbnailOption?.value}
                        onChange={(event) => {
                          if (event.target.value === MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES.DEFAULT) {
                            dispatch(mgResetCustomVideoThumbnailResponse());
                          }
                          dispatch(mediaGalleryUpdateSettings({ have_custom_thumbnail: event.target.value }));
                        }}
                      />
                      <span className="labelText">{thumbnailOption.label}</span>
                    </label>
                  ))}
                  {mediaGallerySettings?.have_custom_thumbnail === MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES.CUSTOMIZE && (
                    <CustomRippleButton rippleClass={`ripple-unset ac-primary-box prime-blue`} custColor={RIPPLE_COLOR.secondary}>
                      <button className={`ac-btn ac-secondary size-md post__image--btn`} onClick={() => handleTabAction(AP_ADD_TO_POST_TAB_NAMES.THUMBNAIL)}>
                        Custom Thumbnail
                      </button>
                    </CustomRippleButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!response.some((mediaRes) => [MG_MEDIA_TYPE.RAW, MG_MEDIA_TYPE.DOCUMENT].includes(mediaRes.mediaType) || mediaRes?.fileName.split('.').pop() === 'pdf') ? (
          <div className="filter-item mg-media-avaliability-wrp medExpDate mas-mr mas-aif-item">
            <div className={`avalible-title-text`}>
              Media Release
              {/* {customMediaReleaseFeature && ( */}
              <CustomTooltip
                customPlacement={TOOLTIP_PLACEMENT.TOP}
                customClassname={'custom-tooltip-long-text'}
                customTooltipText={MEDIA_RELEASE_INFO_TEXT}
                customInput={() => (
                  <div className="info__tool--tip ms-2">
                    <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                  </div>
                )}
              />
              {/* )} */}
            </div>
            <div className="mExpMainRight">
              <div className="mExpTop">
                <div className="form-group">
                  {MG_MEDIA_RELEASE_OPTIONS.map((option, index) => {
                    const isChecked = have_media_release === option.value;
                    return (
                      <label className={`${isChecked ? ` active` : ``}`} key={`media-release-option-${index}`}>
                        <input
                          type="radio"
                          className="option-input radio"
                          value={option.value}
                          checked={isChecked}
                          onChange={(event) => {
                            handleMGSettings('have_media_release', event.target.value);
                            dispatch(mgResetMediaRelease());
                          }}
                          // disabled={isDisabled}
                        />
                        <span className="labelText">{option.label}</span>
                      </label>
                    );
                  })}
                </div>
                {!mediaReleaseSignatures?.length && mediaGallerySettings?.have_media_release === MG_MEDIA_RELEASE_VALUES.YES ? null : (
                  <Button
                    className={`ac-btn ac-primary${disableSaveButton() ? ` pointer-events-none` : ``}`}
                    variant="primary"
                    onClick={() => {
                      setMediaReleaseModalOpen(true);
                    }}
                  >
                    {mediaReleaseSignatures?.length && mediaGallerySettings?.have_media_release === MG_MEDIA_RELEASE_VALUES.YES ? 'Edit' : 'Add'} Media Release
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
