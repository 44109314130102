import { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { useFormik } from 'formik';

import { IStore, commonModalPopup, commonWidgetTypes, mgReducerTypes } from 'types';
import { addAIGeneratedImagesToCloudinary } from 'utils/helpers';
import { ALERT_BOX_TYPES, MG_MEDIA_RELEASE_VALUES, NO_SIGNATURE_WARN_MSG, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { mediaGalleryUpdateSettings, mgAddMediaReleaseRequest, mgDeleteMediaReleaseRequest, mgUpdateMediaCloudResponse } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';
import { Loading, alertBoxCall } from 'components';
import { TextArea } from 'widgets/Text';

export const MediaReleaseSignatureUploadModal = ({ isShowModal, onModalClose, cloudinaryRes }: commonModalPopup.ICommonModalProps & { cloudinaryRes: mgReducerTypes.IMGCloudinaryDataObj | null }) => {
  const dispatch = useDispatch();

  const { userOwnership } = useAccountSwitcherData();

  const signaturePadRef = useRef<commonWidgetTypes.IReactSignatureCanvasType | null>(null);

  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const addedAssetsCount = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.addedAssetsCount);
  const mediaReleaseSignatures = useSelector((state: IStore) => state.mediaGallery.mediaReleaseSignatures);
  const isCloudinaryUploading = useSelector((state: IStore) => state.mediaGallery.isCloudinaryUpload);
  const mediaReleaseText = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR
      ? state.franchisors.franchisorDetails?.media_release_text ?? state.franchisors.franchisorDetails?.ancestor_media_release_text ?? ''
      : state.accounts.accountDetails?.account.media_release_text ?? ''
  );

  const selectedMediaRelease = cloudinaryRes?.cloudinaryId ? mediaReleaseSignatures.find((signature) => signature?.cloudinaryId === cloudinaryRes?.cloudinaryId) : mediaReleaseSignatures[0];

  const getInitialValues = () => {
    const initialValues = {
      name: '',
      text: mediaReleaseText,
      signature_url: ''
    };
    if (cloudinaryRes?.cloudinaryId) {
      if (selectedMediaRelease) {
        initialValues.name = selectedMediaRelease?.name;
        initialValues.signature_url = selectedMediaRelease?.signature_url;
      }
    }
    return initialValues;
  };

  const handleUpdateMediaGalleryRes = (index: number, cloudinaryRes: mgReducerTypes.IMGCloudinaryDataObj) => {
    dispatch(
      mgUpdateMediaCloudResponse({
        index,
        response: { ...cloudinaryRes, have_mediaRelease: true }
      })
    );
  };

  const { values, handleChange, handleBlur, handleSubmit, resetForm, errors, setErrors } = useFormik({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: async () => {
      if (mediaReleaseSignatures?.length) {
        mediaReleaseSignatures
          .filter((mediaRelease) => !mediaRelease.id)
          .forEach(async (signature) => {
            await addAIGeneratedImagesToCloudinary(
              [{ id: String(Math.random() * 100), b64_json: signature.signature_url }],
              response,
              addedAssetsCount,
              false,
              'Media Release',
              dispatch,
              {
                cloudinaryId: cloudinaryRes?.cloudinaryId || null,
                name: values.name,
                text: mediaReleaseText,
                signature_url: signature.signature_url,
                fileName: signature.fileName
              },
              () => {
                if (cloudinaryRes?.cloudinaryId) {
                  const cloudinaryIndex = response.findIndex((cloudRes) => cloudRes.cloudinaryId === cloudinaryRes.cloudinaryId);
                  handleUpdateMediaGalleryRes(cloudinaryIndex, cloudinaryRes);
                } else {
                  response.forEach((cloudinaryRes, index) => {
                    handleUpdateMediaGalleryRes(index, cloudinaryRes);
                  });
                  dispatch(mediaGalleryUpdateSettings({ have_media_release: MG_MEDIA_RELEASE_VALUES.YES }));
                }
                onModalClose();
                clearSignature();
              }
            );
          });
      }
    }
  });

  const clearSignature = () => {
    signaturePadRef.current?.clear();
  };

  const getSignature = async () => {
    if (!values.name) {
      setErrors({ ...errors, name: 'name is required' });
    } else {
      const signatureUrl = signaturePadRef?.current?.getTrimmedCanvas().toDataURL();
      if (signaturePadRef?.current?.isEmpty()) {
        alertBoxCall(ALERT_BOX_TYPES.WARN, NO_SIGNATURE_WARN_MSG);
      } else if (signatureUrl) {
        const signaturePayload = {
          name: values.name,
          text: mediaReleaseText,
          signature_url: signatureUrl,
          fileName: `signature-${mediaReleaseSignatures.length + 1}`,
          id: '',
          cloudinaryId: cloudinaryRes?.cloudinaryId || null
        };
        dispatch(mgAddMediaReleaseRequest(mediaReleaseSignatures?.length ? [...mediaReleaseSignatures, signaturePayload] : [signaturePayload]));
        resetForm();
        clearSignature();
      }
    }
  };

  return (
    <Modal className="mediaReleaseWrap mrExtended" show={isShowModal} onHide={onModalClose}>
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={onModalClose} />
      </div>
      <Modal.Header>Media Release</Modal.Header>
      <Modal.Body className="border-1">
        <h2 className="mr-head">Slow down, celebrity! we want to show you off, but we need your permission</h2>
        <div className="mr-description">
          <p className="mrd-txt mrd-caps">
            <strong>PHOTO/VIDEO LICENSE AND RELEASE FORM</strong>
          </p>
          {/* <p className="mrd-txt mrd-caps">Lorem Ipsum is simply dummy text</p> */}
          <TextArea
            name={'emailInvitationMessage'}
            // rows={2}
            placeholder="No text entered"
            id="exampleForm.ControlTextarea1"
            className="form-control used mrd-txt"
            autoComplete="off"
            maxLength={300}
            value={mediaReleaseText}
            disabled
          />
        </div>

        <div className={`mr-sign-wrap ${!selectedMediaRelease?.signature_url ? 'mr-modal-highlighted' : ''}`}>
          <div className={`mr-sign-modal mr-before-sign ${selectedMediaRelease?.signature_url ? 'd-none' : ''}`}>
            <div className={`mr-sm-head`}>
              <h3>Signature</h3>
              {/* <button className={`trans-txt-btn`} onClick={() => setIsSignatureBoxEnabled(true)}>
                <span>Add Signature</span>
              </button> */}
            </div>
            <div className={`${!selectedMediaRelease?.signature_url ? '' : 'pointer-events-none'}`}>
              <div className={`form-group form-field-item stf-item${!selectedMediaRelease?.signature_url && errors.name ? ' erroritem' : ''}`}>
                <input type="text" name={'name'} autoComplete="off" value={values.name} onBlur={handleBlur} onChange={handleChange} />
                <span className="fltlabels">Print Name</span>
              </div>
              <div className="mr-modal-highlight">
                <SignatureCanvas penColor="black" canvasProps={{ className: 'sigCanvasplaceholder', width: 1150, height: 200 }} ref={signaturePadRef} />
              </div>
            </div>
          </div>

          {selectedMediaRelease?.signature_url ? (
            <div className="mr-after-sign">
              {[selectedMediaRelease].map((mediaRelease, index) => (
                <div className="mr-as-item" key={`mediaRelease-${index}`}>
                  <div className="mr-as-head">
                    <h3>{mediaRelease.name}</h3>
                    <ImageValidation isImgValid defaultImg="delete-red" customName="delete" onClick={() => dispatch(mgDeleteMediaReleaseRequest(mediaRelease.fileName ?? ''))} />
                  </div>
                  <div className="mr-sign-pic">
                    <ImageValidation imgUrl={mediaRelease.signature_url} isNotSvgFormat customClassname={'mrsign-item'} customName={'Media Release Sign'} />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className="mr-modal-higlight mrmbase">
          {!selectedMediaRelease?.signature_url ? (
            <button className={`trans-txt-btn`} onClick={clearSignature}>
              <span>Clear</span>
            </button>
          ) : null}

          <div className="mrm-right">
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary ac-block kc-save ${selectedMediaRelease?.signature_url || Object.keys(errors).length ? 'pointer-events-none' : ''}`}
                type="submit"
                onClick={getSignature}
              >
                <span>Add</span>
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary ac-block kc-save ${!selectedMediaRelease?.signature_url || mediaReleaseSignatures?.every((signature) => signature.id) ? 'pointer-events-none' : ''}`}
                type="submit"
                onClick={() => handleSubmit()}
              >
                <span>Done</span>
              </button>
            </CustomRippleButton>
          </div>
        </div>
        {isCloudinaryUploading && <Loading />}
      </Modal.Body>
    </Modal>
  );
};
