import { put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { commonApiTypes } from 'types';
import {
  accountGetProfileResponse,
  exceptionHandlerRequest,
  logoutRequest,
  socialContentsStatsResponse,
  socialContentsStatsFailure,
  socialContentsPieReloadResponse,
  socialContentsPieReloadFailure,
  socialContentsListResponse,
  socialContentsListFailure,
  socialContentsLikeResponse,
  socialContentsLikeFailure,
  socialContentsReplyResponse,
  socialContentsReplyFailure,
  socialContentsReuseResponse,
  socialContentsReuseFailure,
  socialContentsDeleteResponse,
  socialContentsDeleteFailure,
  inboxLikeResponse,
  deleteInboxResponse
} from 'actions';
import { ALERT_BOX_TYPES, DONE_SUCCESS_MSG, REVIEWS_ADD_REPLY_SUCCESS_MSG, SC_EXCEPTION_ERROR, SC_PIE_RELOAD_FAILED, SIDE_NAV_NAME } from 'utils/constants';
import { API, apiCall, defaultHeader, scMessages } from 'utils/helpers';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendSocialContentsStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params: payload, ...API.getSocialContentsStats });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(socialContentsStatsResponse({ stats_by_platform: response.data.stats_by_platform, total_count: response.data.total_count }));
    } else {
      yield put(socialContentsStatsFailure(scMessages(payload.pageType).SC_FETCHING_STATS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SC_EXCEPTION_ERROR));
  }
}

function* sendSocialContentsPieReloadRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      data: {
        force: payload.force
      },
      apiPath: API.socialContentsPieReload.apiPath.replace(':id', payload.id),
      action: API.socialContentsPieReload.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountGetProfileResponse(response.data));
      yield put(socialContentsPieReloadResponse(response));
    } else {
      yield put(socialContentsPieReloadFailure(SC_PIE_RELOAD_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SC_EXCEPTION_ERROR));
  }
}

function* sendSocialContentsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    delete payload.automaticallyRefreshActivityFeed;
    const response = yield apiCall({ headers, params: payload, ...API.getSocialContentsList });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(socialContentsListResponse(response.data));
    } else {
      yield put(socialContentsListFailure(scMessages(payload.pageType).SC_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SC_EXCEPTION_ERROR));
  }
}

function* sendSocialContentsLikeRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let response;
    if (payload.liked) {
      response = yield apiCall({
        headers,
        apiPath: API.socialContentsLike.apiPath.replace(':id', payload.socialContentId),
        action: API.socialContentsLike.action
      });
    } else {
      response = yield apiCall({
        headers,
        apiPath: API.socialContentsDisLike.apiPath.replace(':id', payload.socialContentId),
        action: API.socialContentsDisLike.action
      });
    }

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload.pageType === SIDE_NAV_NAME.COMMUNITY_INBOX) {
        yield put(inboxLikeResponse({ id: payload.socialContentId, liked: payload.liked }));
      } else {
        yield put(socialContentsLikeResponse({ id: payload.socialContentId, liked: payload.liked }));
      }
    } else {
      yield put(socialContentsLikeFailure(scMessages(payload.pageType).SC_LIKE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SC_EXCEPTION_ERROR));
  }
}

function* sendSocialContentsReuseRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      params: { account_id: payload.accountId },
      apiPath: API.socialContentsReuse.apiPath.replace(':id', payload.socialContentId),
      action: API.socialContentsReuse.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(socialContentsReuseResponse(response.data.saved_post_id));
    } else {
      yield put(socialContentsReuseFailure(scMessages(payload.pageType).SC_REUSE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SC_EXCEPTION_ERROR));
  }
}

function* sendSocialContentsReplyRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      data: { message: payload.message, dismissed: payload.dismissed },
      apiPath: API.socialContentsReply.apiPath.replace(':id', payload.socialContentId),
      action: API.socialContentsReply.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, REVIEWS_ADD_REPLY_SUCCESS_MSG);
      yield put(socialContentsReplyResponse({ socialContentId: payload.socialContentId, replied: true }));
    } else {
      yield put(socialContentsReplyFailure(scMessages(payload.pageType).SC_REPLY_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SC_EXCEPTION_ERROR));
  }
}

function* sendSocialContentsDeleteAssetRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      apiPath: API.deleteSocialContent.apiPath.replace(':id', payload.id),
      action: API.deleteSocialContent.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const responseData = { id: payload.id, platform: payload.platform };
      if (payload.pageType === SIDE_NAV_NAME.COMMUNITY_INBOX) {
        yield put(deleteInboxResponse({ ...responseData, message: DONE_SUCCESS_MSG }));
      } else {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DONE_SUCCESS_MSG);
        yield put(socialContentsDeleteResponse(responseData));
      }
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, scMessages(payload.pageType).SC_DELETE_ASSET_FAILED);
      yield put(socialContentsDeleteFailure(scMessages(payload.pageType).SC_DELETE_ASSET_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SC_EXCEPTION_ERROR));
  }
}

export function* takeSocialContentsRequest() {
  yield takeLatest(types.SOCIAL_CONTENTS_STATS_REQUEST, sendSocialContentsStatsRequest);
  yield takeLatest(types.SOCIAL_CONTENTS_PIE_RELOAD_REQUEST, sendSocialContentsPieReloadRequest);
  yield takeLatest(types.SOCIAL_CONTENTS_LIST_REQUEST, sendSocialContentsListRequest);
  yield takeLatest(types.SOCIAL_CONTENTS_LIKE_REQUEST, sendSocialContentsLikeRequest);
  yield takeLatest(types.SOCIAL_CONTENTS_REUSE_REQUEST, sendSocialContentsReuseRequest);
  yield takeLatest(types.SOCIAL_CONTENTS_REPLY_REQUEST, sendSocialContentsReplyRequest);
  yield takeLatest(types.SOCIAL_CONTENTS_DELETE_REQUEST, sendSocialContentsDeleteAssetRequest);
}
