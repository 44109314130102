import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { EditorState } from 'draft-js';

import { reviewsApiTypes, IStore, reviewsContainerTypes, aiContentCreatorContainerTypes, commonAccountSwitcher, aiReviewResponderReducerTypes } from 'types';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import {
  SocialContentActionType,
  TOOLTIP_PLACEMENT,
  REVIEWS_REPLY_APPROVE_TOOLTIP,
  REVIEWS_REPLY_REJECT_TOOLTIP,
  COMMON_SOCIAL_PROVIDER_TYPE,
  REVIEWS_ACTION_INIT_OBJ,
  IAiIconBgColorType,
  USER_OWNERSHIP,
  // SENTIMENTS,
  VALID_BRAND_CATEGORY_REGEX
  // AI_PHONE_NUMBER_VALIDATOR,
  // AI_EMAIL_VALIDATOR
} from 'utils/constants';
import {
  // aiModelReviewLearningPayload,
  convertEditorStateToString,
  convertStringToEditorState,
  createEmptyEditorState,
  enableReviewsReplyTab,
  getReviewsReplyTemplateDefaultText,
  // getValidTextFromRegex,
  handleSubmitOnEnter
} from 'utils/helpers';
import {
  getReviewsAddReplyToPostRequest,
  reviewsSendForReplyApprovalRequest,
  reviewsUpdateSubmittedReplyRequest,
  reviewsApproveReplyRequest,
  reviewsRejectReplyRequest,
  getAIReplyResponseRequest,
  resetAIContentCreator,
  setAISubscriptionDetailsModal
  // aiModelReviewsLearningRequest
} from 'actions';
import { ModalPopup } from 'components';
import { CustomTextEditor } from 'widgets/Text';
import { AiResponse } from '../AiResponseCreator';
import { useAIFeatureAccess, useAccountSwitcherData } from 'utils/hooks';
import { EnableManualSettingsReviewModal } from './EnableManualSettingsReviewModal';

export const ReviewsReplyItem = (props: reviewsContainerTypes.IReviewsReplyItemProp) => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();
  const { aiReviewReplyAccess } = useAIFeatureAccess();

  const { isSendForApprovalEnabled, replyTabIds, dataItem, reviewsActionObj, setReviewsActionObj, enableManualSettingsReviewResponse } = props;
  const activeUserProfilePhotoUrl = useSelector((state: IStore) => state.login.activeUser?.profile_photo_url || '');
  const reviewReplyTemplateObj: commonAccountSwitcher.IReviewReplyTemplateObj = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR
      ? {
          reviewReplyTemplate1Star: state.franchisors.franchisorDetails?.review_reply_template_1_star || '',
          reviewReplyTemplate2Star: state.franchisors.franchisorDetails?.review_reply_template_2_star || '',
          reviewReplyTemplate3Star: state.franchisors.franchisorDetails?.review_reply_template_3_star || '',
          reviewReplyTemplate4Star: state.franchisors.franchisorDetails?.review_reply_template_4_star || '',
          reviewReplyTemplate5Star: state.franchisors.franchisorDetails?.review_reply_template_5_star || '',
          reviewReplyTemplateFacebookRecommend: state.franchisors.franchisorDetails?.review_reply_template_facebook_recommend || '',
          reviewReplyTemplateFacebookNotRecommend: state.franchisors.franchisorDetails?.review_reply_template_facebook_not_recommend || ''
        }
      : {
          reviewReplyTemplate1Star: state.accounts.accountDetails?.account.review_reply_template?.review_reply_template_1_star || '',
          reviewReplyTemplate2Star: state.accounts.accountDetails?.account.review_reply_template?.review_reply_template_2_star || '',
          reviewReplyTemplate3Star: state.accounts.accountDetails?.account.review_reply_template?.review_reply_template_3_star || '',
          reviewReplyTemplate4Star: state.accounts.accountDetails?.account.review_reply_template?.review_reply_template_4_star || '',
          reviewReplyTemplate5Star: state.accounts.accountDetails?.account.review_reply_template?.review_reply_template_5_star || '',
          reviewReplyTemplateFacebookRecommend: state.accounts.accountDetails?.account.review_reply_template?.review_reply_template_facebook_recommend || '',
          reviewReplyTemplateFacebookNotRecommend: state.accounts.accountDetails?.account.review_reply_template?.review_reply_template_facebook_not_recommend || ''
        }
  );
  const isSendReplyApprovalFetching = useSelector((state: IStore) => state.reviews.isSendReplyApprovalFetching);
  const isEditReplyFetching = useSelector((state: IStore) => state.reviews.isEditReplyFetching);
  const isApproveReplyFetching = useSelector((state: IStore) => state.reviews.isApproveReplyFetching);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor || false);
  const overriddenReviewResponsesLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_review_responses_locked || false);
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const [selectedTextObj, setSelectedTextObj] = useState<aiContentCreatorContainerTypes.ISelectedTextObj>({ index: null, text: '' });
  const replyResponseSuggestion = useSelector((state: IStore) => state.aiContentCreator.replyResponseSuggestion);
  const isReplyResponseFetching = useSelector((state: IStore) => state.aiContentCreator.isReplyResponseFetching);
  const aiPlaybook = useSelector((state: IStore) => state.aiPlayBook.ai_playbook);
  const verticals = useSelector((state: IStore) => state.aiPlayBook.verticals);
  const aiReviewResponse = useSelector((state: IStore) => state.aiReviewResponder.reviewResponder);
  const rallioProfile = useSelector((state: IStore) => state.rallioProfile.rallioProfile);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account);

  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [isAISuggestionsOpen, setAiSuggestionsOpen] = useState(false);
  const [enableManualSettingsMessage, setEnableManualSettingsMessage] = useState(false);

  useEffect(() => {
    if (isAISuggestionsOpen && !enableManualSettingsMessage) {
      const userName = dataItem.user_name.split(' ')[0];
      const contactInfoBasedOnConfig =
        Boolean(
          dataItem.network === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_PLACES &&
            aiReviewResponse?.[`provide_contact_information_google_${dataItem?.rating}_star` as keyof aiReviewResponderReducerTypes.IReviewResponder]
        ) ||
        Boolean(
          dataItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK &&
            ((dataItem.recommended && aiReviewResponse?.provide_contact_information_facebook_recommended) ||
              (!dataItem.recommended && aiReviewResponse?.provide_contact_information_facebook_not_recommended))
        );
      // const getText = convertEditorStateToString(reviewsActionObj.replyText) || getReviewsReplyTemplateDefaultText(dataItem.network, dataItem.rating, dataItem.recommended, reviewReplyTemplateObj);
      // old - getValidTextFromRegex(getText, AI_PHONE_NUMBER_VALIDATOR);
      const phoneNumber =
        aiReviewResponse?.personalize_contact_information && contactInfoBasedOnConfig
          ? dataItem?.phone_number
          : contactInfoBasedOnConfig
          ? aiReviewResponse?.contact_information_phone || (userOwnership === USER_OWNERSHIP.ACCOUNT ? rallioProfile.phone_number : '')
          : '';
      // old - getValidTextFromRegex(getText, AI_EMAIL_VALIDATOR);
      const email =
        aiReviewResponse?.personalize_contact_information && contactInfoBasedOnConfig
          ? dataItem?.business_email
          : contactInfoBasedOnConfig
          ? aiReviewResponse?.contact_information_email || (userOwnership === USER_OWNERSHIP.ACCOUNT ? rallioProfile.business_email : '')
          : '';
      const isEmoji =
        Boolean(
          dataItem.network === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_PLACES && aiReviewResponse?.[`include_emojis_google_${dataItem?.rating}_star` as keyof aiReviewResponderReducerTypes.IReviewResponder]
        ) ||
        Boolean(
          dataItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK &&
            ((dataItem.recommended && aiReviewResponse?.include_emojis_facebook_recommended) || (!dataItem.recommended && aiReviewResponse?.include_emojis_facebook_not_recommended))
        ) ||
        false;
      dispatch(
        getAIReplyResponseRequest({
          text: dataItem.message || `${dataItem.rating} star`,
          type: 'reviewsReply',
          userName,
          brandName: dataItem?.brand_name || aiPlaybook.name,
          phoneNumber,
          email,
          id,
          userOwnership,
          isEmoji,
          /*   sentiment:
            dataItem.rating === 5 || dataItem.recommended
              ? `${SENTIMENTS.POSITIVE}`
              : dataItem.rating === 1 || dataItem.rating === 2 || !dataItem.recommended
              ? `${SENTIMENTS.NEGATIVE}`
              : `${SENTIMENTS.NEUTRAL}`, */
          ...(aiReviewResponse?.ai_playbook_confirmation && {
            customerAlias: aiPlaybook.questions.customer_alias || [],
            businessSearch: aiPlaybook.questions.business_search,
            brandCategory: verticals
              .find((it) => it.id === aiPlaybook.vertical_id)
              ?.value.replace(VALID_BRAND_CATEGORY_REGEX, '')
              .trim()
          }),
          ...(userOwnership === USER_OWNERSHIP.ACCOUNT && {
            preferred_language: accountDetails?.preferred_language || '',
            reply_in_original_language: aiReviewResponse?.reply_in_original_language || false
          })
        })
      );
      setSelectedTextObj({ index: null, text: '' });
    } else {
      dispatch(resetAIContentCreator());
    }
    // eslint-disable-next-line
  }, [isAISuggestionsOpen, dataItem, dispatch]);

  const handleSendReply = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    if (reviewsActionObj.replyText) {
      // dispatch(aiModelReviewsLearningRequest(aiModelReviewLearningPayload(reviewsObj.message, reviewsActionObj.replyText, franchisorId, dataItem.user_name, dataItem, 'reviewsReply', 10)));
      dispatch(getReviewsAddReplyToPostRequest({ postId: reviewsObj.id, customText: convertEditorStateToString(reviewsActionObj.replyText) }));
      setReviewsActionObj(REVIEWS_ACTION_INIT_OBJ);
    }
  };

  const handleSendForApproval = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    if (reviewsActionObj.replyText) {
      // dispatch(aiModelReviewsLearningRequest(aiModelReviewLearningPayload(reviewsObj.message, reviewsActionObj.replyText, franchisorId, dataItem.user_name, dataItem, 'reviewsReply', 10)));
      dispatch(reviewsSendForReplyApprovalRequest({ postId: reviewsObj.id, customText: convertEditorStateToString(reviewsActionObj.replyText) }));
      setReviewsActionObj(REVIEWS_ACTION_INIT_OBJ);
    }
  };

  const handleEditReply = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    if (reviewsObj.reply) {
      setReviewsActionObj({
        ...REVIEWS_ACTION_INIT_OBJ,
        id: reviewsObj.id,
        isEditReply: true,
        replyText: EditorState.moveFocusToEnd(convertStringToEditorState(reviewsObj.reply))
      });
    }
  };

  const handleSaveReply = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    if (reviewsActionObj.replyText) {
      dispatch(reviewsUpdateSubmittedReplyRequest({ postId: reviewsObj.id, customText: convertEditorStateToString(reviewsActionObj.replyText) }));
      setReviewsActionObj({
        ...REVIEWS_ACTION_INIT_OBJ,
        id: reviewsObj.id,
        replyText: reviewsActionObj.replyText
      });
    }
  };

  const handleApproveReply = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    if (reviewsObj.reply) {
      // dispatch(aiModelReviewsLearningRequest(aiModelReviewLearningPayload(reviewsObj.message, reviewsActionObj.replyText, franchisorId, dataItem.user_name, dataItem, 'reviewsReply', 10)));
      dispatch(reviewsApproveReplyRequest({ postId: reviewsObj.id, customText: reviewsObj.reply }));
      setReviewsActionObj(REVIEWS_ACTION_INIT_OBJ);
    }
  };

  const handletoggleFeedbackModal = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    setOpenFeedbackModal(!openFeedbackModal);
    setReviewsActionObj({
      ...REVIEWS_ACTION_INIT_OBJ,
      id: reviewsObj.id,
      replyText: EditorState.moveFocusToEnd(convertStringToEditorState(reviewsObj.reply || ''))
    });
  };

  const handleRejectPost = (reviewsObj: reviewsApiTypes.IReviewsListObj) => {
    if (reviewsActionObj.feedbackText) {
      // dispatch(aiModelReviewsLearningRequest(aiModelReviewLearningPayload(reviewsObj.message, reviewsActionObj.replyText, franchisorId, dataItem.user_name, dataItem, 'reviewsReply', 10)));
      dispatch(reviewsRejectReplyRequest({ postId: reviewsObj.id, customText: convertEditorStateToString(reviewsActionObj.feedbackText) }));
      setOpenFeedbackModal(false);
      setReviewsActionObj(REVIEWS_ACTION_INIT_OBJ);
    }
  };

  const isReplyTabEnabled = enableReviewsReplyTab(isAdmin, isFranchisor, isFranchisee, overriddenReviewResponsesLocked, dataItem);

  const handleCancel = () => {
    setSelectedTextObj({ index: null, text: '' });
    setReviewsActionObj((prevState) => ({ ...prevState, id: dataItem.id, replyText: REVIEWS_ACTION_INIT_OBJ.replyText }));
    setAiSuggestionsOpen(false);
  };

  const handleSuccess = () => {
    setAiSuggestionsOpen(false);
  };

  const handleUse = (state: aiContentCreatorContainerTypes.ISelectedTextObj) => {
    const replyText = state.text ? convertStringToEditorState(state.text) : REVIEWS_ACTION_INIT_OBJ.replyText;
    setReviewsActionObj((prevState) => ({ ...prevState, id: dataItem.id, replyText }));
    setSelectedTextObj(state);
  };

  return (
    <>
      {isReplyTabEnabled ? (
        isSendForApprovalEnabled && replyTabIds.includes(dataItem.id) && dataItem.reply_status === 'pending' && dataItem.reply ? (
          <div className="lv-comment">
            <div className="lvc-top flex-column-reverse">
              <CustomTextEditor
                emojiKey={`reviews-req-reply-approval-${dataItem.id}`}
                className="form-control w-100 reputation__reply--txtarea g-se"
                placeholder="Write a reply..."
                editorState={reviewsActionObj.id === dataItem.id ? reviewsActionObj.replyText : convertStringToEditorState(dataItem.reply || '')}
                keyBindingFn={(e: React.KeyboardEvent<{}>) =>
                  handleSubmitOnEnter(e, reviewsActionObj.replyText, () => (reviewsActionObj.isEditReply ? handleSaveReply(dataItem) : handleEditReply(dataItem)))
                }
                onEditorChange={(e) => {
                  if (reviewsActionObj.id === dataItem.id) {
                    setReviewsActionObj((prevState) => ({ ...prevState, id: dataItem.id, replyText: e }));
                  }
                }}
                isShowAiText={false}
                isShowAiButton={Boolean(isRallioai && aiReviewReplyAccess)}
                coachMarkPosition="left"
                isCoachMark={props.isCoachMark}
                coachMarkType="review"
                handleAiClick={() => {
                  if (isAISubscribed) {
                    if (!enableManualSettingsReviewResponse) setEnableManualSettingsMessage(true);
                    else setAiSuggestionsOpen(!isAISuggestionsOpen);
                  } else {
                    dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: true }));
                  }
                }}
                disableEditor={reviewsActionObj.id === dataItem.id ? !reviewsActionObj.isEditReply : true}
                isMentionEnabled={false}
                isEmoji={reviewsActionObj.id === dataItem.id ? reviewsActionObj.isEditReply : false}
                aiIconBgColorType={IAiIconBgColorType.GREY}
              />
              <Button
                className={'txt-btn ml-auto lv-prime edit-save-btn'}
                disabled={isEditReplyFetching}
                onClick={() => (reviewsActionObj.isEditReply ? handleSaveReply(dataItem) : handleEditReply(dataItem))}
                variant="link"
              >
                {reviewsActionObj.id === dataItem.id && reviewsActionObj.isEditReply ? SocialContentActionType.SAVE : SocialContentActionType.EDIT}
              </Button>
            </div>
            {isAISuggestionsOpen && (
              <AiResponse
                selectedTextObj={selectedTextObj}
                handleCancel={handleCancel}
                handleSuccess={handleSuccess}
                data={replyResponseSuggestion}
                isFetching={isReplyResponseFetching}
                // isSuccessEnable={selectedTextObj.index !== null}
                handleUse={handleUse}
              />
            )}
            <div className="lvc-base">
              <CustomTooltip
                customPlacement={TOOLTIP_PLACEMENT.TOP}
                customClassname="custom-tooltip-long-text"
                customTooltipText={REVIEWS_REPLY_APPROVE_TOOLTIP}
                customInput={() => (
                  <Button
                    className={'txt-btn lv-prime'}
                    disabled={reviewsActionObj.isEditReply || isEditReplyFetching || isApproveReplyFetching}
                    onClick={() => handleApproveReply(dataItem)}
                    variant="link"
                  >
                    {SocialContentActionType.APPROVE}
                  </Button>
                )}
              />
              <CustomTooltip
                customPlacement={TOOLTIP_PLACEMENT.TOP}
                customClassname="custom-tooltip-long-text"
                customTooltipText={REVIEWS_REPLY_REJECT_TOOLTIP}
                customInput={() => (
                  <Button
                    className="txt-btn lv-prime"
                    disabled={reviewsActionObj.isEditReply || isEditReplyFetching || isApproveReplyFetching}
                    onClick={() => handletoggleFeedbackModal(dataItem)}
                    variant="link"
                  >
                    {SocialContentActionType.REJECT}
                  </Button>
                )}
              />
            </div>
          </div>
        ) : (
          replyTabIds.includes(dataItem.id) &&
          (dataItem.network === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK || (dataItem.network === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_PLACES && !dataItem.comments.length)) && (
            <div className="lv-comment">
              <div className="lvc-top">
                <div className="round-asset brand-icon">
                  <ImageValidation imgUrl={activeUserProfilePhotoUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={''} customName={'Reviews Asset Item'} />
                </div>
                <CustomTextEditor
                  emojiKey={`reviews-reply-${dataItem.id}`}
                  className="form-control g-se"
                  placeholder="Write a reply..."
                  editorState={reviewsActionObj.id === dataItem.id ? reviewsActionObj.replyText : createEmptyEditorState()}
                  keyBindingFn={(e: React.KeyboardEvent<{}>) =>
                    handleSubmitOnEnter(e, reviewsActionObj.replyText, () => (isSendForApprovalEnabled ? handleSendForApproval(dataItem) : handleSendReply(dataItem)))
                  }
                  onEditorChange={(e) => {
                    if (reviewsActionObj.id === dataItem.id) {
                      setReviewsActionObj((prevState) => ({ ...prevState, id: dataItem.id, replyText: e }));
                    }
                  }}
                  isShowAiText={false}
                  isShowAiButton={Boolean(isRallioai && aiReviewReplyAccess)}
                  coachMarkPosition="left"
                  isCoachMark={props.isCoachMark}
                  coachMarkType="review"
                  handleAiClick={() => {
                    if (isAISubscribed) {
                      if (!enableManualSettingsReviewResponse) setEnableManualSettingsMessage(true);
                      else setAiSuggestionsOpen(!isAISuggestionsOpen);
                    } else {
                      dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: true }));
                    }
                  }}
                  isMentionEnabled={false}
                  onEditorClick={() => {
                    if (reviewsActionObj.id !== dataItem.id) {
                      setReviewsActionObj({
                        ...REVIEWS_ACTION_INIT_OBJ,
                        id: dataItem.id,
                        replyText: EditorState.moveFocusToEnd(
                          convertStringToEditorState(getReviewsReplyTemplateDefaultText(dataItem.network, dataItem.rating, dataItem.recommended, reviewReplyTemplateObj))
                        )
                      });
                    }
                  }}
                  aiIconBgColorType={IAiIconBgColorType.GREY}
                />
              </div>
              {isAISuggestionsOpen && (
                <AiResponse
                  selectedTextObj={selectedTextObj}
                  handleCancel={handleCancel}
                  handleSuccess={handleSuccess}
                  data={replyResponseSuggestion}
                  isFetching={isReplyResponseFetching}
                  // isSuccessEnable={selectedTextObj.index !== null}
                  handleUse={handleUse}
                />
              )}
              <div className="lvc-base">
                {isSendForApprovalEnabled ? (
                  <Button
                    className={`txt-btn lv-prime${reviewsActionObj.id === dataItem.id ? `` : ` d-none`}`}
                    disabled={!reviewsActionObj.replyText.getCurrentContent().hasText() || isSendReplyApprovalFetching}
                    onClick={() => handleSendForApproval(dataItem)}
                    variant="link"
                  >
                    {SocialContentActionType.SEND_FOR_APPROVAL}
                  </Button>
                ) : null}
                <Button
                  className={`txt-btn lv-prime${reviewsActionObj.id === dataItem.id ? `` : ` d-none`}`}
                  disabled={!reviewsActionObj.replyText.getCurrentContent().hasText()}
                  onClick={() => handleSendReply(dataItem)}
                  variant="link"
                >
                  {SocialContentActionType.POST_REPLY}
                </Button>
              </div>
            </div>
          )
        )
      ) : null}
      <ModalPopup
        isModalShow={openFeedbackModal}
        modalSize="lg"
        containerClassName={'modal-confirmation confirm-delete reputation__review-reject--modal'}
        modalBody={() => (
          <div className="feedback-txtarea">
            <div className="text-left modal-title">Feedback</div>
            <div className="review-reject-input">
              <CustomTextEditor
                emojiKey={`reviews-req-reply-reject-${reviewsActionObj.id}`}
                className="form-control g-se"
                placeholder="e.g. Please correct the spelling and then resubmit this comment for approval."
                editorState={reviewsActionObj.feedbackText}
                keyBindingFn={(e: React.KeyboardEvent<{}>) =>
                  handleSubmitOnEnter(e, reviewsActionObj.feedbackText, () => setReviewsActionObj((prevState) => ({ ...prevState, feedbackText: createEmptyEditorState() })))
                }
                onEditorChange={(e) => {
                  if (reviewsActionObj.id === dataItem.id) setReviewsActionObj((prevState) => ({ ...prevState, id: dataItem.id, feedbackText: e }));
                }}
                isMentionEnabled={false}
                isEmoji={false}
              />
              <div className={`resetIcon${!reviewsActionObj.feedbackText.getCurrentContent().hasText() ? ` pointer-events-none` : ''}`}>
                <ImageValidation
                  defaultImg={'reset'}
                  customClassname={'refClear'}
                  customName={'Reset text'}
                  onClick={() => setReviewsActionObj((prevState) => ({ ...prevState, feedbackText: createEmptyEditorState() }))}
                />
              </div>
            </div>
          </div>
        )}
        modalCloseButton
        handleModalClose={() => handletoggleFeedbackModal(dataItem)}
        isModalFooterShow
        modalFooter={() => (
          <div className="modal-btn-grp-wraps">
            <button className="modal-btn-action-itm modal-cancel-btn" onClick={() => handletoggleFeedbackModal(dataItem)}>
              Cancel
            </button>
            <button
              className={`modal-btn-action-itm modal-delete-btn${!reviewsActionObj.feedbackText.getCurrentContent().hasText() ? ` pointer-events-none` : ''}`}
              onClick={() => handleRejectPost(dataItem)}
            >
              Reject
            </button>
          </div>
        )}
      />
      <EnableManualSettingsReviewModal isShowModal={enableManualSettingsMessage} onModalClose={() => setEnableManualSettingsMessage(false)} />
    </>
  );
};
