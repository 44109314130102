import { FEED_TYPE } from '../common';

export const CONTENT_SUPPLIER_CONTENT_SOURCE_LABELS = {
  ALL: 'All',
  BRAND: 'Brand',
  CONTENT_SUPPLIER: 'Content Supplier',
  NONE: 'None'
};

export const CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER = {
  ALL: 'all',
  CONTENT_SUPPLIER: 'content_suppliers',
  BRAND: 'brand'
};
export const CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES = {
  ALL: 0,
  CONTENT_SUPPLIER: 1,
  BRAND: 2
};

export const CONTENT_SUPPLIER_FEED_TYPE_LABELS = {
  NONE: 'None',
  ALL: 'All',
  SPECIFIC_CONTENT_SOURCE: 'Specific Content Supplier',
  MANUAL_SYNDICATION: 'Standard',
  AUTOMATIC_SCHEDULE: 'Automated Content Scheduler',
  BRAND: 'My Brand'
};

export const CONTENT_SUPPLIER_CONTENT_TYPE_LABELS = {
  ALL: 'All',
  POSTS: 'Posts',
  STORIES: 'Stories'
};

export enum ContentType {
  ALL = 0,
  POSTS = 1,
  STORIES = 2
}

export enum ContentTypeIdentifier {
  ALL = 'all',
  POSTS = 'post',
  STORIES = 'story'
}

export const CONTENT_SUPPLIER_FEED_TYPE_IDENTIFIER = {
  MANUAL_SYNDICATION: 'manual_syndication',
  AUTOMATIC_SCHEDULE: 'automatic_schedule'
};

export const CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS = [
  {
    id: 1,
    label: CONTENT_SUPPLIER_CONTENT_SOURCE_LABELS.NONE,
    value: FEED_TYPE.STANDARD,
    identifier: CONTENT_SUPPLIER_FEED_TYPE_IDENTIFIER.MANUAL_SYNDICATION
  },
  {
    id: 2,
    label: CONTENT_SUPPLIER_FEED_TYPE_LABELS.ALL,
    value: FEED_TYPE.CONTENT_SUPPLIER,
    identifier: CONTENT_SUPPLIER_FEED_TYPE_IDENTIFIER.AUTOMATIC_SCHEDULE,
    content_source: CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.ALL,
    content_source_identifier: CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER.ALL
  },
  {
    id: 3,
    label: CONTENT_SUPPLIER_FEED_TYPE_LABELS.BRAND,
    value: FEED_TYPE.CONTENT_SUPPLIER,
    identifier: CONTENT_SUPPLIER_FEED_TYPE_IDENTIFIER.AUTOMATIC_SCHEDULE,
    content_source: CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.BRAND,
    content_source_identifier: CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER.BRAND
  },
  {
    id: 4,
    label: CONTENT_SUPPLIER_FEED_TYPE_LABELS.SPECIFIC_CONTENT_SOURCE,
    value: FEED_TYPE.CONTENT_SUPPLIER,
    identifier: CONTENT_SUPPLIER_FEED_TYPE_IDENTIFIER.AUTOMATIC_SCHEDULE,
    content_source: CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.CONTENT_SUPPLIER,
    content_source_identifier: CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER.CONTENT_SUPPLIER
  }
];

export const CONTENT_SUPPLIER_CONTENT_TYPE_OPTIONS = [
  {
    id: 1,
    label: CONTENT_SUPPLIER_CONTENT_TYPE_LABELS.ALL,
    value: ContentType.ALL,
    identifier: ContentTypeIdentifier.ALL
  },
  {
    id: 2,
    label: CONTENT_SUPPLIER_CONTENT_TYPE_LABELS.POSTS,
    value: ContentType.POSTS,
    identifier: ContentTypeIdentifier.POSTS
  },
  {
    id: 3,
    label: CONTENT_SUPPLIER_CONTENT_TYPE_LABELS.STORIES,
    value: ContentType.STORIES,
    identifier: ContentTypeIdentifier.STORIES
  }
];

export const CAMPAIGN_WEEKLY_SCHEDULE_FROM = {
  SCHEDULER: 'scheduler',
  CAMPAIGN: 'campaign'
};

export const FEED_FILTER_TYPE = [
  { value: 'all', label: 'All' },
  { value: 'standard', label: CONTENT_SUPPLIER_FEED_TYPE_LABELS.MANUAL_SYNDICATION },
  { value: 'content_supplier', label: CONTENT_SUPPLIER_FEED_TYPE_LABELS.AUTOMATIC_SCHEDULE }
];

export const CONTENT_FEED_INIT_FILTER_DATA = {
  feed_type: 'all'
};

export const FEED_LIST_LOCATION_SELECT_LABELS = {
  NONE: 'None',
  LOCATION_LIST: 'Location List'
};
export const FEED_LIST_LOCATION_SELECT_VALUES = {
  NONE: 'none',
  LOCATION_LIST: 'location_list'
};

export const FEED_LIST_LOCATION_SELECT_OPTIONS = [
  { label: FEED_LIST_LOCATION_SELECT_LABELS.NONE, value: FEED_LIST_LOCATION_SELECT_VALUES.NONE },
  { label: FEED_LIST_LOCATION_SELECT_LABELS.LOCATION_LIST, value: FEED_LIST_LOCATION_SELECT_VALUES.LOCATION_LIST }
];

export const INITIAL_ELIGIBLE_CONTENT_COUNT = {
  eligible_brand_contents_count: 0,
  eligible_brand_post_contents_count: 0,
  eligible_brand_story_contents_count: 0,
  eligible_content_supplier_contents_count: 0,
  eligible_content_supplier_post_contents_count: 0,
  eligible_content_supplier_story_contents_count: 0,
  eligible_total_contents_count: 0,
  eligible_total_post_contents_count: 0,
  eligible_total_story_contents_count: 0
};
