import { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

import { mgDeleteMediaReleaseRequest, mgRemoveCustomVideoThumbnailResponse, mgUpdateMediaCloudResponse } from 'actions';
import { Loading } from 'components';
import { IStore, mgReducerTypes } from 'types';
import {
  USER_OWNERSHIP,
  MG_MEDIA_TYPE,
  AP_AVAIL_MEDIA_FOR_USER,
  COMMON_PAGE_TYPE,
  FILE_SUPPORTED_FORMAT,
  MEDIA_FRAN_EDIT_OPTIONS,
  AP_MEDIA_EXPIRATION,
  AddMediaExpirationValues,
  ICommonDateTypes,
  DATEPICKER_DATE_ONLY_FORMAT,
  MG_CUSTOMIZE_VIDEO_THUMBNAIL_OPTIONS,
  MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES,
  RIPPLE_COLOR,
  AP_ADD_TO_POST_TAB_NAMES,
  MG_MEDIA_RELEASE_OPTIONS,
  MG_MEDIA_RELEASE_VALUES,
  MgMediaType,
  TOOLTIP_PLACEMENT,
  MEDIA_RELEASE_INFO_TEXT
} from 'utils/constants';
import { getEndOfDate, getCurrentDate } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomDatePicker } from 'widgets/DatePicker';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';

export const MgIndividualAssetsUploadView = ({
  response,
  setSelectedCloudinaryRes,
  disableSaveButton,
  setMediaReleaseModalOpen,
  handleRemoveAssetsUpload,
  handleTabAction
}: {
  response: mgReducerTypes.IMGCloudinaryDataObj[];
  setSelectedCloudinaryRes: Dispatch<SetStateAction<mgReducerTypes.IMGCloudinaryDataObj | null>>;
  disableSaveButton: () => boolean;
  setMediaReleaseModalOpen: Dispatch<SetStateAction<boolean>>;
  handleRemoveAssetsUpload: (url: string | null) => void;
  handleTabAction: (data: string | null) => void;
}) => {
  const dispatch = useDispatch();

  const { userOwnership, optionalParams } = useAccountSwitcherData();

  const uploadProgressInfo = useSelector((state: IStore) => state.mediaGallery.uploadProgressInfo);
  const assetUsedCount = useSelector((state: IStore) => state.mediaGallery.mgDetails?.used_count || null);
  // const customMediaReleaseFeature = useSelector(
  //   (state: IStore) => state.franchisors.franchisorDetails?.custom_media_release_feature || state.accounts?.accountDetails?.account?.features?.custom_media_release_feature || false
  // );
  const activeUserProfilePhotoUrl = useSelector((state: IStore) => state.login.activeUser?.profile_photo_url) || null;
  const mediaReleaseSignatures = useSelector((state: IStore) => state.mediaGallery.mediaReleaseSignatures);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier ?? false);
  const accountsCount = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorObj?.accounts_count || 0);

  const isImageEditingEnabled = Boolean(
    userOwnership === USER_OWNERSHIP.FRANCHISOR &&
      optionalParams[1] === COMMON_PAGE_TYPE.NEW &&
      response.every((datum) => datum.mediaType === MG_MEDIA_TYPE.PHOTO && !datum.fileName.includes(FILE_SUPPORTED_FORMAT[0]))
  );
  // }, [response, optionalParams, userOwnership]);
  const removeVideoThumbnail = (videoCloudinaryId: string) => {
    dispatch(mgRemoveCustomVideoThumbnailResponse(videoCloudinaryId));
  };
  return (
    <div className={`msrTable ${response.every((mediaRes) => mediaRes.mediaType === MG_MEDIA_TYPE.VIDEO) ? 'msr-videoactive' : 'msr-imgactive'}`}>
      <Table>
        <Thead>
          <Tr>
            <Th>Media</Th>
            {userOwnership === USER_OWNERSHIP.FRANCHISOR ? <Th>Availability</Th> : null}
            {isImageEditingEnabled ? <Th>Image Editing and Downloading</Th> : null}
            <Th>Expiration</Th>
            {response.every((mediaRes) => mediaRes.mediaType === MG_MEDIA_TYPE.VIDEO) ? <Th>Custom Video Thumbnail</Th> : null}
            {!response.some((mediaRes) => [MG_MEDIA_TYPE.RAW, MG_MEDIA_TYPE.DOCUMENT].includes(mediaRes.mediaType) || mediaRes?.fileName.split('.').pop() === 'pdf') ? (
              <Th className="d-flex align-items-center">
                Media Release
                <CustomTooltip
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customClassname={'custom-tooltip-long-text'}
                  customTooltipText={MEDIA_RELEASE_INFO_TEXT}
                  customInput={() => (
                    <div className="info__tool--tip ms-2">
                      <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                    </div>
                  )}
                />
              </Th>
            ) : null}
          </Tr>
        </Thead>
        <Tbody>
          {response.map((cloudResponse, cloudResIndex) => (
            <Tr key={`cloudinary-individula-${cloudResIndex}`}>
              <Td>
                <div className="masonry-grid">
                  {!cloudResponse.cloudinaryId && !cloudResponse.s3_md5 ? (
                    <div className="m-item mi-skeleton">
                      <div className="m-ast">
                        <div className="m-ast-itm m-ast-img">
                          <div className="mast-green">
                            <div className="mast-loader">
                              <Loading />
                            </div>
                            <div className="mast-skltn-cnt mg-upload-progress-bar">
                              <span className="msc-fileName">{cloudResponse.fileName}</span>
                              <span className="msc-count-percntge-load">
                                {uploadProgressInfo.percentage}% - {uploadProgressInfo.remainingTime} Sec. remaining
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="m-ast-dtls">
                        <div className="mastg-main">
                          <div className="mast-prime-dtls">
                            <div className="mast-prof-pic" />
                            <div className="mast-prof-txt">
                              <span className="sm-txt-top" />
                              <span className="lg-txt-base">
                                <span className="mlg-label" />
                                <span className="mlg-count" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="m-item">
                      <div className="m-ast">
                        {cloudResponse.mediaType === MG_MEDIA_TYPE.PHOTO && !cloudResponse.fileName.includes(FILE_SUPPORTED_FORMAT[0]) && cloudResponse.secureUrl ? (
                          <div className="m-ast-itm m-ast-img">
                            <ImageValidation imgUrl={cloudResponse.secureUrl} customName="Media Gallery Asset" />
                          </div>
                        ) : cloudResponse.thumbnail_url ? (
                          <div className="video-outer-element">
                            <ImageValidation imgUrl={cloudResponse.thumbnail_url} customName="Media Gallery Asset" />
                          </div>
                        ) : cloudResponse.mediaType === MG_MEDIA_TYPE.VIDEO && cloudResponse.secureUrl ? (
                          <div className="video-outer-element">
                            <ReactVideoPlayer videoUrl={cloudResponse.secureUrl} customClassname="m-ast-itm m-ast-img" />
                          </div>
                        ) : (
                          <div className="m-ast-itm m-ast-img">
                            <ImageValidation isImgValid defaultImg={'list_document_icon'} customClassname={'m-ast-itm m-ast-img'} customName={'Media Gallery Asset'} />
                          </div>
                        )}
                      </div>
                      <div className="m-ast-dtls">
                        <div className="mastg-main">
                          <div className="mast-prime-dtls">
                            <div className="mast-prof-pic">
                              <ImageValidation imgUrl={activeUserProfilePhotoUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customName="Profile Picture" />
                            </div>
                            <div className="mast-prof-txt">
                              <span className="sm-txt-top" title={cloudResponse.fileName}>
                                {cloudResponse.fileName}
                              </span>
                              {optionalParams[0] !== MgMediaType.DOCUMENTS ? (
                                <span className="lg-txt-base">
                                  <span className="mlg-label">Uses</span>
                                  <span className="mlg-count">{assetUsedCount || 0}</span>
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {optionalParams[1] === COMMON_PAGE_TYPE.NEW && (
                            <div className="mast-controls mast-close">
                              {/* <div className="lb-btn lb-close"> */}
                              <div className="browse__card--close">
                                <ImageValidation isImgValid defaultImg={'xmark-red'} customName="Remove" onClick={() => handleRemoveAssetsUpload(cloudResponse.secureUrl)} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Td>
              {userOwnership === USER_OWNERSHIP.FRANCHISOR && (
                <Td>
                  {!isContentSupplier || (isContentSupplier && accountsCount >= 1) ? (
                    <div className="form-group">
                      {AP_AVAIL_MEDIA_FOR_USER.map((userItem, idx: number) => {
                        return (
                          <label
                            className={`${AP_AVAIL_MEDIA_FOR_USER.length - 1 === idx ? `r-ml0` : ``}${cloudResponse.mgAvailableForUser === userItem.name ? ` active` : ``}`}
                            key={`mg-media-avail-${idx}`}
                          >
                            <input
                              type="radio"
                              className="option-input radio"
                              value={userItem.name}
                              checked={cloudResponse.mgAvailableForUser === userItem.name}
                              onChange={(event) => dispatch(mgUpdateMediaCloudResponse({ index: cloudResIndex, response: { ...cloudResponse, mgAvailableForUser: event.target.value } }))}
                            />
                            <span className="labelText">{userItem.label}</span>
                          </label>
                        );
                      })}
                    </div>
                  ) : null}
                </Td>
              )}
              {isImageEditingEnabled && (
                <Td>
                  <div className="msr-fg">
                    {/* <div className="avalible-title-text msr-optional-head">Image Editing</div> */}
                    <div className="form-group">
                      {MEDIA_FRAN_EDIT_OPTIONS.map((optionItem, idx: number) => {
                        return (
                          <label
                            className={`${MEDIA_FRAN_EDIT_OPTIONS.length - 1 === idx ? `` : ``}${cloudResponse?.isImageLockPost === optionItem.name ? ` active` : ``}`}
                            key={`mg-edit-options-${idx}`}
                          >
                            <input
                              type="radio"
                              className="option-input radio"
                              checked={cloudResponse?.isImageLockPost === optionItem.name}
                              onChange={() => dispatch(mgUpdateMediaCloudResponse({ index: cloudResIndex, response: { ...cloudResponse, isImageLockPost: optionItem.name } }))}
                            />
                            <span className="labelText">{optionItem.label}</span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </Td>
              )}
              <Td>
                <div className="mExpMainRight">
                  <div className="mExpTop">
                    <div className="form-group">
                      {AP_MEDIA_EXPIRATION.map((item, idx: number) => {
                        return (
                          <label className={`${AP_MEDIA_EXPIRATION.length - 1 === idx ? `r-ml0` : ``}${cloudResponse?.expiration === item.name ? ` active` : ``}`} key={`mg-media-avail-${idx}`}>
                            <input
                              type="radio"
                              className="option-input radio"
                              value={item.name}
                              checked={cloudResponse?.expiration === item.name}
                              onChange={() => {
                                dispatch(mgUpdateMediaCloudResponse({ index: cloudResIndex, response: { ...cloudResponse, expiration: item.name, expires_at: null } }));
                              }}
                            />
                            <span className="labelText">{item.label}</span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div className="mExpBase">
                    {cloudResponse?.expiration === AddMediaExpirationValues.EXPIRE ? (
                      <div className="sc-cdtWrap">
                        <div className="wdt-item da-date">
                          <CustomDatePicker
                            selectedDate={cloudResponse.expires_at ?? null}
                            dateRangeType={ICommonDateTypes.TO_DATE}
                            onChange={(date) =>
                              dispatch(
                                mgUpdateMediaCloudResponse({
                                  index: cloudResIndex,
                                  response: { ...cloudResponse, expiration: AddMediaExpirationValues.EXPIRE, expires_at: getEndOfDate(date) }
                                })
                              )
                            }
                            customClassname={`fltr-date-item fltr-from-date`}
                            minDate={getCurrentDate()}
                            autoComplete="off"
                            placeholderText={'Select Date here'}
                            dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Td>
              {response.every((mediaRes) => mediaRes.mediaType === MG_MEDIA_TYPE.VIDEO) ? (
                <Td>
                  <div className="mExpMainRight">
                    <div className="mExpTop">
                      <div className="form-group">
                        {MG_CUSTOMIZE_VIDEO_THUMBNAIL_OPTIONS.map((thumbnailOption, index) => (
                          <label
                            className={`${MG_CUSTOMIZE_VIDEO_THUMBNAIL_OPTIONS.length - 1 === index ? `r-ml0` : ``}${cloudResponse.customVideoThumbnail === thumbnailOption.value ? ` active` : ``}`}
                            key={`mg-media-avail-${index}`}
                          >
                            <input
                              type="radio"
                              className="option-input radio"
                              value={thumbnailOption.value}
                              checked={cloudResponse.customVideoThumbnail === thumbnailOption.value}
                              onChange={(event) => {
                                dispatch(
                                  mgUpdateMediaCloudResponse({
                                    index: cloudResIndex,
                                    response: { ...cloudResponse, customVideoThumbnail: event.target.value, thumbnail_url: '', cover_cloudinary_id: null }
                                  })
                                );
                                if (cloudResponse?.cloudinaryId) removeVideoThumbnail(cloudResponse?.cloudinaryId);
                              }}
                            />
                            <span className="labelText">{thumbnailOption.label}</span>
                          </label>
                        ))}
                        {cloudResponse.customVideoThumbnail === MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES.CUSTOMIZE && (
                          <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-mt24`} custColor={RIPPLE_COLOR.secondary}>
                            <button
                              className={`ac-btn ac-secondary size-md post__image--btn`}
                              onClick={() => {
                                setSelectedCloudinaryRes(cloudResponse);
                                handleTabAction(AP_ADD_TO_POST_TAB_NAMES.THUMBNAIL);
                              }}
                            >
                              Custom Thumbnail
                            </button>
                          </CustomRippleButton>
                        )}
                      </div>
                    </div>
                  </div>
                </Td>
              ) : null}
              {!response.some((mediaRes) => [MG_MEDIA_TYPE.RAW, MG_MEDIA_TYPE.DOCUMENT].includes(mediaRes.mediaType) || mediaRes?.fileName.split('.').pop() === 'pdf') ? (
                <Td>
                  <div className="form-group">
                    {MG_MEDIA_RELEASE_OPTIONS.map((option, index) => {
                      const isChecked =
                        (option.value === MG_MEDIA_RELEASE_VALUES.YES && cloudResponse?.have_mediaRelease) || (option.value === MG_MEDIA_RELEASE_VALUES.NO && !cloudResponse?.have_mediaRelease);
                      return (
                        <label className={`${isChecked ? ` active` : ``}`} key={`media-release-option-${index}`}>
                          <input
                            type="radio"
                            className="option-input radio"
                            value={option.value}
                            checked={isChecked}
                            onChange={(event) => {
                              const selectedMediaSignature = mediaReleaseSignatures?.find((signature) => signature?.cloudinaryId === cloudResponse?.cloudinaryId);
                              dispatch(
                                mgUpdateMediaCloudResponse({
                                  index: cloudResIndex,
                                  response: { ...cloudResponse, have_mediaRelease: event.target.value === MG_MEDIA_RELEASE_VALUES.YES ? true : false }
                                })
                              );
                              if (selectedMediaSignature?.fileName) dispatch(mgDeleteMediaReleaseRequest(selectedMediaSignature?.fileName));
                            }}
                          />
                          <span className="labelText">{option.label}</span>
                        </label>
                      );
                    })}
                    {!mediaReleaseSignatures?.find((signature) => signature?.cloudinaryId === cloudResponse?.cloudinaryId) && cloudResponse?.have_mediaRelease ? null : (
                      <Button
                        className={`ac-btn ac-primary${disableSaveButton() ? ` pointer-events-none` : ``}`}
                        variant="primary"
                        onClick={() => {
                          setSelectedCloudinaryRes(cloudResponse);
                          setMediaReleaseModalOpen(true);
                        }}
                      >
                        {mediaReleaseSignatures?.find((signature) => signature?.cloudinaryId === cloudResponse?.cloudinaryId) && cloudResponse?.have_mediaRelease ? 'Edit' : 'Add'} Media Release
                      </Button>
                    )}
                  </div>
                </Td>
              ) : null}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};
