import { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { apFranContainerTypes, commonModalPopup, IStore } from 'types';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import {
  RIPPLE_COLOR,
  GoogleCallToActionOptionsName,
  VALID_PHONE_NUMBER_REGEX,
  USER_OWNERSHIP,
  COMMON_SOCIAL_PROVIDER_TYPE,
  socialMediaCallToActionCallOptions,
  TOOLTIP_PLACEMENT,
  LOCALIZE_POST_INFO_TOOLTIP
} from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { CustomDropdown } from 'widgets/CustomDropdown';
import {
  accountUpdateProfileRequest,
  addCustomizeLink,
  addPostAddLinkRequest,
  addPostDeleteLinkRequest,
  addPostGetSelectedContentObj,
  addPostUpdateSavedPostDetails,
  setCustomizeLinkEnable,
  settingsUpdateRallioProfile
} from 'actions';
import { formatRallioProfileRequestPayload, googleCallToActionValidation } from 'utils/helpers';
import { CustomTooltip } from 'widgets/Tooltip';
import { AddCustomizeLink } from './AddCustomizeLink';
import { useAccountSwitcherData } from 'utils/hooks';

export const AddSocialMediaCallToAction = ({ isShowModal, onModalClose, currentTab, actionTypeOptions, userOwnership, ctaAction, id }: commonModalPopup.ISocialMediaAddCallToActionProps) => {
  const dispatch = useDispatch();

  const { currentRoute } = useAccountSwitcherData();

  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const googleLocationPhone = useSelector(
    (state: IStore) => state.accounts.accountDetails?.social_media.find((item) => item.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT)?.gmb_phone_number
  );
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const rallioProfile = useSelector((state: IStore) => state.rallioProfile.rallioProfile);
  const accountData = useSelector((state: IStore) => state.accounts.accountDetails?.account);
  const isCustomizeLinkEnable = useSelector((state: IStore) => state.addPostFranchisorAction.isCustomizeLinkEnable);
  const isContentCustomizeLink = useSelector((state: IStore) => state.accountSwitcher?.content_customize_link || false);
  const isCustomizeLinkAdded = useSelector((state: IStore) => state.addPostFranchisorAction.isCustomizeLinkAdded || false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCustomizeLinkEnabled, setIsCustomizeLinkEnabled] = useState(isCustomizeLinkEnable || isCustomizeLinkAdded);

  const socialMediaLinkType = `${currentTab}_call_to_action_${currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? 'link' : 'url'}`;

  const updateCreatorPost = (values: apFranContainerTypes.ISocialMediaCallToAction, isSubmitting?: boolean) => {
    const isActionTypeNotExist = isSubmitting && (values.action_type === GoogleCallToActionOptionsName.NONE || !values.action_url);
    const isActionUrlNotExist = isSubmitting && values.action_type === GoogleCallToActionOptionsName.NONE;
    if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                [`${currentTab}_call_to_action_type`]: isActionTypeNotExist ? null : values.action_type,
                ...(currentTab !== COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && { [socialMediaLinkType]: isActionUrlNotExist ? null : values.action_url })
              }
            : null
        )
      );
    } else {
      dispatch(
        addPostGetSelectedContentObj({
          ...contentObj,
          personalized_facebook_call_to_action_link: values.isLocalizeCTAEnabled ? `[cta_${values.action_type?.toLowerCase()}]` : '',
          [`${currentTab}_call_to_action_type`]: isActionTypeNotExist ? null : values.action_type,
          ...(currentTab !== COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && { [socialMediaLinkType]: isActionUrlNotExist ? null : values.action_url })
        })
      );
    }
    if ([COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE].includes(currentTab)) {
      // dispatch(addPostDescriptionRequest(actionUrl));
      if (values.action_type === GoogleCallToActionOptionsName.NONE && linkData?.id) {
        dispatch(addPostDeleteLinkRequest(linkData.id));
      } else {
        if (isCustomizeLinkEnabled) {
          setIsSubmitting(true);
        } else {
          dispatch(addPostAddLinkRequest(values.action_url || ''));
          onModalClose();
        }
      }
    }
  };

  const removeTelInActionUrl = (url: string) => {
    if (url?.includes('tel:')) {
      return url.replace('tel:', '');
    }
    return url;
  };

  const getInitialValues = () => {
    if (ctaAction) {
      return { action_type: ctaAction.action, action_url: ctaAction.url, isLocalizeCTAEnabled: false };
    }
    return {
      action_type: contentObj[`${currentTab}_call_to_action_type`] || savedPostDetails?.[`${currentTab}_call_to_action_type`] || actionTypeOptions[1].value,
      action_url:
        currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && linkData?.url
          ? linkData?.url
          : currentTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && savedPostDetails?.google_call_to_action_type === GoogleCallToActionOptionsName.CALL && googleLocationPhone
          ? String(googleLocationPhone)
          : removeTelInActionUrl(savedPostDetails?.[socialMediaLinkType]) || removeTelInActionUrl(contentObj[socialMediaLinkType]) || '',
      isLocalizeCTAEnabled: Boolean(contentObj.personalized_facebook_call_to_action_link)
    };
  };

  const updateCTA = (val: apFranContainerTypes.ISocialMediaCallToAction) => {
    if (ctaAction) {
      const updatedCustomFields = Object.keys({ ...rallioProfile.custom_fields }).reduce((acc: any, curr: string) => {
        if (curr === ctaAction?.action) {
          acc[`${val.action_type}`] = val.action_url;
          if (ctaAction.action !== values.action_type) {
            delete acc[ctaAction?.action];
          }
        } else {
          acc[curr] = rallioProfile.custom_fields[curr];
        }
        return acc;
      }, {});
      dispatch(settingsUpdateRallioProfile({ ...rallioProfile, custom_fields: updatedCustomFields }));
      const formattedProfileData = formatRallioProfileRequestPayload({ ...rallioProfile, custom_fields: updatedCustomFields });
      const profileData = { ...accountData, ...formattedProfileData };
      if (id) {
        dispatch(accountUpdateProfileRequest({ profileData, id }));
      }
      onModalClose();
    }
  };

  const { values, handleBlur, setFieldValue, handleSubmit, touched, errors } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: googleCallToActionValidation(userOwnership, currentTab),
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (val: apFranContainerTypes.ISocialMediaCallToAction) => {
      if (ctaAction) {
        updateCTA(val);
      } else {
        updateCreatorPost(val, true);
      }
      dispatch(setCustomizeLinkEnable(isCustomizeLinkEnabled));
      dispatch(addCustomizeLink(isCustomizeLinkEnabled));
    }
  });

  const getInputBoxClassName = (valueItem: keyof apFranContainerTypes.ISocialMediaCallToAction) => {
    let className = '';
    className = `${
      touched[valueItem] && errors[valueItem]
        ? 'erroritem'
        : Boolean(savedPostDetails?.google_call_to_action_type === GoogleCallToActionOptionsName.CALL && String(googleLocationPhone) && userOwnership === USER_OWNERSHIP.ACCOUNT) ||
          values.action_type === GoogleCallToActionOptionsName.NONE
        ? 'pointer-events-none'
        : ''
    }`;
    return className;
  };

  return (
    <>
      <Modal
        className="glbl__modal--wrp cr-sm-mdl google-post-ca ca-links-wrap"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isShowModal}
        animation={false}
        onHide={onModalClose}
      >
        <div className="mod__close--icon">
          <ImageValidation defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={onModalClose} />
        </div>
        <Modal.Body>
          <h3 className="gm__title">Call to Action</h3>
          <Card className="glbl__modal--card">
            <Card.Body>
              <Card.Title className="static_label">
                <span className="sl-txt">Select Action</span>
              </Card.Title>
              <CustomDropdown
                classNamePrefix="glbl__dropdown"
                id="boost-duration-dropdown"
                placeholder={'Select Action'}
                options={actionTypeOptions}
                value={actionTypeOptions.find((selectOption) => (ctaAction ? `cta_${selectOption.value.toLowerCase()}` === values.action_type : selectOption.value === values.action_type))}
                onChange={(eventObj: any) => {
                  setFieldValue('action_type', ctaAction ? `cta_${eventObj.value.toLowerCase()}` : eventObj.value);
                  if (!ctaAction) {
                    setFieldValue(
                      'action_url',
                      eventObj.value === GoogleCallToActionOptionsName.CALL && currentTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && userOwnership === USER_OWNERSHIP.ACCOUNT
                        ? String(googleLocationPhone)
                        : ''
                    );
                  }
                }}
                name={'action_type'}
              />
              {currentTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
              userOwnership === USER_OWNERSHIP.ACCOUNT &&
              values.action_type === GoogleCallToActionOptionsName.CALL &&
              !googleLocationPhone ? null : (
                <>
                  <Card.Title className="static_label">
                    <span className="sl-txt">{socialMediaCallToActionCallOptions.includes(values.action_type || '') ? 'Enter Phone Number' : 'Enter URL'}</span>
                    <span className="mandatory-sign">*</span>
                  </Card.Title>
                  <div className={`form-group form-field-item ${getInputBoxClassName('action_url')}`}>
                    <input
                      type="text"
                      name={'action_url'}
                      className={'normal-inputText'}
                      value={values.action_url ?? ''}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          handleSubmit();
                        }
                        if (values.action_type === GoogleCallToActionOptionsName.CALL) {
                          const regexData = new RegExp(VALID_PHONE_NUMBER_REGEX);
                          const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                          if (!regexData.test(enteredKey)) {
                            event.preventDefault();
                            return false;
                          }
                        }
                      }}
                      disabled={
                        Boolean(values.action_type === GoogleCallToActionOptionsName.CALL && String(googleLocationPhone) && userOwnership === USER_OWNERSHIP.ACCOUNT) ||
                        values.action_type === GoogleCallToActionOptionsName.NONE
                      }
                    />
                  </div>
                </>
              )}
              <div className={`cta-action-wrap`}>
                {userOwnership === USER_OWNERSHIP.FRANCHISOR && currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                  <>
                    <div className="form-check lcta-cb">
                      <label className="checkbox-item">
                        <span className="checkbox-hover">
                          <input
                            type="checkbox"
                            name="localizePost"
                            className="option-input click-wave"
                            checked={values.isLocalizeCTAEnabled}
                            onChange={() => {
                              setFieldValue('isLocalizeCTAEnabled', !values.isLocalizeCTAEnabled);
                            }}
                          />
                        </span>
                        <div className="label-txt">
                          <span className="cb-label">Localize CTA</span>
                          <CustomTooltip
                            customPlacement={TOOLTIP_PLACEMENT.TOP}
                            customClassname={'custom-tooltip-long-text'}
                            customTooltipText={LOCALIZE_POST_INFO_TOOLTIP}
                            customInput={() => (
                              <div className="info__tool--tip">
                                <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                              </div>
                            )}
                          />
                        </div>
                        <span className="checkmark" />
                      </label>
                    </div>
                    {/* {values.isLocalizeCTAEnabled && (
                    <div className="rcpm-cd">
                      <Card.Title className="static_label">
                        <span className="sl-txt">Select a field below to insert</span>
                        <span className="mandatory-sign">*</span>
                      </Card.Title>
                      <CustomDropdown
                        classNamePrefix="glbl__dropdown"
                        id="boost-duration-dropdown"
                        placeholder={'Select Action'}
                        options={AP_FRAN_PERSONALIZED_CALL_TO_ACTION_TYPE}
                        value={AP_FRAN_PERSONALIZED_CALL_TO_ACTION_TYPE.find((selectOption) => selectOption.value === values.personalized_facebook_call_to_action_link)}
                        onChange={(eventObj: any) => {
                          setFieldValue('personalized_facebook_call_to_action_link', eventObj.value);
                        }}
                        name={'personalized_facebook_call_to_action_link'}
                      />
                    </div>
                  )} */}
                  </>
                ) : null}
                {isContentCustomizeLink && currentRoute === 'content/creator' ? (
                  <div className={`form-check`}>
                    <label className="checkbox-item">
                      <span className="checkbox-hover">
                        <input
                          type="checkbox"
                          name="mobile_app_only_enabled"
                          className="option-input click-wave"
                          checked={isCustomizeLinkEnabled}
                          onChange={() => {
                            setIsCustomizeLinkEnabled((prev) => !prev);
                          }}
                        />
                      </span>
                      <div className="label-txt">Customize</div>
                      <span className="checkmark" />
                    </label>
                  </div>
                ) : null}
              </div>
              {isCustomizeLinkEnabled && isContentCustomizeLink && (
                <AddCustomizeLink linkText={values.action_url || ''} handleModalClose={onModalClose} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
              )}
            </Card.Body>
          </Card>
          <div className="btn-wrp-separate">
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <button type="button" className={'ac-btn ac-primary ac-block'} onClick={() => handleSubmit()}>
                Done
              </button>
            </CustomRippleButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
