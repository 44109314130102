import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ImageValidation } from 'widgets/Media';
import { NoDataFoundProps, RIPPLE_COLOR, TOOLTIP_PLACEMENT } from 'utils/constants';
import { Loading, NoDataFound } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { debounceMethod } from 'utils/helpers';
import { IKeywordCardData, IKeywordsCardProps } from 'analytics/types';
import { CustomTooltip } from 'widgets/Tooltip';

export const KeywordsCard = ({
  title,
  subtitle,
  data,
  checkable,
  checkedKeys,
  onChange,
  searchPlaceHolder,
  disabled,
  hasMore = false,
  handleScroll,
  handleSearch,
  isFetching,
  isShowLoader
}: IKeywordsCardProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [dataList, setDataList] = useState<IKeywordCardData[]>(data);

  const searchTextTimeout = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    if (!searchText) {
      setDataList(data);
    } else {
      setDataList(data.filter((list) => list.name.toLowerCase().includes(searchText.trim().toLowerCase())));
    }
  }, [searchText, data]);

  return (
    <div className={`cas-item cas-left flex-fill`}>
      <h3 className="glbl__title--txt">
        {title}
        <span>{subtitle}</span>
      </h3>
      <div className="cas-tree tree-add position-relative">
        <div className="cas-search">
          <div className="simple-search">
            <input
              type="text"
              placeholder={searchPlaceHolder ?? 'Search'}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyUp={() => {
                // added debounce method for search
                searchTextTimeout.current = debounceMethod(searchText.trim(), searchTextTimeout.current, (searchValue: string) => {
                  if (handleSearch) {
                    handleSearch(searchText.trim());
                  } else {
                    setDataList(dataList.filter((list) => list.name.toLowerCase().includes(searchValue.trim().toLowerCase())));
                  }
                });
              }}
            />
          </div>
        </div>
        <div className="casb">
          {isFetching ? (
            <Loading />
          ) : dataList?.length ? (
            <InfiniteScroll
              next={() => {
                if (handleScroll) {
                  handleScroll(searchText);
                }
              }}
              hasMore={hasMore}
              dataLength={dataList?.length || 0}
              loader={isShowLoader ? <Loading /> : null}
              scrollableTarget="tags-scroll-target"
              height={380}
            >
              <ul>
                {dataList.map((keyword, index) => (
                  <li key={`keyword-${index}`}>
                    <div className={`ar-wrap ${disabled ? 'pointer-events-none' : ''}`}>
                      {checkable ? (
                        <div className="arw-left" onClick={() => onChange(keyword.name)}>
                          <input type="checkbox" className="cur-pointer" checked={checkedKeys?.includes(keyword.name)} onChange={() => null} />
                          {keyword?.tooltipText ? (
                            <CustomTooltip
                              customPlacement={TOOLTIP_PLACEMENT.TOP}
                              customTooltipText={keyword?.tooltipText}
                              customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
                              customInput={() => (
                                <span>
                                  {keyword.name}
                                  {keyword.hasOwnProperty('count') ? ` (${keyword?.count})` : ''}
                                </span>
                              )}
                            />
                          ) : (
                            <span>
                              {keyword.name}
                              {keyword.hasOwnProperty('count') ? ` (${keyword?.count})` : ''}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="kc-sel-kwrds">
                          <div className="arw-left">
                            <span>{keyword.name}</span>
                          </div>
                          <div className="arw-right">
                            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.danger}>
                              <button className="kc-remove" onClick={() => onChange(keyword.name)}>
                                <ImageValidation isImgValid defaultImg="remove-red" customName="Remove Keywords" />
                              </button>
                            </CustomRippleButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          ) : (
            !data.length && !isFetching && <NoDataFound size={NoDataFoundProps.SMALL} />
          )}
        </div>
      </div>
    </div>
  );
};
