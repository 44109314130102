import { useSelector } from 'react-redux';

import { AICoachMark } from 'components/Common/Modal/AICoachMark';
import { IStore } from 'types';
import { useSiteRestriction } from 'utils/hooks';
import { AI_ICON_BG_IMG, POSTS_TOOLTIP_NAMES, TOOLTIP_PLACEMENT } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';

export const MediaGalleryOverlay = ({ isAISubscribed, handleAIClick }: { isAISubscribed: boolean; handleAIClick: () => void }) => {
  const { aiRestriction } = useSiteRestriction();
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  return (
    <>
      {isRallioai && !aiRestriction.button && isAISubscribed && (
        <AICoachMark isCoachMark={false}>
          <div className="eai-wrap ai-tag">
            <CustomTooltip
              customPlacement={TOOLTIP_PLACEMENT.TOP}
              customTooltipText={POSTS_TOOLTIP_NAMES.AI_MEDIA}
              customClassname="r-post-content dv-tp"
              customInput={() => (
                <div className="ai-btn ai-creator-btn ai-anim-btn" onClick={handleAIClick}>
                  <ImageValidation isImgValid defaultImg={AI_ICON_BG_IMG.LIGHT_BLUE} isNotSvgFormat customName="AI" customClassname="aib" />
                </div>
              )}
            />
          </div>
        </AICoachMark>
      )}
    </>
  );
};
