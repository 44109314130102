import { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';

import { REVIEWS_REPLIED_PERCENTAGE_CHART_SERIES, HIGHCHARTS_OBJ, REVIEWS_REPLIED_PERCENTAGE_GAUGE } from 'utils/constants';
import { IStore } from 'types';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export const ReviewsRepliedPercentageComponent = () => {
  const statsByDate = useSelector((state: IStore) => state.reviews.reviewStats.statsByDate);

  const [gaugeChartOptions, setgaugeChartOptions]: Array<any> = useState({ series: REVIEWS_REPLIED_PERCENTAGE_CHART_SERIES });

  useEffect(() => {
    if (gaugeChartOptions.series) {
      const clonedChartSeriesData = JSON.parse(JSON.stringify(gaugeChartOptions.series));
      const chartOptions = { ...REVIEWS_REPLIED_PERCENTAGE_GAUGE };
      clonedChartSeriesData[0].data = statsByDate.length === 1 ? [0, ...statsByDate.map((datum) => datum.repliedPercentCount)] : statsByDate.map((datum) => datum.repliedPercentCount);
      setgaugeChartOptions((state: any) => ({
        ...state,
        ...chartOptions,
        series: clonedChartSeriesData
      }));
    }
  }, [statsByDate.length]); // eslint-disable-line

  /* For Dummy-chart image - add this class 'd-none' to 'chart-details' div below like this 'chart-details d-none'  */
  return <div className="chart-details">{HIGHCHARTS_OBJ.CHART in gaugeChartOptions && <HighchartsReact highcharts={Highcharts} options={gaugeChartOptions} updateArgs={[true]} />}</div>;
};
