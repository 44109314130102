import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { CONTENT_SUPPLIER_FEED_TYPE_LABELS, CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS, FEED_TYPE, CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES } from 'utils/constants';
import { contentFeedResetTags } from 'actions';
import { KeywordsCard } from 'analytics/containers/ReviewAnalytics/SubPages';

export const DefineListElements = ({ contentFeedModifiedObj, handleChange, dispatch }: { contentFeedModifiedObj: any; handleChange: any; dispatch: any }) => {
  const contentSupplierHubs = useSelector((state: IStore) => state.operationsList.cs_franchisors);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);

  const isContentSourceBrand = contentFeedModifiedObj?.content_source === CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.BRAND;
  const isAllContentSource = contentFeedModifiedObj?.content_source === CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.ALL;
  const csHubsWithBrand = [{ id: currentAccountOrFranchisor?.id || '', label: currentAccountOrFranchisor?.name || '' }, ...contentSupplierHubs];
  const modifiedCSHubsLabels =
    isContentSourceBrand && currentAccountOrFranchisor?.name
      ? [{ name: currentAccountOrFranchisor?.name }] || []
      : (isAllContentSource ? csHubsWithBrand : contentSupplierHubs).map((hub) => ({ name: hub.label }));

  const contentSupplierHubWithBrand = (
    contentFeedModifiedObj?.content_suppliers?.length ? [currentAccountOrFranchisor?.id, ...contentFeedModifiedObj?.content_suppliers] : [currentAccountOrFranchisor?.id]
  ).map((hubId: number) => {
    const selectedData = csHubsWithBrand.find((hub: any) => hub.id === hubId);
    return { name: selectedData?.label || '' };
  });
  const modifiedSelectedCSHubs = isContentSourceBrand
    ? currentAccountOrFranchisor?.name
      ? [{ name: currentAccountOrFranchisor?.name }]
      : []
    : isAllContentSource
    ? contentSupplierHubWithBrand
    : contentSupplierHubWithBrand.filter((hub) => hub.name !== currentAccountOrFranchisor?.name);
  const contentSupplierSourceFeedOptions = CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS.map((option) => {
    const obj = option;
    if (obj.label === CONTENT_SUPPLIER_FEED_TYPE_LABELS.BRAND) {
      obj.label = `${obj.label} (${currentAccountOrFranchisor?.name})`;
    }
    return obj;
  });
  return (
    <>
      <div className="bs-cnl dle-ff">
        <div className={`form-group form-field-item`}>
          <input type="text" name={'name'} value={contentFeedModifiedObj?.name} autoComplete="off" onChange={(e) => handleChange('name', e.target.value)} />
          <span className={`fltlabels`}>
            {'Name of the List'}
            <span className="mandatory cursor-help">*</span>
          </span>
        </div>
        {isContentSupplierFranchisor ? (
          <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean`}>
            <CustomDropdown
              classNamePrefix="glbl__dropdown"
              id="boost-duration-dropdown"
              placeholder={'Select Content Source(s)'}
              options={contentSupplierSourceFeedOptions}
              value={contentSupplierSourceFeedOptions.find((selectOption) => {
                if (selectOption.value === FEED_TYPE.CONTENT_SUPPLIER) {
                  return selectOption.content_source === contentFeedModifiedObj?.content_source;
                }
                return selectOption.value === contentFeedModifiedObj?.feed_type;
              })}
              onChange={(eventObj: any) => {
                handleChange('feed_type', eventObj.value);
                if (eventObj.value === FEED_TYPE.CONTENT_SUPPLIER) {
                  const csHubs = eventObj.label === CONTENT_SUPPLIER_FEED_TYPE_LABELS.ALL ? contentSupplierHubs.map((hub: any) => hub.id) : [];
                  handleChange('content_suppliers', csHubs);
                  handleChange('content_source', eventObj?.content_source);
                }
                dispatch(contentFeedResetTags());
              }}
              name={'feed_type'}
            />
            <span className={`fltlabels`}>
              {'Select Content Source(s)'}
              <span className="mandatory cursor-help">*</span>
            </span>
          </div>
        ) : null}
      </div>
      {contentFeedModifiedObj?.feed_type === FEED_TYPE.CONTENT_SUPPLIER ? (
        <div className="create-as d-flex">
          <KeywordsCard
            title="Available"
            subtitle={`(${modifiedCSHubsLabels.length}) ${modifiedCSHubsLabels.length === 1 ? 'Source' : 'Sources'}`}
            data={modifiedCSHubsLabels}
            onChange={(value) => {
              const selectedCSId = contentSupplierHubs.find((hub) => hub.label === value)?.id;
              const updatedCSIds = contentFeedModifiedObj.content_suppliers.includes(selectedCSId)
                ? contentFeedModifiedObj.content_suppliers.filter((csId: number) => csId !== selectedCSId)
                : [...contentFeedModifiedObj.content_suppliers, selectedCSId];
              handleChange('content_suppliers', updatedCSIds);
              handleChange('content_source', contentSupplierHubs.length === updatedCSIds ? CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.ALL : CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.CONTENT_SUPPLIER);
            }}
            checkable
            checkedKeys={
              isContentSourceBrand
                ? currentAccountOrFranchisor?.name
                  ? [currentAccountOrFranchisor?.name]
                  : []
                : modifiedSelectedCSHubs
                    .filter((option) => {
                      const selectedContentSupplierId = (isAllContentSource ? csHubsWithBrand : contentSupplierHubs).find((hub) => hub.label === option?.name)?.id;
                      return contentFeedModifiedObj?.content_suppliers.includes(selectedContentSupplierId) || selectedContentSupplierId === currentAccountOrFranchisor?.id;
                    })
                    .map((option) => option.name)
            }
            disabled={isContentSourceBrand || isAllContentSource ? true : false}
          />
          <KeywordsCard
            title="Selected"
            subtitle={`(${modifiedSelectedCSHubs.length}) ${modifiedSelectedCSHubs?.length === 1 ? 'Source' : 'Sources'}`}
            data={modifiedSelectedCSHubs || []}
            onChange={(value) => {
              const selectedContentSupplierId = contentSupplierHubs.find((hub) => hub.label === value)?.id;
              const filteredCSList = contentFeedModifiedObj?.content_suppliers.filter((CSId: number) => CSId !== selectedContentSupplierId);
              handleChange('content_suppliers', filteredCSList);
            }}
            disabled={isContentSourceBrand || isAllContentSource ? true : false}
          />
        </div>
      ) : null}
    </>
  );
};
