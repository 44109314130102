import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IStore } from 'types';
import { addPostFranResetAll, addPostSetSelectedLocData, addPostSetTags, setCreatorModalName } from 'actions';
import { AddMultiSelectTagsModal, CommonFilter } from 'components';
import { FranSyndicatePostModal } from 'containers/Content/Creator/BrandOrHubSubPages';
import { IFiterHandleChangeData, IMultiSelectModalTypeProp } from 'types/common/filter';
import {
  AP_FRAN_MODAL_NAMES,
  AP_SELECTED_LOC_INIT_DATA,
  BUTTON_PROPS,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CommonValidQueryParams,
  FranchisorSyndicationMediaType,
  MG_SYNDICATED_FILTER_TYPE,
  MULTI_SELECT_MEDIA_POST_FILTER_VALUE,
  MultiSelectValidQueryParams
} from 'utils/constants';
// import { getFormattedNumber } from 'utils/helpers';
import { handleFilterChange } from 'analytics/utils';
import { useAccountSwitcherData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';

export const MultiSelectMediaOrPostFilter = ({ mediaType, handleMainModalClose }: { mediaType: IMultiSelectModalTypeProp; handleMainModalClose: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useAccountSwitcherData();
  const { queryParams, filter } = useParamsDeconstructor({ ...MULTI_SELECT_MEDIA_POST_FILTER_VALUE });

  const creatorModalName = useSelector((state: IStore) => state.addPostFranchisorAction.creatorModalName);
  const selectedPosts = useSelector((state: IStore) => state.campaigns.selectedPosts);
  const selectedMediaList = useSelector((state: IStore) => state.mediaGallery.selectedMediaIds);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier || false);

  // const newlyAddedMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.newlyAddedMediaCount);
  // const usedMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.usedMediaCount);
  // const unusedMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.unusedMediaCount);

  const isContentFilter = mediaType === FranchisorSyndicationMediaType.CONTENT;
  const isMediaOrPostSelected = Boolean(isContentFilter ? selectedPosts.length : selectedMediaList.length);

  const setModalName = (data: string | null) => dispatch(setCreatorModalName(data));

  useParamsValidator(
    { ...CommonValidQueryParams, ...MultiSelectValidQueryParams },
    {
      multi_syndicated_to_any_franchisor: MG_SYNDICATED_FILTER_TYPE.map((filter) => filter.value),
      multi_syndicated_to_any_account: MG_SYNDICATED_FILTER_TYPE.map((filter) => filter.value)
    }
  );

  const mediaGalleryFilter = {
    ...filter,
    multi_syndicated_to_any_franchisor: queryParams?.multi_syndicated_to_any_franchisor || COMMON_SOCIAL_PROVIDER_TYPE.ALL,
    multi_syndicated_to_any_account: queryParams?.multi_syndicated_to_any_account || COMMON_SOCIAL_PROVIDER_TYPE.ALL
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    if (data.multi_syndicated_to_any_franchisor) {
      if (data.multi_syndicated_to_any_franchisor === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.multi_syndicated_to_any_franchisor;
      else queryParamsObj.multi_syndicated_to_any_franchisor = data.multi_syndicated_to_any_franchisor;
    }
    if (data.multi_syndicated_to_any_account) {
      if (data.multi_syndicated_to_any_account === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.multi_syndicated_to_any_account;
      else queryParamsObj.multi_syndicated_to_any_account = data.multi_syndicated_to_any_account;
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const getFilterButtons = () => {
    const filterButtons = [{ ...BUTTON_PROPS.SECONDARY_BUTTON, label: 'Add Tags', value: 'add_tags', isDisable: !isMediaOrPostSelected, onClick: () => setModalName(AP_FRAN_MODAL_NAMES.SELECT_TAG) }];
    if (isContentFilter || isContentSupplier) {
      filterButtons.push({ ...BUTTON_PROPS.PRIMARY_BUTTON, label: 'Syndicate', value: 'syndicate', isDisable: !isMediaOrPostSelected, onClick: () => setModalName(AP_FRAN_MODAL_NAMES.SYNDICATE) });
    }
    return filterButtons;
  };

  return (
    <>
      <CommonFilter
        filter={{ ...mediaGalleryFilter }}
        handleChange={handleChange}
        isDateRangeFilterEnable={false}
        filterActionButtons={getFilterButtons()}
        checkBoxFilter={
          !isContentFilter && !isContentSupplier
            ? []
            : [
                {
                  title: 'Syndication',
                  filter: MG_SYNDICATED_FILTER_TYPE.map((it, index) => ({
                    value: it.value,
                    label: it.label
                    // Hided because unavailability of counts
                    // usedMediaCount || unusedMediaCount || newlyAddedMediaCount
                    //   ?
                    // ? `${it.label} (${
                    //     index === 0
                    //       ? getFormattedNumber(usedMediaCount + unusedMediaCount || 0)
                    //       : index === 1
                    //       ? getFormattedNumber(usedMediaCount || 0)
                    //       : index === 2
                    //       ? getFormattedNumber(unusedMediaCount || 0)
                    //       : getFormattedNumber(newlyAddedMediaCount || 0)
                    //   })`
                    // : it.label
                  })),
                  value: isContentSupplier ? MultiSelectValidQueryParams.MULTI_SYNDICATED_TO_ANY_FRANCHISOR : MultiSelectValidQueryParams.MULTI_SYNDICATED_TO_ANY_ACCOUNT
                }
              ]
        }
      />

      <FranSyndicatePostModal
        isModalShow={creatorModalName === AP_FRAN_MODAL_NAMES.SYNDICATE}
        handleModalClose={() => {
          setModalName(null);
          if (!isContentSupplier && isContentFilter) {
            dispatch(addPostSetSelectedLocData(AP_SELECTED_LOC_INIT_DATA));
            dispatch(addPostFranResetAll());
          }
        }}
        mediaType={mediaType}
        postOrMediaId={isContentFilter ? selectedPosts[0] : selectedMediaList[0]}
        handleMainModalClose={handleMainModalClose}
        isMultiSelect
      />

      <AddMultiSelectTagsModal
        isModalShow={creatorModalName === AP_FRAN_MODAL_NAMES.SELECT_TAG}
        onModalClose={() => {
          setModalName(null);
          dispatch(addPostSetTags([]));
        }}
        mediaType={mediaType}
        handleMainModalClose={handleMainModalClose}
      />
    </>
  );
};
