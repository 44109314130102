// ADD POST - DELETE CONTENT
export const AP_DELETE_CONTENT_REQUEST = 'add_post_franchisor_action/AP_DELETE_CONTENT_REQUEST';
export const AP_DELETE_CONTENT_RESPONSE = 'add_post_franchisor_action/AP_DELETE_CONTENT_RESPONSE';
export const AP_DELETE_CONTENT_FAILURE = 'add_post_franchisor_action/AP_DELETE_CONTENT_FAILURE';

// ADD POST - MASS DELETE CONTENT
export const AP_MASS_DELETE_CONTENT_REQUEST = 'add_post_franchisor_action/AP_MASS_DELETE_CONTENT_REQUEST';
export const AP_MASS_DELETE_CONTENT_RESPONSE = 'add_post_franchisor_action/AP_MASS_DELETE_CONTENT_RESPONSE';
export const AP_MASS_DELETE_CONTENT_FAILURE = 'add_post_franchisor_action/AP_MASS_DELETE_CONTENT_FAILURE';

// ADD POST - GET INTERNAL NOTES LIST
export const AP_GET_INTERNAL_NOTES_LIST_REQUEST = 'add_post_franchisor_action/AP_GET_INTERNAL_NOTES_LIST_REQUEST';
export const AP_GET_INTERNAL_NOTES_LIST_RESPONSE = 'add_post_franchisor_action/AP_GET_INTERNAL_NOTES_LIST_RESPONSE';
export const AP_GET_INTERNAL_NOTES_LIST_FAILURE = 'add_post_franchisor_action/AP_GET_INTERNAL_NOTES_LIST_FAILURE';

// ADD POST - ADD INTERNAL NOTES LIST
export const AP_ADD_INTERNAL_NOTES_LIST_REQUEST = 'add_post_franchisor_action/AP_ADD_INTERNAL_NOTES_LIST_REQUEST';
export const AP_ADD_INTERNAL_NOTES_LIST_RESPONSE = 'add_post_franchisor_action/AP_ADD_INTERNAL_NOTES_LIST_RESPONSE';
export const AP_ADD_INTERNAL_NOTES_LIST_FAILURE = 'add_post_franchisor_action/AP_ADD_INTERNAL_NOTES_LIST_FAILURE';

// ADD POST - ADD LINK
export const AP_ADD_LINK_REQUEST = 'add_post_franchisor_action/AP_ADD_LINK_REQUEST';
export const AP_ADD_LINK_RESPONSE = 'add_post_franchisor_action/AP_ADD_LINK_RESPONSE';
export const AP_ADD_LINK_FAILURE = 'add_post_franchisor_action/AP_ADD_LINK_FAILURE';

// LOCALIZE_PREVIEWS POST - LOCALIZE_PREVIEWS LINK
export const AP_LOCALIZE_PREVIEWS_LINK_REQUEST = 'add_post_franchisor_action/AP_LOCALIZE_PREVIEWS_LINK_REQUEST';
export const AP_LOCALIZE_PREVIEWS_LINK_RESPONSE = 'add_post_franchisor_action/AP_LOCALIZE_PREVIEWS_LINK_RESPONSE';
export const AP_LOCALIZE_PREVIEWS_LINK_FAILURE = 'add_post_franchisor_action/AP_LOCALIZE_PREVIEWS_LINK_FAILURE';

// ADD POST - DELETE LINK
export const AP_DELETE_LINK_REQUEST = 'add_post_franchisor_action/AP_DELETE_LINK_REQUEST';
export const AP_DELETE_LINK_RESPONSE = 'add_post_franchisor_action/AP_DELETE_LINK_RESPONSE';
export const AP_DELETE_LINK_FAILURE = 'add_post_franchisor_action/AP_DELETE_LINK_FAILURE';

// ADD POST - UPDATE LINK
export const AP_UPDATE_LINK_REQUEST = 'add_post_franchisor_action/AP_UPDATE_LINK_REQUEST';
export const AP_UPDATE_LINK_RESPONSE = 'add_post_franchisor_action/AP_UPDATE_LINK_RESPONSE';
export const AP_UPDATE_LINK_FAILURE = 'add_post_franchisor_action/AP_UPDATE_LINK_FAILURE';

// ADD POST - CREATE LINK FOR SELECTED COUPON
export const AP_CREATE_LINK_FOR_COUPON_REQUEST = 'add_post_franchisor_action/AP_CREATE_LINK_FOR_COUPON_REQUEST';
export const AP_CREATE_LINK_FOR_COUPON_RESPONSE = 'add_post_franchisor_action/AP_CREATE_LINK_FOR_COUPON_RESPONSE';
export const AP_CREATE_LINK_FOR_COUPON_FAILURE = 'add_post_franchisor_action/AP_CREATE_LINK_FOR_COUPON_FAILURE';

// ADD POST - CREATE LINK FOR ACCOUNT COUPON
export const AP_CREATE_LINK_FOR_ACC_COUNPON_REQUEST = 'add_post_franchisor_action/AP_CREATE_LINK_FOR_ACC_COUNPON_REQUEST';
export const AP_CREATE_LINK_FOR_ACC_COUNPON_RESPONSE = 'add_post_franchisor_action/AP_CREATE_LINK_FOR_ACC_COUNPON_RESPONSE';
export const AP_CREATE_LINK_FOR_ACC_COUNPON_FAILURE = 'add_post_franchisor_action/AP_CREATE_LINK_FOR_ACC_COUNPON_FAILURE';

// ADD POST - SET DELETE CONTENT OBJ
export const AP_SET_DELETE_CONTENT_OBJ = 'add_post_franchisor_action/AP_SET_DELETE_CONTENT_OBJ';

// ADD POST - SET SELECTED LOC DATA
export const AP_SET_SELECTED_LOC_DATA = 'add_post_franchisor_action/AP_SET_SELECTED_LOC_DATA';

// ADD POST - SET LINK DATA
export const AP_SET_LINK_DATA = 'add_post_franchisor_action/AP_SET_LINK_DATA';

// ADD POST - UPDATE CUSTOM TEXT EDITOR
export const AP_UPDATE_CUSTOM_TEXT_EDITOR = 'add_post_franchisor_action/AP_UPDATE_CUSTOM_TEXT_EDITOR';
export const AP_UPDATE_PERSONALIZED_TEXT_EDITOR = 'add_post_franchisor_action/AP_UPDATE_PERSONALIZED_TEXT_EDITOR';

// ADD POST - SET CUSTOM TEXT EDITOR
export const AP_SET_CUSTOM_TEXT_EDITOR_DATA = 'add_post_franchisor_action/AP_SET_CUSTOM_TEXT_EDITOR_DATA';
export const AP_SET_PERSONALIZED_TEXT_EDITOR_DATA = 'add_post_franchisor_action/AP_SET_PERSONALIZED_TEXT_EDITOR_DATA';

// ADD POST - SET CAMPAIGN DATA
export const AP_SET_CAMPAIGN_DATA = 'add_post_franchisor_action/AP_SET_CAMPAIGN_DATA';

// ADD POST - SET ACTIVE TAB
export const AP_SET_ACTIVE_TAB = 'add_post_franchisor_action/AP_SET_ACTIVE_TAB';

// ADD POST - IS EMOJI OPEN
export const AP_IS_EMOJI_OPEN = 'add_post_franchisor_action/AP_IS_EMOJI_OPEN';

// ADD POST - PERSONALIZED IS EMOJI OPEN
export const AP_PERSONALIZED_IS_EMOJI_OPEN = 'add_post_franchisor_action/AP_PERSONALIZED_IS_EMOJI_OPEN';

// ADD POST IS CONFIRM ACTIVE CAMPAIGN UPDATE
export const AP_IS_CONFIRM_ACTIVE_CAMPAIGN_UPDATE = 'add_post_franchisor_action/AP_IS_CONFIRM_ACTIVE_CAMPAIGN_UPDATE';

export const AP_ACTION_RESET_ALL = 'add_post_franchisor_action/AP_ACTION_RESET_ALL';

export const SET_CREATOR_MODAL_NAME = 'add_post_franchisor_action/SET_CREATOR_MODAL_NAME';

export const SET_CREATOR_AI_TAB_NAME = 'add_post_franchisor_action/SET_CREATOR_AI_TAB_NAME';

export const AI_SHOW_IMAGE_TAB = 'add_post_franchisor_action/AI_SHOW_IMAGE_TAB';

// ADD POST GET HASHTAGS LIST
export const AP_GET_HASHTAGS_LIST_REQUEST = 'add_post_franchisor_action/AP_GET_HASHTAGS_LIST_REQUEST';
export const AP_GET_HASHTAGS_LIST_RESPONSE = 'add_post_franchisor_action/AP_GET_HASHTAGS_LIST_RESPONSE';
export const AP_GET_HASHTAGS_LIST_FAILURE = 'add_post_franchisor_action/AP_GET_HASHTAGS_LIST_FAILURE';

// ADD POST CREATE HASHTAG
export const AP_CREATE_HASHTAG_REQUEST = 'add_post_franchisor_action/AP_CREATE_HASHTAG_REQUEST';
export const AP_CREATE_HASHTAG_RESPONSE = 'add_post_franchisor_action/AP_CREATE_HASHTAG_RESPONSE';
export const AP_CREATE_HASHTAG_FAILURE = 'add_post_franchisor_action/AP_CREATE_HASHTAG_FAILURE';

// ADD POST DELETE HASHTAG
export const AP_DELETE_HASHTAG_REQUEST = 'add_post_franchisor_action/AP_DELETE_HASHTAG_REQUEST';
export const AP_DELETE_HASHTAG_RESPONSE = 'add_post_franchisor_action/AP_DELETE_HASHTAG_RESPONSE';
export const AP_DELETE_HASHTAG_FAILURE = 'add_post_franchisor_action/AP_DELETE_HASHTAG_FAILURE';

// ADD POST SELECT HASHTAG
export const AP_SELECT_HASHTAG_RESPONSE = 'add_post_franchisor_action/AP_SELECT_HASHTAG_RESPONSE';

// SET CUSTOMIZE LINK ENABLE
export const SET_CUSTOMIZE_LINK_ENABLE = 'add_post_franchisor_action/SET_CUSTOMIZE_LINK_ENABLE';

// ADD CUSTOMIZE LINK
export const ADD_CUSTOMIZE_LINK = 'add_post_franchisor_action/ADD_CUSTOMIZE_LINK';

// ADD LOCALIZE LINK
export const ADD_LOCALIZE_LINK = 'add_post_franchisor_action/ADD_LOCALIZE_LINK';

// ADD LOCALIZE LINK URL
export const ADD_LOCALIZE_LINK_URL = 'add_post_franchisor_action/ADD_LOCALIZE_LINK_URL';

// SET CREATOR VIDEO THUMNAIL MODAL NAME
export const SET_CREATOR_VIDEO_THUMBNAIL_MODAL_NAME = 'add_post_franchisor_action/SET_CREATOR_VIDEO_THUMBNAIL_MODAL_NAME';

// ADD POST TRANSLATE CAPTION
export const AP_CAPTION_TRANSLATE_REQUEST = 'add_post_franchisor_action/AP_CAPTION_TRANSLATE_REQUEST';
export const AP_CAPTION_TRANSLATE_RESPONSE = 'add_post_franchisor_action/AP_CAPTION_TRANSLATE_RESPONSE';
export const AP_CAPTION_TRANSLATE_FAILURE = 'add_post_franchisor_action/AP_CAPTION_TRANSLATE_FAILURE';

// ADD POST SET CREATOR ORIGINAL TEXT
export const AP_SET_CREATOR_ORIGINAL_TEXT = 'add_post_franchisor_action/AP_SET_CREATOR_ORIGINAL_TEXT';

// ADD POST RESSET LOCALIZE PREVIEW LINKS
export const AP_RESET_LOCALIZE_PREVIEW_LINKS = 'add_post_franchisor_action/AP_RESET_LOCALIZE_PREVIEW_LINKS';
