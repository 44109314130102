import { communicationContainerTypes } from 'types';

export const CONTENT_TYPE_OPTION_LABELS = {
  PROMOTIONAL: 'Promotional'
};

export const CONTENT_TYPE_OPTION_VALUES = {
  PROMOTIONAL: 'promotional'
};
export const CREATOR_CONTENT_TYPE_OPTIONS = [{ id: 1, label: CONTENT_TYPE_OPTION_LABELS.PROMOTIONAL, value: CONTENT_TYPE_OPTION_VALUES.PROMOTIONAL }];

export const COMMUNICATION_TAB_FIELDS: Record<string, keyof communicationContainerTypes.ICreateCommunicationInitialValues> = {
  // SUBJECT: 'subject',
  BODY: 'body',
  SEND_ALL_ACCOUNTS: 'send_to_all_accounts',
  SEND_EMAIL_NOTIFICATION: 'send_email_notification'
};
