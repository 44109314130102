import { Modal } from 'react-bootstrap';

import { MultiSelectMediaOrPostFilter } from 'layouts/Filter/SubPages/MultiSelectMediaOrPostFilter';
import { IMultiSelectModalProps } from 'types/common/filter';
import { ImageValidation } from 'widgets/Media';

export const MultiSelectMediaOrPostModal = ({ isShowModal, onModalClose, children, type }: IMultiSelectModalProps) => {
  return (
    <Modal
      show={isShowModal}
      className="connection-secondary add-location ralprofile-hl add-booster cal-soicalpreview-secion-wrp-modal addpost-campaign-only multiSelModal"
      centered
      size="lg"
      animation={false}
      onHide={onModalClose}
    >
      <Modal.Body>
        <div className="sec-conn__wrp">
          <div className="sec-header done__sec--header  modal-mbl-filter--main">
            <div className="header-item__wrp">
              <span className="title">Multi select {type}s</span>
              <div className="apch-right">
                <div className="mod__close--icon header__close--icon">
                  <ImageValidation isImgValid defaultImg="mpcIcon" customName="close" customClassname="close-icon close mod__close--img wh-30" onClick={onModalClose} />
                </div>
              </div>
              <button className={`modal-mbl--filter mobile-filter`} />
            </div>
          </div>
          <div className="sec-main__content hubuser-createpost-new-section calendar-edit__modal">
            <div className="addpost-section-main-wrp">{children}</div>
            <div className={`right-button-section post-mbl-filter`}>
              <MultiSelectMediaOrPostFilter mediaType={type} handleMainModalClose={onModalClose} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
