import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';

import { IStore } from 'types';
import { MG_MEDIA_TYPE, MOMENT_DATE_FORMAT_TH, MgMediaType, PostOrImageEditStatus, ROUTES, USER_OWNERSHIP } from 'utils/constants';
import {
  dateFormatByRequestedFormat,
  getApprovedStatus,
  mgLinkAccessProvided,
  mgProvidedBy,
  mgLinkAccess,
  mgMediaAssetLikeDislikeFeature,
  handleLinkNavigation /* mgProvidedById, */,
  capitalizeName
} from 'utils/helpers';
import { ImageValidation } from 'widgets/Media';
import { mgDetailDataReset } from 'actions';
import { PLATFORM_FILTER } from 'analytics/utils';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';

export const MgImageDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userOwnership, currentRoute, optionalParams, dropDownData } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const mgDetails = useSelector((state: IStore) => state.mediaGallery.mgDetails);
  const activeUser = useSelector((state: IStore) => state.login.activeUser);
  const franMediaAssetLikeDislikeFeature = useSelector((state: IStore) => state.franchisors.franchisorDetails?.media_asset_like_dislike_feature);
  const accMediaAssetLikeDislikeFeatureEnabled = useSelector((state: IStore) => state.accounts.accountDetails?.account?.media_asset_like_dislike_feature_enabled);
  const postSyndicationDetails = useSelector((state: IStore) => state.addPostFranchisor.postSyndicationDetails);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);

  const mediaAssetLikeDislikeFeature = mgMediaAssetLikeDislikeFeature(userOwnership, franMediaAssetLikeDislikeFeature, accMediaAssetLikeDislikeFeatureEnabled);

  const mediaType = optionalParams[0];

  const handleNavigateLocation = (type: string, validId?: number) => {
    if (mgDetails?.id && validId) {
      dispatch(mgDetailDataReset());
      navigate({ pathname: `/${type}/${validId.toString()}/${currentRoute}/photos` });
    }
  };

  const handleNavigateProfile = () => {
    if (mgDetails?.created_by_user_id) {
      navigate({ search: `?${new URLSearchParams({ ...queryParams, user_id: String(mgDetails?.created_by_user_id) }).toString()}` });
    }
  };

  const isImageEditEnabled = () => {
    if (mgDetails?.locked) {
      return PostOrImageEditStatus.NOT_ALLOWED;
    } else {
      return PostOrImageEditStatus.ALLOWED;
    }
  };

  const renderLastSyndicatedLabel = () => {
    return postSyndicationDetails.map((syndication, index) => {
      const validId = isAdmin ? syndication.franchisor_id : dropDownData.find((it) => it.value === syndication.franchisor_id)?.value;
      return (
        <Fragment key={index}>
          <span
            className={`wbfi-txt${validId ? ' blue-text cur-pointer' : ''} ms-2`}
            onClick={() => {
              if (validId) {
                navigate(
                  ROUTES.CONTENT_MEDIA.replace(':type', USER_OWNERSHIP.FRANCHISOR)
                    .replace(':id', validId.toString())
                    .replace('/*', `/${mediaType === MgMediaType.PHOTOS ? MgMediaType.PHOTOS : MgMediaType.VIDEOS}`)
                );
              }
            }}
            key={`syndication-${index}`}
          >
            {capitalizeName(syndication.franchisor_name || '')}
          </span>
          {index + 1 === postSyndicationDetails.length ? null : index === postSyndicationDetails.length - 2 ? (
            <span className="mx-2">{syndication.franchisor_name ? 'and' : ''} </span>
          ) : (
            <span className="me-2">,</span>
          )}
        </Fragment>
      );
    });
  };

  const MediaField = ({
    label,
    value,
    commentPlatform,
    commentUrl,
    isCommentFrom,
    accountObj,
    isProvidedby,
    labelChild
  }: {
    label: string;
    value: string | number;
    commentPlatform?: string;
    commentUrl?: string;
    isCommentFrom?: boolean;
    accountObj?: { type: string; id: number };
    isProvidedby?: boolean;
    labelChild?: () => JSX.Element[];
  }) => {
    const validId = isAdmin ? accountObj?.id : dropDownData.find((it) => it.value === accountObj?.id)?.value;
    return (
      <div className="wb-fields">
        <div className="wbf-item">
          <div className="wbf-label-main">
            <span className="wbf-label">{label}</span>
          </div>
          {accountObj?.id ? (
            <div className={`wbf-inputs wbfi-txt${validId ? ' blue-text cur-pointer' : ''}`} onClick={() => handleNavigateLocation(accountObj.type, validId)}>
              {value}
            </div>
          ) : (
            <div className={`wbf-inputs ${isCommentFrom ? 'db-txt flex-center' : isProvidedby ? 'wbfi-txt blue-text cur-pointer' : ''}`} onClick={() => isProvidedby && handleNavigateProfile()}>
              {value}
              {labelChild ? labelChild() : null}
              <ImageValidation
                isImgValid
                defaultImg={PLATFORM_FILTER.find((datum) => (commentPlatform === 'Google' ? datum.value === 'google' : datum?.label === commentPlatform))?.platformImg || null}
                customName={'Platform'}
                onClick={() => commentUrl && handleLinkNavigation(commentUrl)}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const MediaSourceField = ({ label, locationName, userName }: { label: string; locationName: string; userName: string }) => (
    <div className="wb-fields srnMain">
      <div className="wbf-item srnWraps">
        <div className="wbf-label-main">
          <span className="wbf-label">{label}</span>
        </div>
        <div className="srnItem">
          <div className="wbf-inputs">
            <div className="srn-icon">
              <ImageValidation isImgValid defaultImg={'location-grey'} customName={'location'} />
            </div>
            <span title={locationName}>{locationName}</span>
          </div>
          <div className="wbf-inputs">
            <div className="srn-icon">
              <ImageValidation isImgValid defaultImg={'user-grey'} customName={'user'} />
            </div>
            <span title={userName}>{userName}</span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    // <div className="white-box wb-second">
    <div className="post__white--box wb-second">
      <div className="wb-top">
        <div className="wb-label">
          <div className="wbl-head">{mgDetails?.media_type === MG_MEDIA_TYPE.PHOTO ? 'Image Details' : mgDetails?.media_type === MG_MEDIA_TYPE.VIDEO ? 'Video Details' : 'Document Details'}</div>
        </div>
        <div className="wb-f-main">
          <MediaField label="Uploaded on" value={mgDetails?.created_at ? dateFormatByRequestedFormat(mgDetails?.created_at || '', MOMENT_DATE_FORMAT_TH) || '---' : '---'} />
          <MediaField label="AI generated" value={mgDetails?.ai_photo ? 'Yes' : 'No'} />
          {mgDetails?.media_type === MG_MEDIA_TYPE.PHOTO && <MediaField label="Image Editing and Downloading" value={isImageEditEnabled()} />}
          {mediaType !== MgMediaType.DOCUMENTS ? <MediaField label="Times used" value={mgDetails?.used_count || '0'} /> : null}
          {mediaAssetLikeDislikeFeature ? <MediaField label="Approved" value={mgDetails ? getApprovedStatus(mgDetails?.liked) : '---'} /> : null}
          {mgDetails?.copied_from_account_name ? (
            <MediaSourceField label="Source" locationName={mgDetails?.copied_from_account_name || '---'} userName={mgDetails?.created_by_user_name || '---'} />
          ) : null}
          {(mgDetails?.franchisor_id === mgDetails?.original_franchisor_id && userOwnership === USER_OWNERSHIP.FRANCHISOR) || mgDetails?.account_id ? (
            mgDetails?.comment_from ? (
              <MediaField
                label="Provided by"
                value={mgDetails?.comment_from || '---'}
                isCommentFrom={Boolean(mgDetails?.comment_from)}
                commentPlatform={mgDetails?.comment_platform}
                commentUrl={mgDetails?.comment_url}
              />
            ) : mgLinkAccessProvided(activeUser, mgDetails) ? (
              mgDetails?.account_id ? (
                <MediaField label="Provided by" value={mgProvidedBy(mgDetails) || '---'} isProvidedby={Boolean(mgDetails?.created_by_user_name)} />
              ) : (
                <MediaField
                  label="Provided by"
                  value={mgProvidedBy(mgDetails) || '---'}
                  isProvidedby={Boolean(mgDetails?.created_by_user_name)} /* accountObj={{ type: USER_OWNERSHIP.FRANCHISOR, id: mgProvidedById(mgDetails) }} */
                />
              )
            ) : (
              <MediaField label="Provided by" value={mgProvidedBy(mgDetails) || '---'} isProvidedby={Boolean(mgDetails?.created_by_user_name)} />
            )
          ) : null}

          {mgDetails?.franchisor_id ? (
            mgDetails?.original_franchisor_id ? (
              mgDetails?.original_franchisor_id === mgDetails?.brand_id ? (
                <MediaField
                  label="Brand"
                  value={mgDetails?.brand_name || '---'}
                  accountObj={mgLinkAccess(activeUser, mgDetails) ? { type: USER_OWNERSHIP.FRANCHISOR, id: mgDetails?.brand_id || 0 } : undefined}
                />
              ) : (
                <MediaField
                  label="Hub"
                  value={mgDetails?.original_franchisor_name || '---'}
                  accountObj={mgLinkAccess(activeUser, mgDetails) ? { type: USER_OWNERSHIP.FRANCHISOR, id: mgDetails?.original_franchisor_id || 0 } : undefined}
                />
              )
            ) : mgDetails?.franchisor_id === mgDetails?.brand_id ? (
              <MediaField
                label="Brand"
                value={mgDetails?.brand_name || '---'}
                accountObj={mgLinkAccess(activeUser, mgDetails) ? { type: USER_OWNERSHIP.FRANCHISOR, id: mgDetails?.brand_id || 0 } : undefined}
              />
            ) : (
              <MediaField
                label="Hub"
                value={mgDetails?.franchisor_name || '---'}
                accountObj={mgLinkAccess(activeUser, mgDetails) ? { type: USER_OWNERSHIP.FRANCHISOR, id: mgDetails?.franchisor_id || 0 } : undefined}
              />
            )
          ) : (
            <MediaField
              label="Location"
              value={mgDetails?.account_name || '---'}
              accountObj={mgLinkAccess(activeUser, mgDetails) ? { type: USER_OWNERSHIP.ACCOUNT, id: mgDetails?.account_id || 0 } : undefined}
            />
          )}
          {postSyndicationDetails && postSyndicationDetails.length ? (
            <MediaField
              label="Last Syndicated"
              value={mgDetails?.created_at ? dateFormatByRequestedFormat(mgDetails?.created_at || '', MOMENT_DATE_FORMAT_TH) || '---' : '---'}
              labelChild={renderLastSyndicatedLabel}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
