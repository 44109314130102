import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Tag } from 'react-tag-autocomplete';

import { debounceMethod, formNewInternalTag } from 'utils/helpers';
import { FranchisorSyndicationMediaType, RIPPLE_COLOR } from 'utils/constants';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { IStore } from 'types';
import { addFranchisorUpdateMultiTagsRequest, addPostFranUpdatedMsgReset, addPostSetTags, campaignSelectedPostsReset, mgResetSelectedMediaList, postsTagsRequest } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';

export const AddMultiSelectTagsModal = ({
  isModalShow,
  onModalClose,
  mediaType,
  handleMainModalClose
}: {
  isModalShow: boolean;
  onModalClose: () => void;
  handleMainModalClose: () => void;
  mediaType: string;
}) => {
  const dispatch = useDispatch();

  const { id } = useAccountSwitcherData();
  const internalTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const selectedPosts = useSelector((state: IStore) => state.campaigns.selectedPosts);
  const selectedMediaIds = useSelector((state: IStore) => state.mediaGallery.selectedMediaIds);
  const successMsg = useSelector((state: IStore) => state.addPostFranchisor.updatedMessage.contentSuccessMsg);
  const failureMsg = useSelector((state: IStore) => state.addPostFranchisor.updatedMessage.contentFailureMsg);

  const [searchValue, setSearchValue] = useState<null | string>(null);

  let tagsApiTimeout: null | NodeJS.Timeout = null;
  const tagsList = formNewInternalTag(internalTagsList, searchValue);

  useEffect(() => {
    if (successMsg || failureMsg) {
      dispatch(addPostFranUpdatedMsgReset());
      onModalClose();
      handleMainModalClose();
      // reset selected tags and selected post or media
      dispatch(addPostSetTags([]));
      if (mediaType === FranchisorSyndicationMediaType.CONTENT) {
        dispatch(campaignSelectedPostsReset());
      } else {
        dispatch(mgResetSelectedMediaList());
      }
    }
  }, [successMsg, failureMsg, onModalClose, mediaType, handleMainModalClose, dispatch]);

  const handleAddTags = (tag: Tag) => {
    tag.name = internalTagsList.length === 0 ? tag.name.replace('Add ', '') : tag.name;
    const addedTagsArray: Tag[] = [...selectedTags, tag];
    dispatch(addPostSetTags(addedTagsArray));
  };

  const handleInputChange = (search: string) => {
    setSearchValue(search);
    dispatch(postsTagsRequest({ searchTag: search, ...(isContentSupplier && { contentSupplierId: id }) }));
  };

  const handleDeleteTag = (idx: number) => {
    if (idx !== -1) {
      const removedTags = selectedTags;
      removedTags.splice(idx, 1);
      dispatch(addPostSetTags(removedTags));
    }
  };

  const massUpdateTags = () => {
    dispatch(
      addFranchisorUpdateMultiTagsRequest({
        tags_list: selectedTags.map((tag) => tag.name).join(','),
        [`${mediaType}_ids`]: mediaType === FranchisorSyndicationMediaType.CONTENT ? selectedPosts : selectedMediaIds,
        mediaType
      })
    );
  };

  return (
    <Modal
      className={`glbl__modal--wrp syndicate-post-modal-hubuser syndicate__modal--wrp addmsTags`}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      animation={false}
      onHide={onModalClose}
    >
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg={'close-creator'} customName={'close'} customClassname="mod__close--img" onClick={onModalClose} />
      </div>

      <Modal.Body>
        <h3 className="glbl__title--txt">Add Tags</h3>
        <div className={`cscl-selTags`}>
          <div className={`form-group form-field-item`}>
            <CustomReactTags
              key={`add-tags-dropdown`}
              suggestions={tagsList}
              handleInputChange={(search: string) => {
                tagsApiTimeout = debounceMethod(search, tagsApiTimeout, handleInputChange);
              }}
              handleDelete={() => false}
              maxSuggestionsLength={tagsList.length}
              handleAddition={(tag: Tag) => handleAddTags(tag)}
              placeholder="Search"
              autofocus
              allowNew
              minQueryLength={1}
            />
            <span className={`fltlabels`}>{'Select Tags'}</span>
          </div>
          {selectedTags?.length ? (
            <div className="tags-wrp">
              {selectedTags?.length &&
                selectedTags.map((tagDatum: Tag, index: number) => {
                  return (
                    <div className="list-expanded-tag-item" key={`loc-creator-tag-${index}`}>
                      <span className="list-expanded-tag-text">{tagDatum.name}</span>
                      <span className="list-expanded-tag-remove" onClick={() => handleDeleteTag(index)} />
                    </div>
                  );
                })}
            </div>
          ) : null}
        </div>
        <div className="footer-section">
          <CustomRippleButton rippleClass={`ac-primary-box ${!selectedTags?.length ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary ac-block`} onClick={massUpdateTags}>
              Save
            </button>
          </CustomRippleButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
