import { useSelector } from 'react-redux';

import { AreaSplineChart } from 'analytics/components';
import { INominalGraphSeries, IReviewAnalyticsFilterObj } from 'analytics/types';
import {
  ChartColors,
  REVIEW_ANALYTICS_STATS,
  REVIEW_PLATFORM_CHART_OPTIONS,
  ReviewAnalyticsStatsValue,
  convertChartMsTimeFormat,
  multiSeriesToolTipFormatter,
  onLoadHandler,
  squigglyLineSvgPath,
  yLabelFormatter
} from 'analytics/utils';
import { config } from 'config';
import { IStore } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';

interface IKeywordsCategoryAreaSplineChartProps {
  filter: IReviewAnalyticsFilterObj;
  isViewNumber: boolean;
  currentTab: string;
  tabGraphSeries: INominalGraphSeries;
}
export const KeywordsCategoryAreaSplineChart = ({ isViewNumber, currentTab, filter, tabGraphSeries }: IKeywordsCategoryAreaSplineChartProps) => {
  const groupedTableData = useSelector((state: IStore) => state.reviewAnalytics.groupedTableData);

  const colorOptions = REVIEW_PLATFORM_CHART_OPTIONS.filter((it) => (filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? it : it.value === filter.platform));
  const chartColorOptions = [...colorOptions].filter((it) => Object.keys(tabGraphSeries?.data).includes(it.value));

  const isBrandAverage = Object.keys(tabGraphSeries?.data).includes('average');
  const isHubAverage = Object.keys(tabGraphSeries?.data).includes('hubAverage');
  const isAverageOnly = Object.keys(tabGraphSeries?.data).length === 1 ? isBrandAverage || isHubAverage : Object.keys(tabGraphSeries?.data).length === 2 ? isBrandAverage && isHubAverage : false;
  const chartData = [...REVIEW_ANALYTICS_STATS].find((it) => it.value === currentTab);
  const chartTitle = currentTab === ReviewAnalyticsStatsValue.REPLY ? (isViewNumber ? chartData?.chartTitle : chartData?.chartTitlePerc) : chartData?.chartTitle;
  const primarySvgPath = squigglyLineSvgPath.replaceAll('hpx', '212px').replaceAll('wpx', '49px');
  return (
    <>
      <AreaSplineChart
        title={chartTitle || ''}
        height="520"
        series={{
          ...tabGraphSeries?.data
        }}
        color={
          isAverageOnly
            ? [...(isBrandAverage ? [ChartColors.BLACK] : []), ...(isHubAverage ? ['#1F527C'] : [])]
            : [...chartColorOptions].map((it) => it.color as string).concat([...(isBrandAverage ? [ChartColors.BLACK] : []), ...(isHubAverage ? ['#1F527C'] : [])])
        }
        fillColor={[...chartColorOptions].map((it) => it.fillColor)}
        fadeColor={[...chartColorOptions].map((it) => it.fadeColor)}
        immutable
        yMin={currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING ? 1 : undefined}
        yMax={
          currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING
            ? 5
            : currentTab === ReviewAnalyticsStatsValue.REPLY && !isViewNumber
            ? 100
            : // currentTab !== ReviewAnalyticsStatsValue.RESPONSE_TIME ?
            tabGraphSeries?.tickInterval
            ? tabGraphSeries?.tickInterval * 5
            : undefined
        }
        // : undefined}
        isLegendEnabled
        xCategories={groupedTableData?.headers ? [...groupedTableData?.headers].slice(1).map((it) => it.title) : undefined}
        plotLines={
          currentTab !== ReviewAnalyticsStatsValue.RESPONSE_TIME && currentTab !== ReviewAnalyticsStatsValue.AVERAGE_RATING && tabGraphSeries.tickInterval
            ? [
                {
                  value: tabGraphSeries?.tickInterval * 3
                }
              ]
            : []
        }
        onLoadHandler={(data: Highcharts.Chart) =>
          currentTab !== ReviewAnalyticsStatsValue.RESPONSE_TIME && currentTab !== ReviewAnalyticsStatsValue.AVERAGE_RATING ? onLoadHandler(data, primarySvgPath) : undefined
        }
        yTickInterval={currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING ? 1 : tabGraphSeries?.tickInterval}
        yLabelFormatter={(it) => {
          return currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING
            ? `${it}<img class='star-icon' src=${config.cdnImgUrl}star.svg alt="rating increase icon" />`
            : currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME
            ? `${convertChartMsTimeFormat(+it)}`
            : currentTab === ReviewAnalyticsStatsValue.REPLY && !isViewNumber
            ? `${it}%`
            : `${yLabelFormatter(+it, currentTab, { data: {} }, tabGraphSeries)}`;
        }}
        toolTipformatter={(it: Highcharts.TooltipFormatterContextObject) =>
          multiSeriesToolTipFormatter(
            it,
            chartColorOptions,
            tabGraphSeries?.data,
            false,
            currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING || currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME,
            currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME,
            false,
            currentTab === ReviewAnalyticsStatsValue.REPLY && !isViewNumber,
            currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING
          )
        }
        yTickAmount={currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME ? 7 : undefined}
        isTooltipDisabled={isAverageOnly}
      />
    </>
  );
};
