import { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { MentionData } from '@draft-js-plugins/mention';

import { apAccountContainerTypes, IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { addPostGetFBTagsListRequest, addPostUpdateSavedPostDetails, addPostGetSelectedFBTagList, addPostFBTagsListReset, addPostGetSelectedContentObj } from 'actions';
import { Loading, NoDataFound } from 'components';
import { NoDataFoundProps, CREATOR_USER_OWNERSHIP, USER_OWNERSHIP } from 'utils/constants';
import { convertStringToEditorState, debounceMethod } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { EditorStateContext } from '..';

export const AddTagPagesModal = (props: apAccountContainerTypes.IAPTagPagesModalProps) => {
  const dispatch = useDispatch();

  const { isModalShow, handleModalClose } = props;
  const { id, userOwnership } = useAccountSwitcherData();

  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const postStatus = useSelector((state: IStore) => state.addPostFranchisor.contentObj.postStatus);
  const customText = useSelector((state: IStore) => state.addPostFranchisor.contentObj.customText);
  const isFranchisorFBTaggingEnabled = useSelector((state: IStore) => state.franchisors.franchisorDetails?.facebook_page_tagging_feature || false);
  const isAccountFBTaggingEnabled = useSelector((state: IStore) => state.accounts.accountDetails?.account?.facebook_page_tagging_feature || false);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const isListFetching = useSelector((state: IStore) => state.addPostAccount.fbTagsData.isListFetching);
  const fbTagsList = useSelector((state: IStore) => state.addPostAccount.fbTagsData.fbTagsList);

  const [selectedFBTag, setSelectedFBTag] = useState<MentionData>({ name: '' });
  const [textEditorState, setEditorState] = useContext(EditorStateContext);

  let tagPagesApiTimeout: null | NodeJS.Timeout = null;

  useEffect(() => {
    if (isModalShow && fbTagsList.length) dispatch(addPostFBTagsListReset());
  }, [isModalShow, dispatch]); // eslint-disable-line

  const handleSearchChange = (searchValue: string) => {
    if (
      creatorCompName &&
      [CREATOR_USER_OWNERSHIP.ACCOUNT, CREATOR_USER_OWNERSHIP.LOCATION_CONTENT].includes(creatorCompName) &&
      isAccountFBTaggingEnabled &&
      searchValue &&
      savedPostDetails?.use_facebook
    ) {
      dispatch(addPostGetFBTagsListRequest(searchValue));
    } else if (creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR && isFranchisorFBTaggingEnabled && searchValue && postStatus.facebook) {
      dispatch(addPostGetFBTagsListRequest(searchValue));
    }
  };

  const handleUpdateFacebookTag = (fbData: MentionData) => {
    setSelectedFBTag(fbData);
  };

  const handleFBTagsResetData = () => {
    if (fbTagsList.length) dispatch(addPostFBTagsListReset());
    handleModalClose();
  };

  const handleAddFacebookTags = () => {
    if (!disableTagUpdateAction()) {
      dispatch(addPostGetSelectedFBTagList([...selectedFBTagList, selectedFBTag]));
      const facebookTagText =
        userOwnership === USER_OWNERSHIP.ACCOUNT
          ? savedPostDetails?.facebook_text
            ? `${savedPostDetails?.facebook_text} @${selectedFBTag.name}`
            : `@${selectedFBTag.name}`
          : customText.facebook
          ? `${customText.facebook} @${selectedFBTag.name}`
          : `@${selectedFBTag.name}`;

      setEditorState({ ...textEditorState, facebook: convertStringToEditorState(facebookTagText) });
      if (creatorCompName && [CREATOR_USER_OWNERSHIP.ACCOUNT, CREATOR_USER_OWNERSHIP.LOCATION_CONTENT].includes(creatorCompName)) {
        dispatch(
          addPostUpdateSavedPostDetails(
            savedPostDetails
              ? {
                  ...savedPostDetails,
                  facebook_text: facebookTagText,
                  use_facebook: true
                }
              : null
          )
        );
      } else {
        dispatch(
          addPostGetSelectedContentObj({
            ...contentObj,
            customText: {
              ...customText,
              facebook: facebookTagText
            }
          })
        );
      }
      handleFBTagsResetData();
    }
  };

  const disableTagUpdateAction = () => Boolean(!selectedFBTag.id);

  return (
    <>
      <Modal
        className="creator-tag-modal-wrp addTagsModal addtagMain"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModalShow}
        onHide={handleModalClose}
        animation={false}
      >
        <Modal.Body>
          <h3>Tag Pages</h3>
          {/* <div className="close">
            <ImageValidation isImgValid defaultImg={'close'} customName={'close'} onClick={handleFBTagsResetData} />
          </div> */}
          <div className="mod__close--icon">
            <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={handleFBTagsResetData} />
          </div>
          <div className="search-tk">
            <CustomReactTags
              key={`page-tag-${id}-${creatorCompName}`}
              suggestions={[]}
              handleInputChange={(search: string) => {
                tagPagesApiTimeout = debounceMethod(search, tagPagesApiTimeout, handleSearchChange);
              }}
              handleAddition={(tag) => false}
              handleDelete={() => false}
              placeholder="Search"
              autofocus={false}
            />
          </div>

          <div className="sug-section">
            <h4>SUGGESTIONS</h4>
            {!isListFetching && fbTagsList.length ? (
              fbTagsList.map((fbDatum, index) => {
                return (
                  <div className={`p-header-title${selectedFBTag.id === fbDatum.id ? ` active` : ``}`} key={`creator-tag-page-${index}`} onClick={() => handleUpdateFacebookTag(fbDatum)}>
                    <div className="p-avatar">
                      <ImageValidation isImgValid imgUrl={fbDatum.avatar} defaultImg={'user-avatar-common.png'} isNotSvgFormat customName={'page-avatar'} onClick={handleFBTagsResetData} />
                    </div>
                    <div className="p-title">
                      <h5>{fbDatum.label}</h5>
                      {/* <span>
                          Internet company 1.7k like this
                        </span> */}
                    </div>
                  </div>
                );
              })
            ) : isListFetching ? (
              <Loading isSmall />
            ) : (
              <NoDataFound size={NoDataFoundProps.MEDIUM} />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={`tags-btn-done${disableTagUpdateAction() ? ` pointer-events-none` : ``}`} onClick={handleAddFacebookTags}>
            Done
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
