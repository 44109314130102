// import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';

export const SocialMediaAlertMessage = ({ message }: { message: string }) => {
  return (
    <div className="instaAlert">
      <p>{message}</p>
    </div>
  );
};

export const LimitExceedAlertMessage = ({ socialMedia, isHashTagLimit }: { socialMedia: string; isHashTagLimit?: boolean }) => {
  return (
    <div className="instaAlert">
      <p>{`Content exceeds ${socialMedia} ${isHashTagLimit ? 'hashtag' : 'character'} limit`}</p>
      {/* Reverted back RAS-4886 ticket */}
      {/* <p>
        {socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
          ? 'Content exceeds Twitter/X character limit. If you choose to post it anyway, please ensure that you are subscribed to X Premium, or the post will fail.'
          : `Content exceeds ${socialMedia} ${isHashTagLimit ? 'hashtag' : 'character'} limit`}
      </p> */}
    </div>
  );
};
