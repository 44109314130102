import { useNavigate } from 'react-router-dom';

import { ModalPopup } from 'components';
import { commonModalPopup } from 'types';
import { ENABLE_MANUAL_SETTINGS_AI_REVIEW_RESPONDER, MODAL_BOX_TYPES, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData } from 'utils/hooks';

export const EnableManualSettingsReviewModal = ({ isShowModal, onModalClose }: commonModalPopup.ICommonModalProps) => {
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();

  return (
    <ModalPopup
      isModalShow={isShowModal}
      modalHeaderType={MODAL_BOX_TYPES.INFO}
      containerClassName={'modal-confirmation confirm-delete'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{ENABLE_MANUAL_SETTINGS_AI_REVIEW_RESPONDER}</div>
        </div>
      )}
      modalCloseButton
      handleModalClose={() => {
        onModalClose();
      }}
      isModalFooterShow
      modalFooter={() => (
        <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
          <button
            className={`ac-btn ac-primary ac-block kc-save`}
            type="submit"
            onClick={() => {
              navigate(`/${userOwnership}/${id}/reputation/ai_review_responder`);
              onModalClose();
            }}
          >
            Go to AI Review Settings
          </button>
        </CustomRippleButton>
      )}
    />
  );
};
