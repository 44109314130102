import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from 'react';

import { ICarouselTableProps, IIndexable } from 'types';
import { Loading } from 'components';
import { CarouselTable } from '../Table';

interface IPagination<T> {
  currentPage: number;
  totalPage: number;
  activeData: T[];
}

export const VirtualTable = <T extends IIndexable, E extends {} = {}>({
  scrollHolderClassName,
  scrollThreshold,
  scrollDivHeight,
  data,
  headers,
  tableClassName,
  isFetching,
  noDataMesg,
  ipadBoundary,
  ipadColCount,
  mobileBoundary,
  mobileColCount,
  mdDeviceBoundary,
  mdDeviceColCount,
  pinnedColumns,
  pinColumnsRight,
  responsiveNavigation,
  onHeaderClick,
  onRowClick,
  onColumnClick,
  onCustomHeaderDropDownClick,
  responsiveNavigationNextIcon,
  responsiveNavigationPreviousIcon,
  renderResponsiveNavNextIcon,
  renderResponsiveNavPrevIcon,
  scrollableTargetString, // For modal based infinite scroll
  colState,
  additionalColumnTitle,
  hasAdditionalColumns,
  handleShowAdditionalColumns
}: ICarouselTableProps<T, E>) => {
  const [page, setPage] = useState<IPagination<T>>({
    currentPage: 1,
    totalPage: 1,
    activeData: []
  });

  const rowData = data[data.length - 1];
  useEffect(() => {
    setPage({
      currentPage: 1,
      totalPage: rowData?.length ? Math.floor(rowData?.length / 20) + (rowData?.length % 20 > 0 ? 1 : 0) : 1,
      activeData: rowData?.slice(0, 20) || []
    });
  }, [rowData]);

  return (
    <InfiniteScroll
      scrollableTarget={scrollableTargetString || 'scrollTarget'}
      scrollThreshold={scrollThreshold}
      height={scrollDivHeight}
      className={scrollHolderClassName}
      hasMore={page.totalPage >= page.currentPage}
      dataLength={page.activeData.length}
      next={() => {
        const rowData = data[data.length - 1];
        const currentPage = page.currentPage + 1;
        setPage({
          ...page,
          currentPage,
          activeData: page.activeData.concat(rowData.slice((currentPage - 1) * 20, (currentPage - 1) * 20 + 20))
        });
      }}
      loader={isFetching ? <Loading /> : null}
    >
      <CarouselTable<T, E>
        data={data.length > 1 ? [data[0], page.activeData] : [page.activeData]}
        tableClassName={tableClassName}
        headers={headers}
        isFetching={isFetching}
        noDataMesg={noDataMesg}
        ipadBoundary={ipadBoundary}
        ipadColCount={ipadColCount}
        mobileBoundary={mobileBoundary}
        mobileColCount={mobileColCount}
        mdDeviceBoundary={mdDeviceBoundary}
        mdDeviceColCount={mdDeviceColCount}
        pinnedColumns={pinnedColumns}
        pinColumnsRight={pinColumnsRight}
        responsiveNavigation={responsiveNavigation}
        onHeaderClick={onHeaderClick}
        onRowClick={onRowClick}
        onColumnClick={onColumnClick}
        onCustomHeaderDropDownClick={onCustomHeaderDropDownClick}
        responsiveNavigationNextIcon={responsiveNavigationNextIcon}
        responsiveNavigationPreviousIcon={responsiveNavigationPreviousIcon}
        renderResponsiveNavNextIcon={renderResponsiveNavNextIcon}
        renderResponsiveNavPrevIcon={renderResponsiveNavPrevIcon}
        colState={colState}
        hasAdditionalColumns={hasAdditionalColumns}
        additionalColumnTitle={additionalColumnTitle}
        handleShowAdditionalColumns={handleShowAdditionalColumns}
      />
    </InfiniteScroll>
  );
};
