import { useSelector } from 'react-redux';

import { KEYWORDS_CATEGORY_TAB_FIELDS } from 'utils/constants';
import { KeywordsCard } from './KeywordsCard';
import { TextArea } from 'widgets/Text';
import { IStore, reviewsContainerTypes } from 'types';

export const KeywordsManagementContainer = ({
  values,
  handleBlur,
  handleChange,
  getInputTitleClassName,
  getInputBoxClassName,
  getClassNames,
  allKeywordsList
}: reviewsContainerTypes.IKeywordManagementContainerProps) => {
  const isAllKeywordsFetching = useSelector((state: IStore) => state.reviewAnalytics.isAllKeywordsFetching);

  const handleCategories = (keyword: string) => {
    if (values.keywords.includes(keyword)) {
      handleChange(
        KEYWORDS_CATEGORY_TAB_FIELDS.KEYWORDS,
        values.keywords.filter((data) => data !== keyword)
      );
    } else {
      handleChange(KEYWORDS_CATEGORY_TAB_FIELDS.KEYWORDS, [...values.keywords, keyword]);
    }
  };

  return (
    <div className="sec-main__content hubuser-createpost-new-section">
      <div className="ra-card sec-conn__wrp">
        <>
          <div className={`form-group form-field-item kc-ctg-name ${getInputBoxClassName(KEYWORDS_CATEGORY_TAB_FIELDS.NAME)}`}>
            <input
              type="text"
              name={KEYWORDS_CATEGORY_TAB_FIELDS.NAME}
              className={getClassNames(KEYWORDS_CATEGORY_TAB_FIELDS.NAME)}
              value={values.name}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(KEYWORDS_CATEGORY_TAB_FIELDS.NAME, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(KEYWORDS_CATEGORY_TAB_FIELDS.NAME)}`}>
              {'Category Name'}
              <span className="mandatory cursor-help">*</span>
            </span>
          </div>
          <div className={`form-group form-field-item kc-dsrn ${getInputBoxClassName(KEYWORDS_CATEGORY_TAB_FIELDS.DESCRIPTION)}`}>
            <TextArea
              name={KEYWORDS_CATEGORY_TAB_FIELDS.DESCRIPTION}
              className="form-control"
              onChange={(e) => {
                handleChange(KEYWORDS_CATEGORY_TAB_FIELDS.DESCRIPTION, e.target.value);
              }}
              onBlur={handleBlur}
              value={values.description}
              maxRows={3}
            />
            <span className={`fltlabels ${getInputTitleClassName(KEYWORDS_CATEGORY_TAB_FIELDS.DESCRIPTION)}`}>
              {'Description'}
              <span className="mandatory cursor-help">*</span>
            </span>
          </div>
          <div className="create-as d-flex">
            <KeywordsCard
              title="Available Keywords"
              subtitle={`(${allKeywordsList.length})`}
              data={allKeywordsList}
              onChange={handleCategories}
              checkable
              checkedKeys={values.keywords}
              isFetching={isAllKeywordsFetching}
            />
            <KeywordsCard
              title="Selected Keywords"
              subtitle={`(${values.keywords.length})`}
              data={values.keywords.map((keyword) => ({ name: keyword }))}
              onChange={handleCategories}
              isFetching={isAllKeywordsFetching}
            />
          </div>
        </>
      </div>
    </div>
  );
};
