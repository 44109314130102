export enum OverviewAnalyticsGraphColors {
  POST_ENGAGEMENT = '#491D8B', // #491D8B
  FOLLOWERS = '#33B100',
  PUBLISHED_POSTS = '#791E6C'
}

export enum OverviewAnalyticsGraphFillColors {
  POST_ENGAGEMENT = 'rgba(138, 63, 252, 0.1)', // rgba(138, 63, 252, 0.1)
  FOLLOWERS = 'rgba(51, 177, 0, 0.15)',
  PUBLISHED_POSTS = 'rgba(213, 27, 160, 0.15)'
}

export enum OverviewAnalyticsGraphFadeColors {
  POST_ENGAGEMENT = 'rgba(138, 63, 252, 0)', // 'rgba(138, 63, 252, 0)
  FOLLOWERS = 'rgba(51, 177, 0, 0)',
  PUBLISHED_POSTS = 'rgba(213, 27, 160, 0)'
}

export enum GraphColors {
  PROFILE_VIEW = '#008F87',
  WEBSITE_CLICKS = '#8F001A;',
  PHONE_CALLS = '#73008F',
  DIRECTION_REQUEST = '#00208F',
  BUTTON_CLICKS = '#8F8100'
}

export enum CombinedGraphFillColors {
  PROFILE_VIEW = 'rgba(0, 143, 135, 0.1)',
  WEBSITE_CLICKS = 'rgba(143, 0, 26, 0.1)',
  PHONE_CALLS = 'rgba(115, 0, 143, 0.1)',
  DIRECTION_REQUEST = 'rgba(0, 32, 143, 0.2)',
  BUTTON_CLICKS = 'rgba(143, 129, 0, 0.2)'
}

export enum FadeCombinedGraphFillColors {
  PROFILE_VIEW = 'rgba(0, 143, 135, 0)',
  WEBSITE_CLICKS = 'rgba(143, 0, 26, 0)',
  PHONE_CALLS = 'rgba(115, 0, 143, 0)',
  DIRECTION_REQUEST = 'rgba(0, 32, 143, 0)',
  BUTTON_CLICKS = 'rgba(143, 129, 0, 0)'
}

export enum KeywordsCAGraphColors {
  LOCATION_SCORE = '#21D184'
}

export enum KeywordCAGraphFillColors {
  LOCATION_SCORE = 'rgba(33, 209, 132, 0.15)'
}

export enum KeywordCAGraphFadeColors {
  LOCATION_SCORE = 'rgba(33, 209, 132, 0)'
}
