import { useSelector } from 'react-redux';

import { IStore, aiContentCreatorContainerTypes } from 'types';
import { AISubscriptionModal } from 'components';
import { AiResponseSuggestions } from './AiResponseSuggestion';

export const AiResponse = ({ handleCancel, handleSuccess, isFetching, data, selectedTextObj, handleUse }: aiContentCreatorContainerTypes.IAiResponseSuggestions) => {
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  return (
    <>
      {isAISubscribed ? (
        <AiResponseSuggestions selectedTextObj={selectedTextObj} handleCancel={handleCancel} handleSuccess={handleSuccess} data={data} isFetching={isFetching} handleUse={handleUse} />
      ) : (
        <AISubscriptionModal />
      )}
    </>
  );
};
