import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';

import { ImageValidation } from 'widgets/Media';
import { commonModalPopup } from 'types';
import { PostsInfinityScrollList } from 'containers/Content/Posts/SubPages';
import { useParamsDeconstructor, useAccountSwitcherData } from 'utils/hooks';
import { POSTS_INIT_FILTER_DATA, POSTS_STATUS_TYPE, SIDE_NAV_NAME } from 'utils/constants';
import { getPostListReqPayload, getPostStatsReqPayload } from 'utils/helpers';
import { getPostsRequest, getPostsStatsRequest } from 'actions';
import { PostFilter } from 'layouts/Filter/SubPages';

export const AddPostsToCampaignModal = ({ isShowModal, onModalClose, headerActionSection, renderExtra }: commonModalPopup.IAddPostsToCampaignModalProps) => {
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId, optionalParams } = useAccountSwitcherData();
  const { queryParams, filter } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);

  const validCampaignId = optionalParams[0];

  const startDateTimeStamp = filter?.dateRange?.fromDate?.timeStamp || null;
  const endDateTimeStamp = filter?.dateRange?.toDate?.timeStamp || null;

  const isCallApi = Boolean((startDateTimeStamp && endDateTimeStamp) || (!startDateTimeStamp && !endDateTimeStamp));
  const postDateRange = startDateTimeStamp && endDateTimeStamp ? [startDateTimeStamp, endDateTimeStamp] : [];

  const handlePostsStats = () => {
    if (id && userOwnership && isCallApi && isValidId) {
      dispatch(getPostsStatsRequest(getPostStatsReqPayload(id, userOwnership, queryParams, postDateRange, false)));
    }
  };

  const handlePostListBasedOnFilters = (lastPostId?: number) => {
    if (id && userOwnership && isCallApi && isValidId) {
      const reqPayload = getPostListReqPayload(id, userOwnership, { ...queryParams, status: POSTS_STATUS_TYPE.APPROVED, campaign_id: validCampaignId }, postDateRange, false, 1, lastPostId);
      dispatch(getPostsRequest(reqPayload));
    }
  };
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <Modal
      show={isShowModal}
      className="connection-secondary add-location ralprofile-hl add-booster cal-soicalpreview-secion-wrp-modal addpost-campaign-only"
      centered
      size="lg"
      animation={false}
      onHide={onModalClose}
    >
      <Modal.Body>
        <div className="sec-conn__wrp">
          <div className="sec-header done__sec--header  modal-mbl-filter--main">
            <div className="header-item__wrp">
              {/* <div className="header-item__wrp apc-head"> */}
              <span className="title">Add Posts</span>
              <div className="apch-right">
                {headerActionSection ? headerActionSection : null}
                <div className="mod__close--icon header__close--icon">
                  <ImageValidation isImgValid defaultImg="mpcIcon" customName="close" customClassname="close-icon close mod__close--img wh-30" onClick={onModalClose} />
                </div>
              </div>
              <button className={`modal-mbl--filter mobile-filter ${isFilterOpen ? 'open' : ''}`} onClick={() => setIsFilterOpen(!isFilterOpen)} />
            </div>
          </div>
          <div className="sec-main__content hubuser-createpost-new-section calendar-edit__modal">
            <div className="addpost-section-main-wrp">
              <PostsInfinityScrollList
                isCallApi={isCallApi}
                startDateTimeStamp={startDateTimeStamp}
                endDateTimeStamp={endDateTimeStamp}
                type={SIDE_NAV_NAME.CONTENT_CAMPAIGNS}
                handlePostListBasedOnFilters={handlePostListBasedOnFilters}
                handlePostsStats={handlePostsStats}
                height={785}
              />
            </div>
            <div className={`right-button-section post-mbl-filter ${isFilterOpen ? 'open' : ''}`}>
              <PostFilter />
            </div>
          </div>
        </div>
        {renderExtra ? renderExtra : null}
      </Modal.Body>
    </Modal>
  );
};
