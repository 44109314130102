import { IGraphStats } from 'analytics/types';
import { inValidData, numberFormatter, validData } from 'analytics/utils/helpers';
import { getFormattedNumber } from 'utils/helpers';
import { CustomTooltip } from 'widgets/Tooltip';
import { NoDataFoundProps, TOOLTIP_PLACEMENT, NOT_APPLICABLE } from 'utils/constants';
import {
  AnalyticsPlatoformCombinedGraphFillColors,
  OverviewStatsTitle,
  AnalyticsPlatoformFadeCombinedGraphFillColors,
  AnalyticsPlatoformGraphColors,
  ChartType,
  GRAPH_STAT_CHART_OPTION
} from 'analytics/utils/constants';
import { CustomIndicator } from './CustomIndicator';
import { Loading, NoDataFound } from 'components';
import { ReactSpringCountUp } from 'widgets/ReactSpring';
import { AreaSplineChart } from '../Chart';

export const GraphStats = ({
  title,
  count,
  delta,
  avg,
  onStatsClick,
  rank,
  graphSeries = [],
  graphColor = [AnalyticsPlatoformGraphColors.ALL],
  graphFadeColor = [AnalyticsPlatoformFadeCombinedGraphFillColors.ALL],
  graphFillColor = [AnalyticsPlatoformCombinedGraphFillColors.ALL],
  fromDate,
  toDate,
  customClass,
  rankDelta,
  height,
  isLoading = false,
  pageType = 'page',
  isHubUser = false,
  isContentSupplier,
  isHideDelta = false
}: // hubAvg
IGraphStats) => {
  const valueFormatter = (value: string | number | null | undefined, fallback: string | number) => {
    return value && validData(value) ? numberFormatter(+value) : fallback;
  };

  const deltaValue = valueFormatter(delta, NOT_APPLICABLE);
  const average = valueFormatter(avg, 0);
  const rankValue = valueFormatter(rank, 0);
  const rankDeltaValue = valueFormatter(rankDelta, 0);
  // const hubAverage = valueFormatter(hubAvg, 0);
  const isDeltaDisable = inValidData(count) && inValidData(delta);
  const isDisableTooltip = isDeltaDisable && inValidData(average);
  // const isDisableTooltip = isDeltaDisable && inValidData(average) && inValidData(hubAverage);

  return (
    <>
      <div
        className={`an-stat--card stats-level-${pageType === 'page' ? '04' : '05'} ${avg !== undefined && avg !== null ? '' : 'with-out-brand--avg'}`}
        onClick={() => {
          if (onStatsClick) onStatsClick();
        }}
      >
        {!isDisableTooltip ? (
          <CustomTooltip
            customPlacement={TOOLTIP_PLACEMENT.BOTTOM}
            customClassname={`custom-tooltip-long-text ov-an-tooltip social-tooltip ${'big-box-width'} ${customClass}`}
            customTooltipText={
              <>
                {count !== undefined && count !== null ? (
                  <>
                    {' '}
                    <span className="cust-tooltip-txt">
                      {title === OverviewStatsTitle.POST_ENGAGEMENT
                        ? `${isContentSupplier ? 'Between' : 'On average, between'} ${fromDate} and ${toDate} you had ${getFormattedNumber(+count)} post engagements${
                            isContentSupplier ? '' : ' per day'
                          }.`
                        : title === OverviewStatsTitle.PUBLISHED_POSTS
                        ? `You have published ${getFormattedNumber(+count)} posts from ${fromDate} to ${toDate}`
                        : `You have  ${getFormattedNumber(+count)} ${title}.`}
                    </span>
                    <br />
                  </>
                ) : null}
                {delta !== undefined && delta !== null ? (
                  <div>
                    <CustomIndicator value={+delta} />
                    <span className="cust-tooltip-txt delta">
                      {' '}
                      {+delta > 0
                        ? `You ${title === OverviewStatsTitle.POST_ENGAGEMENT || title === OverviewStatsTitle.PUBLISHED_POSTS ? 'increased' : 'grew'} by `
                        : +delta === 0
                        ? 'No change '
                        : 'You decreased by '}
                      {+delta !== 0 ? getFormattedNumber(Math.abs(+delta)) : null} {title === OverviewStatsTitle.PUBLISHED_POSTS ? 'posts ' : ''}
                      {title === OverviewStatsTitle.POST_ENGAGEMENT || title === OverviewStatsTitle.PUBLISHED_POSTS
                        ? 'compared to the same previous time period'
                        : title === OverviewStatsTitle.FOLLOWERS
                        ? `during ${fromDate} and ${toDate}`
                        : `compared to ${fromDate} to ${toDate}`}
                      .
                    </span>
                  </div>
                ) : null}
                {avg !== undefined ? (
                  <>
                    <span className="cust-tooltip-txt">The brand average is {getFormattedNumber(avg === null ? 0 : +avg)}</span>
                    <br />
                  </>
                ) : null}
                {rank !== undefined && rank !== null ? (
                  <>
                    <span className="cust-tooltip-txt">Your brand ranking is {getFormattedNumber(+rank)}</span>
                  </>
                ) : null}
              </>
            }
            customInput={() => (
              <div className="card-content-top">
                <span className={`stats-title-text ${rank === undefined ? 'revert-w' : ''}`}>
                  {title === OverviewStatsTitle.POST_ENGAGEMENT ? `${isContentSupplier ? '' : 'Avg Daily'} ${title}` : title}
                </span>
                <div className="an-stat--b1">
                  <div className="stat-count-top">
                    <span className="big-number-text">{count !== undefined ? <ReactSpringCountUp end={+count} /> : NOT_APPLICABLE}</span>
                    {!isDeltaDisable && (
                      <>
                        <CustomIndicator value={delta ? +delta : 0} />
                        <span className="delta-small-text">{deltaValue}</span>
                      </>
                    )}
                  </div>
                  {avg !== undefined && !isHubUser ? (
                    <div className="stat-count-base">
                      <span className="small-title-text lbl">Brand Average</span>
                      <span className="value">{average}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          />
        ) : (
          <div className="card-content-top">
            <span className={`stats-title-text ${rank === undefined ? 'revert-w' : ''}`}>{title}</span>
            <div className="an-stat--b1">
              <div className="stat-count-top">
                <span className="big-number-text">{count !== undefined ? <ReactSpringCountUp end={+count} /> : NOT_APPLICABLE}</span>
                {!isDeltaDisable && (
                  <>
                    <CustomIndicator value={delta ? +delta : 0} />
                    <span className="delta-small-text">{deltaValue}</span>
                  </>
                )}
              </div>
              {avg !== undefined ? (
                <div className="stat-count-base">
                  <span className="small-title-text lbl">Brand Average</span>
                  <span className="value">{average}</span>
                </div>
              ) : null}
            </div>
          </div>
        )}
        <div className="review-an__stats stats rcharts">
          <div className="hub-chart__container">
            <div className="avg-ratings__bar">
              {isLoading ? (
                <Loading />
              ) : graphSeries.length ? (
                <AreaSplineChart
                  title={''}
                  series={{
                    data: graphSeries
                  }}
                  color={graphColor}
                  fillColor={graphFillColor}
                  fadeColor={graphFadeColor}
                  chartType={ChartType.AREASPLINE}
                  isAxisEnabled={false}
                  isTooltipDisabled
                  isMultipleAxisDisabled
                  isMarkerDisable
                  height={height}
                  isMouseTracking={false}
                  xMinPadding={0}
                  xMaxPadding={0}
                  chartOptions={GRAPH_STAT_CHART_OPTION}
                />
              ) : (
                <NoDataFound size={NoDataFoundProps.MEDIUM} />
              )}
            </div>
          </div>
          <div className="ras d-none">
            <div className="brand-hub__wrp">
              <span className="title">Average</span>
              <div className="inner-sec responsetime-tooltip-wrp">
                <div>
                  <span className="title">Brand</span>
                  <br />
                  <span className="value">80</span>
                </div>
                <div className="v_line" />
                <div>
                  <span className="title">Hub</span>
                  <br />
                  <span className="value">80</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {validData(rank) ? (
          <>
            <div className="ranking">
              <div className="rank-title">Ranking</div>
              <div className="rank-value__wrp">
                <div className="rank-value">{rankValue}</div>
                <div className="delta-wrp">
                  <CustomIndicator value={rankDelta || 0} />
                  <span className="delta-small-text">{rankDeltaValue}</span>
                </div>
              </div>
            </div>
            <div className="triangle" />
          </>
        ) : null}

        {/* UI class needed to be added
        {isHubUser && <div className='brand-hub__wrp'>
          <span className='title'>Average</span>

          <div className='inner-sec responsetime-tooltip-wrp'>
            <div>
              <span className='title'>Brand</span><br />
              <span className='value'>{average}</span>
            </div>
            <div className='v_line' />
            <div>
              <span className='title'>Hub</span><br />
              <span className='value'>{hubAverage}</span>
            </div>
          </div>
        </div>
        } */}
      </div>
    </>
  );
};
