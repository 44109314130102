import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import {
  AP_AVAIL_MEDIA_FOR_USER,
  MG_MEDIA_TYPE,
  MEDIA_FRAN_EDIT_OPTIONS,
  ALERT_BOX_TYPES,
  MG_CONTENT_ALREADY_LOCKED,
  RIPPLE_COLOR,
  CreatorMediaTypeForUser,
  MEDIA_CLONE_AVAILBILITY,
  COMMON_PAGE_TYPE,
  USER_OWNERSHIP,
  MgSourceType
} from 'utils/constants';
import { mgCloneAssetRequest, setEditedMgDetails } from 'actions';
import { IStore, mgContainerTypes } from 'types';
import { alertBoxCall } from 'components';
import { useAccountSwitcherData } from 'utils/hooks';
import { mgAvailabilityModalMessage } from 'utils/helpers';

export const MgMediaAvailability = ({ editEnabled, availabilityTabName, setAlertShown, handleSaveMgAsset }: mgContainerTypes.IMgMediaAvailabilityProps) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line

  const { id, userOwnership, optionalParams } = useAccountSwitcherData();

  const mgDetails = useSelector((state: IStore) => state.mediaGallery.mgDetails);
  const editedMgDetails = useSelector((state: IStore) => state.mediaGallery.editedMgDetails);
  const accountFranchisorId = useSelector((state: IStore) => state.accountSwitcher?.currentAccountOrFranchisor?.franchisor_id || 0);
  const clonedFranchisorIds = useSelector((state: IStore) => state.mediaGallery.mgDetails?.cloned_franchisor_ids || []);
  const editedClonedFranchisorIds = useSelector((state: IStore) => state.mediaGallery.editedMgDetails?.cloned_franchisor_ids || []);
  const assetId = useSelector((state: IStore) => state.mediaGallery.mgDetails?.id);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier ?? false);
  const accountsCount = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorObj?.accounts_count || 0);

  const isCloneEnabled = userOwnership === USER_OWNERSHIP.FRANCHISOR ? editedClonedFranchisorIds.includes(id) : Boolean(editedClonedFranchisorIds.length);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleCloneAssetForLocationView = () => {
    if (assetId) {
      dispatch(
        mgCloneAssetRequest({
          mediaType: optionalParams[0],
          assetId,
          params: {
            ...(userOwnership === USER_OWNERSHIP.ACCOUNT && accountFranchisorId ? { franchisor_id: accountFranchisorId } : { franchisor_id: id }),
            copy_to_descendants: true
          },
          id,
          userOwnership
        })
      );
      setOpenConfirmationModal(false);
    }
  };

  return (
    <>
      {availabilityTabName === COMMON_PAGE_TYPE.NON_CLONE ? (
        <>
          {/* <div className="white-box int-note"> */}
          {!isContentSupplier || (isContentSupplier && accountsCount >= 1) ? (
            <div className="post__white--box int-note">
              <div className="wb-top">
                <div className="wb-label">
                  <div className="wbl-head">Availability</div>
                </div>
                <div className="wb-f-main">
                  <div className="wb-fields">
                    <div className="wbf-item">
                      <div className="wbf-inputs">
                        <div className="input-fields">
                          <div className="if-main if-h avail-radio__group">
                            {AP_AVAIL_MEDIA_FOR_USER.map((userItem, idx: number) => {
                              return (
                                <label
                                  className={`${(idx === 0 && !editedMgDetails?.copy_to_descendants) || (idx === 1 && editedMgDetails?.copy_to_descendants) ? ` active` : ``}`}
                                  key={`mg-detail-media-avail-${idx}`}
                                >
                                  <input
                                    type="radio"
                                    className="option-input radio r-ml2"
                                    value={userItem.name}
                                    name={userItem.name}
                                    checked={idx === 0 && !editedMgDetails?.copy_to_descendants ? true : idx === 1 && editedMgDetails?.copy_to_descendants ? true : false}
                                    onChange={(event) => {
                                      if (editEnabled) {
                                        const tempPayload = {
                                          copy_to_descendants: event.target.value === CreatorMediaTypeForUser.EVERYONE ? true : false,
                                          ...(editedMgDetails?.media_type === MG_MEDIA_TYPE.PHOTO && {
                                            locked: event.target.value === CreatorMediaTypeForUser.PERSONAL ? false : editedMgDetails?.locked || false
                                          })
                                        };
                                        dispatch(setEditedMgDetails(tempPayload));
                                      }
                                    }}
                                    onClick={() => {
                                      if (!editEnabled) {
                                        setAlertShown(true);
                                        alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_CONTENT_ALREADY_LOCKED);
                                      }
                                    }}
                                  />
                                  <span className="labelText">{userItem.label}</span>
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {mgDetails?.copy_to_descendants !== editedMgDetails?.copy_to_descendants ? (
                <div className="btn-wrp g-20">
                  <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                    <button
                      className="ac-btn ac-transparent ac-outline size-xs border-0"
                      onClick={() => {
                        dispatch(setEditedMgDetails({ copy_to_descendants: mgDetails?.copy_to_descendants }));
                      }}
                    >
                      Cancel
                    </button>
                  </CustomRippleButton>
                  <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                    <button className="ac-btn ac-primary size-xs" onClick={() => handleSaveMgAsset()}>
                      Save
                    </button>
                  </CustomRippleButton>
                </div>
              ) : null}
            </div>
          ) : null}

          {editedMgDetails?.media_type === MG_MEDIA_TYPE.PHOTO && (
            // <div className="white-box int-note">
            <div className="post__white--box int-note">
              <div className="wb-top">
                <div className="wb-label">
                  <div className="wbl-head">Image Editing and Downloading</div>
                </div>
                <div className="wb-f-main">
                  <div className="wb-fields">
                    <div className="wbf-item">
                      <div className="wbf-inputs">
                        <div className="input-fields">
                          <div className="if-main if-h avail-radio__group">
                            {MEDIA_FRAN_EDIT_OPTIONS.map((optionItem, idx) => {
                              return (
                                <label className={`${editedMgDetails?.locked === optionItem.name ? ` active` : ``}`} key={`mg-detail-edit-option-${idx}`}>
                                  <input
                                    type="radio"
                                    className="option-input radio r-ml2"
                                    name={'locked'}
                                    checked={editedMgDetails?.locked === optionItem.name}
                                    onChange={(event) => {
                                      if (editEnabled) {
                                        const tempPayload = {
                                          [event.target.name]: optionItem.name
                                        };
                                        dispatch(setEditedMgDetails(tempPayload));
                                      }
                                    }}
                                    onClick={() => {
                                      if (!editEnabled) {
                                        setAlertShown(true);
                                        alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_CONTENT_ALREADY_LOCKED);
                                      }
                                    }}
                                  />
                                  <span className="labelText">{optionItem.label}</span>
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {mgDetails?.locked !== editedMgDetails?.locked ? (
                <div className="btn-wrp g-20">
                  <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                    <button
                      className="ac-btn ac-transparent ac-outline size-xs border-0"
                      onClick={() => {
                        dispatch(setEditedMgDetails({ locked: mgDetails?.locked }));
                      }}
                    >
                      Cancel
                    </button>
                  </CustomRippleButton>
                  <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                    <button className="ac-btn ac-primary size-xs" onClick={() => handleSaveMgAsset()}>
                      Save
                    </button>
                  </CustomRippleButton>
                </div>
              ) : null}
            </div>
          )}
        </>
      ) : availabilityTabName === COMMON_PAGE_TYPE.CLONE && (!isContentSupplier || (isContentSupplier && accountsCount >= 1)) ? (
        // <div className="white-box int-note avail-clne">
        <div className="post__white--box int-note avail-clne">
          <div className="wb-top">
            <div className="wb-label">
              <div className="wbl-head">Availability</div>
            </div>
            <div className="wb-f-main">
              <div className="wb-fields">
                <div className="wbf-item">
                  <div className="wbf-inputs">
                    <div className="input-fields">
                      <div className={`form-group${userOwnership === USER_OWNERSHIP.FRANCHISOR ? clonedFranchisorIds.includes(id) : Boolean(clonedFranchisorIds.length) ? ` events-none` : ''}`}>
                        <label className={isCloneEnabled ? 'checkbox-item active' : 'checkbox-item'}>
                          <span className="checkbox-hover">
                            <input
                              value={MEDIA_CLONE_AVAILBILITY.name}
                              type="checkbox"
                              className="option-input checkbox"
                              name="cloned"
                              checked={isCloneEnabled}
                              disabled={userOwnership === USER_OWNERSHIP.FRANCHISOR ? clonedFranchisorIds.includes(id) : Boolean(clonedFranchisorIds.length)}
                              onChange={() => {
                                const franchisorId = userOwnership === USER_OWNERSHIP.FRANCHISOR ? id : accountFranchisorId;
                                dispatch(
                                  setEditedMgDetails({
                                    cloned_franchisor_ids: !editedClonedFranchisorIds.includes(franchisorId) ? [...clonedFranchisorIds, franchisorId] : clonedFranchisorIds
                                  })
                                );
                              }}
                            />
                          </span>
                          <span className="labelText">{MEDIA_CLONE_AVAILBILITY.label}</span>
                        </label>
                      </div>
                    </div>
                    {/* Clone Availability -starts */}
                    <div className="clone-avail d-none">
                      <div className="cla-left">
                        <span className="cla-label">{mgDetails?.source === MgSourceType.LOCATION ? 'Currently available only for:' : 'Cloned and made available for everyone else under:'}</span>
                        <div className="cla-value">
                          <ImageValidation isImgValid defaultImg="location-d-grey" customName="Availability" customClassname="cla-icon" />
                          <span className="cla-v-txt">{mgDetails?.account_name}</span>
                        </div>
                      </div>
                      <div className="cla-right">
                        <button className={`gnrc-btn blue-gnr${isCloneEnabled ? ` pointer-events-none` : ''}`} onClick={() => setOpenConfirmationModal(true)}>
                          <span>{isCloneEnabled ? 'Manage Availability' : 'Make Available For Everyone Else'}</span>
                        </button>
                      </div>
                    </div>
                    {/* Clone Availability -ends */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {clonedFranchisorIds?.length !== editedClonedFranchisorIds?.length ? (
            <div className="btn-wrp g-20">
              <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button
                  className="ac-btn ac-transparent ac-outline size-xs border-0"
                  onClick={() => {
                    dispatch(setEditedMgDetails({ cloned_franchisor_ids: clonedFranchisorIds }));
                  }}
                >
                  Cancel
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                <button className="ac-btn ac-primary size-xs" onClick={() => handleCloneAssetForLocationView()}>
                  Save
                </button>
              </CustomRippleButton>
            </div>
          ) : null}
        </div>
      ) : null}
      <Modal
        className={'prime-modal modal-confirmation confirm-delete creator-modal-confirm-new'}
        size={'sm'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openConfirmationModal}
        onHide={() => setOpenConfirmationModal(false)}
        animation={false}
      >
        <Modal.Header>
          <div className="modal-icon-wraps">
            <span className="modal-itm-wraps modal-danger-wraps modal-info-wraps" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="modal-message-wraps-text">{mgAvailabilityModalMessage(mgDetails?.media_type || '')}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-grp-wraps">
            <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={() => setOpenConfirmationModal(false)}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary ac-block" onClick={handleCloneAssetForLocationView}>
                Yes
              </button>
            </CustomRippleButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
