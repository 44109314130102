import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete';
import { useNavigate } from 'react-router';

import { postsTagsRequest, addPostSetTags, mgUpdateDetailDataRequest, setEditedMgDetails, mgDetailDataReset, mgCloneAssetRequest } from 'actions';
import { IStore, mgContainerTypes } from 'types';
import {
  ALERT_BOX_TYPES,
  MG_CONTENT_ALREADY_LOCKED,
  INBOX_COMMENTS_DELAY_SECONDS,
  RIPPLE_COLOR,
  MG_FILTER_INITIAL_VALUE,
  MgSourceType,
  COMMON_PAGE_TYPE,
  MG_MEDIA_TYPE,
  MgMediaType,
  USER_OWNERSHIP
} from 'utils/constants';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { compareTwoObjects, formNewInternalTag, getTagsListInString } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { alertBoxCall } from 'components';
import { MgMediaAvailability } from './MgMediaAvailability';
import { TextArea } from 'widgets/Text';
import { FranSyndicatePostModal } from 'containers/Content/Creator/BrandOrHubSubPages';
import { MgMediaExpiry } from './MgMediaExpiry';

export const MgTagsAndNotes = ({ editEnabled, showDeleteModal }: mgContainerTypes.IMgTagsAndNotesProps) => {
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();

  const { queryParams } = useParamsDeconstructor(MG_FILTER_INITIAL_VALUE);

  const internalTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const mgDetails = useSelector((state: IStore) => state.mediaGallery.mgDetails);
  const editedMgDetails = useSelector((state: IStore) => state.mediaGallery.editedMgDetails);
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor || false);
  const assetAccountId = useSelector((state: IStore) => state.mediaGallery.mgDetails?.account_id);
  const assetSourceType = useSelector((state: IStore) => state.mediaGallery.mgDetails?.source);
  const franchisorSyndication = useSelector((state: IStore) => state.franchisors?.currentFranDetails?.franchisors_for_syndication);

  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [isTagsDisabled, setTagsDisabled] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [openSyndicateModal, setOpenSyndicateModal] = useState(false);

  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line

  const saveDisable = !compareTwoObjects(mgDetails, editedMgDetails);
  const isTagsChanged = mgDetails?.tags_list !== editedMgDetails?.tags_list;
  const isCloneMedia = (!assetAccountId && editEnabled) || (isFranchisor && assetSourceType === MgSourceType.LOCATION);
  const availabilityTabName = !assetAccountId && editEnabled ? COMMON_PAGE_TYPE.NON_CLONE : isFranchisor && assetSourceType === MgSourceType.LOCATION ? COMMON_PAGE_TYPE.CLONE : null;
  const isFranchisorSydicationMedia = franchisorSyndication?.length && !mgDetails?.account_id;
  const assetId = useSelector((state: IStore) => state.mediaGallery.mgDetails?.id);
  const accountFranchisorId = useSelector((state: IStore) => state.accountSwitcher?.currentAccountOrFranchisor?.franchisor_id || 0);
  const editedClonedFranchisorIds = useSelector((state: IStore) => state.mediaGallery.editedMgDetails?.cloned_franchisor_ids || []);
  // const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);

  const mediaType = optionalParams[0];
  const isCloneEnabled = userOwnership === USER_OWNERSHIP.FRANCHISOR ? editedClonedFranchisorIds.includes(id) : Boolean(editedClonedFranchisorIds.length);

  useEffect(() => {
    const existingTagsList: Tag[] = [];
    if (mgDetails?.tags_list) {
      const tempExistingTags = mgDetails?.tags_list.split(',');
      tempExistingTags?.forEach((item: string, idx: number) => {
        existingTagsList.push({ id: idx, name: item });
      });
    }
    dispatch(addPostSetTags(existingTagsList));
  }, [mgDetails, dispatch]);

  useEffect(() => {
    if (searchValue) dispatch(postsTagsRequest({ searchTag: searchValue || '' }));
  }, [searchValue, dispatch]);

  useEffect(() => {
    if (selectedTags) {
      const tempEditedContent = { tags_list: getTagsListInString(selectedTags) };
      dispatch(setEditedMgDetails(tempEditedContent));
    }
  }, [selectedTags, searchValue, dispatch]);

  useEffect(() => {
    if (alertShown) {
      setTimeout(() => {
        setAlertShown(false);
        setTagsDisabled(false);
      }, INBOX_COMMENTS_DELAY_SECONDS);
    }
  }, [alertShown]); // eslint-disable-line

  const tagsList = formNewInternalTag(internalTagsList, searchValue);

  const handleInputChange = (search: string) => {
    if (editEnabled) {
      if (!search.trim()) setSearchValue(null);
      else setSearchValue(search);
    }
  };

  const handleAddition = (tag: Tag) => {
    if (!isTagsDisabled) {
      tag.name = internalTagsList.length === 0 ? tag.name.replace('Add ', '') : tag.name;
      const addedTagsArray: Tag[] = [...selectedTags, tag];
      dispatch(addPostSetTags(addedTagsArray));
    }
  };

  const handleDeleteInternalTag = (idx: number) => {
    if (!editEnabled) {
      setAlertShown(true);
      alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_CONTENT_ALREADY_LOCKED);
    } else if (idx !== -1) {
      const removedTags = selectedTags;
      removedTags.splice(idx, 1);
      dispatch(addPostSetTags(removedTags));
    }
  };

  const handleSaveMgAsset = useCallback(() => {
    if (id && userOwnership) {
      dispatch(
        mgUpdateDetailDataRequest({
          mediaType,
          editedContent: editedMgDetails
        })
      );
      if (isCloneEnabled) {
        handleCloneAssetForLocationView();
      }
    }
  }, [id, userOwnership, editedMgDetails, mediaType, dispatch]); // eslint-disable-line

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  const handleCancelMGDetailView = () => {
    dispatch(mgDetailDataReset());
    routePush(`/${userOwnership}/${id.toString()}/${currentRoute}/${mediaType}`, queryParams);
  };

  const handleCloneAssetForLocationView = () => {
    if (assetId) {
      dispatch(
        mgCloneAssetRequest({
          mediaType: optionalParams[0],
          assetId,
          params: {
            ...(userOwnership === USER_OWNERSHIP.ACCOUNT && accountFranchisorId ? { franchisor_id: accountFranchisorId } : { franchisor_id: id }),
            copy_to_descendants: true
          },
          id,
          userOwnership
        })
      );
    }
  };

  return (
    <Fragment>
      {/* <div className="white-box tags"> */}
      {mediaType !== MgMediaType.DOCUMENTS ? (
        <div className="post__white--box tags">
          <div className="wb-top">
            <div className="wb-label">
              <div className="wbl-head">Tags</div>
            </div>
            <div className="wb-f-main">
              <div className="tags-wrp">
                {selectedTags.length > 0 &&
                  selectedTags.map((tagItem, tagIndex) => {
                    return (
                      <div className="list-expanded-tag-item" key={tagIndex}>
                        <span className="list-expanded-tag-text">{tagItem.name}</span>
                        <span className="list-expanded-tag-remove" onClick={() => handleDeleteInternalTag(tagIndex)} />
                      </div>
                    );
                  })}
              </div>

              <div className="wb-fields">
                <div className="wbf-item">
                  <div className="wbf-inputs">
                    <div className="input-fields">
                      <div className="if-main if-h">
                        <CustomReactTags
                          key={`${id}${userOwnership}`}
                          suggestions={tagsList}
                          handleInputChange={(search: string) => handleInputChange(search)}
                          handleDelete={() => false}
                          maxSuggestionsLength={tagsList.length}
                          handleAddition={(tag: Tag) => handleAddition(tag)}
                          placeholder="Add Tags"
                          autofocus={false}
                          inputAttributes={{
                            onClick: () => {
                              if (editEnabled) {
                                setTagsDisabled(false);
                              } else {
                                setTagsDisabled(true);
                                setAlertShown(true);
                                alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_CONTENT_ALREADY_LOCKED);
                              }
                            },
                            disabled: isTagsDisabled
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isTagsChanged ? (
            <div className="btn-wrp">
              <div>
                <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                  <button
                    className="ac-btn ac-secondary-white ac-outline size-xs border-0"
                    onClick={() => {
                      dispatch(setEditedMgDetails({ tags_list: mgDetails?.tags_list }));
                      if (mgDetails?.tags_list !== '') {
                        const tempExistingTags = mgDetails?.tags_list.split(',');
                        const existingTagsList: Tag[] = [];
                        tempExistingTags?.forEach((item: string, idx: number) => {
                          existingTagsList.push({ id: idx, name: item });
                        });
                        dispatch(addPostSetTags(existingTagsList));
                      }
                    }}
                  >
                    Cancel
                  </button>
                </CustomRippleButton>
                <CustomRippleButton rippleClass="ripple-unset ac-primary-box r-ml2" custColor={RIPPLE_COLOR.primary}>
                  <button className="ac-btn ac-primary size-xs" onClick={() => handleSaveMgAsset()}>
                    Save
                  </button>
                </CustomRippleButton>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {/* <div className="white-box int-note"> */}

      <div className="post__white--box int-note aiuDesc">
        {editedMgDetails?.ai_description && (
          <div className="wb-top wt-ai-Desc">
            <div className="wb-label">
              <div className="wbl-head">AI Description</div>
            </div>
            <div className="wb-f-main">
              <p>{editedMgDetails?.ai_description}</p>
            </div>
          </div>
        )}

        <div className="wb-top wt-user-Desc">
          <div className="wb-label">
            <div className="wbl-head">User Description</div>
          </div>
          <div className="wb-f-main">
            <div className="wb-fields">
              <div className="wbf-item">
                <div className="wbf-inputs">
                  <div className="input-fields">
                    <div className="if-main if-h">
                      <TextArea
                        className="internal-notes"
                        value={editedMgDetails?.comments || ''}
                        readOnly={!editEnabled}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                          if (editEnabled) {
                            const tempPayload = { comments: e.target.value };
                            dispatch(setEditedMgDetails(tempPayload));
                          }
                        }}
                        onClick={() => {
                          if (!editEnabled) {
                            setAlertShown(true);
                            alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_CONTENT_ALREADY_LOCKED);
                          }
                        }}
                        minRows={4}
                        maxRows={4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {mgDetails?.comments !== editedMgDetails?.comments ? (
          <div className="btn-wrp g-20">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button
                className="ac-btn ac-secondary-white ac-outline size-xs border-0"
                onClick={() => {
                  dispatch(setEditedMgDetails({ comments: mgDetails?.comments }));
                }}
              >
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary size-xs" onClick={() => handleSaveMgAsset()}>
                Save
              </button>
            </CustomRippleButton>
          </div>
        ) : null}
      </div>
      {isCloneMedia ? <MgMediaAvailability editEnabled={editEnabled} availabilityTabName={availabilityTabName} setAlertShown={setAlertShown} handleSaveMgAsset={handleSaveMgAsset} /> : null}

      <MgMediaExpiry editEnabled={editEnabled} handleSaveMgAsset={handleSaveMgAsset} setAlertShown={setAlertShown} />

      <div className="btn-wrp-separate g-20 r-flx r-flx-je">
        {editEnabled && (
          <>
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline size-sm border-0" onClick={handleCancelMGDetailView}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass="ripple-unset ac-danger-box" custColor={RIPPLE_COLOR.danger}>
              <button className="ac-btn ac-danger size-sm" onClick={() => showDeleteModal(true)}>
                Delete
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box ${!saveDisable ? 'pointer-events-none no-shadow' : ''}`} custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary size-sm ${!saveDisable ? 'disabled' : ''}`} onClick={() => handleSaveMgAsset()}>
                Save All
              </button>
            </CustomRippleButton>
          </>
        )}
        {isFranchisorSydicationMedia ? (
          <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary size-sm`} onClick={() => setOpenSyndicateModal(true)}>
              Syndicate
            </button>
          </CustomRippleButton>
        ) : null}
      </div>
      <FranSyndicatePostModal
        isModalShow={openSyndicateModal}
        handleModalClose={() => setOpenSyndicateModal(false)}
        mediaType={mgDetails?.media_type === MG_MEDIA_TYPE.RAW || mgDetails?.name.split('.').pop() === 'pdf' ? MG_MEDIA_TYPE.DOCUMENT : mgDetails?.media_type || ''}
        postOrMediaId={mgDetails?.id}
      />
    </Fragment>
  );
};
