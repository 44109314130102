import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CommonFilter } from 'components';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { BUTTON_PROPS, FeedValue, USER_OWNERSHIP } from 'utils/constants';
import { activeFilterOptions, downloadRequest, onChangeDateRange } from 'revv/actions';
import { IDownloadParams } from 'revv/types';
import { IStore } from 'types';
import { weekOrMonthBeforeTodayFilter } from 'utils';
import { dateHelperForWeek, handleFilterChange } from 'analytics/utils';
import { IFiterHandleChangeData } from 'types/common/filter';
import moment from 'moment';

export const CustomersFilter = () => {
  const { userOwnership, id } = useAccountSwitcherData();
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const { queryParams, filter: basicQueryFilter } = useParamsDeconstructor({
    dateRange: weekOrMonthBeforeTodayFilter(FeedValue.INBOX)
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isFranchisor = useMemo(() => {
    return userOwnership === USER_OWNERSHIP.FRANCHISOR;
  }, [userOwnership]);

  const isFilterDisabled = useMemo(() => {
    if (Object.keys(queryParams).length) {
      return false;
    } else {
      return true;
    }
  }, [queryParams]);

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (isClear) {
      dispatch(
        onChangeDateRange({
          fiscalStartDate: dateHelperForWeek(true).fromDate.selected || new Date(),
          fiscalEndDate: dateHelperForWeek(true).toDate.selected || new Date()
        })
      );
    }
    const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
    navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
  };

  return (
    <CommonFilter
      filter={{ ...basicQueryFilter }}
      sectionVariant=" revv-customer__filter"
      isRevvCustomers
      handleChange={handleChange}
      primaryButtons={[
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isFilterDisabled
        }
      ]}
      filterActionButtons={[
        // {
        //   ...BUTTON_PROPS.PRIMARY_BUTTON,
        //   label: 'Send Survey',
        //   parentVariant: 'filter-item connection-report border-0',
        //   value: 'sendsurvey',
        //   onClick: () => {
        //     dispatch(activeFilterOptions('sendsurvey'));
        //   },
        //   isFetching: isSendSurveyFetching
        // },
        {
          ...BUTTON_PROPS.SECONDARY_BUTTON,
          label: 'Download All-Time',
          customImage: 'csv-export.svg',
          parentVariant: 'connection-report border-0',
          buttonVariant: 'ac-btn ac-icon-btn ac-secondary ac-block csv',
          onClick: () => {
            const payload: IDownloadParams = {
              type: 'customers',
              rallioFranchisorIds: isFranchisor && id !== topLevelFranchisorId ? [String(id)] : undefined,
              rallioLocationId: !isFranchisor ? [id] : undefined,
              rallioBrandId: isFranchisor && id === topLevelFranchisorId ? id : undefined
            };
            dispatch(downloadRequest(payload));
          }
        },
        {
          ...BUTTON_PROPS.SECONDARY_BUTTON,
          label: 'Download Filtered',
          customImage: 'csv-export.svg',
          parentVariant: 'connection-report border-0',
          buttonVariant: 'ac-btn ac-icon-btn ac-secondary ac-block csv',
          onClick: () => {
            const payload: IDownloadParams = {
              type: 'customers',
              from: queryParams.start_date || moment(weekOrMonthBeforeTodayFilter(FeedValue.INBOX).fromDate.selected).format('YYYY-MM-DD'),
              to: queryParams.end_date || moment(weekOrMonthBeforeTodayFilter(FeedValue.INBOX).toDate.selected).format('YYYY-MM-DD'),
              rallioFranchisorIds: isFranchisor && id !== topLevelFranchisorId ? [String(id)] : undefined,
              rallioLocationId: !isFranchisor ? [id] : undefined,
              rallioBrandId: isFranchisor && id === topLevelFranchisorId ? id : undefined
            };
            dispatch(downloadRequest(payload));
          }
        },
        // {
        //   ...BUTTON_PROPS.PRIMARY_BUTTON,
        //   label: 'Download',
        //   customImage: 'csv-export.svg',
        //   parentVariant: 'connection-report border-0',
        //   buttonVariant: 'ac-btn ac-icon-btn ac-primary ac-block csv',
        //   onClick: () => {
        //     const payload: IDownloadParams = {
        //       type: 'customers',
        //       rallioFranchisorIds: isFranchisor && id !== topLevelFranchisorId ? [String(id)] : undefined,
        //       rallioLocationId: !isFranchisor ? [id] : undefined,
        //       rallioBrandId: isFranchisor && id === topLevelFranchisorId ? id : undefined
        //     };
        //     dispatch(downloadRequest(payload));
        //   }
        // },
        {
          ...BUTTON_PROPS.SECONDARY_BUTTON,
          label: 'Upload',
          customImage: 'upload-white.svg',
          parentVariant: 'connection-report border-0',
          buttonVariant: 'ac-btn ac-icon-btn ac-secondary ac-block csv',
          onClick: () => {
            dispatch(activeFilterOptions('customerUpload'));
          }
        }
      ]}
    />
  );
};
