import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { NoDataFoundProps, RIPPLE_COLOR, CORP_HUBS, USER_OWNERSHIP, CONTENT_SUPPLIERS_DROPDOWN_OBJ, ALL_BRANDS } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { NoDataFound } from 'components';
import { ILocationOptions } from 'analytics/types';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

interface IContentSuppliersFilterModal {
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: any;
  initialObj: ILocationOptions;
}

export const ContentSuppliersFilterModal = ({ setModalState, initialObj, handleChange }: IContentSuppliersFilterModal) => {
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();

  const contentSupplierHubs = useSelector((state: IStore) => state.operationsList.cs_franchisors);

  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;

  const contentSuppliersDropdownOptions = [
    {
      label: CORP_HUBS,
      options: contentSupplierHubs
    }
  ];

  const [options, setOptions] = useState(initialObj);
  const [searchString, setsearchString] = useState('');

  const contentSuppliersFilterHandler = () => {
    setModalState(false);
    if (initialObj !== options) {
      handleChange({
        content_supplier_id: options?.id ? options?.id : 'all',
        contentSupplierFilterObj: options
      });
    }
  };

  return (
    <Modal show className="lb-filter__modal accountSwitchModal global-modal-secondary cssWrap" centered size="lg" onHide={() => setModalState(false)}>
      <>
        <div className="mod__close--icon">
          <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={() => setModalState(false)} />
        </div>
        <Modal.Body>
          <div className="asm-top-head">
            <h3 className="title">Content Supplier Selector</h3>
            <div className="asm-lf">
              <input type="text" name="ContentSuppliers" onChange={(event) => setsearchString(event.target.value.trim())} placeholder="Search" />
            </div>
          </div>
          <div className="asm-accord cssheadLabels">
            <div className="all-locs cssTopLabel">
              <label onClick={() => setOptions(CONTENT_SUPPLIERS_DROPDOWN_OBJ)} className="cur-pointer r-flx r-flx-ac">
                <input type="radio" defaultChecked={options?.id === CONTENT_SUPPLIERS_DROPDOWN_OBJ?.id} className="option-input radio" name="selectedLocation" />
                <span>{ALL_BRANDS}</span>
              </label>
            </div>
            {contentSuppliersDropdownOptions.map((datum, index: number) => {
              return (
                <div className="asm-accord cssSearchList" key={index}>
                  <div className="all-locs">
                    <ul className="hub-list">
                      {datum.options && datum.options?.length ? (
                        datum.options
                          .filter((iterated) => iterated.label.toLowerCase().indexOf(searchString?.toLowerCase()) > -1)
                          .map((it, itIndex: number) => (
                            <li key={itIndex}>
                              <label className={`r-flx r-flx-ac lc-acc-cnt${options?.id === it.id ? ` active` : ''}`} onClick={() => setOptions(it)}>
                                <input type="radio" defaultChecked={options?.id === it.id} className="option-input radio" name="selectedLocation" />
                                <div className={`list-lbl cur-pointer`}>
                                  <span className="lcs-name" title={it.label}>
                                    {it.label}
                                  </span>
                                </div>
                              </label>
                            </li>
                          ))
                      ) : (
                        <NoDataFound size={NoDataFoundProps.SMALL} />
                      )}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
          <p className="anchorWrap">
            To add or remove Content Suppliers, click here:{' '}
            <span onClick={() => navigate({ pathname: `/${userOwnership}/${id.toString()}/${isHubUser ? 'team_management/lists' : 'content/feed'}`, search: '' })}>Feed-Enroll</span>
          </p>
          <Modal.Footer>
            <div className="asm-btn">
              <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={() => setModalState(false)}>
                  Cancel
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-btn ac-primary ac-block`} onClick={contentSuppliersFilterHandler}>
                  Ok
                </button>
              </CustomRippleButton>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </>
    </Modal>
  );
};
