import { ImageValidation } from 'widgets/Media';
import { ICustomIndicator } from 'analytics/types';

export const CustomIndicator = ({ value, isRotate = false, isHidden = false }: ICustomIndicator) => {
  let imageUrl = 'an-rating-grey';

  if (value && value > 0) {
    imageUrl = 'an-ratings-up-green';
  } else if (value && value < 0) {
    imageUrl = 'rating-down-red';
  }

  return <ImageValidation isImgValid defaultImg={`${imageUrl}${isRotate ? '-rotate' : ''}`.trim()} customName={imageUrl} customClassname={`an-stat--indicator ${isHidden ? 'v-hid' : ''}`} />;
};
