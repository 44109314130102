import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Modal, Spinner } from 'react-bootstrap';

import { ALERT_BOX_TYPES, CHANGE_PASSWORD_FILEDS, CHANGE_PASSWORD_INITIAL_VALUE, RIPPLE_COLOR } from 'utils/constants';
import { changePasswordRequest } from 'actions';
import { commonModalPopup, IStore, changePasswordContainerTypes } from 'types';
import { changePasswordValidation } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { alertBoxCall } from 'components';

export const ChangePasswordModalPopup = ({ isModalShow, handleModalClose }: commonModalPopup.IDetailModalPopup) => {
  const dispatch = useDispatch();

  const { isFetching, changePasswordSuccessMsg, changePasswordFailMsg } = useSelector((state: IStore) => state.changePassword);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);

  const { values, touched, setFieldValue, errors, handleBlur, handleSubmit, isValid } = useFormik({
    initialValues: { ...CHANGE_PASSWORD_INITIAL_VALUE },
    validationSchema: changePasswordValidation,
    onSubmit: (values: changePasswordContainerTypes.IChangePasswordFieldValue) => {
      if (activeUserId && values) {
        dispatch(changePasswordRequest({ id: activeUserId, inputValues: values }));
      }
    }
  });

  useEffect(() => {
    if (changePasswordSuccessMsg) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, changePasswordSuccessMsg);
      handleModalClose();
    }
  }, [changePasswordSuccessMsg]); // eslint-disable-line

  /** Handle and set Values when changes happen in forms */
  const handleChange = (field: string, value: string) => {
    setFieldValue(field, value);
  };

  const getClassNames = (valueItem: keyof changePasswordContainerTypes.IChangePasswordFieldValue) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] !== '' && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'error-disp' : ''}`;
    return className;
  };

  const isSaveValid = (isValid || isFetching) && values.confirm_password && values.current_password && values.new_password;

  return (
    <>
      <Modal className="change-password-modal-section-wrp cpms-main" aria-labelledby="contained-modal-title-vcenter" centered size="lg" show={isModalShow} animation={false} onHide={handleModalClose}>
        <Modal.Body>
          <div className="header__modal--content">
            <div className="chng__password--title">
              Change Password
              <div className="mod__close--icon">
                <ImageValidation defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={handleModalClose} />
              </div>
            </div>
            <p className="chng__password--text">Want to change your password?</p>
            <p className="chng__password--text text__space--wrp">No problem! Just enter your current password, and confirm your new one.</p>

            <form className="form-section-wrp">
              <div className="form-group form-field-item">
                <input
                  type="password"
                  name={CHANGE_PASSWORD_FILEDS.CURRENT_PASSWORD}
                  className={getClassNames(CHANGE_PASSWORD_FILEDS.CURRENT_PASSWORD)}
                  autoComplete="off"
                  value={values.current_password}
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(CHANGE_PASSWORD_FILEDS.CURRENT_PASSWORD, e.target.value);
                  }}
                />
                <span className="fltlabels"> Current Password</span>
              </div>

              <div className="form-group form-field-item">
                <input
                  type="password"
                  name={CHANGE_PASSWORD_FILEDS.NEW_PASSWORD}
                  className={getClassNames(CHANGE_PASSWORD_FILEDS.NEW_PASSWORD)}
                  autoComplete="off"
                  value={values.new_password}
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(CHANGE_PASSWORD_FILEDS.NEW_PASSWORD, e.target.value);
                  }}
                />
                <span className="fltlabels">New Password</span>
              </div>

              <div className="form-group form-field-item">
                <input
                  type="password"
                  name={CHANGE_PASSWORD_FILEDS.CONFIRM_PASSWORD}
                  className={getClassNames(CHANGE_PASSWORD_FILEDS.CONFIRM_PASSWORD)}
                  autoComplete="off"
                  value={values.confirm_password}
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(CHANGE_PASSWORD_FILEDS.CONFIRM_PASSWORD, e.target.value);
                  }}
                />
                <span className="fltlabels">Confirm New Password</span>
              </div>
              {/* <div className={`btn-sec`}>
                <CustomRippleButton rippleClass={`ac-primary-box  ${isSaveValid ? '' : 'pointer-events-none'}`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className={`ac-btn ac-primary ac-block h-default r-flx r-flx-jc r-flx-ac ${isSaveValid ? '' : 'disabled'}`}
                    type="submit"
                    onClick={(event: React.MouseEvent<HTMLElement, MouseEvent> | any) => {
                      handleSubmit(event);
                    }}
                  />
                </CustomRippleButton>
                <span className="fltlabels">Confirm New Password</span>
              </div> */}
              <div className={`chng__password--btn`}>
                <CustomRippleButton rippleClass={`ac-primary-box  ${isSaveValid ? '' : 'pointer-events-none'}`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className={`ac-btn ac-primary ac-block h-default r-flx r-flx-jc r-flx-ac ${isSaveValid ? '' : 'disabled'}`}
                    type="submit"
                    onClick={(event: React.MouseEvent<HTMLElement, MouseEvent> | any) => {
                      handleSubmit(event);
                    }}
                  >
                    {isFetching ? <Spinner animation="border" variant="primary" /> : 'Save'}
                  </button>
                </CustomRippleButton>
              </div>
              <div>{changePasswordFailMsg ? <span className="errorText">{changePasswordFailMsg}</span> : null}</div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
