import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { THEME_SOURCE_PATH } from 'utils/constants';
import * as rallio from '../../theme/rallio';
import * as renderseo from '../../theme/renderseo';

export const useChartTheme = (statsArray: { [key: string]: string | number }[], colorObjKey: string, fillColorObjKey: string, fadeColorObjKey: string) => {
  const brandConfig = useSelector((state: IStore) => state.accountSwitcher.brand_config);

  if (THEME_SOURCE_PATH === 'rallio' && !brandConfig?.chart_colors) return statsArray;

  const statsData = [...statsArray];
  let theme: any = rallio;

  if (brandConfig?.chart_colors) {
    theme = brandConfig?.chart_colors;
  } else if (THEME_SOURCE_PATH === 'renderseo') {
    theme = renderseo;
  }

  const colorObj = theme[colorObjKey] || (rallio as any)[colorObjKey];
  const fillColorObj = theme[fillColorObjKey] || (rallio as any)[fillColorObjKey];
  const fadeColorObj = theme[fadeColorObjKey] || (rallio as any)[fadeColorObjKey];

  const returnData = statsData.map((it: any) => {
    return {
      ...it,
      color: colorObj[it.colorKey],
      fillColor: fillColorObj[it.colorKey],
      fadeColor: fadeColorObj[it.colorKey]
    };
  });
  return returnData;
};
