import { IReviewKeywordStatsChart } from 'analytics/types';
import { KEYWORDS_FONT_VARIANT, REVIEW_ANALYTICS_KEYWORD_STATS } from 'analytics/utils';

export const KeywordsStatsChart = ({ keywordsSummary = { pos: 0, neut: 0, neg: 0 } }: IReviewKeywordStatsChart) => {
  const sortedArray = Object.keys(keywordsSummary).sort((a, b) => keywordsSummary[b] - keywordsSummary[a]);

  return (
    <div className="platform-icon">
      {REVIEW_ANALYTICS_KEYWORD_STATS.map((it) => {
        const index = sortedArray.findIndex((datum) => datum === it.value);
        const fontVariant = index <= 3 ? KEYWORDS_FONT_VARIANT[index] : KEYWORDS_FONT_VARIANT[2];
        return keywordsSummary[`${it.value}`] ? (
          <span key={it.value} className={`${it.variant} ${fontVariant}`}>
            {it.title}
          </span>
        ) : null;
      })}
    </div>
  );
};
