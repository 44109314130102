import { useEffect } from 'react';
import { UIEvent, PhotoEditorSDKUI, ImageFormat, ExportFormat, Tool, UploadedSticker } from 'photoeditorsdk';

import { config } from 'config';

interface IPhotoEditor {
  imgUrl: string;
  defaultTool?: Tool;
  watermarkItems: Array<{
    identifier: string;
    name: string;
    thumbnailURI: string;
    stickerURI: string;
    tintMode: string;
    resizeMode: string;
  }>;
  setImageLy: () => void;
  exportCallback: (image: string) => void;
  uploadCallback?: (watermarkImages: UploadedSticker[]) => void;
  closeCallback?: () => void;
  onToolChange?: (activeTool: Tool) => void;
}

export const PhotoEditorSDK = (props: IPhotoEditor) => {
  const { imgUrl, defaultTool, watermarkItems, setImageLy, exportCallback, uploadCallback, closeCallback, onToolChange } = props;

  useEffect(() => {
    const initEditor = async () => {
      const editor = await PhotoEditorSDKUI.init({
        assetBaseUrl: `${config.siteUrl}assets`,
        defaultTool: defaultTool || Tool.FILTER,
        theme: 'light',
        container: '#editor',
        image: imgUrl,
        license: `${config.imgLy.license}`,
        library: {
          flattenCategories: true
          // enableWebcam: true, // Disables the webcam
          // enableUpload: true // Disables the upload
        },
        text: {
          fonts: [
            {
              identifier: 'google-roboto',
              fontFamily: 'Roboto',
              fontWeight: 400,
              provider: 'google',
              textMetrics: {
                unitsPerEm: 2048,
                ascender: 1900,
                descender: -500
              }
            }
          ]
        },
        export: {
          image: {
            enableDownload: false, // will prevent the file download on the user side
            format: ImageFormat.PNG,
            exportType: ExportFormat.DATA_URL
          }
        },
        sticker: {
          categories: [
            {
              identifier: 'saved watermark',
              name: 'Saved Watermarks',
              thumbnailURI: `${config.cdnImgUrl}black-white.svg`,
              items: watermarkItems
            },
            {
              identifier: 'imgly_sticker_emoticons',
              items: [
                { identifier: 'imgly_sticker_emoticons_grin' },
                { identifier: 'imgly_sticker_emoticons_laugh' },
                { identifier: 'imgly_sticker_emoticons_smile' },
                { identifier: 'imgly_sticker_emoticons_wink' },
                { identifier: 'imgly_sticker_emoticons_tongue_out_wink' },
                { identifier: 'imgly_sticker_emoticons_angel' },
                { identifier: 'imgly_sticker_emoticons_kisses' },
                { identifier: 'imgly_sticker_emoticons_loving' },
                { identifier: 'imgly_sticker_emoticons_kiss' },
                { identifier: 'imgly_sticker_emoticons_wave' },
                { identifier: 'imgly_sticker_emoticons_nerd' },
                { identifier: 'imgly_sticker_emoticons_cool' },
                { identifier: 'imgly_sticker_emoticons_blush' },
                { identifier: 'imgly_sticker_emoticons_duckface' },
                { identifier: 'imgly_sticker_emoticons_furious' },
                { identifier: 'imgly_sticker_emoticons_angry' },
                { identifier: 'imgly_sticker_emoticons_steaming_furious' },
                { identifier: 'imgly_sticker_emoticons_sad' },
                { identifier: 'imgly_sticker_emoticons_anxious' },
                { identifier: 'imgly_sticker_emoticons_cry' },
                { identifier: 'imgly_sticker_emoticons_sobbing' },
                { identifier: 'imgly_sticker_emoticons_loud_cry' },
                { identifier: 'imgly_sticker_emoticons_wide_grin' },
                { identifier: 'imgly_sticker_emoticons_impatient' },
                { identifier: 'imgly_sticker_emoticons_tired' },
                { identifier: 'imgly_sticker_emoticons_asleep' },
                { identifier: 'imgly_sticker_emoticons_sleepy' },
                { identifier: 'imgly_sticker_emoticons_deceased' },
                { identifier: 'imgly_sticker_emoticons_attention' },
                { identifier: 'imgly_sticker_emoticons_question' },
                { identifier: 'imgly_sticker_emoticons_not_speaking_to_you' },
                { identifier: 'imgly_sticker_emoticons_sick' },
                { identifier: 'imgly_sticker_emoticons_pumpkin' },
                { identifier: 'imgly_sticker_emoticons_boxer' },
                { identifier: 'imgly_sticker_emoticons_idea' },
                { identifier: 'imgly_sticker_emoticons_smoking' },
                { identifier: 'imgly_sticker_emoticons_beer' },
                { identifier: 'imgly_sticker_emoticons_skateboard' },
                { identifier: 'imgly_sticker_emoticons_guitar' },
                { identifier: 'imgly_sticker_emoticons_music' },
                { identifier: 'imgly_sticker_emoticons_sunbathing' },
                { identifier: 'imgly_sticker_emoticons_hippie' },
                { identifier: 'imgly_sticker_emoticons_humourous' },
                { identifier: 'imgly_sticker_emoticons_hitman' },
                { identifier: 'imgly_sticker_emoticons_harry_potter' },
                { identifier: 'imgly_sticker_emoticons_business' },
                { identifier: 'imgly_sticker_emoticons_batman' },
                { identifier: 'imgly_sticker_emoticons_skull' },
                { identifier: 'imgly_sticker_emoticons_ninja' },
                { identifier: 'imgly_sticker_emoticons_masked' },
                { identifier: 'imgly_sticker_emoticons_alien' },
                { identifier: 'imgly_sticker_emoticons_wrestler' },
                { identifier: 'imgly_sticker_emoticons_devil' },
                { identifier: 'imgly_sticker_emoticons_star' },
                { identifier: 'imgly_sticker_emoticons_baby_chicken' },
                { identifier: 'imgly_sticker_emoticons_rabbit' },
                { identifier: 'imgly_sticker_emoticons_pig' },
                { identifier: 'imgly_sticker_emoticons_chicken' }
              ]
            },
            {
              identifier: 'imgly_sticker_shapes',
              items: [
                { identifier: 'imgly_sticker_shapes_badge_01' },
                { identifier: 'imgly_sticker_shapes_badge_04' },
                { identifier: 'imgly_sticker_shapes_badge_12' },
                { identifier: 'imgly_sticker_shapes_badge_06' },
                { identifier: 'imgly_sticker_shapes_badge_13' },
                { identifier: 'imgly_sticker_shapes_badge_36' },
                { identifier: 'imgly_sticker_shapes_badge_08' },
                { identifier: 'imgly_sticker_shapes_badge_11' },
                { identifier: 'imgly_sticker_shapes_badge_35' },
                { identifier: 'imgly_sticker_shapes_badge_28' },
                { identifier: 'imgly_sticker_shapes_badge_32' },
                { identifier: 'imgly_sticker_shapes_badge_15' },
                { identifier: 'imgly_sticker_shapes_badge_20' },
                { identifier: 'imgly_sticker_shapes_badge_18' },
                { identifier: 'imgly_sticker_shapes_badge_19' },
                { identifier: 'imgly_sticker_shapes_arrow_02' },
                { identifier: 'imgly_sticker_shapes_arrow_03' },
                { identifier: 'imgly_sticker_shapes_spray_01' },
                { identifier: 'imgly_sticker_shapes_spray_04' },
                { identifier: 'imgly_sticker_shapes_spray_03' }
              ]
            }
          ]
        },
        custom: {
          languages: {
            en: {
              mainCanvasActions: {
                buttonExport: 'Save'
              },
              sticker: {
                title: 'Watermark',
                controls: {
                  buttonUpload: 'Upload Watermark'
                }
              }
            }
          }
        }
      });
      editor.on(UIEvent.EXPORT, (imageSrc) => {
        if (exportCallback) {
          exportCallback(imageSrc);
        }
        setImageLy();
      });
      editor.on(UIEvent.CLOSE, () => {
        if (closeCallback) {
          closeCallback();
        }
        setImageLy();
      });
      editor.on(UIEvent.STICKER_UPLOAD_ADD, (uploadedStickers: Array<UploadedSticker>) => {
        if (uploadCallback) {
          uploadCallback(uploadedStickers);
        }
      });
      editor.on(UIEvent.TOOL_ENTER, (activeTool) => {
        if (onToolChange) {
          onToolChange(activeTool);
        }
      });
    };

    initEditor();
  }, [imgUrl, defaultTool, watermarkItems, setImageLy, exportCallback, uploadCallback, closeCallback, onToolChange]);

  return <div id="editor" className="img-editor--wrp" key={watermarkItems.length} />;
};
