import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { apFranReducerTypes } from 'types';
import {
  AP_CONTENT_INIT_OBJ,
  AP_CONTENT_ACTION_TYPE,
  ADD_POST_SAVE_CONTENT_DRAFT_SUCCESS_MSG,
  AP_UPDATED_MSG_INIT_OBJ,
  ADD_POST_APPROVE_AND_SAVE_TO_DB_SUCCESS_MSG,
  ADD_POST_SCHEDULE_SUCCESS_MSG,
  ADD_POST_FRAN_POST_NOW_SUCCESS_MSG,
  ADD_POST_APPROVE_AND_SYNDICATE_MSG,
  AP_ACTION_TYPES,
  AP_FRAN_INIT_CONTENT_SAVED_DATE,
  POST_ASSIGNED_TO_CAMPAIGN_SUCCESS_MSG,
  ADD_MEDIA_APPROVE_AND_SYNDICATE_MSG,
  FranchisorSyndicationMediaType,
  AP_FRAN_INIT_VIDEO_COMPATIBILITY,
  AP_MEDIA_EXPIRATION_INITIAL_OBJ
} from 'utils/constants';
import { AddPostFranchisorActions } from 'actions';
import { getSelectedLocationList, getSelectedMediaSortedList, getUniqueSelectedLocationList } from 'utils/helpers';

type IAddPostFranReducer = apFranReducerTypes.IAddPostFranReducer;

const initialState: IAddPostFranReducer = {
  error: null,
  isSaveDraftFetching: false,
  isCopyFetching: false,
  isApproveAndSaveToDBFetching: false,
  isApproveAndAutoScheduleFetching: false,
  isApproveAndSyndicateFetching: false,
  isCampaignFetching: false,
  isPostFetching: false,
  actionType: null,
  pageType: AP_ACTION_TYPES.ADD,
  contentData: null,
  updatedMessage: AP_UPDATED_MSG_INIT_OBJ,
  contentObj: AP_CONTENT_INIT_OBJ,
  selectedMediaList: [],
  selectedFBTagList: [],
  accountIdsBasedOnHub: [],
  selectedLocList: [],
  accountListIds: [],
  contentSavedDate: AP_FRAN_INIT_CONTENT_SAVED_DATE,
  useMediaContentData: null,
  syndicationUpdateMessage: AP_UPDATED_MSG_INIT_OBJ,
  selectedSyndicateFranchisors: [],
  postSyndicationDetails: [],
  videoCompatibility: AP_FRAN_INIT_VIDEO_COMPATIBILITY
  // urlDescription: { URL: '', description: '', title: '' }
};

export const addPostFranReducer: Reducer<IAddPostFranReducer, AddPostFranchisorActions> = (state: IAddPostFranReducer = initialState, action: AddPostFranchisorActions) => {
  switch (action.type) {
    // ADD POST - GET CONTENT POST
    case types.AP_GET_CONTENT_POST_DATA_REQUEST:
      return {
        ...state,
        contentData:
          state.actionType &&
          [
            AP_CONTENT_ACTION_TYPE.APPROVE_AND_SAVE_TO_DB,
            AP_CONTENT_ACTION_TYPE.APPROVE_AND_INIT_AUTO_SCHEDULE,
            AP_CONTENT_ACTION_TYPE.APPROVE_AND_SYNDICATE,
            AP_CONTENT_ACTION_TYPE.SCHEDULE,
            AP_CONTENT_ACTION_TYPE.POST_NOW
          ].includes(state.actionType)
            ? state.contentData
            : null,
        isPostFetching: true
      };
    case types.AP_GET_CONTENT_POST_DATA_RESPONSE:
      return {
        ...state,
        contentData: action.payload,
        isSaveDraftFetching: initialState.isSaveDraftFetching,
        isApproveAndSaveToDBFetching: initialState.isApproveAndSaveToDBFetching,
        isApproveAndAutoScheduleFetching: initialState.isApproveAndAutoScheduleFetching,
        isApproveAndSyndicateFetching: initialState.isApproveAndSyndicateFetching,
        isCampaignFetching: initialState.isCampaignFetching,
        isPostFetching: initialState.isPostFetching,
        useMediaContentData: initialState.useMediaContentData
      };
    case types.AP_GET_CONTENT_POST_DATA_FAILURE:
      return {
        ...state,
        contentData: null,
        error: action.payload || 'Unexpected error'
      };
    case types.AP_GET_SELECTED_CONTENT_OBJ:
      return {
        ...state,
        contentObj: action.payload
      };
    case types.AP_UPDATE_CONTENT_OBJ:
      return {
        ...state,
        contentData: action.payload
      };
    // ADD POST UPDATE CONTENT
    case types.AP_UPDATE_CONTENT_REQUEST:
      return {
        ...state,
        actionType: action.payload.actionType,
        isSaveDraftFetching: action.payload.actionType === AP_CONTENT_ACTION_TYPE.SAVE_DRAFT,
        isApproveAndSaveToDBFetching: action.payload.actionType === AP_CONTENT_ACTION_TYPE.APPROVE_AND_SAVE_TO_DB,
        isApproveAndAutoScheduleFetching: action.payload.actionType === AP_CONTENT_ACTION_TYPE.APPROVE_AND_INIT_AUTO_SCHEDULE,
        isApproveAndSyndicateFetching: action.payload.actionType === AP_CONTENT_ACTION_TYPE.APPROVE_AND_SYNDICATE,
        isCampaignFetching: [AP_CONTENT_ACTION_TYPE.SINGLE_CAMPAIGN_POST, AP_CONTENT_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(action.payload.actionType)
      };
    case types.AP_UPDATE_CONTENT_RESPONSE:
      return {
        ...state,
        updatedMessage: {
          ...state.updatedMessage,
          contentSuccessMsg:
            state.actionType && [AP_CONTENT_ACTION_TYPE.SINGLE_CAMPAIGN_POST, AP_CONTENT_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(state.actionType)
              ? `${POST_ASSIGNED_TO_CAMPAIGN_SUCCESS_MSG}${state.actionType === AP_CONTENT_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS ? 's' : ''}`
              : state.actionType === AP_CONTENT_ACTION_TYPE.SAVE_DRAFT
              ? ADD_POST_SAVE_CONTENT_DRAFT_SUCCESS_MSG
              : state.actionType && [AP_CONTENT_ACTION_TYPE.APPROVE_AND_SAVE_TO_DB, AP_CONTENT_ACTION_TYPE.APPROVE_AND_INIT_AUTO_SCHEDULE].includes(state.actionType)
              ? ADD_POST_APPROVE_AND_SAVE_TO_DB_SUCCESS_MSG
              : state.actionType === AP_CONTENT_ACTION_TYPE.APPROVE_AND_SYNDICATE
              ? ADD_POST_APPROVE_AND_SYNDICATE_MSG
              : state.updatedMessage.contentSuccessMsg
        },
        contentData: action.payload
      };
    case types.AP_UPDATE_CONTENT_FAILURE:
      return {
        ...state,
        isSaveDraftFetching: false,
        isApproveAndSaveToDBFetching: false,
        isApproveAndAutoScheduleFetching: false,
        isApproveAndSyndicateFetching: false,
        isCampaignFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - GET LOC IDS BASED ON HUB SELECTION
    case types.AP_GET_LOC_IDS_BASED_ON_HUB_REQUEST:
      return {
        ...state,
        accountIdsBasedOnHub: []
      };
    case types.AP_GET_LOC_IDS_BASED_ON_HUB_RESPONSE:
      return {
        ...state,
        accountIdsBasedOnHub: action.payload
      };
    case types.AP_GET_LOC_IDS_BASED_ON_HUB_FAILURE:
      return {
        ...state,
        accountIdsBasedOnHub: [],
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - GET LOC DETAIL BASED ON LOCATION SELECTION
    case types.AP_GET_LOC_DETAIL_BASED_ON_LOC_REQUEST:
      return {
        ...state
      };
    case types.AP_GET_LOC_DETAIL_BASED_ON_LOC_RESPONSE:
      return {
        ...state,
        accountListIds: Array.from(new Map([...state.accountListIds, action.payload.id].map((dataItem) => [dataItem, dataItem])).values()),
        selectedLocList: getSelectedLocationList(state.selectedLocList, action.payload.included_accounts)
      };
    case types.AP_GET_LOC_DETAIL_BASED_ON_LOC_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - ADD SCHEDULED MULTIPOSTS
    case types.AP_ADD_SCHEDULED_MULTIPOSTS_REQUEST:
      return {
        ...state,
        actionType: action.payload.actionType,
        isPostFetching: true
      };
    case types.AP_ADD_SCHEDULED_MULTIPOSTS_RESPONSE:
      return {
        ...state,
        updatedMessage: {
          ...state.updatedMessage,
          contentSuccessMsg:
            action.payload && state.actionType === AP_CONTENT_ACTION_TYPE.SCHEDULE
              ? ADD_POST_SCHEDULE_SUCCESS_MSG
              : action.payload && state.actionType === AP_CONTENT_ACTION_TYPE.POST_NOW
              ? ADD_POST_FRAN_POST_NOW_SUCCESS_MSG
              : null
        }
      };
    case types.AP_ADD_SCHEDULED_MULTIPOSTS_FAILURE:
      return {
        ...state,
        updatedMessage: {
          ...state.updatedMessage,
          contentSuccessMsg: initialState.updatedMessage.contentSuccessMsg
        },
        isPostFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.AP_GET_SELECTED_FB_TAG_LIST:
      return {
        ...state,
        selectedFBTagList: action.payload
      };
    case types.AP_SET_SELECTED_MEDIA_LIST:
      const uniqueMediaList = Array.from(new Map(action.payload.map((dataItem) => [dataItem.id, dataItem])).values());
      const sortedMediaList = uniqueMediaList.sort((x, y) => x.name.localeCompare(y.name));
      return {
        ...state,
        selectedMediaList: sortedMediaList
      };
    case types.AP_SET_SELECTED_LOC_LIST_DATA:
      return {
        ...state,
        selectedLocList: getUniqueSelectedLocationList(action.payload),
        accountIdsBasedOnHub: initialState.accountIdsBasedOnHub
      };
    case types.AP_FRAN_SET_PAGE_TYPE:
      return {
        ...state,
        pageType: action.payload
      };
    case types.AP_FRAN_SET_SAVED_DATE:
      return {
        ...state,
        contentSavedDate: {
          ...state.contentSavedDate,
          ...action.payload
        }
      };
    case types.USE_MEDIA_CONTENT_DATA:
      return {
        ...state,
        useMediaContentData: action.payload
      };
    case types.AP_PREVIOUS_PACKAGE_DATA_RESET:
      return {
        ...state,
        error: initialState.error,
        isSaveDraftFetching: initialState.isSaveDraftFetching,
        isApproveAndSaveToDBFetching: initialState.isApproveAndSaveToDBFetching,
        updatedMessage: AP_UPDATED_MSG_INIT_OBJ,
        contentObj: AP_CONTENT_INIT_OBJ,
        selectedMediaList: initialState.selectedMediaList,
        selectedFBTagList: initialState.selectedFBTagList,
        selectedSyndicateFranchisors: [],
        syndicationUpdateMessage: AP_UPDATED_MSG_INIT_OBJ
      };
    case types.AP_SCHEDULED_CONTENT_RESET:
      return {
        ...state,
        accountIdsBasedOnHub: initialState.accountIdsBasedOnHub,
        selectedLocList: initialState.selectedLocList,
        accountListIds: initialState.accountListIds
      };
    case types.AP_FRAN_UPDATED_MSG_RESET:
      return {
        ...state,
        updatedMessage: AP_UPDATED_MSG_INIT_OBJ,
        error: initialState.error
      };
    case types.AP_SET_FRAN_SELECTED_MEDIA_LIST_RESET:
      return {
        ...state,
        overallSelectedMediaList: []
      };
    case types.ADD_FRANCHISOR_SYNDICATION_REQUEST:
      return {
        ...state,
        syndicationUpdateMessage: AP_UPDATED_MSG_INIT_OBJ
      };
    case types.ADD_FRANCHISOR_SYNDICATION_RESPONSE:
      return {
        ...state,
        syndicationUpdateMessage: {
          ...state.syndicationUpdateMessage,
          contentSuccessMsg: action.payload ? (action.payload === FranchisorSyndicationMediaType.CONTENT ? ADD_POST_APPROVE_AND_SYNDICATE_MSG : ADD_MEDIA_APPROVE_AND_SYNDICATE_MSG) : ''
        }
      };
    case types.ADD_FRANCHISOR_SYNDICATION_FAILURE:
      return {
        ...state,
        syndicationUpdateMessage: AP_UPDATED_MSG_INIT_OBJ
      };
    case types.GET_FRANCHISOR_SYNDICATION_RESPONSE:
      return {
        ...state,
        postSyndicationDetails: action.payload
      };
    case types.GET_FRANCHISOR_SYNDICATION_FAILURE:
      return {
        ...state,
        postSyndicationDetails: []
      };
    case types.FRANCHISOR_SYNDICATION_RESET_ALL:
      return {
        ...state,
        syndicationUpdateMessage: AP_UPDATED_MSG_INIT_OBJ,
        selectedSyndicateFranchisors: [],
        // postSyndicationDetails: [],
        contentObj: {
          ...state.contentObj,
          dateAndTime: { selected: null, utcFormat: null },
          customDate: { fromDate: { selected: null, utcFormat: null }, toDate: { selected: null, utcFormat: null } },
          franSyndicationMinMaxDate: { minDate: null, maxDate: null }
        }
      };
    case types.SELECT_SYNDICATE_TO_FRANCHISOR:
      return {
        ...state,
        selectedSyndicateFranchisors: action.payload
      };
    case types.AP_GET_VIDEO_COMPATIBILITY_REQUEST:
      return {
        ...state,
        videoCompatibility: AP_FRAN_INIT_VIDEO_COMPATIBILITY
      };
    case types.AP_GET_VIDEO_COMPATIBILITY_RESPONSE:
      return {
        ...state,
        videoCompatibility: action.payload
      };
    case types.AP_GET_VIDEO_COMPATIBILITY_FAILURE:
      return {
        ...state,
        videoCompatibility: AP_FRAN_INIT_VIDEO_COMPATIBILITY
      };
    case types.AP_VIDEO_COMPATIBILITY_RESET:
      return {
        ...state,
        videoCompatibility: AP_FRAN_INIT_VIDEO_COMPATIBILITY
      };
    case types.SELECTED_MEDIA_LIST_SORT:
      const updatedMediaList = getSelectedMediaSortedList(state.selectedMediaList, action.payload.dropIndex, action.payload.draggedItem);
      return {
        ...state,
        selectedMediaList: updatedMediaList
      };
    case types.AP_ADD_CUSTOM_VIDEO_THUMBNAIL_REQUEST:
      const selectedMediaIndex = state.selectedMediaList.findIndex((media) => media.id === action.payload.id);
      const updatedVideoList = [...state.selectedMediaList];
      if (selectedMediaIndex > -1) {
        updatedVideoList[selectedMediaIndex] = { ...state.selectedMediaList[selectedMediaIndex], ...action.payload };
      }
      return {
        ...state,
        selectedMediaList: updatedVideoList
      };
    case types.AP_RESET_MEDIA_EXPIRATION:
      return {
        ...state,
        contentObj: { ...state.contentObj, expiration: AP_MEDIA_EXPIRATION_INITIAL_OBJ }
      };
    case types.UPDATE_MULTI_SELECT_TAGS_RESPONSE:
      return {
        ...state,
        updatedMessage: {
          ...AP_UPDATED_MSG_INIT_OBJ,
          contentSuccessMsg: action.payload
        }
      };
    case types.UPDATE_MULTI_SELECT_TAGS_FAILURE:
      return {
        ...state,
        updatedMessage: {
          ...AP_UPDATED_MSG_INIT_OBJ,
          contentFailureMsg: action.payload
        }
      };
    case types.AP_FRAN_RESET_ALL:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
