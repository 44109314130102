import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ImageValidation } from 'widgets/Media';
import { IPlatformTable, ITablePlatformBody } from 'analytics/types';
import { convertMsTimeFormat, numberFormatter } from 'analytics/utils';
import { CustomIndicator } from 'analytics/components';
import { getFormattedNumber, scrollTolLeftToRight } from 'utils/helpers';
import { NOT_APPLICABLE } from 'utils/constants';
import { CarouselTable } from 'components';
import { ITableHeader } from 'types';
import { useParamsDeconstructor } from 'utils/hooks';

export const PlatformTable = ({
  headers,
  totals,
  data,
  isContentSupplier,
  isTotal = true,
  tableVariant = '',
  tableParentVariant = '',
  totalText = isContentSupplier ? 'Average total per day' : 'Totals',
  isTimeFormat = false,
  isMinSecEnabled = false,
  isDelta = true,
  isPercentage = false,
  isToFixed = false,
  defaultValue = NOT_APPLICABLE
}: IPlatformTable) => {
  const { queryParams } = useParamsDeconstructor();
  const valueFormatter = useCallback(
    (value: number, isDelta = false) => {
      return +value ? (!isTimeFormat ? (isDelta ? (+value > 0 ? `+${numberFormatter(+value)}` : numberFormatter(+value)) : numberFormatter(+value)) : convertMsTimeFormat(+value, true)) : 0;
    },
    [isTimeFormat]
  );

  const pageName = useLocation().pathname.split('/')[4];

  useEffect(() => {
    scrollTolLeftToRight('scrollLeftToRight');
  }, [queryParams?.selected_tile]);

  const TableBody = useCallback(
    ({ delta, value, isTimeFormat, isMinSecEnabled, isDelta }: ITablePlatformBody) => {
      return (
        <div className="tbl-delta--wrp">
          <span
            className="value1"
            title={isTimeFormat && value ? convertMsTimeFormat(+value) : value !== undefined && !isTimeFormat && Math.abs(+value) >= 10000 ? getFormattedNumber(+value) || '' : undefined}
          >
            {value !== undefined && !Number.isNaN(+value) ? (isToFixed ? Number(value).toFixed(1) : `${valueFormatter(+value)}${isPercentage ? '%' : ''}`) : defaultValue}
          </span>
          {delta !== undefined && isDelta ? (
            <div className="deltaHolder">
              {/* <div className={`deltaHolder ${delta > 0 ? 'plus-val' : delta <= -1 ? 'minus-val' : 'value-none'} ${delta < 1 && delta > 0 ? 'zero-plus' : '' }`}> if we want to match the figma we need this line*/}
              <CustomIndicator value={isMinSecEnabled ? -1 * +delta : +delta} isRotate={isMinSecEnabled ? !(+delta === 0) : false} isHidden={+delta < 0} />
              <span
                title={isTimeFormat && delta ? convertMsTimeFormat(+delta) : delta !== undefined && !isTimeFormat && Math.abs(+delta) >= 10000 ? getFormattedNumber(+delta) || '' : undefined}
                className={`variant-v2 ${valueFormatter(+delta, true) === 0 ? 'zero-value' : ''}`}
              >
                {valueFormatter(+delta, true)}
              </span>
              <CustomIndicator value={isMinSecEnabled ? -1 * +delta : +delta} isRotate={isMinSecEnabled || !+delta} isHidden={+delta > 0} />
            </div>
          ) : null}
        </div>
      );
    },
    [defaultValue, isPercentage, isToFixed, valueFormatter]
  );

  const TablePlatformImage = ({ image, variant, name, isName }: { image: string; variant: string; name: string; isName: boolean }) => {
    return (
      <div className="an-social-icon-title">
        <div>
          <ImageValidation isImgValid defaultImg={image} isNotSvgFormat customName={'social-media'} customClassname={'social-media'} />
        </div>
        {isName && <span className={`${variant}`}>{name}</span>}
      </div>
    );
  };

  const renderColumn = useCallback(
    (rowObj: { [key: string]: string | number | boolean }, header: ITableHeader<{ [key: string]: string | number | boolean }>, rowIndex: number) => {
      const isPlatform = header.identifier === 'platform' || header.identifier === 'profile';

      const currentHeader = headers.find((it) => it.value === header.identifier);

      return rowObj.isTotalsRow && currentHeader && isTotal ? (
        header.identifier === 'platform' || header.identifier === 'profile' ? (
          totalText
        ) : (
          <TableBody isDelta={isDelta} value={totals[currentHeader?.totalValue]} delta={totals[currentHeader?.totalValueDelta]} isTimeFormat={isTimeFormat} isMinSecEnabled={isMinSecEnabled} />
        )
      ) : isPlatform ? (
        <TablePlatformImage image={rowObj?.image?.toString()} name={rowObj?.name?.toString()} variant={rowObj?.class?.toString()} isName={headers.length < 5} />
      ) : (
        <TableBody isDelta={isDelta} value={rowObj[header.identifier]} delta={rowObj[`${header.identifier}_delta`]} isTimeFormat={isTimeFormat} isMinSecEnabled={isMinSecEnabled} />
      );
    },
    [TableBody, headers, isDelta, isMinSecEnabled, isTimeFormat, totalText, totals, isTotal]
  );

  const carouselTableHeader: ITableHeader<{ [key: string]: string | number | boolean }>[] = useMemo(() => {
    return headers.map((it, index) => {
      return {
        identifier: it.value,
        label: it.title,
        labelString: it.title,
        renderHeader: (headerObj, _1, setShowDropdown) => (
          <span
            className="hard_left"
            onClick={() => {
              if (setShowDropdown) setShowDropdown((val) => (val ? '' : headerObj.identifier));
            }}
          >
            {it.title} {!index && data?.length ? `(${data?.length})` : ''}
          </span>
        ),
        renderColumn,
        headerClassName: 'hard_left'
      };
    });
  }, [headers, renderColumn, data?.length]);

  return (
    <div className="flex-item-xs lpx vpy-20">
      <div id="scrollLeftToRight" className={`profile-tbl-wrp resuseTable ${tableParentVariant} ${pageName === 'analytics_page' ? 'profile__tbl--page' : ''}`}>
        <CarouselTable<{ [key: string]: string | number | boolean }>
          data={isTotal ? [[{ ...totals, rowClassName: 'total', isTotalsRow: true }], data] : [data]}
          headers={carouselTableHeader}
          isFetching={false}
          tableClassName={`${tableVariant} ${isTimeFormat ? 'response-tbl' : ''} responsiveTable ${
            pageName === 'analytics_page' ? 'analytics_platform--page' : pageName === 'analytics_social' ? 'analytics_platform--social' : ''
          }`}
          ipadBoundary={768}
          ipadColCount={2}
          mobileColCount={2}
          mdDeviceBoundary={992}
          mdDeviceColCount={3}
          pinnedColumns={[0]}
        />
      </div>
    </div>
  );
};
