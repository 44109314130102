import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

import { KEY_WORD_MANAGEMENT_TABLE_HEADERS, KeywordManagementTableValue, ReviewAnalyticsStatsValue, handleKeywordsTableTotal } from 'analytics/utils';
import { Loading, ScrollableCarouselTable } from 'components';
import { IStore, commonProps, ITableHeader, ICustomHeaderDropDownOption } from 'types';
import { config } from 'config';

interface IKeywordsTableProps {
  queryParams: commonProps.ICommonObj<string>;
  dispatchKeyWords: (page: number, isAscending: boolean, sortKey: string, fetchAllKeywords?: boolean) => void;
  handleTableClick: (value: KeywordManagementTableValue, id: string | number | boolean) => void;
}

type ISortingOptions = 'ascending' | 'descending';
interface IKeywordsTableData {
  [key: string]: string | number | boolean;
}

export const KeywordsCategoryTable = ({ queryParams, dispatchKeyWords, handleTableClick }: IKeywordsTableProps) => {
  const navigate = useNavigate();

  const keyWordsData = useSelector((state: IStore) => state.reviewAnalytics.keyWordsData);
  const keyWordPageCount = useSelector((state: IStore) => state.reviewAnalytics.keyWordPageCount);
  const isKeyWordDataFetching = useSelector((state: IStore) => state.reviewAnalytics.isKeyWordDataFetching);

  const isKeywordAscending = queryParams?.ascending === '1' ? true : false;
  const sortKey = queryParams?.sort_by || 'used';

  const responsiveSortingOptions: ICustomHeaderDropDownOption[] = useMemo(
    () => [
      {
        identifier: 'ascending' as ISortingOptions,
        text: 'Low to High',
        type: 'sort'
      },
      {
        identifier: 'descending' as ISortingOptions,
        text: 'High to Low',
        type: 'sort'
      }
    ],
    []
  );

  const handleSort = (sortKey: string, dropdownItem?: ICustomHeaderDropDownOption) => {
    const urlParams = { ...queryParams };
    if (!urlParams.selected_tile) {
      urlParams.selected_tile = ReviewAnalyticsStatsValue.KEYWORDS;
    }
    if (sortKey === 'used') {
      delete urlParams.sort_by;
    } else {
      urlParams.sort_by = sortKey;
    }
    if (dropdownItem) {
      if (queryParams.ascending === '1' && dropdownItem?.identifier === 'descending') {
        delete urlParams.ascending;
      } else {
        if (!queryParams.ascending && dropdownItem?.identifier === 'ascending') {
          urlParams.ascending = '1';
        }
      }
    } else {
      if (queryParams.ascending === '1') {
        delete urlParams.ascending;
      } else {
        urlParams.ascending = '1';
      }
    }
    navigate({ search: `?${new URLSearchParams(urlParams).toString()}` });
  };

  const renderHeader = (headerObj: ITableHeader<IKeywordsTableData, {}>, isResponsiveMode?: boolean, setShowDropdown?: React.Dispatch<React.SetStateAction<string>>, pinned?: boolean) => {
    return (
      <div
        onClick={() => {
          if (isResponsiveMode && !pinned && setShowDropdown) {
            setShowDropdown((prevState) => (headerObj.identifier === prevState ? '' : headerObj.identifier));
          }
        }}
      >
        {headerObj.label}
        {headerObj.identifier === sortKey ? <img className={`sort-icon ${isKeywordAscending ? 'sort-icon__rotate' : ''}`} src={`${config.cdnImgUrl}sort.svg`} alt="keyword sort icon" /> : null}
      </div>
    );
  };

  const renderColumn = (rowObj: IKeywordsTableData, header: ITableHeader<IKeywordsTableData, {}>) => {
    const tableColClass = () => {
      switch (header.identifier) {
        case 'positive':
          return 'positive-col';
        case 'negative':
          return 'negative-col';
        case 'neutral':
          return 'neutral-col';
        default:
          return '';
      }
    };
    return rowObj.isTotalsRow ? (
      <div className={`${tableColClass()} total-txt`}>{rowObj[header.identifier]}</div>
    ) : (
      <div className={tableColClass()} onClick={() => handleTableClick(header.identifier as KeywordManagementTableValue, rowObj[KEY_WORD_MANAGEMENT_TABLE_HEADERS[0].value])}>
        {rowObj[header.identifier]}
        {header.identifier === KEY_WORD_MANAGEMENT_TABLE_HEADERS[5].value && <span className="percent-sign">%</span>}
      </div>
    );
  };

  const keywordsTableHeader = KEY_WORD_MANAGEMENT_TABLE_HEADERS.map(
    (it): ITableHeader<IKeywordsTableData> => ({
      identifier: it.value,
      label: it.title,
      labelString: it.title,
      renderColumn,
      renderHeader,
      colClassName: `${it.value === sortKey ? 'active' : ''}`,
      headerClassName: `cur-pointer ${it.value === sortKey ? 'active' : ''}`,
      headerDropdownOptions: responsiveSortingOptions
    })
  );

  const handleScroll = () => {
    dispatchKeyWords(keyWordPageCount + 1, isKeywordAscending, sortKey);
  };
  return (
    <InfiniteScroll
      next={handleScroll}
      hasMore={keyWordsData?.length ? true : false}
      dataLength={keyWordsData?.length || 0}
      loader={isKeyWordDataFetching && keyWordsData?.length === 450 ? <Loading /> : null}
      scrollableTarget="scrollTarget"
    >
      <div className="flex-item-xs">
        <div className="review-tbl-wrp keyword-tbl__wrp resuseTable">
          <ScrollableCarouselTable<IKeywordsTableData>
            hasMore={keyWordsData.length > 0}
            onScroll={handleScroll}
            isFetching={isKeyWordDataFetching}
            headers={keywordsTableHeader}
            data={[[{ ...handleKeywordsTableTotal(keyWordsData), keyword: 'Totals', rowClassName: 'total', isTotalsRow: true }], keyWordsData]}
            ipadBoundary={768}
            ipadColCount={2}
            mobileColCount={2}
            mdDeviceBoundary={992}
            mdDeviceColCount={3}
            pinnedColumns={[0, 1]}
            scrollHolderClassName="lpx vpy-20"
            tableClassName="responsiveTable"
            onHeaderClick={(headerObj) => {
              handleSort(headerObj.identifier);
            }}
            onCustomHeaderDropDownClick={(headerObj, dropdownItem) => {
              handleSort(headerObj.identifier, dropdownItem);
            }}
          />
        </div>
      </div>
    </InfiniteScroll>
  );
};
