import { ENABLED_PLATFORMS } from 'analytics/utils/constants';
import { accountApiTypes, menuAccessApiTypes } from 'types';
import { IPlatformFilter } from 'types/common/filter';
import { USER_OWNERSHIP } from 'utils/constants';
import { formatter, getFormattedNumber, getRoundNumberTo2Decimal } from 'utils/helpers';

export const numberHumanReadableConversion = (value: number, isAvg: boolean = false, isFixedValue: boolean = false, isRevv: boolean = false) => {
  const toFixedValue = isFixedValue ? 1 : 2;
  let numberFormatObj = { value: 1, unit: '' };
  numberFormatObj =
    Math.abs(Number(value)) >= 1.0e9
      ? { value: 1.0e9, unit: 'B' }
      : Math.abs(Number(value)) >= 1.0e6
      ? { value: 1.0e6, unit: 'M' }
      : Math.abs(Number(value)) >= 1.0e3
      ? { value: 1.0e3, unit: 'K' }
      : { value: 1, unit: '' };
  if (isRevv) {
    return `${isAvg ? getRoundNumberTo2Decimal(Number(value / numberFormatObj.value)) : formatter.format(getRoundNumberTo2Decimal(Number(value / numberFormatObj.value)))}${numberFormatObj.unit}`;
  } else {
    return `${isAvg ? getRoundNumberTo2Decimal(Number(value / numberFormatObj.value)) : getRoundNumberTo2Decimal(Number(value / numberFormatObj.value)).toFixed(toFixedValue)}${numberFormatObj.unit}`;
  }
};

export const revvNumberFormatter = (data: number, digit: number = 10000, isRevv: boolean = true, isFixedValue: boolean = false) => {
  return Math.abs(+data) >= digit ? numberHumanReadableConversion(+data, false, isFixedValue, isRevv) : getFormattedNumber(getRoundNumberTo2Decimal(+data));
};

export const sumAverageObjectsInArray = (data: any[], avgArray: string[] = [], prevData: any = null) => {
  return data?.reduce((acc: any, curr: any) => {
    Object.keys(curr).forEach((k) => {
      const isAverage = avgArray.includes(k);
      if (k.includes('_delta') && prevData) {
        const perviousKey = k.replace('_delta', '');
        return (acc[k] = !isNaN(acc[k]) ? +acc[perviousKey] - +prevData?.[perviousKey] || 0 : 0);
      }
      return (acc[k] = !Number.isNaN(+curr[k]) ? (+acc[k] || 0) + +curr[k] / (isAverage ? data.length : 1) : curr[k] / (isAverage ? data.length : 1));
    });
    return acc;
  }, {});
};

export const numberFormatter = (data: number, digit: number = 10000, isFixedValue: boolean = false) => {
  return Math.abs(+data) >= digit ? numberHumanReadableConversion(+data, false, isFixedValue) : getFormattedNumber(getRoundNumberTo2Decimal(+data));
};

export const downloadFormatHandler = (data: any, id: number | null = 0, userOwnership: string = '', currentName: string = '') => {
  return data.map((it: any) => ({
    ...(id && userOwnership ? (userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: id } : { account_id: id }) : null),
    ...(id && userOwnership ? (userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisor_name: currentName } : { account_name: currentName }) : null),
    ...it
  }));
};

export const hubPlatformHandler = (platforms: IPlatformFilter[], isGoodleEnabled: boolean | undefined, enabledPlatforms: menuAccessApiTypes.IUserDetailsAccess | undefined) => {
  const availablePlatform = platforms.filter((it) => {
    if (it.label === 'Platform') {
      return it;
    } else if (it.label === 'Google Business' || it.label === 'Google') {
      return isGoodleEnabled ? it : null;
      // return it;
    } else {
      const enabled = ENABLED_PLATFORMS.find((platform) => platform.label === it.label);
      return enabledPlatforms ? (enabledPlatforms[enabled?.key || ''] ? it : null) : null;
    }
  });
  return availablePlatform[0]?.label === 'Platform' && availablePlatform?.length === 1 ? [] : availablePlatform;
};

export const locationPlatformHandler = (
  platform: IPlatformFilter[],
  socialMedia: accountApiTypes.ISocialMedia[],
  enabledPlatforms: accountApiTypes.IAccountProfileAccess | undefined,
  isProfileAccess?: boolean,
  yelpBusinessId?: null | string
) => {
  const availablePlatform = platform.filter((it) => {
    if (it.value === 'all') {
      return it;
    } else {
      /* if (it.label === 'Instagram') {
        const isInstagramEnabled = socialMedia.find((it) => ['instagram_business_page', 'instagram_public_content', 'instagram'].includes(it.provider_name));
        return isInstagramEnabled ? it : null;
      } else */
      if (it.label === 'Google Business' || it.label === 'Google') {
        const isGoogleEnabled = socialMedia.find((it) => it.provider_name === 'google_oauth2');
        return isGoogleEnabled ? it : null;
      } else {
        const enabled = ENABLED_PLATFORMS.find((platform) => platform.label === it.label);
        const activePlatform = enabledPlatforms ? (enabledPlatforms[enabled?.key || ''] ? it : null) : null;
        return activePlatform;
      }
    }
  });
  const filteredAvailablePlatform = isProfileAccess
    ? availablePlatform
    : availablePlatform.filter(
        (platform) =>
          ['all', yelpBusinessId ? 'yelp' : ''].includes(platform?.value || '') ||
          socialMedia.some((it) => {
            return it.provider_name === 'google_oauth2'
              ? platform.value === 'google'
              : ['instagram_business_page', 'instagram_public_content'].includes(it.provider_name)
              ? platform.value === 'instagram' || platform.value === 'instagram_business_page'
              : it.provider_name === 'instagram'
              ? false
              : it.provider_name === platform.value;
          })
      );
  return filteredAvailablePlatform[0]?.label === 'Platform' && filteredAvailablePlatform?.length === 1 ? [] : filteredAvailablePlatform;
};

export const initialPlatformHandler = (platform: IPlatformFilter[]) => {
  if (platform.length === 2 && platform[0].label === 'Platform') {
    return [platform[1]];
  } else {
    return platform;
  }
};

export const validData = (value: any) => {
  return value !== undefined && value !== null;
};

export const inValidData = (value: any) => {
  return value === null || value === undefined;
};

export const validNumericalData = (data: string | number | null | undefined) => {
  return data ? Number(data) : 0;
};

export const valueFormatter = (value: string | number | null | undefined, fallback: string | number) => {
  return value && validData(value) ? value : fallback;
};
