import { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Tool, UploadedSticker } from 'photoeditorsdk';
import { useNavigate } from 'react-router';

import { IStore, commonImgExistsTypes } from 'types';
import {
  MG_MEDIA_TYPE,
  NAV_ACTIONS,
  USER_OWNERSHIP,
  MG_FILTER_INITIAL_VALUE,
  COMMON_PAGE_TYPE,
  UTC_TIMESTAMP_FORMAT,
  CreatorMediaTypeForUser,
  MgMediaType,
  MG_CLOUD_MEDIA_TYPE
} from 'utils/constants';
import { addAssetReqParams, capitalizeName, dateFormatByRequestedFormat, getCurrentDate, imageExists } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { cloudSignRequest, mgSaveAssetRequest, mgUpdateDetailDataRequest, mgUploadCloudMediaRequest, uploadWatermarkImageToCloudRequest } from 'actions';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { Loading } from 'components';
import { PhotoEditorSDK } from './ImgLy';

export const MgImage = () => {
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(MG_FILTER_INITIAL_VALUE);

  const assetsList = useSelector((state: IStore) => state.mediaGallery.assetsList);
  const mgDetails = useSelector((state: IStore) => state.mediaGallery.mgDetails);
  const editedMgDetails = useSelector((state: IStore) => state.mediaGallery.editedMgDetails);
  const cropperProps = useSelector((state: IStore) => state.mediaGallery.mgMediaCropperPorps);
  const savedWatermarkImage = useSelector((state: IStore) => state.mediaGallery.watermarkUpload.watermarkImageObj);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const userId = useSelector((state: IStore) => state.login.activeUser?.id);
  const isCloudinaryUpload = useSelector((state: IStore) => state.mediaGallery.isCloudinaryUpload);
  const isWatermarkUpload = useSelector((state: IStore) => state.mediaGallery.watermarkUpload.isUpload);
  const clonedFranchisorIds = useSelector((state: IStore) => state.mediaGallery.mgDetails?.cloned_franchisor_ids || []);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id || 0);
  const activeUserName = useSelector((state: IStore) => capitalizeName(state.login.activeUser?.first_name || '', state.login.activeUser?.last_name || ''));
  // const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);

  const [editedImage, setEditedImage] = useState<string | null>(null);
  const [imageLoad, setImageLoad] = useState(false);
  const [imageLy, setImageLy] = useState(false);
  const [activeEditorTool, setActiveEditorTool] = useState<Tool>(Tool.FILTER);
  const [mgValidateMedia, setMgValidateMedia] = useState<commonImgExistsTypes.IImageExistsWithPayload<commonImgExistsTypes.IUserImageExistsObj>>();

  const mediaType = optionalParams[0];

  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line

  useEffect(() => {
    if (mgDetails?.id && [MG_MEDIA_TYPE.PHOTO, MG_CLOUD_MEDIA_TYPE.IMAGE, MG_MEDIA_TYPE.RAW].includes(mgDetails.media_type)) {
      const imageExistsPayload: commonImgExistsTypes.IUserImageExistsObj = {
        id: mgDetails.id,
        profilePhotoUrl: mgDetails?.medium_size_url || null
      };
      const imageExistValidation = imageExists(mgDetails?.medium_size_url || null, imageExistsPayload);
      imageExistValidation.then((results) => setMgValidateMedia(results));
    }
  }, [mgDetails?.id]); // eslint-disable-line

  const activeIndex = useMemo(() => {
    return assetsList && mgDetails ? assetsList.findIndex((item) => item.id === mgDetails.id) : 0;
  }, [mgDetails, assetsList]);

  const watermarkItems = useMemo(() => {
    if (savedWatermarkImage?.length > 0) {
      return savedWatermarkImage.map((it: any, index: number) => ({
        identifier: `saved_sticker ${index + 1}`,
        name: `Saved Watermark ${index + 1}`,
        thumbnailURI: it.photo_url,
        stickerURI: it.photo_url,
        tintMode: 'none',
        resizeMode: 'keepAspect'
      }));
    }
    return [];
  }, [savedWatermarkImage]);

  const routePush = useCallback(
    (path: string, searchParams: { [key: string]: string } = {}) => {
      navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
    },
    [navigate]
  );

  const renderCloneAsset = useCallback(() => {
    return (
      <>
        {userOwnership === USER_OWNERSHIP.FRANCHISOR && mgDetails?.account_id && (
          <ToggleButton
            id={`ToggleButton3`}
            value={6}
            className={clonedFranchisorIds.includes(id) ? 'events-none pointer-events-none disabled' : ''}
            onClick={() => {
              routePush(`/${userOwnership}/${id}/${currentRoute}/${mediaType}/${mgDetails?.id}/${COMMON_PAGE_TYPE.CLONE}`, queryParams);
            }}
          >
            <div className="fi-item fi-clone g-20">
              <div className="fi-icons">
                <ImageValidation isImgValid defaultImg={'clone'} customName="Clone" />
              </div>
              <span className="tgb-txt">Clone</span>
            </div>
          </ToggleButton>
        )}
      </>
    );
  }, [mgDetails?.account_id, mgDetails?.id, userOwnership, id, currentRoute, queryParams, clonedFranchisorIds, mediaType, routePush]);

  const renderImageToggleButtons = useCallback(() => {
    if (mgDetails && mgDetails?.media_type === MG_MEDIA_TYPE.PHOTO && !imageLy && mgValidateMedia?.isImageExists) {
      const disableImgEdit = Boolean(userOwnership === USER_OWNERSHIP.ACCOUNT && editedMgDetails?.locked);
      // const disableImgEdit = !mgDetails.account_id && mgDetails.franchisor_id !== mgDetails.original_franchisor_id
      // ? true
      // : userOwnership === USER_OWNERSHIP.FRANCHISOR && editedMgDetails?.created_by_user_id !== userId && editedMgDetails?.copy_to_descendants && editedMgDetails?.locked
      // ? true
      // : userOwnership === USER_OWNERSHIP.ACCOUNT && editedMgDetails?.copy_to_descendants && editedMgDetails?.locked
      // ? true
      // : false;
      return (
        <ToggleButtonGroup
          className={
            !mgDetails?.active && userOwnership === USER_OWNERSHIP.ACCOUNT && mgDetails?.franchisor_id
              ? 'btn-group-toggle g-20 features-btn-group events-none pointer-events-none disabled'
              : (editedMgDetails?.locked || !mgDetails?.active) && mgDetails?.original_franchisor_id !== mgDetails?.franchisor_id
              ? 'btn-group-toggle g-20 features-btn-group events-none pointer-events-none disabled'
              : 'btn-group-toggle g-20 features-btn-group'
          }
          type="radio"
          name="image-edit"
        >
          <ToggleButton id={`ToggleButton1`} value={4} className={disableImgEdit ? 'events-none pointer-events-none disabled' : ''}>
            <div
              className="fi-item fi-watermark g-20"
              onClick={() => {
                setActiveEditorTool(Tool.STICKER);
                setImageLy(true);
              }}
            >
              <div className="fi-icons">
                <ImageValidation isImgValid defaultImg="watermark" customName="Watermark" />
              </div>
              <span className="tgb-txt">Watermark</span>
            </div>
          </ToggleButton>
          <ToggleButton
            id={`ToggleButton2`}
            value={5}
            className={disableImgEdit ? 'events-none pointer-events-none disabled' : ''}
            onClick={() => {
              setActiveEditorTool(Tool.FILTER);
              setImageLy(true);
            }}
          >
            <div className="fi-item fi-edit g-20">
              <div className="fi-icons">
                <ImageValidation isImgValid defaultImg={'editGreyMedia'} customName="Edit" />
              </div>
              <span className="tgb-txt">Edit</span>
            </div>
          </ToggleButton>
          {renderCloneAsset()}
        </ToggleButtonGroup>
      );
    }
    return null;
  }, [imageLy, mgDetails, userOwnership, mgValidateMedia?.isImageExists, editedMgDetails?.locked, renderCloneAsset]);
  // }, [imageLy, mgDetails, userOwnership, mgValidateMedia?.isImageExists, editedMgDetails?.copy_to_descendants, editedMgDetails?.locked, editedMgDetails?.created_by_user_id, userId, renderCloneAsset]);

  const renderVideoToggleButton = useCallback(() => {
    return (
      <ToggleButtonGroup className="btn-group-toggle" type="radio" name="image-edit">
        {renderCloneAsset()}
      </ToggleButtonGroup>
    );
  }, [renderCloneAsset]);

  const ImageViewMainContent = useCallback(() => {
    if ([MG_MEDIA_TYPE.PHOTO, MG_MEDIA_TYPE.RAW, MG_CLOUD_MEDIA_TYPE.IMAGE].includes(mgDetails?.media_type || '')) {
      if (!imageLy && mgValidateMedia?.isImageExists) {
        return (
          <>
            <ImageValidation
              imgUrl={editedImage || cropperProps.image}
              customName={'Big Asset'}
              customClassname="ba-item"
              onLoad={() => {
                setImageLoad(false);
              }}
            />
          </>
        );
      } else if ([MG_MEDIA_TYPE.RAW, MG_CLOUD_MEDIA_TYPE.IMAGE].includes(mgDetails?.media_type || '')) {
        return (
          <>
            <ImageValidation
              defaultImg={'full_list_document_icon'}
              customName={'Big Asset'}
              customClassname="ba-item"
              onLoad={() => {
                setImageLoad(false);
              }}
            />
          </>
        );
      } else {
        return null;
      }
    } else if (mgDetails?.media_type === MG_MEDIA_TYPE.VIDEO) {
      return <ReactVideoPlayer videoUrl={mgDetails?.medium_size_url || ''} light={mgDetails?.thumbnail_url} customClassname="gma-assets gma-video videoThumbImg" />;
    } else {
      return null;
    }
  }, [mgDetails, cropperProps, imageLy, editedImage, mgValidateMedia?.isImageExists]);

  const handleNextOrPreviousAction = (actionType: string) => {
    setEditedImage(null);
    if (actionType === NAV_ACTIONS.NEXT) {
      routePush(`/${userOwnership}/${id}/${currentRoute}/${mediaType}/${assetsList[activeIndex + 1]?.id}`, queryParams);
    }
    if (actionType === NAV_ACTIONS.PREVIOUS) {
      routePush(`/${userOwnership}/${id}/${currentRoute}/${mediaType}/${assetsList[activeIndex - 1]?.id}`, queryParams);
    }
  };

  const handleSaveEditedMedia = (imageSrc: string) => {
    setEditedImage(imageSrc);
    setActiveEditorTool(Tool.FILTER);
    const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
    if (currentUnixTimestamp) {
      dispatch(
        cloudSignRequest({
          unixTimestamp: +currentUnixTimestamp,
          onSignatureCallback: (cloudSign) => {
            if (cloudSign.timestamp && cloudSign.apikey && cloudSign.signature && cloudSign.eager.length) {
              dispatch(
                mgUploadCloudMediaRequest({
                  timestamp: cloudSign.timestamp,
                  apiKey: cloudSign.apikey,
                  signature: cloudSign.signature,
                  eager: cloudSign.eager.join(),
                  file: imageSrc,
                  fileName: '',
                  successCallback: (response) => {
                    const isEditImage: boolean = userOwnership === USER_OWNERSHIP.FRANCHISOR ? mgDetails?.franchisor_id === id : mgDetails?.account_id === id;
                    if (id && userOwnership && response && editedMgDetails) {
                      if (isEditImage) {
                        const newNameEnd = response.secure_url?.substring(response.secure_url?.lastIndexOf('/') + 1, response.secure_url?.length);
                        const newURL = editedMgDetails.url.replace(editedMgDetails.url.substring(editedMgDetails.url.lastIndexOf('/') + 1, editedMgDetails.url?.length), newNameEnd || '');
                        editedMgDetails.cloudinary_id = response.public_id;
                        editedMgDetails.url = newURL;
                        dispatch(
                          mgUpdateDetailDataRequest({
                            mediaType,
                            editedContent: editedMgDetails,
                            successCallback: () => {
                              setEditedImage(null);
                            }
                          })
                        );
                      } else if (mgDetails) {
                        const mediaType = mgDetails.media_type;
                        const reqParams = addAssetReqParams(
                          {
                            cloudinaryId: response.public_id,
                            fileName: mgDetails.name,
                            tags: response.tags.join(',') || '',
                            eTag: response.etag || null,
                            secureUrl: response.secure_url,
                            mediaType,
                            qualityAnalysis: {
                              focus: response.quality_score
                            }
                          },
                          activeUserId,
                          activeUserName,
                          id,
                          userOwnership,
                          mgDetails.copy_to_descendants ? CreatorMediaTypeForUser.EVERYONE : CreatorMediaTypeForUser.PERSONAL,
                          mgDetails.locked || false,
                          currentName,
                          null,
                          null,
                          response,
                          { ancestor_photo_id: mgDetails.id }
                        );
                        dispatch(
                          mgSaveAssetRequest({
                            mediaType,
                            dataParams: reqParams,
                            isMediaClone: true,
                            onNavigate: () => routePush(`/${userOwnership}/${id.toString()}/${currentRoute}/${mediaType}`, queryParams)
                          })
                        );
                      }
                    }
                  }
                })
              );
            }
          }
        })
      );
    }
  };

  return (
    // <div className="white-box post-img imglyMain">
    <div className="post__white--box post-img imglyMain">
      <div className="media-features">
        <div className="scl-controls slide-controls r-flx r-flx-ac">
          <Button
            className={`primeblueaction cmn-blue-btn sc-prev${!assetsList.findIndex((datum) => datum.id === mgDetails?.id) ? ` pointer-events-none` : ``}`}
            variant="primary"
            disabled={imageLy || imageLoad || isCloudinaryUpload || isWatermarkUpload}
            onClick={() => handleNextOrPreviousAction(NAV_ACTIONS.PREVIOUS)}
          >
            <span className="scp-txt">Previous</span>
          </Button>
          <Button
            className={`primeblueaction cmn-blue-btn sc-next${assetsList.findIndex((datum) => datum.id === mgDetails?.id) + 1 === assetsList.length ? ` pointer-events-none` : ``}`}
            variant="primary"
            disabled={imageLy || imageLoad || isCloudinaryUpload || isWatermarkUpload}
            onClick={() => handleNextOrPreviousAction(NAV_ACTIONS.NEXT)}
          >
            <span className="scp-txt">Next</span>
          </Button>
        </div>
        {mgDetails?.media_type === MG_MEDIA_TYPE.PHOTO ? renderImageToggleButtons() : mediaType !== MgMediaType.DOCUMENTS ? renderVideoToggleButton() : null}
      </div>
      {/* (mgDetails?.media_type === MG_MEDIA_TYPE.VIDEO) */}
      <div className={`big-asset${mgDetails?.media_type === MG_MEDIA_TYPE.VIDEO ? ` vti-wrap-on` : ` vti-wrap-off`} ${!mgDetails?.active ? 'mediaInactive' : ''}`} id="watermark_img">
        {!mgDetails?.active ? (
          <div className="campaign-tag media-tag">
            <span>Expired</span>
          </div>
        ) : null}
        <ImageViewMainContent />
      </div>

      {imageLoad || isCloudinaryUpload || isWatermarkUpload ? <Loading /> : null}
      {imageLy ? (
        <PhotoEditorSDK
          imgUrl={mgDetails?.url || ''}
          setImageLy={() => setImageLy(false)}
          exportCallback={(imageSrc: string) => handleSaveEditedMedia(imageSrc)}
          defaultTool={activeEditorTool}
          watermarkItems={watermarkItems}
          uploadCallback={(uploadedStickers) => {
            const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
            if (currentUnixTimestamp) {
              dispatch(
                cloudSignRequest({
                  unixTimestamp: +currentUnixTimestamp,
                  onSignatureCallback: (cloudSign) => {
                    if (cloudSign.timestamp && cloudSign.apikey && cloudSign.signature && cloudSign.eager.length && uploadedStickers.length > 0 && userId && topLevelFranchisorId) {
                      dispatch(
                        uploadWatermarkImageToCloudRequest({
                          timestamp: cloudSign.timestamp,
                          apiKey: cloudSign.apikey,
                          signature: cloudSign.signature,
                          eager: cloudSign.eager.join(),
                          imageList: uploadedStickers.map((it: UploadedSticker) => ({ file: it.stickerURI })),
                          franchisorId: topLevelFranchisorId,
                          userId
                        })
                      );
                    }
                  }
                })
              );
            }
          }}
          closeCallback={() => setActiveEditorTool(Tool.FILTER)}
          onToolChange={(activeTool) => setActiveEditorTool(activeTool)}
        />
      ) : null}
      <div />
    </div>
  );
};
