import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment-timezone';
import { Spinner } from 'react-bootstrap';

import {
  ALERT_BOX_TYPES,
  BUTTON_PROPS,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  EXPORT_DOWNLOAD_STARTED_MSG,
  USER_OWNERSHIP,
  FEED_TYPE,
  // FEED_FILTER_TYPE,
  RIPPLE_COLOR,
  CONTENT_FEED_INIT_FILTER_DATA,
  CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES
} from 'utils/constants';
import { useParamsValidator, useParamsDeconstructor, useAccountSwitcherData } from 'utils/hooks';

import { utcToLocalHandler } from 'utils/helpers';
import { IStore } from 'types';
import { alertBoxCall, DeleteAssetModalPopup, CommonFilter } from 'components';
import { deleteLocationListRequest, operationListsResetModifiedObj, saveLocationGroupRequest } from 'actions';
import { IFiterHandleChangeData } from 'types/common/filter';
import { handleFilterChange } from 'analytics/utils';

const TEAM_MANAGEMENT_LIST_BUTTON = {
  CREATE_NEW: {
    ...BUTTON_PROPS.PRIMARY_EXPORT_CSV,
    value: 'newList',
    label: 'Create A New List',
    customImage: '',
    isBorder: false
  },
  VIEW_CONTENT_SUPPLIER: {
    ...BUTTON_PROPS.PRIMARY_EXPORT_CSV,
    value: 'view_content_supplier',
    label: 'View Content Supplier',
    customImage: '',
    parentVariant: 'filter-item pb-20 b-none lite-blue',
    buttonVariant: 'ac-btn ac-secondary ac-block h-default r-flx r-flx-jc r-flx-ac',
    isBorder: false
  },
  SAVE: {
    ...BUTTON_PROPS.PRIMARY_EXPORT_CSV,
    value: 'save',
    label: 'Save',
    customImage: '',
    parentVariant: 'filter-item pb-20 b-none',
    buttonVariant: 'ac-btn ac-primary ac-block h-default r-flx r-flx-jc r-flx-ac',
    isBorder: false
  },
  DELETE: {
    rippleColor: RIPPLE_COLOR.danger,
    customImage: '',
    buttonVariant: `ac-btn ac-block ac-danger h-default r-flx r-flx-jc r-flx-ac`,
    isDisable: false,
    rippleVariant: 'ac-danger-box r-mt2',
    parentVariant: '',
    isBorder: false,
    value: 'delete',
    label: 'Delete this list'
  }
};

export const TeamManagementListsFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: accountId, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { queryParams, filter } = useParamsDeconstructor(CONTENT_FEED_INIT_FILTER_DATA);

  const locationDetail = useSelector((state: IStore) => state.operationsList.modifiedObj);
  const isSaveRequest = useSelector((state: IStore) => state.operationsList.isSaveRequest);
  const isDeleteRequest = useSelector((state: IStore) => state.operationsList.isDeleteRequest);
  const locationGroupDetail = useSelector((state: IStore) => state.operationsList.locationGroupDetail);
  const list = useSelector((state: IStore) => state.operationsList.locationsGroupList);
  // const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);

  const [showModal, setShowModal] = useState(false);

  const contentFeedFilter = {
    ...filter,
    feed_type: queryParams.feed_type || 'all'
  };

  // const showFeedTypeFilter = isContentSupplierFranchisor && !optionalParams[0];

  useParamsValidator(CommonValidQueryParams, { ...COMMON_VALID_QUERY_PARAMS_OBJ, feed_type: ['1'] });

  const formAndDownloadCSV = () => {
    alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
    const listObj = list.find((it) => it.id === locationGroupDetail?.id);
    const rows = [
      ['Name', locationGroupDetail?.name],
      ['Edited by', listObj?.created_user.name],
      ['Edited on', moment(utcToLocalHandler(listObj?.updated_at || '')).format('MM/DD/YYYY @ hh:mm A')],
      ['Locations', '']
    ];
    locationGroupDetail?.included_accounts.forEach((it) => {
      rows.push(['', it.name]);
    });
    const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const anchor = document.createElement('a');
    anchor.href = encodedUri;
    anchor.style.display = 'none';
    anchor.download = `${listObj?.name}.csv`;
    document.body.append(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleChange = (data: IFiterHandleChangeData) => {
    const queryParamsObj = handleFilterChange(data, false, null, queryParams);
    if (data?.newList) {
      if (accountId) {
        // handleLocationHubDetailRequest();
        navigate({ pathname: `/${userOwnership}/${accountId}/${currentRoute}/new/step-1` });
      }
    }
    if (data.exportCsv) {
      formAndDownloadCSV();
    }
    if (data.save) {
      if (!isSaveRequest && locationDetail?.name) {
        dispatch(
          saveLocationGroupRequest({
            id: locationDetail.id,
            reqPayload: {
              missing_accounts: locationDetail?.missing_accounts,
              included_accounts: locationDetail?.included_accounts,
              franchisor_id: locationDetail?.franchisor_id,
              name: locationDetail?.name,
              feed_type: locationDetail?.feed_type,
              content_supplier_franchisor_ids: locationDetail?.content_suppliers || [],
              content_type: locationDetail?.content_type,
              ...(locationDetail?.tags_list && locationDetail?.tags_list?.length
                ? { tags_list: Boolean(Array.isArray(locationDetail?.tags_list)) ? locationDetail?.tags_list.join(',') : locationDetail?.tags_list }
                : { tags_list: '' }),
              ...(locationDetail?.feed_type === FEED_TYPE.CONTENT_SUPPLIER && { content_source: locationDetail?.content_source })
            }
          })
        );
        navigate({ pathname: `/${userOwnership}/${accountId}/${currentRoute}` });
        dispatch(operationListsResetModifiedObj());
      }
    }
    if (data.delete) {
      setShowModal(true);
      // navigate({ pathname: `/${userOwnership}/${accountId}/${currentRoute}` });
    }
    if (data.feed_type) {
      if (data.feed_type === 'all') {
        delete queryParamsObj.feed_type;
      } else {
        queryParamsObj.feed_type = data.feed_type;
      }
      navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
    }
  };

  const primaryButtons = !optionalParams[0]
    ? userOwnership === USER_OWNERSHIP.FRANCHISOR
      ? [TEAM_MANAGEMENT_LIST_BUTTON.CREATE_NEW]
      : []
    : optionalParams[0] === 'new'
    ? [
        {
          ...TEAM_MANAGEMENT_LIST_BUTTON.SAVE,
          isDisable:
            !locationDetail?.name ||
            Boolean(
              locationDetail?.feed_type === FEED_TYPE.CONTENT_SUPPLIER &&
                locationDetail?.content_source === CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.CONTENT_SUPPLIER &&
                !locationDetail?.content_suppliers?.length
            )
          // isDisable: showCSHubFilter ? (locationDetail?.content_suppliers?.length ? false : true) : false
        }
      ]
    : [
        BUTTON_PROPS.PRIMARY_EXPORT_CSV,
        {
          ...TEAM_MANAGEMENT_LIST_BUTTON.SAVE,
          isDisable:
            !locationDetail?.name ||
            Boolean(
              locationDetail?.feed_type === FEED_TYPE.CONTENT_SUPPLIER &&
                locationDetail?.content_source === CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.CONTENT_SUPPLIER &&
                !locationDetail?.content_suppliers?.length
            ),
          label: isSaveRequest ? <Spinner animation="border" variant="light" /> : 'Save'
        },
        {
          ...TEAM_MANAGEMENT_LIST_BUTTON.DELETE,
          label: isDeleteRequest ? <Spinner animation="border" variant="light" /> : 'Delete this list'
          // isDisable:locationDetail?.included_accounts?.length
        }
      ];

  return (
    <>
      <CommonFilter
        filter={contentFeedFilter}
        handleChange={handleChange}
        isDateRangeFilterEnable={false}
        primaryButtons={primaryButtons}
        /* checkBoxFilter={
          showFeedTypeFilter
            ? [
                {
                  title: 'List Type',
                  filter: FEED_FILTER_TYPE,
                  value: 'feed_type'
                }
              ]
            : []
        } */
        isSecondaryDateRangePlaceholder
        sectionVariant="tmListFilterMain"
      />
      <DeleteAssetModalPopup
        showModal={showModal}
        handleModalClose={() => {
          setShowModal(false);
        }}
        handleDelete={() => {
          if (locationDetail.id) {
            dispatch(deleteLocationListRequest({ id: locationDetail.id }));
            setShowModal(false);
            navigate({ pathname: `/${userOwnership}/${accountId}/${currentRoute}` });
          }
        }}
      />
    </>
  );
};
