import { adminAreaContainerTypes } from 'types';
import { MAIN_NAV_BAR_NAME, ROUTE_TABS } from '../app-routes';

export const ACCOUNTS_TABLE_HEADER = [
  { title: 'Name', value: 'name' },
  { title: 'Created', value: 'created' },
  { title: 'Dashboard', value: 'dashboard' },
  { title: 'Setup', value: 'setup' }
];

export const FRANCHISORS_TABLE_HEADER = [
  { title: 'Name', value: 'name' },
  { title: 'Created', value: 'created' },
  { title: 'Stats', value: 'stats' },
  { title: 'Report', value: 'report' },
  { title: 'Dash', value: 'dash' },
  { title: 'Franchisor Content', value: 'franchisorContent' },
  { title: 'New Location', value: 'newLocation' }
];

export const USERS_TABLE_HEADER = [
  { title: 'Name', value: 'name' },
  { title: 'User', value: 'user' },
  { title: 'Company', value: 'company' },
  { title: 'Admin?', value: 'admin' },
  { title: 'Become', value: 'become' }
];

export const FRAN_STATS_TABLE_HEADER = [
  { title: 'Franchisee Name', value: 'franchiseeName' },
  { title: 'Next unapproved post', value: 'noPost' },
  { title: '# Posts', value: 'posts' },
  { title: '# Approved Posts', value: 'approvedPosts' }
];

export const USER_DETAILS_FILEDS: adminAreaContainerTypes.IUserDetailsField = {
  NAME: 'name',
  EMAIL: 'email'
};

export const ADMIN_AREA_ROUTES = ['/admin_area/accounts', '/admin_area/franchisors', '/admin_area/users'];

export const COMMON_ROUTES = [
  { menu: ROUTE_TABS.VIDEO_TUTORIALS, link: MAIN_NAV_BAR_NAME.VIDEO_TUTORIALS },
  { menu: ROUTE_TABS.AI_ASSISTANT, link: MAIN_NAV_BAR_NAME.AI_ASSISTANT }
];

export enum AdminAreaAccountsValidQueryParams {
  SEARCH = 'text',
  INCLUDE_HIDDEN_ACCOUNTS = 'include_hidden_accounts'
}

export enum AdminAreaValidQueryParams {
  SEARCH = 'text',
  EXCLUDE_GRAVEYARD = 'not_in_graveyard',
  BRANDS_ONLY = 'only_top_level_and_brands',
  LARGEST_ON_TOP = 'order'
}

export const ADMIN_AREA_FILTER_OPTION_LABEL = {
  EXCLUDE_GRAVEYARD: 'Exclude Graveyard',
  BRANDS_ONLY: 'Brands Only',
  LARGEST_ON_TOP: 'Largest on top'
};

export const ADMIN_AREA_FILTER_OPTION_VALUES = {
  EXCLUDE_GRAVEYARD: 'not_in_graveyard',
  BRANDS_ONLY: 'only_top_level_and_brands',
  LARGEST_ON_TOP: 'order'
};

export const ADMIN_AREA_FILTER_OPTION_FILTER_VALUES = {
  EXCLUDE_GRAVEYARD: '1',
  BRANDS_ONLY: '1',
  LARGEST_ON_TOP: 'descendant_accounts_count'
};

export const FRANCHISOR_FILTER_OPTIONS = [
  { id: 1, label: ADMIN_AREA_FILTER_OPTION_LABEL.EXCLUDE_GRAVEYARD, value: ADMIN_AREA_FILTER_OPTION_VALUES.EXCLUDE_GRAVEYARD, filterValue: ADMIN_AREA_FILTER_OPTION_FILTER_VALUES.EXCLUDE_GRAVEYARD },
  { id: 1, label: ADMIN_AREA_FILTER_OPTION_LABEL.BRANDS_ONLY, value: ADMIN_AREA_FILTER_OPTION_VALUES.BRANDS_ONLY, filterValue: ADMIN_AREA_FILTER_OPTION_FILTER_VALUES.BRANDS_ONLY },
  { id: 1, label: ADMIN_AREA_FILTER_OPTION_LABEL.LARGEST_ON_TOP, value: ADMIN_AREA_FILTER_OPTION_VALUES.LARGEST_ON_TOP, filterValue: ADMIN_AREA_FILTER_OPTION_FILTER_VALUES.LARGEST_ON_TOP }
];
