import { useFormik } from 'formik';
import { Button, Card, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { IStore, commonModalPopup, communicationContainerTypes } from 'types';
import { RIPPLE_COLOR } from 'utils/constants';
import { COMMUNICATION_TAB_FIELDS } from 'utils/constants/communications';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { TextArea } from 'widgets/Text';
import { communicationCreateUpdateRequest } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';
import { createCommunicationValidation, getIncludedAccounts } from 'utils/helpers';
import { ScheduleLocationSection } from 'components';

export const CreateCommunications = ({ isShowModal, onModalClose, communicationData }: commonModalPopup.ICreateCommunicationProps) => {
  const dispatch = useDispatch();

  const { id } = useAccountSwitcherData();

  const isFetching = useSelector((state: IStore) => state.communications.isFetching);
  const schLocationTree = useSelector((state: IStore) => state.franchisors.franScheduleTree.schLocationTree);

  const handleModalClose = () => {
    onModalClose();
    resetForm();
  };

  const getInitialValues = () => {
    let initialValues = {
      // subject: '',
      body: '',
      send_to_all_accounts: true,
      send_email_notification: false,
      account_ids: []
    };
    if (communicationData?.id) {
      initialValues = {
        // subject: communicationData?.subject,
        body: communicationData?.body,
        send_to_all_accounts: true,
        send_email_notification: false,
        account_ids: []
      };
    }
    return initialValues;
  };

  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: createCommunicationValidation,
    onSubmit: (values: communicationContainerTypes.ICreateCommunicationInitialValues) => {
      const includedAccounts = getIncludedAccounts(schLocationTree);
      values.account_ids = includedAccounts.map((it) => it.id);
      dispatch(communicationCreateUpdateRequest(communicationData?.id ? { id: communicationData.id, ...values } : { franchisor_id: id, ...values }));
      handleModalClose();
    }
  });

  const getInputBoxClassName = (valueItem: keyof communicationContainerTypes.ICreateCommunicationInitialValues) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'erroritem' : ''}`;
    return className;
  };

  const selectedLocationsCount = () => {
    return [
      ...new Set(
        schLocationTree?.reduce((acc: number[], curr) => {
          if (curr.type) acc = [...acc, ...(curr.locations || []).filter((it) => it.active).map((it) => it.id)];
          else acc = [...acc, curr.id];
          return acc;
        }, [])
      )
    ].length;
  };

  // const getClassNames = (valueItem: keyof communicationContainerTypes.ICreateCommunicationInitialValues) => {
  //   let className = 'form-control';
  //   className = `${className} ${values[valueItem] && values[valueItem] !== '' && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'err-disp' : ''}`;
  //   return className;
  // };

  return (
    <>
      <Modal
        className={`glbl__modal--wrp cr-sm-mdl schCorpPost nmcWrap`}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="esDate-bckdrp"
        show={isShowModal}
        animation={false}
        onHide={handleModalClose}
      >
        <div className="mod__close--icon" onClick={handleModalClose}>
          <ImageValidation isImgValid defaultImg={'close-creator'} customName={'close'} customClassname="mod__close--img" />
        </div>
        <Modal.Body>
          <h3 className="glbl__title--txt">{communicationData?.id ? 'Edit' : 'New'} Communication</h3>
          <Card className="glbl__modal--card">
            <Card.Body className="nc-fields">
              <Card.Header>Details</Card.Header>
              <Card className="glbl__modal--card">
                <Card.Header>Location Selector</Card.Header>
                <Card.Body>
                  <ScheduleLocationSection selectedLocationsCount={selectedLocationsCount} />
                </Card.Body>
              </Card>
              <>
                <label className="checkbox-item">
                  <span className="checkbox-hover">
                    <input
                      type="checkbox"
                      name="send_email_notification"
                      className="option-input click-wave"
                      checked={values.send_email_notification}
                      onChange={(e) => {
                        setFieldValue(COMMUNICATION_TAB_FIELDS.SEND_EMAIL_NOTIFICATION, e.target.checked);
                      }}
                    />
                  </span>
                  <div className="label-txt">Send email notification</div>
                  <span className="checkmark" />
                </label>
              </>
              <div className={`form-group form-field-item ncTa ${getInputBoxClassName(COMMUNICATION_TAB_FIELDS.BODY)}`}>
                <TextArea
                  name={COMMUNICATION_TAB_FIELDS.BODY}
                  placeholder="Type here..."
                  id="exampleForm.ControlTextarea1"
                  className="form-control"
                  autoComplete="off"
                  onChange={(e) => setFieldValue(COMMUNICATION_TAB_FIELDS.BODY, e.target.value)}
                  onBlur={handleBlur}
                  value={values.body}
                  maxRows={2}
                />
                <span className="fltlabels">Description</span>
              </div>
            </Card.Body>
          </Card>
          {!communicationData || communicationData?.can_update ? (
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <Button className={`ac-btn ac-primary ${isFetching ? 'pointer-events-none' : ''}`} onClick={() => handleSubmit()}>
                <span>Save and Send Now</span>
              </Button>
            </CustomRippleButton>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};
