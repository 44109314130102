import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';

import {
  NoDataFoundProps,
  MEDIA_PAGINATION_PER_PAGE,
  SCROLL_THRESHOLD,
  MG_FILTER_SORT_BY,
  MG_MEDIA_TYPE,
  MgMediaType,
  USER_OWNERSHIP,
  MG_FILTER_INITIAL_VALUE,
  MultiSelectMGSourceOptions
} from 'utils/constants';
import { Loading, NoDataFound } from 'components';
import { MediaGalleryList } from './MediaGalleryList';
import { IStore, mgContainerTypes } from 'types';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { getMultiSelectMGListRequest } from 'actions';
import { MultiSelectPostOrMediaSearch } from 'containers/Content/Posts/SubPages';

export const MultiSelectMGInfinityScrollList = ({ height }: mgContainerTypes.IMultiSelectMGInfinityScrollProps) => {
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor({ ...MG_FILTER_INITIAL_VALUE });

  const currentPageCount = useSelector((state: IStore) => state.mediaGallery.multiSelectCurrentPageCount);
  const allMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.allMediaCount);
  const isListFetching = useSelector((state: IStore) => state.mediaGallery.isMultiSelectMGListFetching);
  const assetsList = useSelector((state: IStore) => state.mediaGallery.multiSelectMediaList);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier || false);

  const [searchText, setSearchText] = useState('');

  const totalPageCount = Math.ceil(allMediaCount / MEDIA_PAGINATION_PER_PAGE);
  const mediaType = optionalParams[0];

  const handleMediaGalleryDataRequest = useCallback(
    (page: number, search?: string) => {
      if (id && userOwnership && isValidId) {
        const reqPayload = {
          page,
          per: MEDIA_PAGINATION_PER_PAGE,
          media_type: mediaType === MgMediaType.DOCUMENTS ? MG_MEDIA_TYPE.RAW : mediaType,
          source: isContentSupplier ? MultiSelectMGSourceOptions.ALL : MultiSelectMGSourceOptions.AUTHORIZED,
          order: MG_FILTER_SORT_BY[0]?.paramVal,
          isMultiSelect: true,
          active: 'true',
          ...(search && { search }),
          ...(userOwnership === USER_OWNERSHIP.FRANCHISOR && { franchisor_id: id }),
          ...(userOwnership === USER_OWNERSHIP.ACCOUNT && { account_id: id }),
          ...(queryParams?.multi_syndicated_to_any_franchisor && { syndicated_to_any_franchisor: queryParams?.multi_syndicated_to_any_franchisor })
        };
        dispatch(getMultiSelectMGListRequest(reqPayload));
      }
    },
    [userOwnership, id, isValidId, mediaType, queryParams?.multi_syndicated_to_any_franchisor, isContentSupplier, dispatch]
  );

  const handleLoadMoreItems = () => {
    handleMediaGalleryDataRequest(currentPageCount + 1, searchText);
  };

  useEffect(() => {
    handleMediaGalleryDataRequest(1);
  }, [handleMediaGalleryDataRequest]);

  return (
    <div className="mainContent animate__animated animate__fadeIn mediaGallery mg__main mbl-head--margin">
      <MultiSelectPostOrMediaSearch searchText={searchText} setSearchText={setSearchText} handlePostListBasedOnFilters={(_, search) => handleMediaGalleryDataRequest(1, search)} />
      {isListFetching && currentPageCount === 1 ? (
        <Loading />
      ) : assetsList.length ? (
        <InfiniteScroll
          scrollThreshold={SCROLL_THRESHOLD}
          next={handleLoadMoreItems}
          hasMore={totalPageCount >= currentPageCount}
          dataLength={assetsList.length}
          loader={isListFetching ? <Loading /> : null}
          className="local-ini"
          height={height}
        >
          <MediaGalleryList isMultiSelect />
        </InfiniteScroll>
      ) : !isListFetching && !assetsList.length ? (
        <NoDataFound size={NoDataFoundProps.FULL} />
      ) : (
        <Loading />
      )}
    </div>
  );
};
