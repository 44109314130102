import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { ReactVideoPlayer, CarousalImages, ImageValidation } from 'widgets/Media';
import { commonModalPopup, IStore } from 'types';
import {
  COMMON_SOCIAL_PROVIDER_TYPE,
  LOCATION_TIME_ZONE_DATE_TIME_FORMAT,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  YEAR_MONTH_DAY_WITH_TIME_FORMAT,
  USER_OWNERSHIP,
  CREATOR_FB_PREVIEW_FOOTER_ICONS,
  CREATOR_TWITTER_PREVIEW_FOOTER_ICONS,
  CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS,
  CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS,
  CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS
} from 'utils/constants';
import {
  getPermittedImgsBasedOnPlatform,
  getTimezoneAbbreviation,
  convertDateBasedOnTimeZone,
  getParsedDate,
  calendarUTCtoLocalHandler,
  getFormatBasedOnExactDate,
  convertURLAsLink,
  getBrowserLocalDateTime
} from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform } from 'utils/hooks';
import { Paragraph } from 'widgets/Text';
import { CalendarIconHandler } from 'containers/Content/Calendar/SubPages/CalendarIconHandler';
import { PLATFORM_FILTER } from 'analytics/utils/constants';
import { SocialMediaPreviewFooterIcons } from 'containers/Content/Creator/CommonModal/SocialMediaPreviewFooterIcons';
import { config } from 'config';
import { FBCallToActionPreview } from 'components';

export const ViewCalendarSelectedEventModal = (props: commonModalPopup.IAddPostSelectedCalendarProps) => {
  const { isModalShow, selectedEvent, setSelectedEvent, handleCloseModal } = props;
  const { userOwnership } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const brandImageUrl = useSelector((state: IStore) => state.accountSwitcher.brandImageUrl);
  const timeZone = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.tzinfo_name || '');

  const availablePlatform = PLATFORM_FILTER.filter((platform) => activePlatforms.includes(platform.value) && selectedEvent?.[`use_${platform.value}`]);

  const [activeSocialMedia, setActiveSocialMedia] = useState<string>(availablePlatform[0]?.value || '');

  const photoUrls = !activeSocialMedia ? selectedEvent?.photo_urls : getPermittedImgsBasedOnPlatform(activeSocialMedia, selectedEvent?.photo_urls || []);

  const parsedDate =
    userOwnership === USER_OWNERSHIP.ACCOUNT && timeZone
      ? convertDateBasedOnTimeZone(timeZone, selectedEvent?.scheduled_for || selectedEvent?.start_datetime, YEAR_MONTH_DAY_WITH_TIME_FORMAT)
      : selectedEvent?.post_now
      ? getBrowserLocalDateTime(selectedEvent.scheduled_for)
      : getParsedDate(calendarUTCtoLocalHandler(selectedEvent?.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT));

  const handleCloseCalendarEventsModal = () => {
    setSelectedEvent(null);
    handleCloseModal();
  };

  return (
    <Modal
      className="creator-tag-modal-wrp creator-calendar-monty-modal-popup view-calendar-event__modal"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      animation={false}
      onHide={handleCloseCalendarEventsModal}
    >
      <div className="main-cps">
        <div className="mod__close--icon" onClick={handleCloseCalendarEventsModal}>
          <ImageValidation isImgValid defaultImg="close-creator" customClassname="mod__close--img" />
        </div>
        <Modal.Body>
          <div className="header-section">
            {activeSocialMedia && (
              <div className="left-itm">
                <div className="gmhl-img">
                  <img
                    src={`${config.cdnImgUrl}${
                      activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                        ? 'fb-t'
                        : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                        ? `twitter-lv`
                        : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                        ? `insta-t`
                        : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                        ? `in-t`
                        : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
                        ? `tiktok-lv`
                        : `google-t`
                    }.svg`}
                    alt={'Social media'}
                  />
                </div>
                <span>
                  {activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                    ? `Facebook Page`
                    : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                    ? `Instagram Business`
                    : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                    ? `X (Formerly Twitter)`
                    : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                    ? `LinkedIn`
                    : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
                    ? `TikTok`
                    : `Google My Business`}
                </span>
              </div>
            )}
            <div className="rght-itm">
              <div className="fltr-imc selectsocial">
                {availablePlatform?.length
                  ? availablePlatform.map((mediaDatum, index) => {
                      return (
                        mediaDatum.value && (
                          <button
                            type="button"
                            className={`roundedbtn btn btn-link img-siz${activeSocialMedia === mediaDatum.value ? ` active` : ``}`}
                            key={`creator-vc-sm-${index}`}
                            onClick={() => setActiveSocialMedia(mediaDatum.value)}
                          >
                            <ImageValidation defaultImg={mediaDatum.platformImg} customClassname="gmhl-img" customName={mediaDatum.label} />
                          </button>
                        )
                      );
                    })
                  : null}
              </div>
            </div>
          </div>

          <div className="cal-social-preview-sec">
            <div className="card-bg">
              <div className="p-header">
                <div className="p-header-title">
                  {brandImageUrl ? (
                    <div className="p-avatar">
                      <ImageValidation imgUrl={brandImageUrl} customName={'View calendar avatar'} />
                    </div>
                  ) : null}
                  <div className="p-title">
                    <h5>{currentName}</h5>
                    <Paragraph customText={`@ ${getFormatBasedOnExactDate(parsedDate, LOCATION_TIME_ZONE_DATE_TIME_FORMAT)} ${timeZone ? getTimezoneAbbreviation(timeZone) : ''}`} />
                  </div>
                  <CalendarIconHandler isBoosted={selectedEvent?.has_facebook_boost_enduser_campaign} isAwaiting={selectedEvent?.draft} isCampaign={selectedEvent?.campaign} />
                </div>
              </div>

              <div className="p-body">
                {activeSocialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (
                  <Paragraph
                    customText={selectedEvent ? selectedEvent[`${activeSocialMedia}_text`] || '' : ''}
                    facebookTagList={activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? selectedEvent?.page_tag_details?.facebook || [] : []}
                    actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
                  />
                )}
                {!selectedEvent?.link_url && photoUrls?.length ? (
                  <div className="am-media-preview">
                    {photoUrls?.length === 1 ? (
                      <div>
                        <ImageValidation imgUrl={photoUrls[0]} customName="p-avatar" />
                      </div>
                    ) : (
                      <CarousalImages imagesList={photoUrls || []} showThumbs={false} showArrows />
                    )}
                  </div>
                ) : null}
                {selectedEvent?.video_url ? (
                  <div className="am-media-preview">
                    <ReactVideoPlayer videoUrl={selectedEvent?.video_url || ''} light={selectedEvent?.video_thumbnail_url} customClassname={'full-width'} />
                  </div>
                ) : null}
                {selectedEvent?.link_url && activeSocialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? (
                  ![COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN].includes(activeSocialMedia) ? (
                    <div className="coupon-link-preview-wrp">
                      <div className={selectedEvent?.photo_url ? 'grid-section' : ''}>
                        {selectedEvent?.photo_url && (
                          <div className="left-section">
                            <div className="img-thumb">
                              <ImageValidation imgUrl={selectedEvent?.photo_url} customName={'Link preview'} />
                            </div>
                          </div>
                        )}
                        <div className="right-section">
                          <div className="learn-more-section">
                            <div className="url-head">{selectedEvent?.link_url}</div>
                            <a href={selectedEvent?.link_url} target="_blank" rel="noopener noreferrer">
                              <div className="url-subhead-txt">{selectedEvent?.link_title}</div>
                            </a>
                            {selectedEvent?.link_description && (
                              <div className="url-head" title={selectedEvent?.link_description}>
                                {selectedEvent?.link_description}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FBCallToActionPreview
                      title={selectedEvent?.link_title || ''}
                      description={selectedEvent?.link_description || ''}
                      actionType={selectedEvent.facebook_call_to_action_type || ''}
                      linkUrl={selectedEvent?.link_url || ''}
                      imageUrl={selectedEvent?.photo_url || null}
                      socialMedia={activeSocialMedia}
                      isLinkCustomized={selectedEvent?.link_preview_customized || false}
                    />
                  )
                ) : null}
                {activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && selectedEvent?.google_call_to_action_url && selectedEvent?.google_call_to_action_type && (
                  <>
                    <div className="insta-social-sec-wrp">
                      <div className="lefticon">
                        {parse(
                          convertURLAsLink(
                            selectedEvent?.google_call_to_action_url,
                            GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === selectedEvent?.google_call_to_action_type)?.label || ''
                          )
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {activeSocialMedia && [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN].includes(activeSocialMedia) ? (
                <SocialMediaPreviewFooterIcons
                  socialMedia={activeSocialMedia}
                  footerIconsList={
                    activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                      ? CREATOR_FB_PREVIEW_FOOTER_ICONS.map((it) => ({ ...it, labelClassname: 'social-text' }))
                      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                      ? CREATOR_TWITTER_PREVIEW_FOOTER_ICONS
                      : CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS
                  }
                />
              ) : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? (
                <>
                  <div className="p-footer" />
                  <div className="insta-social-sec-wrp">
                    <div className="lefticon">
                      <SocialMediaPreviewFooterIcons socialMedia={`${activeSocialMedia}-left`} footerIconsList={CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS} />
                    </div>

                    <div className="righticon">
                      <SocialMediaPreviewFooterIcons socialMedia={`${activeSocialMedia}-right`} footerIconsList={CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS} />
                    </div>
                  </div>
                  {selectedEvent && <Paragraph customText={selectedEvent ? selectedEvent[`${activeSocialMedia}_text`] : ''} customClassname={'insta-text-main'} />}
                </>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
