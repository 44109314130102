import { Accordion, Button, Form } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import Ripples from 'react-ripples';
import { useEffect, useState } from 'react';

import { ModalPopup } from 'revv/components';
import { config } from 'config';
import { IHubLocationList } from 'revv/types';

interface ILocationFilterModal {
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: any;
  isModalOpen: boolean;
  filter: { [key: string]: any };
}

export const RevvLocationFilterModal = ({ setModalState, handleChange, isModalOpen, filter }: ILocationFilterModal) => {
  const [searchValue, setSearchValue] = useState('');
  const [clickedLocations, setClickedLocations] = useState<number[]>([]);

  useEffect(() => {
    setClickedLocations(filter?.selectedLocation);
  }, [filter?.selectedLocation]);

  const handleModalClose = () => {
    setModalState((prevState) => !prevState);
    setClickedLocations(filter?.selectedLocation);
  };

  return (
    <ModalPopup
      show={isModalOpen}
      centered
      className="location-filter-dialog"
      onHide={handleModalClose}
      renderHeader={() => (
        <div className="non-psp-loc-popup">
          <h3>Location Selector </h3>
          <div className="non-psp-loc-search">
            <Form.Control size="lg" type="text" placeholder="Search" className="non-psp-search-input w-100" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <img src={`${config.cdnRevvImgUrl}/filter/search-icon.svg`} alt="search" />
          </div>
        </div>
      )}
      renderBody={() => (
        <div>
          <div
            className={`${clickedLocations.length === 0 ? 'active' : ''} cur-pointer all-selection`}
            onClick={() => {
              setClickedLocations([]);
            }}
          >
            All Locations
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{`Locations  ${clickedLocations.length > 0 ? `(${clickedLocations.length} Location Selected)` : ''}`}</Accordion.Header>
              <Accordion.Body className="overflow-hidden">
                <Scrollbars autoHeight>
                  <ul>
                    {filter.locations && filter.locations?.length > 0 ? (
                      filter.locations
                        ?.filter((it: IHubLocationList) => it.name.toLowerCase().indexOf(searchValue.toLowerCase().trim()) > -1)
                        .map((it: IHubLocationList, index: number) => (
                          <li
                            key={`locationsOption${index}`}
                            className={`cur-pointer${clickedLocations.includes(it.id) ? ' active' : ''}`}
                            onClick={() => {
                              let changedLocations = [...clickedLocations];
                              if (changedLocations.includes(it.id)) {
                                changedLocations = changedLocations.filter((cl) => cl !== it.id);
                              } else {
                                changedLocations.push(it.id);
                              }
                              setClickedLocations(changedLocations);
                            }}
                          >
                            <img
                              src={clickedLocations.includes(it.id) ? `${config.cdnRevvImgUrl}/filter/location-blue-active.svg` : `${config.cdnRevvImgUrl}/filter/location-grey.svg`}
                              alt="location Icon"
                            />
                            <span className={`lbl ${clickedLocations.includes(it.id) ? 'active' : ''}`}>{it.name}</span>
                          </li>
                        ))
                    ) : (
                      <li>
                        <span>No Locations found.</span>
                      </li>
                    )}
                  </ul>
                </Scrollbars>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      renderFooter={() => (
        <div className="non-psp-foot-btn">
          <Ripples>
            <Button
              variant="outline-primary"
              size="lg"
              className="non-psp-ac-btn non-psp-ac-cancel"
              onClick={() => {
                setModalState((prevState) => !prevState);
                setClickedLocations(filter?.selectedLocation);
              }}
            >
              Cancel
            </Button>
          </Ripples>
          <Ripples>
            <Button size="lg" className="non-psp-ac-btn non-psp-ac-ok " onClick={handleModalClose}>
              Ok
            </Button>
          </Ripples>
        </div>
      )}
    />
  );
};
