export const AI_REVIEW_RESPONDER_TABS = {
  REVIEW_RESPONSE: 'review_response',
  REVIEW_AUTOMATION: 'review_automation'
};

export const AI_AUTO_RESPONDER_LOCATION_HEADER_VALUES = {
  STATUS: 'status',
  NAME: 'name',
  SERIAL_NO: 's.no'
};

export const AI_AUTO_RESPONDER_LOCATION_HEADER = [
  { label: 'S.No', value: AI_AUTO_RESPONDER_LOCATION_HEADER_VALUES.SERIAL_NO },
  { label: 'Name', value: AI_AUTO_RESPONDER_LOCATION_HEADER_VALUES.NAME },
  { label: 'Status', value: AI_AUTO_RESPONDER_LOCATION_HEADER_VALUES.STATUS }
];
