import { Point } from 'highcharts';

import { TotalPercentagePie } from 'analytics/components';
import { IReviewAnalyticsData, IReviewAnalyticsFilterObj } from 'analytics/types';
import {
  AnalyticsPlatoformGraphColors,
  PLATFORM_FILTER,
  REVIEW_ANALYTICS_FB_VARIEANT_PIE_LEGEND,
  REVIEW_ANALYTICS_PIE_LEGEND,
  REVIEW_ANALYTICS_PLATFORM_FILTER,
  REVIEW_ANALYTICS_STAR_PIE_LEGEND,
  REVIEW_ANALYTICS_STATS,
  ReviewAnalyticsStatsValue,
  fbVariantColors,
  pieLegendFormatter,
  pieStarColors
} from 'analytics/utils';
import { COMMON_SOCIAL_PROVIDER_TYPE, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData, useAvailablePlatforms } from 'utils/hooks';

interface IReviewAnalyticsPieChartProps {
  data: IReviewAnalyticsData;
  filter: IReviewAnalyticsFilterObj;
  isViewNumber: boolean;
  currentTab: string;
}

export const ReviewAnalyticsPieChart = ({ data, filter, isViewNumber, currentTab }: IReviewAnalyticsPieChartProps) => {
  const { userOwnership } = useAccountSwitcherData();
  const availablePlatform = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false, userOwnership === USER_OWNERSHIP.ACCOUNT ? true : false);

  const currentTabObj = [...REVIEW_ANALYTICS_STATS].find((it) => it.value === currentTab);
  const pieChartTitle = isViewNumber && currentTab === ReviewAnalyticsStatsValue.REPLY ? currentTabObj?.chartTitle : currentTabObj?.pieChartTitle;

  const percentageRepliedData = (percentageData: number, reviews: number | null | undefined) => {
    return reviews === 0 ? 100 : percentageData;
  };

  const platformReviewsCount =
    data?.reviews_analytics?.platform_reviews_count && typeof data?.reviews_analytics?.platform_reviews_count !== 'string' && typeof data?.reviews_analytics?.platform_reviews_count !== 'number'
      ? data?.reviews_analytics?.platform_reviews_count
      : {};

  const avgRatingsCount =
    data?.reviews_analytics?.platform_avg_rating && typeof data?.reviews_analytics?.platform_avg_rating !== 'string' && typeof data?.reviews_analytics?.platform_avg_rating !== 'number'
      ? data?.reviews_analytics?.platform_avg_rating
      : {};

  const avgRatingPieChartData = {
    facebook: avgRatingsCount?.facebook,
    google: avgRatingsCount?.google_places,
    yelp: avgRatingsCount?.yelp
  };

  const averageResponseTimePieChartValue = {
    facebook: data?.reviews_analytics?.facebook_avg_response_time,
    google: data?.reviews_analytics?.google_avg_response_time,
    yelp: data?.reviews_analytics?.yelp_avg_response_time
  };

  const repliedPercPieChartValue = {
    facebook:
      data?.reviews_analytics && percentageRepliedData(+data?.reviews_analytics[`facebook_${isViewNumber ? 'replied_count' : 'replied_percentage'}`], isViewNumber ? 1 : +avgRatingsCount?.facebook),
    google: data?.reviews_analytics && percentageRepliedData(+data?.reviews_analytics[`google_${isViewNumber ? 'replied_count' : 'replied_percentage'}`], isViewNumber ? 1 : +avgRatingsCount?.google),
    yelp: data?.reviews_analytics && percentageRepliedData(+data?.reviews_analytics[`yelp_${isViewNumber ? 'replied_count' : 'replied_percentage'}`], isViewNumber ? 1 : +avgRatingsCount?.yelp)
  };

  const avgPieChartData = [...(filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? REVIEW_ANALYTICS_FB_VARIEANT_PIE_LEGEND : REVIEW_ANALYTICS_STAR_PIE_LEGEND)].reduce((acc: any, curr) => {
    const pieData = data
      ? data?.reviews_analytics_by_date?.map((it) => {
          return {
            avg_rating: filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? +it[`avg_rating`] : +it[`${filter.platform}_avg_rating`] || 0
          };
        })
      : [];
    acc[`${curr.value}`] = pieData?.filter((it) => Math.round(+it.avg_rating) === +curr.ratings)?.length || 0;
    return acc;
  }, {});
  const totalReviewsPieChartData = {
    facebook: platformReviewsCount?.facebook,
    google: platformReviewsCount.google_places,
    yelp: platformReviewsCount.yelp
  };

  const pieChartData = {
    ...(currentTab === ReviewAnalyticsStatsValue.REVIEWS
      ? totalReviewsPieChartData
      : currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING
      ? avgRatingPieChartData
      : currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME
      ? averageResponseTimePieChartValue
      : currentTab === ReviewAnalyticsStatsValue.REPLY
      ? repliedPercPieChartValue
      : {}),
    ...(currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING && {
      ...avgPieChartData
    })
  };

  const updatedPieChartData = Object.keys(pieChartData).reduce((acc: Record<string, string>, curr: string) => {
    if (availablePlatform.some((platform) => platform.value === curr)) {
      acc[curr] = pieChartData[curr];
    }
    return acc;
  }, {});

  const updatedSeriesArray = REVIEW_ANALYTICS_PIE_LEGEND.filter((reviewPlatform) => availablePlatform.some((platform) => platform.value === reviewPlatform.value));

  return (
    <TotalPercentagePie
      title={pieChartTitle || ''}
      data={updatedPieChartData}
      pieArray={updatedSeriesArray}
      seriesArray={
        currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING
          ? filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
            ? REVIEW_ANALYTICS_FB_VARIEANT_PIE_LEGEND
            : REVIEW_ANALYTICS_STAR_PIE_LEGEND
          : updatedSeriesArray
      }
      pieChartColours={
        currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING
          ? filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
            ? fbVariantColors
            : pieStarColors
          : [AnalyticsPlatoformGraphColors.FACEBOOK, AnalyticsPlatoformGraphColors.GOOGLE, AnalyticsPlatoformGraphColors.YELP]
      }
      platformArray={REVIEW_ANALYTICS_PLATFORM_FILTER}
      showInLegend={currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING}
      pieLabelFormatter={(it) => pieLegendFormatter(it as Point)}
      selectedTile={filter.platform}
      isTimeFormat={currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME}
      isDataLabelEnabled={currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING ? false : true}
      selectedTileArray={currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING ? filter.ratings : undefined}
      isRotate={currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME}
      isDelta={false}
      chartTitle={REVIEW_ANALYTICS_STATS.find((it) => it.value === currentTab)?.piChartChartTitle}
      isPercentage={currentTab === ReviewAnalyticsStatsValue.REPLY && !isViewNumber}
      defaultValue={0}
    />
  );
};
