import { commonProps, mgContainerTypes } from 'types';

export const MG_MAX_VIDEO_SIZE = 524288000; // MAX LIMIT: 500 MB to Bytes
export const MG_MAX_DOCUMENT_SIZE = 41943040; // MAX LIMIT: 40 MB to Bytes

export const MG_SOURCE_TYPE = {
  ALL: 'all',
  BRAND: 'brand',
  LOCAL: 'local'
};

export const MG_MEDIA_TYPE = {
  VIDEO: 'video',
  PHOTO: 'photo',
  RAW: 'raw',
  DOCUMENT: 'document'
};

export enum MgMediaType {
  PHOTOS = 'photos',
  VIDEOS = 'videos',
  DOCUMENTS = 'documents'
}

export enum MgMediaSortType {
  NEWEST_FIRST = 'newest_first',
  OLDEST_FIRST = 'oldest_first',
  ALPHABETICAL = 'alphabetical',
  REVERSE_ALPHABETICAL = 'reverse_alphabetical'
}

export enum MgMediaSortTypeParamValue {
  NEWEST_FIRST = 'created_at_desc',
  OLDEST_FIRST = 'created_at_asc',
  ALPHABETICAL = 'name_asc',
  REVERSE_ALPHABETICAL = 'name_desc'
}

export enum MgMediaExpirationStatusValue {
  ALL = 'all',
  ACTIVE = 'true',
  INACTIVE = 'false'
}
export enum MgMediaExpirationStatusLabel {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Expired'
}

export const MG_FILTER_MEDIA_TYPE = [
  { value: MgMediaType.PHOTOS, label: 'Image' },
  { value: MgMediaType.VIDEOS, label: 'Video' },
  { value: MgMediaType.DOCUMENTS, label: 'Documents' }
];

export enum MgSourceType {
  ALL = 'all',
  BRAND = 'brand',
  ACCOUNT = 'account',
  LOCATION = 'location',
  CONTENT_SUPPLIER = 'syndicated'
}

export const MG_POST_SOURCE_TYPE_FILTER_OPTION = [
  { value: MgSourceType.ALL, label: 'All' },
  { value: MgSourceType.BRAND, label: 'Brand' },
  { value: MgSourceType.ACCOUNT, label: 'Location' },
  { value: MgSourceType.CONTENT_SUPPLIER, label: 'Content Supplier' }
];

export const MG_FILTER_MODEL_RELEASE_TYPE = [
  { value: 'all', label: 'All' },
  { value: '1', label: 'With' },
  { value: '0', label: 'Without' }
];

export enum MgRatingType {
  ALL = 'all',
  LIKED = 'liked',
  DISLIKED = 'disliked',
  UNRATED = 'unrated'
}

export const MG_FILTER_RATING_TYPE = [
  { value: MgRatingType.ALL, label: 'All' },
  { value: MgRatingType.LIKED, label: 'Liked' },
  { value: MgRatingType.DISLIKED, label: 'Disliked' },
  { value: MgRatingType.UNRATED, label: 'Unrated' }
];

export const MG_FILTER_TYPE_VALUE: mgContainerTypes.IMgFilterType = {
  ALL: 'all',
  BRAND: 'brand',
  LOCAL: 'account',
  USED: 'post_used',
  UNUSED: 'post_unused',
  WITH: 'with',
  WITHOUT: 'without',
  NEWLYADDED: 'newly_added'
};

export const MG_CLOUD_MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  RAW: 'raw'
};

export const MG_TOOLTIP_ICONS_NAME = {
  SHARE: 'Use',
  DOWNLOAD_IMAGE: 'Download Image',
  DOWNLOAD_VIDEO: 'Download Video',
  DOWNLOAD_DOCUMENT: 'Download Document',
  EDIT: 'Edit',
  DOWNLOAD_RELEASE: 'Download Release',
  NO_FILE: 'No File',
  RELEASE_NOT_AVAILABLE: 'Model Release not available',
  DELETE: 'Delete',
  VIEW: 'View',
  LIKE: 'Like',
  DISLIKE: 'Dislike',
  SYNDICATE: 'Syndicate'
};

export const APPROVED_STATUS = {
  NOT_YET: 'Not yet',
  YES: 'Yes',
  NO: 'No'
};

export const NAV_ACTIONS = {
  PREVIOUS: 'previous',
  NEXT: 'next'
};

export const MEDIA_CLONE_AVAILBILITY: commonProps.IMainPageRouteData = { name: 'everyone', label: 'Available for Everyone Else' };

export const MEDIA_FRAN_EDIT_OPTIONS = [
  { name: true, label: 'Lock Image from Editing and Downloading' },
  { name: false, label: 'Allow Others to Edit Image and Downloading' }
];

export enum MediaGalleryValidQueryParams {
  SOURCE = 'source',
  POST_USED = 'post_used',
  USED = 'used',
  UNUSED = 'unused',
  NEWLY_ADDED = 'newly_added',
  MEDIA_RELEASE = 'media_release',
  RATING = 'rating',
  TAGS_LIST = 'tags_list',
  SEARCH = 'text',
  AI_IMAGE = 'ai_type',
  SORT_BY = 'sort_by',
  ACTIVE = 'active',
  SYNDICATED_TO_ANY_FRANCHISOR = 'syndicated_to_any_franchisor',
  SYNDICATED_TO_ANY_ACCOUNT = 'syndicated_to_any_account'
}

export enum MultiSelectValidQueryParams {
  MULTI_SYNDICATED_TO_ANY_FRANCHISOR = 'multi_syndicated_to_any_franchisor',
  MULTI_SYNDICATED_TO_ANY_ACCOUNT = 'multi_syndicated_to_any_account',
  STATUS = 'status',
  SOURCE = 'source'
}

export enum MgUsageType {
  ALL = 'all',
  USED = 'used',
  UNUSED = 'unused',
  NEWLY_ADDED = 'newly_added'
}

export enum MgSyndicateValues {
  ALL = 'all',
  SYNDICATED = '1',
  NOT_SYNDICATED = '0'
}

export enum MediaDropPages {
  MEDIA_GALLERY = 'media_gallery',
  SETTINGS = 'settings',
  CREATOR = 'creator',
  CUSTOMIZE_THUMBNAIL = 'customize_thumbnail'
}

export const MG_USAGE_FILTER_TYPE = [
  { value: MgUsageType.ALL, label: 'All' },
  { value: MgUsageType.USED, label: 'Used' },
  { value: MgUsageType.UNUSED, label: 'Unused' },
  { value: MgUsageType.NEWLY_ADDED, label: 'Newly Added' }
];

export const FRANCHISOR_SYN_CONTENT_WRITER_OBJ = { isShowModal: false, mediaType: '', assetId: null };
export const MG_PHOTO_VIDEO = [MgMediaType.PHOTOS, MgMediaType.VIDEOS] as string[];

export const MG_PROVIDER_TYPE = {
  S3: 's3',
  CLOUDINARY: 'cloudinary'
};

export const MG_FILTER_SORT_BY = [
  { value: MgMediaSortType.NEWEST_FIRST, label: 'Newest First', paramVal: MgMediaSortTypeParamValue.NEWEST_FIRST },
  { value: MgMediaSortType.OLDEST_FIRST, label: 'Oldest First', paramVal: MgMediaSortTypeParamValue.OLDEST_FIRST },
  { value: MgMediaSortType.ALPHABETICAL, label: 'Alphabetical', paramVal: MgMediaSortTypeParamValue.ALPHABETICAL },
  { value: MgMediaSortType.REVERSE_ALPHABETICAL, label: 'Reverse Alphabetical', paramVal: MgMediaSortTypeParamValue.REVERSE_ALPHABETICAL }
];

export const MG_FILTER_MEDIA_EXPIRATION_STATUS = [
  { value: MgMediaExpirationStatusValue.ALL, label: MgMediaExpirationStatusLabel.ALL },
  { value: MgMediaExpirationStatusValue.ACTIVE, label: MgMediaExpirationStatusLabel.ACTIVE },
  { value: MgMediaExpirationStatusValue.INACTIVE, label: MgMediaExpirationStatusLabel.INACTIVE }
];

export const MEDIA_RELEASE_NO_SIGNATURE = 'no_signature';
export const MEDIA_RELEASE_NO_TEXT = 'no_text';

export const MG_MEDIA_SETTINGS_VALUES = {
  ALL: 'all',
  INDIVIDUAL: 'individual'
};

export const MG_MEDIA_SETTINGS_LABELS = {
  ALL: 'Apply Settings for All',
  INDIVIDUAL: 'Apply Settings Individually'
};

export const MG_MEDIA_SETTINGS = [
  {
    value: MG_MEDIA_SETTINGS_VALUES.ALL,
    label: MG_MEDIA_SETTINGS_LABELS.ALL
  },
  {
    value: MG_MEDIA_SETTINGS_VALUES.INDIVIDUAL,
    label: MG_MEDIA_SETTINGS_LABELS.INDIVIDUAL
  }
];

export const MG_MEDIA_RELEASE_VALUES = {
  YES: 'yes',
  NO: 'no'
};

export const MG_MEDIA_RELEASE_LABELS = {
  YES: `Yes`,
  NO: `No`
};

export const MG_MEDIA_RELEASE_OPTIONS = [
  {
    value: MG_MEDIA_RELEASE_VALUES.YES,
    label: MG_MEDIA_RELEASE_LABELS.YES
  },
  {
    value: MG_MEDIA_RELEASE_VALUES.NO,
    label: MG_MEDIA_RELEASE_LABELS.NO
  }
];

export const MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES = {
  DEFAULT: 'default',
  CUSTOMIZE: 'customize'
};

export const MG_CUSTOMIZE_VIDEO_THUMBNAIL_LABELS = {
  DEFAULT: `Use Default Thumbnail`,
  CUSTOMIZE: `Customize Thumbnail`
};

export const MG_CUSTOMIZE_VIDEO_THUMBNAIL_OPTIONS = [
  {
    value: MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES.DEFAULT,
    label: MG_CUSTOMIZE_VIDEO_THUMBNAIL_LABELS.DEFAULT
  },
  {
    value: MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES.CUSTOMIZE,
    label: MG_CUSTOMIZE_VIDEO_THUMBNAIL_LABELS.CUSTOMIZE
  }
];

export const MG_SYNDICATED_FILTER_TYPE = [
  { value: MgSyndicateValues.ALL, label: 'All' },
  { value: MgSyndicateValues.SYNDICATED, label: 'Syndicated' },
  { value: MgSyndicateValues.NOT_SYNDICATED, label: 'Not Syndicated' }
];

export enum MultiSelectMGSourceOptions {
  ALL = 'all',
  AUTHORIZED = 'authorized'
}
