import { useCallback, useLayoutEffect, useState, useEffect } from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { COMMON_PAGE_TYPE, COMMON_SOCIAL_PROVIDER_TYPE, ROUTES, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData, useAvailablePlatforms } from 'utils/hooks';
import { actualChartData, reviewSelectedTableData, secondaryTableHeader, statsChartDataHandler } from 'analytics/utils/helpers';
import { IStore } from 'types';
import {
  ViewTypes,
  REVIEW_ANALYTICS_STATS,
  REVIEW_ANALYTICS_PIE_LEGEND,
  REVIEW_ANALYTICS_PLATFORM_FILTER,
  ChartType,
  REVIEW_ANALYTICS_WORD_CLOUD_OPTIONS,
  ReviewAnalyticsStatsValue,
  REVIEW_ANALYTICS_SENTIMENTS,
  VALID_ON_CLICK_HEADERS,
  KeywordManagementTableValue,
  PLATFORM_FILTER
} from 'analytics/utils/constants';
import { IReviewAnalytics, IReviewsGraphSeries, INominalGraphSeries } from 'analytics/types';
import { getKeyWordsCategoryListRequest, getKeyWordsCategoryRequest, resetKeywordsState, setKeyWordData } from 'analytics/actions';
import { Loading } from 'components';
import { HighChartReact, PlatformTable } from 'analytics/components';
import { KeywordsModal } from './KeyWordsModal';
import { KeywordsCategoryManagementModal } from './KeywordsCategoryManagementModal';
import { KeywordsCategoryAnalyticsContainer } from './KeywordsCategoryAnalyticsContainer';
import { ImageValidation } from 'widgets/Media';
import { ReviewAnalyticsStats } from './ReviewAnalyticsStats';
import { KeywordsCategoryTable } from './KeywordsCategoryTable';
import { ReviewAnalyticsPieChart } from './ReviewAnalyticsPieChart';
import { KeywordsCategoryAreaSplineChart } from './KeywordsCategoryAreaSplineChart';

export const ReviewAnalyticsContainer = ({ filter, data, currentTab, dispatch, dispatchKeyWords, queryParams }: IReviewAnalytics) => {
  const navigate = useNavigate();
  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const availablePlatform = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false, userOwnership === USER_OWNERSHIP.ACCOUNT ? true : false);

  const keyWordCloudData = useSelector((state: IStore) => state.reviewAnalytics.keyWordCloudData);
  const keyWordsData = useSelector((state: IStore) => state.reviewAnalytics.keyWordsData);
  const isKeyWordDataFetching = useSelector((state: IStore) => state.reviewAnalytics.isKeyWordDataFetching);
  const groupedTableData = useSelector((state: IStore) => state.reviewAnalytics.groupedTableData);
  const uniqueKeyWord = useSelector((state: IStore) => state.reviewAnalytics.uniqueKeyWord);
  const keywordsCategoryList = useSelector((state: IStore) => state.reviewAnalytics.keywordsCategoryList);
  const showCategoryModal = useSelector((state: IStore) => state.reviewAnalytics.showCategoryModal);
  const allKeywordsList = useSelector((state: IStore) => state.reviewAnalytics.allKeywordsList);

  const isViewNumber = queryParams?.is_number === '1' ? true : false;

  const [tabGraphSeries, setTabGraphSeries] = useState<INominalGraphSeries>({ data: {} });
  const [statsGraphSeries, setStatsGraphSeries] = useState<IReviewsGraphSeries>({ percReplied: [], responseTime: [] });

  const sortKey = queryParams?.sort_by || 'used';
  const currentTabObj = [...REVIEW_ANALYTICS_STATS].find((it) => it.value === currentTab);
  const tabDelta = currentTabObj?.delta;
  const tableTabKey = isViewNumber && currentTab === ReviewAnalyticsStatsValue.REPLY ? 'replied_count' : currentTabObj?.chartValue;

  useEffect(() => {
    if (currentTab === ReviewAnalyticsStatsValue.KEYWORDS && !keywordsCategoryList.length) {
      dispatch(getKeyWordsCategoryListRequest({ userOwnership, id }));
    }
  }, [currentTab]); // eslint-disable-line

  useEffect(() => {
    if (showCategoryModal.isShowModal && !allKeywordsList.length) {
      dispatchKeyWords(1, false, sortKey, true);
    }
  }, [showCategoryModal.isShowModal, allKeywordsList]); // eslint-disable-line

  useEffect(() => {
    if (showCategoryModal.type === COMMON_PAGE_TYPE.EDIT) {
      dispatch(getKeyWordsCategoryRequest(Number(queryParams?.category || keywordsCategoryList[0]?.id)));
    }
  }, [queryParams?.category, showCategoryModal.type, keywordsCategoryList, dispatch]); // eslint-disable-line

  useLayoutEffect(() => {
    setStatsGraphSeries({
      ...statsChartDataHandler(groupedTableData, queryParams?.platform)
    });
  }, [groupedTableData, isViewNumber, queryParams?.platform]); // eslint-disable-line

  useLayoutEffect(() => {
    const tabValue = isViewNumber && currentTab === ReviewAnalyticsStatsValue.REPLY ? 'replied_count' : currentTab;
    const headers = [...groupedTableData.headers].slice(1);
    const tabSeriesPlatforms = [...REVIEW_ANALYTICS_PLATFORM_FILTER].filter((it) => (filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? it : it.value === filter.platform));
    const fallBack = tabValue === 'avg_rating' ? null : 0;
    const tabSeries = headers.length
      ? tabSeriesPlatforms.reduce((acc: { [key: string]: any }, curr, index) => {
          const platform = curr.value === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? 'total' : curr.value;
          acc[`${curr.value}`] = [...headers].map((datum) => {
            return {
              y: +groupedTableData.summary[`${platform}_${datum.value}_${tabValue}`] || fallBack,
              toolTipY: +groupedTableData.summary[`${platform}_${datum.value}_${tabValue}`] || fallBack
            };
          });
          if (acc[`${curr.value}`].length > 1 && !acc[`${curr.value}`].some((it: { y: number }) => it?.y > 0)) {
            delete acc[`${curr.value}`];
          }
          if (index === tabSeriesPlatforms.length - 1) {
            const selectedPlatoform = filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? 'total' : curr.value;
            acc.average = [...headers]?.map((datum) => {
              return {
                // x: datum.value,
                y: +groupedTableData.summary[`brand_avg_${selectedPlatoform}_${datum.value}_${tabValue}`] || fallBack,
                hubAvg: null,
                chartType: ChartType.SPLINE,
                dashStyle: 'Dash'
              };
            });
            acc.hubAverage = [...headers]?.map((datum) => {
              return {
                // x: datum.value,
                y: +groupedTableData.summary[`hub_avg_${selectedPlatoform}_${datum.value}_${tabValue}`] || fallBack,
                avg: null,
                hubAvg: null,
                chartType: ChartType.SPLINE,
                dashStyle: 'Dash'
              };
            });
            if (Object.keys(acc).includes(COMMON_SOCIAL_PROVIDER_TYPE.ALL)) {
              const keys = Object.keys(acc).filter((it) => it !== 'average' && it !== 'hubAverage');
              if (keys.length === 1) {
                delete acc.all;
              }
            }
          }
          return acc;
        }, {})
      : {};
    const isActualChart =
      currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME || currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING || (currentTab === ReviewAnalyticsStatsValue.REPLY && !isViewNumber);
    let max;
    let tick;
    if (currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME) {
      const maxArray = [].concat(...Object.values(tabSeries).map((it) => it.map((datum: any) => datum.y)));
      const actualMax = Math.max(...maxArray);
      if (actualMax >= 60 * 60) {
        const converter = actualMax >= 60 * 60 * 24 ? 3600 * 24 : 3600;
        const absoluteMax = 1.5 * Math.ceil(actualMax / converter / 1.5);
        tick = (absoluteMax * converter) / 6;
        max = absoluteMax * converter;
      }
    }
    setTabGraphSeries(isActualChart ? { data: tabSeries, tickInterval: tick, yMax: max } : actualChartData(tabSeries, false));
  }, [currentTab, filter.platform, isViewNumber, groupedTableData]); // eslint-disable-line

  const handleTableClick = useCallback(
    (id: KeywordManagementTableValue, value: string | number | boolean) => {
      if (VALID_ON_CLICK_HEADERS.includes(id)) {
        dispatch(setKeyWordData(value.toString()));
        const platform: string =
          queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
            ? COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
            : queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.YELP
            ? COMMON_SOCIAL_PROVIDER_TYPE.YELP
            : COMMON_SOCIAL_PROVIDER_TYPE.ALL;
        const ratings = REVIEW_ANALYTICS_SENTIMENTS[platform][id]?.toString();
        if (ratings) {
          const params = { ...queryParams, ratings };
          navigate({ search: `?${new URLSearchParams(params).toString()}` });
        }
      }
    },
    [queryParams, dispatch, navigate]
  );

  const wordCloudChart = useCallback(() => {
    const updatedCloudOptions = { ...REVIEW_ANALYTICS_WORD_CLOUD_OPTIONS };
    if (updatedCloudOptions.title?.text) {
      updatedCloudOptions.title.text = queryParams?.category ? `${keywordsCategoryList.find((keyword) => keyword.id === Number(queryParams?.category))?.name} Category Keywords` : 'All Keywords';
    }
    const options: Highcharts.Options = {
      ...updatedCloudOptions,
      plotOptions: {
        series: {
          cursor: 'pointer',
          events: {
            click: (event) => {
              const nameData = keyWordsData.find((it) => it.keyword === event.point.name);
              const sentimentFilterArr = [
                {
                  value: Number(nameData?.positive),
                  sentiment: KeywordManagementTableValue.POSITIVE
                },
                {
                  value: Number(nameData?.neutral),
                  sentiment: KeywordManagementTableValue.NEUTRAL
                },
                {
                  value: Number(nameData?.negative),
                  sentiment: KeywordManagementTableValue.NEGATIVE
                }
              ];
              const validSentiment = sentimentFilterArr.sort((a, b) => b.value - a.value)[0].sentiment;
              handleTableClick(validSentiment, event.point.name);
            }
          }
        }
      }
    };
    if (REVIEW_ANALYTICS_WORD_CLOUD_OPTIONS?.series) {
      options.series = [
        {
          ...REVIEW_ANALYTICS_WORD_CLOUD_OPTIONS.series[0],
          data: keyWordCloudData as any
        }
      ];
    }
    return <HighChartReact options={options} />;
  }, [keyWordCloudData, keyWordsData, handleTableClick, keywordsCategoryList, queryParams?.category]);

  const handleChangeTab = (tab: string) => {
    const selectedTab = currentTab === tab || tab === 'reviews' ? '' : tab;
    const urlParams = { ...queryParams };
    delete urlParams.ratings;
    delete urlParams.is_number;
    delete urlParams.sort_by;
    delete urlParams.ascending;
    if (selectedTab) {
      urlParams.selected_tile = selectedTab;
    } else if (urlParams.selected_tile) {
      delete urlParams.selected_tile;
    }
    navigate({ search: `?${new URLSearchParams(urlParams).toString()}` });
    if (tab === ReviewAnalyticsStatsValue.KEYWORDS && dispatchKeyWords) {
      dispatchKeyWords(1, false, sortKey);
    } else if (keyWordsData.length) {
      dispatch(resetKeywordsState());
    }
  };

  const handleViewinNumber = () => {
    const urlParams = { ...queryParams };
    if (urlParams.is_number === '1') {
      delete urlParams.is_number;
    } else {
      urlParams.is_number = '1';
    }
    navigate({ search: `?${new URLSearchParams(urlParams).toString()}` });
  };

  const handleReviewsNavigation = () => {
    navigate(ROUTES.REPUTATION_REVIEWS.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
  };

  return (
    <div className="mainContent analytics analytics-post animate__animated animate__fadeInUp review-an__main mbl-head--margin">
      <div className="review-an-container">
        <ReviewAnalyticsStats
          statsGraphSeries={statsGraphSeries}
          data={data}
          handleReviewsNavigation={handleReviewsNavigation}
          handleChangeTab={handleChangeTab}
          filter={filter}
          isViewNumber={isViewNumber}
        />
        {currentTab === ReviewAnalyticsStatsValue.KEYWORDS && userOwnership === USER_OWNERSHIP.ACCOUNT && keywordsCategoryList.length ? <KeywordsCategoryAnalyticsContainer /> : null}
        {
          <div className={`flex-item-xs lpx ${currentTab === ReviewAnalyticsStatsValue.KEYWORDS ? '' : 'review-an--chart'}`}>
            <div className={`ra-card ${currentTab === ReviewAnalyticsStatsValue.KEYWORDS ? 'kwrds' : ''}`}>
              {currentTab === ReviewAnalyticsStatsValue.KEYWORDS ? (
                isKeyWordDataFetching ? (
                  <Loading />
                ) : (
                  wordCloudChart()
                )
              ) : (
                <>
                  {currentTab === ReviewAnalyticsStatsValue.REPLY ? (
                    <div className="replies-btn--wrp">
                      <button className="view-in-percentage-btn" onClick={handleViewinNumber}>
                        {isViewNumber ? ViewTypes.PERCENTAGE : ViewTypes.NUMBER}
                      </button>
                    </div>
                  ) : null}
                  <KeywordsCategoryAreaSplineChart filter={filter} isViewNumber={isViewNumber} currentTab={currentTab} tabGraphSeries={tabGraphSeries} />
                </>
              )}
              {(currentTab !== ReviewAnalyticsStatsValue.KEYWORDS && userOwnership === USER_OWNERSHIP.ACCOUNT) || (currentTab && userOwnership === USER_OWNERSHIP.FRANCHISOR) ? (
                <ImageValidation isImgValid defaultImg="triangle-w" customName="indicator" customClassname={`an-chart--indicator ${currentTab}`} />
              ) : null}
            </div>
          </div>
        }

        {currentTab === ReviewAnalyticsStatsValue.KEYWORDS ? (
          isKeyWordDataFetching && !keyWordsData?.length ? null : (
            <KeywordsCategoryTable queryParams={queryParams} dispatchKeyWords={dispatchKeyWords} handleTableClick={handleTableClick} />
          )
        ) : (
          <PlatformTable
            headers={secondaryTableHeader(groupedTableData.headers, filter.platform, currentTab === ReviewAnalyticsStatsValue.REPLY && isViewNumber ? 'replied_count' : currentTab)}
            totals={groupedTableData.summary || {}}
            data={reviewSelectedTableData(
              groupedTableData,
              REVIEW_ANALYTICS_PIE_LEGEND.filter((it) => availablePlatform.some((socialMedia) => socialMedia.value === it.value)),
              filter.platform,
              tableTabKey || '',
              tabDelta || ''
            )}
            isTotal={filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL}
            tableParentVariant={'platform-review__tbl'}
            tableVariant={''}
            isTimeFormat={currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME}
            totalText={[ReviewAnalyticsStatsValue.AVERAGE_RATING.toString(), ReviewAnalyticsStatsValue.RESPONSE_TIME.toString()].includes(currentTab) ? 'Average' : 'Totals'}
            isMinSecEnabled={currentTab === ReviewAnalyticsStatsValue.RESPONSE_TIME}
            isDelta={false}
            isPercentage={currentTab === ReviewAnalyticsStatsValue.REPLY && !isViewNumber}
            isToFixed={currentTab === ReviewAnalyticsStatsValue.AVERAGE_RATING}
            defaultValue={0}
          />
        )}
        {currentTab && currentTab !== ReviewAnalyticsStatsValue.KEYWORDS ? <ReviewAnalyticsPieChart data={data} filter={filter} isViewNumber={isViewNumber} currentTab={currentTab} /> : null}
        {uniqueKeyWord && currentTab === ReviewAnalyticsStatsValue.KEYWORDS && <KeywordsModal dispatch={dispatch} uniqueKeyWord={uniqueKeyWord} navigate={navigate} />}
        {(currentTab === ReviewAnalyticsStatsValue.KEYWORDS || optionalParams[0] === 'category') && (
          <KeywordsCategoryManagementModal
            isShowModal={showCategoryModal.isShowModal}
            type={showCategoryModal.type}
            categoryId={Number(queryParams?.category) || null}
            dispatchKeyWords={() => dispatchKeyWords(1, false, sortKey)}
          />
        )}
      </div>
    </div>
  );
};
