import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartWordCloud from 'highcharts/modules/wordcloud';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import BreakAxis from 'highcharts/modules/broken-axis';

HighchartWordCloud(Highcharts);
NoDataToDisplay(Highcharts);
BreakAxis(Highcharts);

export const HighChartReact = (props: HighchartsReact.Props) => {
  const { options, immutable, updateArgs = [true, true, true] } = props;
  return <HighchartsReact highcharts={Highcharts} options={options} allowChartUpdate immutable={immutable} updateArgs={updateArgs} />;
};
