import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomRippleButton } from 'widgets/CustomRippleButton';
import {
  RIPPLE_COLOR,
  AP_MEDIA_EXPIRATION,
  AddMediaExpirationValues,
  ICommonDateTypes,
  DATEPICKER_DATE_ONLY_FORMAT,
  USER_OWNERSHIP,
  ALERT_BOX_TYPES,
  MG_CONTENT_ALREADY_LOCKED
} from 'utils/constants';
import { setEditedMgDetails } from 'actions';
import { IStore, mgContainerTypes } from 'types';
import { getCurrentDate, getEndOfDate, getFormatBasedOnDateString, getParsedDate, utcFormatForDateValue } from 'utils/helpers';
import { CustomDatePicker } from 'widgets/DatePicker';
import { useAccountSwitcherData } from 'utils/hooks';
import { alertBoxCall } from 'components';

export const MgMediaExpiry = ({ editEnabled, handleSaveMgAsset, setAlertShown }: Omit<mgContainerTypes.IMgMediaAvailabilityProps, 'availabilityTabName'>) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line

  const { userOwnership } = useAccountSwitcherData();
  const mgDetails = useSelector((state: IStore) => state.mediaGallery.mgDetails);
  const editedMgDetails = useSelector((state: IStore) => state.mediaGallery.editedMgDetails);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);

  const [mediaExpiration, setMediaExpiration] = useState<string>(mgDetails?.expires_at ? AddMediaExpirationValues.EXPIRE : AddMediaExpirationValues.NEVER);

  const handleExpireDate = (mediaExpiryeDate: Date | null) => {
    dispatch(
      setEditedMgDetails({
        expires_at: userOwnership === USER_OWNERSHIP.FRANCHISOR ? getFormatBasedOnDateString(mediaExpiryeDate) : utcFormatForDateValue(accountDetails?.account?.tzinfo_name, mediaExpiryeDate)
      })
    );
  };

  return (
    <>
      {/* <div className="white-box int-note"> */}
      <div className="post__white--box int-note smExpiration">
        <div className="wb-top">
          <div className="wb-label">
            <div className="wbl-head">Expiration</div>
          </div>
          <div className="wb-f-main">
            <div className="wb-fields">
              <div className="wbf-item">
                <div className="wbf-inputs">
                  <div className="input-fields">
                    <div className="if-main if-h avail-radio__group">
                      {AP_MEDIA_EXPIRATION.map((item, idx: number) => {
                        return (
                          <label className={`${AP_MEDIA_EXPIRATION.length - 1 === idx ? `r-ml0` : ``}`} key={`mg-media-avail-${idx}`}>
                            <input
                              type="radio"
                              className="option-input radio"
                              value={item.name}
                              checked={mediaExpiration === item.name}
                              onChange={(event) => {
                                if (!editEnabled) {
                                  setAlertShown(true);
                                  alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_CONTENT_ALREADY_LOCKED);
                                } else {
                                  setMediaExpiration(event.target.value);
                                  if (event.target.value === AddMediaExpirationValues.NEVER) {
                                    dispatch(
                                      setEditedMgDetails({
                                        expires_at: null,
                                        active: true
                                      })
                                    );
                                  }
                                }
                              }}
                            />
                            <span className="labelText">{item.label}</span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div className="mExpBase">
                    {mediaExpiration === AddMediaExpirationValues.EXPIRE ? (
                      <div className="sc-cdtWrap">
                        <div className="wdt-item da-date">
                          <CustomDatePicker
                            selectedDate={getParsedDate(editedMgDetails?.expires_at ?? mgDetails?.expires_at ?? null)}
                            dateRangeType={ICommonDateTypes.TO_DATE}
                            onChange={(date) => {
                              handleExpireDate(getEndOfDate(date));
                            }}
                            customClassname={`fltr-date-item fltr-from-date`}
                            minDate={getCurrentDate()}
                            autoComplete="off"
                            placeholderText={'Select Date here'}
                            dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                            disabled={Boolean(!editEnabled)}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {mgDetails?.expires_at !== editedMgDetails?.expires_at ? (
          <div className="btn-wrp g-20">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button
                className="ac-btn ac-transparent ac-outline size-xs border-0"
                onClick={() => {
                  dispatch(setEditedMgDetails({ expires_at: mgDetails?.expires_at }));
                  setMediaExpiration(mgDetails?.expires_at ? AddMediaExpirationValues.EXPIRE : AddMediaExpirationValues.NEVER);
                }}
              >
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary size-xs" onClick={() => handleSaveMgAsset()}>
                Save
              </button>
            </CustomRippleButton>
          </div>
        ) : null}
      </div>
    </>
  );
};
