import { Fragment } from 'react';

import {
  ChartType,
  DateFormatType,
  PERCENT_REPLIED_IN_NUMBER,
  REVIEW_ANALYTICS_PLATFORM_FILTER,
  REVIEW_ANALYTICS_STATS,
  ReviewAnalyticsStatsValue,
  ReviewsPlatformColors
} from 'analytics/utils/constants';
import { dateFormatter, reviewsTableDateGrouping } from 'analytics/utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { COMMON_SOCIAL_PROVIDER_TYPE, USER_OWNERSHIP } from 'utils/constants';
import { getAvgRatingSeriesList, getReviewStatsByDate, selectedChartType } from 'utils/helpers';
import { IReviewAnalyticsData, IReviewAnalyticsFilterObj, IReviewsGraphSeries } from 'analytics/types';
import { ReviewStats } from './ReviewStats';

interface IReviewAnalyticsStats {
  statsGraphSeries: IReviewsGraphSeries;
  data: IReviewAnalyticsData;
  handleReviewsNavigation: () => void;
  handleChangeTab: (tab: string) => void;
  filter: IReviewAnalyticsFilterObj;
  isViewNumber: boolean;
}
export const ReviewAnalyticsStats = ({ statsGraphSeries, data, isViewNumber, handleReviewsNavigation, handleChangeTab, filter }: IReviewAnalyticsStats) => {
  const { userOwnership } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const avgBarSeries = (data: any, total: number | string) => {
    const reviewChartObj = reviewsTableDateGrouping(data, REVIEW_ANALYTICS_STATS, REVIEW_ANALYTICS_PLATFORM_FILTER);
    const statsByDate = getReviewStatsByDate(reviewChartObj.data, queryParams?.platform);
    const seriesArray = getAvgRatingSeriesList(statsByDate, +total);
    return [
      {
        name: 'Average Rating',
        type: ChartType.COLUMN,
        data: seriesArray
      }
    ];
  };

  const selectedChartSeries = (stat: string, isHubUser: boolean, data: any, seriesData: any[], isNumber: boolean, statsGraphSeries: IReviewsGraphSeries) => {
    switch (stat) {
      case ReviewAnalyticsStatsValue.REVIEWS: {
        return isHubUser
          ? [...REVIEW_ANALYTICS_PLATFORM_FILTER].slice(1).map((it) => ({
              name: it.value,
              type: ChartType.BAR,
              data: [data[`${it.value === 'google' ? 'google_places' : it.value}`] || 0],
              color:
                it.value === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                  ? ReviewsPlatformColors.FACEBOOK
                  : it.value === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
                  ? ReviewsPlatformColors.GOOGLE
                  : ReviewsPlatformColors.YELP
            }))
          : [];
      }
      case ReviewAnalyticsStatsValue.AVERAGE_RATING: {
        return avgBarSeries(seriesData, data);
      }
      case ReviewAnalyticsStatsValue.KEYWORDS: {
        return 'wordCloud';
      }
      case ReviewAnalyticsStatsValue.REPLY: {
        return !isNumber ? statsGraphSeries?.percReplied : statsGraphSeries?.percRepliedCount;
      }
      case ReviewAnalyticsStatsValue.RESPONSE_TIME: {
        return statsGraphSeries?.responseTime;
      }
      default: {
        return {};
      }
    }
  };

  const percentageRepliedData = (percentageData: number, reviews: number | null | undefined) => {
    return reviews === 0 ? 100 : percentageData;
  };

  return (
    <div className={`stats review-an__stats analy-review-stats-wrp global-stats-section-wrp lpx vpy-20 g-20`}>
      {[...REVIEW_ANALYTICS_STATS].map((it, index) => {
        const chartType = selectedChartType(it.value, userOwnership === USER_OWNERSHIP.FRANCHISOR);
        const graphSeries = selectedChartSeries(
          it.value,
          userOwnership === USER_OWNERSHIP.FRANCHISOR,
          data?.reviews_analytics ? (it.value === ReviewAnalyticsStatsValue.REVIEWS ? data?.reviews_analytics?.platform_reviews_count || {} : data?.reviews_analytics[it.totalValue]) : 0,
          data?.reviews_analytics_by_date || [],
          isViewNumber,
          statsGraphSeries
        );
        const deltaKey = it.value === ReviewAnalyticsStatsValue.REPLY && isViewNumber ? `total_replied_delta` : `${it.delta}`;
        const brandAvgKey = it.value === ReviewAnalyticsStatsValue.REPLY && isViewNumber ? `brand_avg_replied_count` : `${it.brandAvg}`;
        const hubAvgKey = it.value === ReviewAnalyticsStatsValue.REPLY && isViewNumber ? `hub_avg_replied_count` : `${it.hubAvg}`;
        const countKey = it.value === ReviewAnalyticsStatsValue.REPLY && isViewNumber ? `total_replied_count` : `${it.totalValue}`;

        const delta = data?.reviews_analytics ? data?.reviews_analytics[`${deltaKey}`] : undefined;
        const brandAvg = data?.reviews_analytics
          ? brandAvgKey === 'brand_avg_replied_percentage'
            ? Math.round(+data?.reviews_analytics[`${brandAvgKey}`])
            : data?.reviews_analytics[`${brandAvgKey}`]
          : undefined;
        const hubAvg = data?.reviews_analytics
          ? hubAvgKey === 'hub_avg_replied_percentage'
            ? Math.round(+data?.reviews_analytics[`${hubAvgKey}`])
            : data?.reviews_analytics[`${hubAvgKey}`]
          : undefined;
        const count = data?.reviews_analytics
          ? countKey === 'total_replied_percentage'
            ? percentageRepliedData(+data?.reviews_analytics[countKey], +data?.reviews_analytics[REVIEW_ANALYTICS_STATS[0].totalValue])
            : data?.reviews_analytics[countKey]
          : undefined;

        const title = it.value === ReviewAnalyticsStatsValue.REPLY && isViewNumber ? PERCENT_REPLIED_IN_NUMBER.title : it.title;
        const value = it.value === ReviewAnalyticsStatsValue.REPLY && isViewNumber ? PERCENT_REPLIED_IN_NUMBER.value : it.value;

        return (
          <Fragment key={`${it.value}-${index}`}>
            <ReviewStats
              title={title}
              onStatsClick={() => handleChangeTab(it.value)}
              chartType={chartType}
              count={count ? +count : 0}
              delta={delta ? +delta : 0}
              brandAverage={it.value !== ReviewAnalyticsStatsValue.KEYWORDS && brandAvg ? +brandAvg : null}
              hubAverage={it.value !== ReviewAnalyticsStatsValue.KEYWORDS && hubAvg ? +hubAvg : null}
              isHubUser={userOwnership === USER_OWNERSHIP.FRANCHISOR}
              graphSeries={graphSeries}
              graphColor={[it.color]}
              graphFadeColor={[it.fadeColor]}
              graphFillColor={[it.fillColor]}
              titleValue={value}
              isDelta={it.value !== ReviewAnalyticsStatsValue.KEYWORDS}
              fromDate={dateFormatter(filter.startDate || '', DateFormatType.STAT_TOOLTIP)}
              toDate={dateFormatter(filter.endDate || '', DateFormatType.STAT_TOOLTIP)}
              isToFixed={it.value === ReviewAnalyticsStatsValue.AVERAGE_RATING}
              isPercentage={it.value === ReviewAnalyticsStatsValue.REPLY && !isViewNumber}
            />
          </Fragment>
        );
      })}
      <div className="stats-item sicp stats-level-04" onClick={handleReviewsNavigation}>
        <span className="mod-head stats-title-text">Review Management</span>
      </div>
    </div>
  );
};
