import { useRef, Dispatch, SetStateAction } from 'react';

import { debounceMethod } from 'utils/helpers';

export const MultiSelectPostOrMediaSearch = ({
  handlePostsStats,
  handlePostListBasedOnFilters,
  searchText,
  setSearchText
}: {
  handlePostsStats?: () => void;
  handlePostListBasedOnFilters: (lastPostId?: number, search?: string) => void;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
}) => {
  const postsListApiTimeout = useRef<null | NodeJS.Timeout>(null);

  const handleInputSearch = (value: string) => {
    if (handlePostsStats) {
      handlePostsStats();
    }
    if (handlePostListBasedOnFilters) {
      handlePostListBasedOnFilters(undefined, value);
    }
  };

  return (
    <>
      <div className={`search-tk post-search post-list__search lpx r-mt0`}>
        <div className="react-tags">
          <div className="react-tags__search">
            <div className="react-tags__search-input">
              <input
                type="text"
                placeholder={'Search text'}
                autoComplete="off"
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                onKeyUp={() => (postsListApiTimeout.current = debounceMethod(searchText.trim(), postsListApiTimeout.current, handleInputSearch))}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
