import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import { reviewsContainerTypes } from 'types';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES, MOMENT_DATE_FORMAT, MOMENT_TIME_FORMAT, MODAL_BOX_TYPES, REVIEWS_REPLY_DELETE_CONFIRM_MSG } from 'utils/constants';
import { capitalizeName, dateFormatByRequestedFormat } from 'utils/helpers';
import { ImageValidation } from 'widgets/Media';
import { Paragraph } from 'widgets/Text';
import { ModalPopup } from 'components';
import { reviewsDeleteReplyForGoogleRequest } from 'actions/modules/reviews';

export const GoogleReviewsReplyItem = ({ dataItem }: reviewsContainerTypes.IGoogleReviewsReplyItemProp) => {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleModalClose = () => setShowConfirmModal(false);

  const handleDeleteReviewsReply = () => {
    dispatch(reviewsDeleteReplyForGoogleRequest(dataItem.id));
    setShowConfirmModal(false);
  };

  return (
    <Fragment>
      <div className="review-notes-top">
        <div>
          <div className="li-top-internal-notes">
            <ImageValidation imgUrl={dataItem.imageUrl} customClassname={'round-asset brand-icon'} customName={'Asset Item'} />
            <div className="lvt-details">
              <h3>{capitalizeName(dataItem?.name || '')}</h3>
              <div className={`lvt-brief multi-lvtxt`}>
                <span className="lvt-txt">{`@${dateFormatByRequestedFormat(dataItem.createdAt, MOMENT_TIME_FORMAT)} on `}</span>
                <span className="lvt-txt lvt-dateyear">{`${dateFormatByRequestedFormat(dataItem.createdAt, MOMENT_DATE_FORMAT)}`}</span>
              </div>
            </div>
            {/* {dataItem?.deleteReplyUrl && ( */}
            <div className="closeIcon" onClick={() => setShowConfirmModal(true)}>
              <ImageValidation defaultImg={'xmark'} customClassname={'removeIcon'} customName={'Remove the Comment'} />
            </div>
            {/* )} */}
          </div>
          <div className="vcm-details">
            <Paragraph customText={dataItem.replyMessage} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.LIST_OR_DETAIL} />
          </div>
        </div>
      </div>
      <ModalPopup
        isModalShow={showConfirmModal}
        modalHeaderType={MODAL_BOX_TYPES.DANGER}
        containerClassName={'modal-confirmation confirm-delete'}
        modalBody={() => (
          <div>
            <div className="modal-message-wraps">{REVIEWS_REPLY_DELETE_CONFIRM_MSG}</div>
          </div>
        )}
        modalCloseButton
        handleModalClose={handleModalClose}
        isModalFooterShow
        modalFooter={() => (
          <div className="modal-btn-grp-wraps">
            <button className="modal-btn-action-itm modal-cancel-btn" onClick={handleModalClose}>
              Cancel
            </button>
            <button className="modal-btn-action-itm modal-delete-btn" onClick={handleDeleteReviewsReply}>
              Ok
            </button>
          </div>
        )}
      />
    </Fragment>
  );
};
