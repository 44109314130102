// import { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { COMMON_SOCIAL_PROVIDER_TYPE, ROUTES, /* USER_OWNERSHIP, REVIEWS_BRAND_PLATFORMS, */ REVIEWS_PLATFORM_STATS } from 'utils/constants';
import { ReactSpringCountUp } from 'widgets/ReactSpring';
import { ReviewsTotalCountComponent, ReviewsRepliedPercentageComponent, ReviewsAvgRatingComponent } from 'containers/Reputation/Reviews/Subpages';
import { useAccountSwitcherData, useAvailablePlatforms, useParamsDeconstructor } from 'utils/hooks';
import { REVIEW_ANALYTICS_PLATFORM_FILTER } from 'analytics/utils';

export const ReviewsStats = () => {
  const navigate = useNavigate();
  const { id, userOwnership } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const platformStats = useSelector((state: IStore) => state.reviews.reviewStats.platformStats);
  const reviewsCount = useSelector((state: IStore) => state.reviews.reviewStats.reviewsCount);
  const averageRating = useSelector((state: IStore) => state.reviews.reviewStats.averageRating);
  const statsByDate = useSelector((state: IStore) => state.reviews.reviewStats.statsByDate);
  const repliedPercentage = useSelector((state: IStore) => state.reviews.reviewStats.repliedPercentage);
  const isStatsFetching = useSelector((state: IStore) => state.reviews.isStatsFetching);
  const yelpBusinessId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.yelp_business_id);
  // const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);

  const activePlatforms = useAvailablePlatforms(REVIEW_ANALYTICS_PLATFORM_FILTER, userOwnership, yelpBusinessId || null, false);

  const availableReviewPlatforms = REVIEWS_PLATFORM_STATS.filter((it) => activePlatforms.some((platform) => platform.value === it.value));
  /* const activePlatforms = useMemo(() => {
    if (id && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      return getAccReviewsPlatformList(accountDetails?.social_media || [], accountDetails?.account_profiles[0]?.yelp_profile_url || null);
    } else {
      return REVIEWS_BRAND_PLATFORMS;
    }
  }, [id, userOwnership, accountDetails?.social_media, accountDetails?.account_profiles[0]?.yelp_profile_url]); // eslint-disable-line */

  const handlePlatformSelect = (value: string) => {
    const queryParamsObj = { ...queryParams };
    if (value === COMMON_SOCIAL_PROVIDER_TYPE.ALL) {
      delete queryParamsObj.platform;
    } else {
      queryParamsObj.platform = value;
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const handleReviewAnalyticsNavigation = () => {
    navigate(ROUTES.ANALYTICS_REVIEW.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
  };

  return (
    <>
      <div className={`stats review-metrics animate__animated animate__fadeIn reputation-stats repu-review-ststs-wrp global-stats-section-wrp lpx vpy-20 g-20 mbl-head--margin`}>
        <div className="stats-item total-count stats-level-03" onClick={() => handlePlatformSelect(COMMON_SOCIAL_PROVIDER_TYPE.ALL)}>
          <div className="chart-module-top">
            <span className="mod-head">TOTALS</span>
            {isStatsFetching ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <span className="mod-count">
                <ReactSpringCountUp end={reviewsCount} />
              </span>
            )}
          </div>
          <div className={reviewsCount ? `chart-container` : 'd-none'}>{isStatsFetching ? null : <ReviewsTotalCountComponent />}</div>
        </div>

        {availableReviewPlatforms?.length
          ? availableReviewPlatforms.map((it, index: number) => {
              const value = it.value === REVIEWS_PLATFORM_STATS[0].value ? platformStats.facebook : it.value === REVIEWS_PLATFORM_STATS[1].value ? platformStats.google : platformStats.yelp;
              return (
                <div
                  key={`reviews-stats-fb-${index}`}
                  className={`stats-item ${it.parentVariant} stats-level-02
                    ${queryParams.platform === it.value ? 'active' : ''}`}
                  onClick={() => handlePlatformSelect(it.value)}
                >
                  <div className="chart-module-top">
                    <span className="platform-text">{it.label}</span>
                    {isStatsFetching ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <span className="platform-count">
                        <ReactSpringCountUp end={value || 0} />
                      </span>
                    )}
                  </div>
                  <div className="platform-icon">
                    <ImageValidation defaultImg={it.image} isNotSvgFormat customName={it.label} />
                  </div>
                </div>
              );
            })
          : null}
        <div className={`stats-item chart-values stats-level-02`}>
          <div className="chart-module-top">
            <span className="platform-text">Avg Rating</span>
            {isStatsFetching ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <span className="platform-count">{typeof averageRating === 'number' ? <ReactSpringCountUp end={averageRating} round={false} /> : averageRating}</span>
            )}
          </div>
          <div className="avg-rating-chart-container">{isStatsFetching ? null : statsByDate.length ? <ReviewsAvgRatingComponent /> : null}</div>
        </div>

        <div className={`stats-item chart-values stats-level-02`}>
          <div className="chart-module-top">
            <span className="platform-text">% Replied</span>
            {isStatsFetching ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <span className="platform-count">
                {typeof repliedPercentage === 'number' ? (
                  <div className="springcount-core">
                    <ReactSpringCountUp end={repliedPercentage} />
                    <span className="percentage-text">%</span>
                  </div>
                ) : (
                  repliedPercentage
                )}
              </span>
            )}
          </div>
          <div className="replied-review-chart-container">{isStatsFetching ? null : statsByDate.length ? <ReviewsRepliedPercentageComponent /> : null}</div>
        </div>
        <div className="stats-item sicp featured-itm stats-level-03" onClick={() => handleReviewAnalyticsNavigation()}>
          <span className="mod-head">REVIEW ANALYTICS</span>
        </div>
      </div>
    </>
  );
};
