import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';

import {
  ADD_POST_INVALID_LINK_MSG,
  ALERT_BOX_TYPES,
  AP_ADD_TO_POST_TAB_NAMES,
  ADD_CUSTOMIZE_LINK_FIELDS,
  IMAGE_TO_THUMBANIL_URL_REGEX,
  MediaDropPages,
  THUMBNAIL_URL_SUFFIX,
  USER_OWNERSHIP
} from 'utils/constants';
import { AddMediaDragAndDrop } from './AddMediaDragAndDrop';
import { TextArea } from 'widgets/Text';
import { IStore, calendarApiTypes, commonModalPopup } from 'types';
import { customizeLinkValidation, convertValidUrlFromLinkText, isValidUrl } from 'utils/helpers';
import { addCustomizeLink, addPostAddLinkRequest, addPostUpdateLinkRequest, addPostUpdateSavedPostDetails, mgGetCloudResponseList, mgSetAddedAssetsCount, setCustomizeLinkEnable } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { Loading, alertBoxCall } from 'components';
import { useAccountSwitcherData } from 'utils/hooks';

export const AddCustomizeLink = ({ linkText, handleModalClose, isSubmitting, setIsSubmitting }: commonModalPopup.ICustomizeLinkProps) => {
  const dispatch = useDispatch();

  const { userOwnership } = useAccountSwitcherData();

  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const linkObj = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj);
  const uploadProgressInfo = useSelector((state: IStore) => state.mediaGallery.uploadProgressInfo);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount?.savedPostDetails);
  const localizeLinkURL = useSelector((state: IStore) => state.addPostFranchisorAction.localizeLinkURL);
  // const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);

  const { values, handleSubmit, setFieldValue, touched, errors, setTouched, resetForm, handleBlur } = useFormik({
    initialValues: {
      url: linkObj?.linkData?.url || '',
      short_url: null,
      verticals_list: '',
      tags_list: '',
      link_preview_title: linkObj?.linkData?.link_preview_title || '',
      link_preview_description: linkObj?.linkData?.link_preview_description || '',
      link_preview_image_url: linkObj?.linkData?.link_preview_image_url || '',
      link_preview_image_url_choices: linkObj?.linkData?.link_preview_image_url_choices ? linkObj?.linkData?.link_preview_image_url_choices : [],
      tracpoint_coupon_id: '',
      link_preview_customized: true,
      personalized_url: ''
    },
    validationSchema: customizeLinkValidation,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const payload = { ...values };
      const thumbnailUrl = values.link_preview_image_url.replace(IMAGE_TO_THUMBANIL_URL_REGEX, THUMBNAIL_URL_SUFFIX);
      payload.url = convertValidUrlFromLinkText(values.url);
      payload.link_preview_image_url = thumbnailUrl;
      payload.link_preview_image_url_choices = [thumbnailUrl];
      payload.personalized_url = localizeLinkURL;
      if (linkObj?.linkData?.id) {
        dispatch(addPostUpdateLinkRequest({ linkId: linkObj?.linkData?.id, reqPayload: payload }));
      } else {
        dispatch(addPostAddLinkRequest(payload));
      }
      if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
        dispatch(
          addPostUpdateSavedPostDetails(
            savedPostDetails
              ? {
                  ...savedPostDetails,
                  link_preview_customized: true
                }
              : null
          )
        );
      }
      resetForm();
      dispatch(setCustomizeLinkEnable(true));
      dispatch(addCustomizeLink(true));
      handleModalClose();
      dispatch(mgGetCloudResponseList([]));
      dispatch(mgSetAddedAssetsCount(0));
    }
  });

  const handleFieldsTouched = () => {
    const errorFields = Object.keys(errors).reduce((acc, curr) => {
      acc = {
        ...acc,
        [curr]: true
      };
      return acc;
    }, {});
    setTouched(errorFields, true);
  };

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length) {
        handleFieldsTouched();
        setIsSubmitting(false);
      } else if (isValidUrl(values.url)) {
        handleSubmit();
      } else {
        alertBoxCall(ALERT_BOX_TYPES.WARN, ADD_POST_INVALID_LINK_MSG);
      }
    }
  }, [errors, isSubmitting]); // eslint-disable-line

  useEffect(() => {
    if (response.length) {
      setFieldValue(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_IMAGE_URL, response[0]?.secureUrl);
      setFieldValue(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_IMAGE_URL_CHOICES, response[0]?.secureUrl);
    }
    if (linkText) {
      setFieldValue(ADD_CUSTOMIZE_LINK_FIELDS.URL, linkText);
    }
  }, [linkText, response[0]?.secureUrl]); // eslint-disable-line

  const getClassNames = (valueItem: keyof Omit<calendarApiTypes.ICouponLinks, 'id'>) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] !== '' && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'err-disp' : ''}`;
    return className;
  };

  const getInputBoxClassName = (valueItem: keyof Omit<calendarApiTypes.ICouponLinks, 'id'>) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'erroritem' : ''}`;
    return className;
  };

  const handleRemoveAssetsUpload = () => {
    dispatch(mgGetCloudResponseList([]));
    dispatch(mgSetAddedAssetsCount(0));
    setFieldValue(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_IMAGE_URL, '');
    setFieldValue(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_IMAGE_URL_CHOICES, []);
  };

  return (
    <>
      <div className="alc-item">
        <h6>
          Thumbnail <span className="mandateField">*</span>
        </h6>
        <div className={`media-dnd ${getInputBoxClassName('link_preview_image_url')}`}>
          <AddMediaDragAndDrop mediaName={AP_ADD_TO_POST_TAB_NAMES.PHOTOS} from={MediaDropPages.CREATOR} title={'Drag & Drop your files here to upload'} />
          {!response.length && errors[ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_IMAGE_URL] && touched[ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_IMAGE_URL] && (
            <label className="text-danger">{errors[ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_IMAGE_URL]}</label>
          )}
        </div>
        <div className="masonry-grid">
          {response.length
            ? response.map((cloudResponse, index) => {
                return !cloudResponse.cloudinaryId ? (
                  <div className="m-item mi-skeleton" key={`ap-cloudinary-without-data-${index}`}>
                    <div className="m-ast">
                      <div className="m-ast-itm m-ast-img">
                        <div className="mast-green">
                          <div className="mast-loader">
                            <Loading />
                          </div>
                          <div className="mast-skltn-cnt mg-upload-progress-bar">
                            <span className="msc-fileName">{cloudResponse.fileName}</span>
                            <span className="msc-count-percntge-load">
                              {uploadProgressInfo.percentage}% - {uploadProgressInfo.remainingTime} Sec. remaining
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null;
              })
            : null}
        </div>
        {values.link_preview_image_url ? (
          <div className="alc-upload">
            <div className="m-ast-itm m-ast-img">
              <ImageValidation imgUrl={values.link_preview_image_url} customName={'Media Asset'} />
              <div className="lb-btn lb-close" onClick={handleRemoveAssetsUpload}>
                <ImageValidation isImgValid defaultImg={'delete-bright-red'} customName={'Remove'} />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="alc-item">
        <h6>
          Title <span className="mandateField">*</span>
        </h6>
        <Form.Group className={`form-group form-field-item ${getInputBoxClassName(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_TITLE)}`}>
          <input
            name={ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_TITLE}
            type="text"
            placeholder="Title"
            value={values[ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_TITLE] as string}
            onChange={(event) => setFieldValue(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_TITLE, event.target.value)}
            className={getClassNames(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_TITLE)}
            onBlur={handleBlur}
          />
        </Form.Group>
      </div>

      <div className="alc-item alc-ta">
        <h6>Description</h6>
        <Form.Group className={`form-group form-field-item ${getInputBoxClassName(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_DESCRIPTION)}`}>
          <TextArea
            name={ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_DESCRIPTION}
            placeholder=""
            className={getClassNames(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_DESCRIPTION)}
            autoComplete="off"
            onChange={(e) => {
              setFieldValue(ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_DESCRIPTION, e.target.value);
            }}
            onBlur={handleBlur}
            value={values[ADD_CUSTOMIZE_LINK_FIELDS.LINK_PREVIEW_DESCRIPTION]?.toString() || ''}
            maxRows={4}
          />
        </Form.Group>
      </div>
    </>
  );
};
